/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import loadable from "@loadable/component";
import { ISectionMenuComponentModel } from "@bms/common";
import React from "react";
import { InView } from "react-intersection-observer";

import { ListComponentArrow } from "../ListComponent/components/ListComponentArrow";

import { SectionMenuListComponentItem } from "./components/SectionMenuListComponentItem/SectionMenuListComponentItem";
import "./SectionMenuListComponent.scss";

const Slider = loadable(() => import("react-slick"));

export interface ISectionMenuListComponentProps {
  component: ISectionMenuComponentModel;
}

export const SectionMenuListComponent = ({
  component,
}: ISectionMenuListComponentProps) => {
  const isListNotEmpty = component.Items ? component.Items.length > 0 : false;

  const renderItems = () => {
    return component.Items && isListNotEmpty
      ? component.Items.map((item) => (
          <SectionMenuListComponentItem key={item.Id} item={item} />
        ))
      : null;
  };

  const renderContent = () => {
    if (component.Items && component.Items.length < 1) {
      return null;
    }
    return (
      <div
        className="SectionMenuListComponentItem_container"
        style={{
          backgroundImage: `url(${component.BackgroundUrl}), linear-gradient(
              90deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 25%,
              rgba(63, 141, 234, 1) 35%,
              rgba(63, 141, 234, 1) 100%
            )`,
        }}
      >
        <Slider
          slidesToScroll={3}
          slidesToShow={5}
          infinite={component.Items && component.Items.length >= 6}
          speed={500}
          nextArrow={
            <ListComponentArrow
              direction="right"
              containerStyle={{ background: "rgba(0, 0, 0, 0.6 )" }}
            />
          }
          prevArrow={<ListComponentArrow direction="left" />}
          variableWidth
          responsive={[
            {
              breakpoint: 1700,
              settings: {
                slidesToScroll: 4,
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1500,
              settings: {
                slidesToScroll: 3,
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToScroll: 2,
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 700,
              settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
              },
            },
          ]}
        >
          {renderItems()}
        </Slider>
      </div>
    );
  };

  return (
    <InView
      as="div"
      className="SectionMenuListComponent"
      root={document.body}
      rootMargin="25% 0px"
      triggerOnce
    >
      {renderContent()}
    </InView>
  );
};
