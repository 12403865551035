/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { ROUTES, useIsUserLicensed } from "@bms/common";

export const LicensedRoute: React.FC<RouteProps> = (props) => {
  const isUserLicensed = useIsUserLicensed();

  return (
    <Route {...props}>
      {!isUserLicensed && (
        <Redirect
          to={{
            pathname: ROUTES.BASE,
          }}
        />
      )}
    </Route>
  );
};
