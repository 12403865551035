/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ImageHelper,
  MediaType,
  RouteHelper,
  useMediaProgress,
  useTheme,
} from "@bms/common";
import cx from "classnames";
import Color from "color";
import React, { useState } from "react";

import {
  ImageWithPlaceholder,
  MediaInfo,
  PlayIconButton,
  ProgressBar,
} from "components";
import InfoCircleIcon from "resources/icons/info-circle.svg";
import resources from "resources/list";

import { IListComponentItemProps } from "../../models";

import "./ListComponentItemCover.scss";

export type IListComponentItemCoverProps = IListComponentItemProps;

export const ListComponentItemCover = (props: IListComponentItemCoverProps) => {
  const { media, readOnly, isPlaceholder, withProgress, isActive } = props;

  const [isShowInfo, setShowInfo] = useState<boolean>(false);

  const progress = useMediaProgress(media);
  const { themeProvider } = useTheme();

  const coverImageUrl: string =
    ImageHelper.getCoverImageUrl(media?.Images) ?? "";

  const showInfo = () => setShowInfo(true);
  const hideInfo = () => setShowInfo(false);

  if (!media || isPlaceholder) {
    return <div className="ListComponentItemCover__skeleton" />;
  }

  const isAdvertisement = media.MediaTypeCode === MediaType.Advertisement;
  const isArticle = media.MediaTypeCode === MediaType.Article;
  const showOnHoverOverlay = isArticle || isAdvertisement;

  return (
    <div onMouseLeave={hideInfo} className="ListComponentItemCover">
      <div
        className={cx("ListComponentItemCover__container", {
          "ListComponentItemCover__container--active": isActive,
        })}
      >
        <div
          className="ListComponentItemCover__image-container"
          onClick={() => RouteHelper.goToDetails(media)}
          style={{
            background: `${Color(
              themeProvider.getColor("AppDetailsBackgroundColor")
            ).string()}`,
          }}
        >
          <ImageWithPlaceholder
            placeholderSrc={resources.coverPlaceholder}
            imageSrc={coverImageUrl}
            imageContainerClassName="ListComponentItemCover__image"
          />
          {withProgress && progress != -1 && (
            <ProgressBar progress={progress} />
          )}
        </div>
        {showOnHoverOverlay && (
          <div className="ListComponentItemCover__overlay" />
        )}

        {!readOnly && !isAdvertisement && (
          <>
            <PlayIconButton
              media={media}
              className="ListComponentItemCover__play-button"
              style={isShowInfo ? { opacity: 0 } : {}}
            />
            <div className="ListComponentItemCover__content">
              <i
                className="ListComponentItemCover__info-trigger"
                onClick={showInfo}
              >
                <InfoCircleIcon />
              </i>
            </div>

            {media && (
              <div
                className={cx("ListComponentItemCover__info", {
                  "ListComponentItemCover__info--visible": isShowInfo,
                })}
                style={{
                  pointerEvents: isShowInfo ? "auto" : "none",
                }}
                onMouseLeave={hideInfo}
              >
                <MediaInfo media={media} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
