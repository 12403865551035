/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export class EPGUtils {
  getShortTime(timeMillis: number) {
    const now = new Date(timeMillis);
    const minutes = now.getMinutes();
    let hour = now.getHours();
    //return dateWithouthSecond.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    let minutesText = "";
    let ampm = "AM";
    if (minutes < 15) {
      minutesText = "00";
    } else if (minutes < 45) {
      minutesText = "30";
    } else {
      minutesText = "00";
      ++hour;
    }
    if (hour > 23) {
      hour = 12;
    } else if (hour > 12) {
      hour = hour - 12;
      ampm = "PM";
    } else if (hour == 12) {
      ampm = "PM";
    } else if (hour == 0) {
      hour = 12;
    }

    return hour + ":" + minutesText + " " + ampm;
    //return dateWithouthSecond.getHours() + ":" + dateWithouthSecond.getMinutes();
  }

  getTime(timeMillis: number) {
    const now = new Date(timeMillis);
    const minutes = now.getMinutes();
    let hour = now.getHours();
    //return dateWithouthSecond.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    let minutesText = "";
    const _ampm = "AM";
    if (minutes < 15) {
      minutesText = "00";
    } else if (minutes < 45) {
      minutesText = "30";
    } else {
      minutesText = "00";
      ++hour;
    }

    return hour + ":" + minutesText;
    //return dateWithouthSecond.getHours() + ":" + dateWithouthSecond.getMinutes();
  }

  getWeekdayName(dateMillis: number) {
    const days = [
      "Niedziela",
      "Poniedziałek",
      "Wtorek",
      "Środa",
      "Czwartek",
      "Piątek",
      "Sobota",
    ];
    const date = new Date(dateMillis);
    return days[date.getDay()];
  }

  scaleBetween(
    unscaledNum: number,
    max: number,
    min = 0,
    minAllowed = 0,
    maxAllowed = 1280
  ) {
    return (
      ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) +
      minAllowed
    );
  }
}
