/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ImageHelper,
  MediaType,
  ThemeContext,
  useConfigurationSelector,
  useGetMedia,
} from "@bms/common";
import cx from "classnames";
import Color from "color";
import React, { useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router";

import { AppFooter, AppHeader, ImageOverlay } from "../../components";
import RightArrowIcon from "../../resources/icons/right-arrow.svg";
import { Splash } from "../Splash";

import {
  IMediaDetailsParams,
  useMediaDetailsLoadingSelector,
  useMediaDetailsMediaSelector,
} from ".";

import "./MediaDetailsScreen.scss";

export interface IMediaDetailsScreenProps {
  className?: string;
  children?: JSX.Element;
}

export const MediaDetailsScreen = ({
  className,
  children,
}: IMediaDetailsScreenProps) => {
  const { themeProvider } = useContext(ThemeContext);
  const configuration = useConfigurationSelector();
  const media = useMediaDetailsMediaSelector();
  const loading = useMediaDetailsLoadingSelector();
  const params = useParams<IMediaDetailsParams>();
  const history = useHistory();

  const getMedia = useGetMedia();

  useEffect(() => {
    document.body.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (params.id) {
      getMedia({
        MediaId: +params.id,
      });
    }
  }, [params.id]);

  const backgroundImageUrl = useMemo(() => {
    switch (media?.MediaTypeCode) {
      case MediaType.Podcast:
        return undefined;
      default:
        return ImageHelper.getBackgroundImageUrl(media?.Images);
    }
  }, [media]);

  if (loading && !media) {
    return <Splash />;
  }

  const isImageHorizontal = (url: string | undefined): boolean | undefined => {
    const image = new Image();
    if (url) {
      image.src = url;
      return image.width > image.height;
    }
  };

  return (
    <div className={cx("MediaDetails", className)}>
      <AppHeader configuration={configuration} />
      <section>
        <div className="MediaDetails__back" onClick={history.goBack}>
          <RightArrowIcon />
        </div>

        <div
          className="MediaDetails__background"
          style={{
            height: `${
              isImageHorizontal(backgroundImageUrl) ? "auto" : "100%"
            }`,
          }}
        >
          {backgroundImageUrl && <ImageOverlay src={backgroundImageUrl} />}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: `linear-gradient(
                to bottom,
                ${Color(themeProvider.getColor("AppBackgroundColor"))
                  .alpha(0.15)
                  .string()},
                ${Color(themeProvider.getColor("AppBackgroundColor")).string()}
              )`,
            }}
          />
        </div>

        {children}
      </section>
      <AppFooter />
    </div>
  );
};
