/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { HTTP_METHOD } from "../../../../../constants";
import {
  GetUploadFileInfoParams,
  UploadFileInfoModel,
} from "../../../../../models";
import { AxiosSubscriber } from "../../../../../services";

export class StorageService {
  public get url(): string {
    return "/Storage";
  }

  public getUploadInfo = (
    params: GetUploadFileInfoParams
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetUploadFileInfo`,
          axiosConfig: {
            // payload as query params in url
            params,
          },
        })
    );
}
