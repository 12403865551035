/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useCallback, useEffect, useRef, useState } from "react";
import { StorageHelper } from "../../helpers/storageHelper";
import { IMediaModel, IUserAssetPropertiesModel } from "../../models";

export const useMediaProgress = (media?: IMediaModel) => {
  const isMounted = useRef(false);
  const [progress, setProgress] = useState(-1);

  const getProgress = useCallback(
    (userAssetProperties: IUserAssetPropertiesModel[]) => {
      if (media?.Id && media?.Duration && userAssetProperties) {
        const mediaProperties = userAssetProperties.find(
          (row) => row.AssetId === media?.Id
        );

        if (mediaProperties?.Timestamp && media.Duration) {
          const { Hour, Minute, Second } = mediaProperties.Timestamp;
          const progressInMiliseconds =
            ((Hour ?? 0) * 3600 + (Minute ?? 0) * 60 + (Second ?? 0)) * 1000;

          return (
            (progressInMiliseconds /
              (Math.floor(media.Duration / 1000) * 1000)) *
            100
          );
        }
      }

      return -1;
    },
    [media]
  );

  const calcProgress = useCallback(async (): Promise<number> => {
    if (media?.Id && media?.Duration) {
      const userAssetProperties = await StorageHelper.getUserAssetsProperties();
      const progress = getProgress(userAssetProperties);

      return Promise.resolve(progress);
    }

    return Promise.resolve(-1);
  }, [media]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const subscription = StorageHelper.userAssetProperties$.subscribe(
      (value: IUserAssetPropertiesModel[]) => {
        if (isMounted.current) {
          const progress = getProgress(value);
          setProgress(progress);
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [media]);

  useEffect(() => {
    calcProgress()
      .then((progress) => {
        if (isMounted.current) {
          setProgress(progress);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [calcProgress, media]);

  return progress;
};
