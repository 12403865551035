/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  EpgConsts,
  EPGData,
  EPGEvent,
  EPGUtils,
  IEpgComponentModel,
  IMediaListModel,
  IMediaModel,
  MediaDayTimeFilter,
  ROUTES,
  ThemeContext,
  TimeHelper,
} from "@bms/common";
import dayjs from "dayjs";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import { MediaButton } from "../..";
import resources from "../../../resources/list";
import { Clock } from "../../Clock";
import { ImageRatio, ImageWithRatio } from "../../ImageWithRatio";
import { LoaderSpinner } from "../../LoaderSpinner";
import "./EpgComponent.scss";

export interface IEpgComponentStateProps {
  source?: IMediaListModel;
}

export interface IEpgComponentDispatchProps {
  changeRoute?: ActionCreator<Action>;
  getMediaListForEpg?: ActionCreator<Action>;
  getMediaListForEpgCancel?: ActionCreator<Action>;
}

export interface IEpgComponentOwnProps {
  component: IEpgComponentModel;
}

export interface IEpgComponentProps
  extends IEpgComponentStateProps,
    IEpgComponentDispatchProps,
    IEpgComponentOwnProps,
    WithTranslation {}

export interface IEpgComponentState {
  isActive: boolean;
}

export class EpgComponent extends React.Component<
  IEpgComponentProps,
  IEpgComponentState
> {
  public state: IEpgComponentState = {
    isActive: false,
  };

  static contextType = ThemeContext;
  static d = dayjs;

  epgData = new EPGData();
  epgUtils = new EPGUtils();

  epgRef: HTMLDivElement | null = null;

  scrollX = 0;
  scrollY = 0;
  focusedChannelPosition = 0;
  focusedEventPosition = -1;
  //state = {translate3d : `translate3d(${this.scrollX}px, 0px, 0px)`};
  //translate3d = `translate3d(${this.scrollX}px, 0px, 0px)`;

  mChannelImageCache = new Map();

  fontName = "Arial";

  mEPGBackground = "#1e1e1e";
  mVisibleChannelCount = EpgConsts.VISIBLE_CHANNEL_COUNT;
  mChannelLayoutMargin = 3;
  mChannelLayoutPadding = 8;
  mChannelLayoutHeight = 80;
  mChannelLayoutWidth = 430; // 70;
  mChannelLayoutBackground = "#234054"; //'#323232';

  //mEventLayoutBackground = '#4f4f4f';
  mEventLayoutBackground = "#234054";
  //mEventLayoutBackgroundCurrent = '#4f4f4f';
  mEventLayoutBackgroundCurrent = "#999999";
  mEventLayoutBackgroundFocus = "rgba(65,182,230,1)";
  mEventLayoutTextColor = "#d6d6d6";
  mEventLayoutTextSize = 20;
  mEventPadding = 100;
  mEventFocusInfoHeight = 230;

  mTimeBarHeight = 68;
  mTimeBarTextSize = 32;
  mTimeBarLineWidth = 4;
  mTimeBarLineColor = "#c57120";
  mTimeNowIndicatorHeight = this.mChannelLayoutHeight / 2;
  timeBarBackgroundColor = "#0c1026";

  mResetButtonSize = 40;
  mResetButtonMargin = 10;

  mMillisPerPixel = 0;
  mTimeOffset = 0;
  mTimeLowerBoundary = 0;
  mTimeUpperBoundary = 0;

  mMaxHorizontalScroll = 0;
  mMaxVerticalScroll = 0;

  scroller?: Element;
  focusedEvent?: EPGEvent;
  width = 0;

  constructor(props: IEpgComponentProps) {
    super(props);
    // this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  resetBoundaries() {
    this.mMillisPerPixel = this.calculateMillisPerPixel();
    this.mTimeOffset = this.calculatedBaseLine();
    this.mTimeLowerBoundary = this.getTimeFrom(0);
    this.mTimeUpperBoundary = this.getTimeFrom(this.getWidth());
  }

  calculateMaxHorizontalScroll() {
    this.mMaxHorizontalScroll =
      (EpgConsts.DAYS_BACK_MILLIS +
        EpgConsts.DAYS_FORWARD_MILLIS -
        EpgConsts.HOURS_IN_VIEWPORT_MILLIS) /
      this.mMillisPerPixel;
  }

  calculateMaxVerticalScroll() {
    const maxVerticalScroll =
      this.getTopFrom(this.epgData.getChannelCount() - 2) +
      this.mChannelLayoutHeight;
    this.mMaxVerticalScroll =
      maxVerticalScroll < this.getHeight()
        ? 0
        : maxVerticalScroll - this.getHeight();
  }

  calculateMillisPerPixel() {
    return EpgConsts.HOURS_IN_VIEWPORT_MILLIS / this.getBoundaryWidth();
  }

  calculatedBaseLine() {
    //return LocalDateTime.now().toDateTime().minusMillis(DAYS_BACK_MILLIS).getMillis();
    return Date.now() - EpgConsts.DAYS_BACK_MILLIS;
  }

  getProgramPosition(channelPosition: number, time: number) {
    const events = this.epgData.getEvents(channelPosition);

    if (events != null) {
      for (let eventPos = 0; eventPos < events.length; eventPos++) {
        const event = events[eventPos];
        if (event.getStart() <= time && event.getEnd() >= time) {
          return eventPos;
        }
      }
    }

    return -1;
  }

  getFirstVisibleChannelPosition() {
    const y = this.getScrollY(false);

    let position = Math.floor(
      (y - this.mChannelLayoutMargin - this.mTimeBarHeight) /
        (this.mChannelLayoutHeight + this.mChannelLayoutMargin)
    );

    if (position < 0) {
      position = 0;
    }
    return position;
  }

  getLastVisibleChannelPosition() {
    const y = this.getScrollY(false);
    const totalChannelCount = this.epgData.getChannelCount();
    const screenHeight = this.getHeight();
    let position = Math.floor(
      (y + screenHeight + this.mTimeBarHeight - this.mChannelLayoutMargin) /
        (this.mChannelLayoutHeight + this.mChannelLayoutMargin)
    );

    if (position > totalChannelCount - 1) {
      position = totalChannelCount - 1;
    }

    // Add one extra row if we don't fill screen with current..
    return y + screenHeight > position * this.mChannelLayoutHeight &&
      position < totalChannelCount - 1
      ? position + 1
      : position;
  }

  getXFrom(time: number) {
    return (time - this.mTimeLowerBoundary) / this.mMillisPerPixel;
  }

  getTopFrom(position: number) {
    const y =
      position * (this.mChannelLayoutHeight + this.mChannelLayoutMargin) +
      this.mChannelLayoutMargin +
      this.mTimeBarHeight;
    return y - this.getScrollY(false);
  }

  getStartTime(): number {
    const time =
      EpgConsts.TIME_LABEL_SPACING_MILLIS *
        Math.floor(
          (Date.now() - EpgConsts.TIME_LABEL_SPACING_MILLIS) /
            EpgConsts.TIME_LABEL_SPACING_MILLIS
        ) -
      EpgConsts.TIME_LABEL_SPACING_MILLIS / 2;

    return time;
  }

  getStartDate(): Date {
    return TimeHelper.getDate(this.getStartTime());
  }

  getXPositionStart() {
    return this.getXFrom(this.getStartTime());
    //return this.getXFrom(Date.now() - (Epg.HOURS_IN_VIEWPORT_MILLIS / 2));
  }

  getTimeFrom(x: number) {
    return x * this.mMillisPerPixel + this.mTimeOffset;
  }

  shouldDrawTimeLine(now: number) {
    return now >= this.mTimeLowerBoundary && now < this.mTimeUpperBoundary;
  }

  isEventVisible(start: number, end: number) {
    return (
      (start >= this.mTimeLowerBoundary && start <= this.mTimeUpperBoundary) ||
      (end >= this.mTimeLowerBoundary && end <= this.mTimeUpperBoundary) ||
      (start <= this.mTimeLowerBoundary && end >= this.mTimeUpperBoundary)
    );
  }

  getFocusedChannelPosition() {
    return this.focusedChannelPosition;
  }

  getFocusedEventPosition() {
    return this.focusedEventPosition;
  }

  isRTL() {
    return false;
  }

  getScrollX(neglect = true) {
    if (neglect) {
      return 0;
    }

    return this.scrollX;
    //return window.scrollX;
  }

  getScrollY(neglect = true) {
    if (neglect) {
      return 0;
    }

    return this.scrollY;
    //return window.scrollY;
  }

  getWidth() {
    return this.width;
  }

  getBoundaryWidth() {
    return (
      this.getWidth() - this.mChannelLayoutWidth - this.mChannelLayoutMargin
    );
  }

  getHeight() {
    return (
      this.mTimeBarHeight +
      (this.mChannelLayoutMargin + this.mChannelLayoutHeight) *
        EpgConsts.VISIBLE_CHANNEL_COUNT
    );
  }

  scrollToTime(time: number) {
    return (time - this.getTimeFrom(0)) / this.mMillisPerPixel;
  }

  scrollToStart() {
    this.focusedEventPosition = -1;
    this.recalculateAndRedraw();
    this.forceUpdate();
  }

  onDraw() {
    if (this.epgData != null && this.epgData.hasData()) {
      this.mTimeLowerBoundary = this.getTimeFrom(this.getScrollX(false));
      this.mTimeUpperBoundary = this.getTimeFrom(
        this.getScrollX(false) + this.getBoundaryWidth()
      );

      return (
        <div
          style={{
            position: "relative",
            width: `${this.getWidth()}px`,
            height: `${this.getHeight() + this.mEventFocusInfoHeight}px`,
          }}
        >
          {this.drawChannelListItems()}
          {this.drawEvents()}
          {this.drawTimebar()}
          {this.drawTimebarDayIndicator()}
          {this.drawTimebarBottomStroke()}
          {this.drawTimeLine()}
          {this.drawFocusEvent()}
        </div>
      );
    }
  }

  drawTimebar() {
    const times = [];

    for (
      let i = 0;
      i <
      EpgConsts.HOURS_IN_VIEWPORT_MILLIS / EpgConsts.TIME_LABEL_SPACING_MILLIS;
      i++
    ) {
      // Get time and round to nearest half hour
      const time =
        EpgConsts.TIME_LABEL_SPACING_MILLIS *
        Math.floor(
          (this.mTimeLowerBoundary +
            EpgConsts.TIME_LABEL_SPACING_MILLIS * i +
            EpgConsts.TIME_LABEL_SPACING_MILLIS / 2) /
            EpgConsts.TIME_LABEL_SPACING_MILLIS
        );

      times.push(
        <div
          key={i}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            left: this.getXFrom(time),
            top: 0,
            height: `${this.mTimeBarHeight}px`,
          }}
        >
          <span
            style={{
              color: this.mEventLayoutTextColor,
              fontSize: this.mTimeBarTextSize,
              fontFamily: this.fontName,
            }}
          >
            {this.epgUtils.getTime(time)}
          </span>
        </div>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left:
            this.getScrollX() +
            this.mChannelLayoutWidth +
            this.mChannelLayoutMargin,
          top: this.getScrollY(),
          width: `${this.getBoundaryWidth()}px`,
          height: `${this.mTimeBarHeight}px`,
          backgroundColor: this.timeBarBackgroundColor,
        }}
      >
        {times}
      </div>
    );
  }

  drawTimebarDayIndicator() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: this.getScrollX(),
          top: this.getScrollY(),
          width: `${this.mChannelLayoutWidth}px`,
          height: `${this.mTimeBarHeight}px`,
          backgroundColor: this.timeBarBackgroundColor,
        }}
      >
        <span
          style={{
            textAlign: "center",
            fontSize: this.mTimeBarTextSize,
            fontFamily: this.fontName,
            color: this.mEventLayoutTextColor,
          }}
        >
          {this.epgUtils.getWeekdayName(this.mTimeLowerBoundary)}
        </span>
      </div>
    );
  }

  drawTimebarBottomStroke() {
    return (
      <div
        style={{
          position: "absolute",
          left: this.getScrollX(),
          top: this.getScrollY() + this.mTimeBarHeight,
          width: `${this.getWidth()}px`,
          height: `${this.mChannelLayoutMargin}px`,
          backgroundColor: this.mEPGBackground,
        }}
      />
    );
  }

  drawTimeLine() {
    const now = Date.now();

    if (this.shouldDrawTimeLine(now)) {
      const arrowSize = 8;
      return (
        <div
          style={{
            position: "absolute",
            left:
              this.getXFrom(now) +
              this.mChannelLayoutWidth +
              this.mChannelLayoutMargin,
            top: this.getScrollY() + this.mTimeBarHeight,
            width: `${this.mTimeBarLineWidth}px`,
            height: `${this.getHeight()}px`,
            backgroundColor: this.mTimeBarLineColor,
          }}
        >
          <div
            style={{
              position: "absolute",
              left: -arrowSize + this.mTimeBarLineWidth / 2,
              top: -arrowSize - 2,
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderTopWidth: arrowSize,
              borderBottomWidth: arrowSize,
              borderRightWidth: arrowSize,
              borderLeftWidth: arrowSize,
              borderTopColor: this.mTimeBarLineColor,
              borderBottomColor: "transparent",
              borderLeftColor: "transparent",
              borderRightColor: "transparent",
            }}
          />
        </div>
      );
    }

    const top =
      this.getScrollY() +
      this.mTimeBarHeight +
      this.mChannelLayoutMargin -
      Math.floor(this.mTimeNowIndicatorHeight / 2);
    let left;
    let right;
    let arrowLeft;
    let arrowRight;
    const text = "Teraz";

    if (this.mTimeLowerBoundary > now) {
      // Display "NOW" on left hand side
      left =
        this.getXFrom(this.mTimeLowerBoundary) +
        this.mChannelLayoutWidth +
        this.mChannelLayoutMargin +
        11;
      arrowLeft = -11;
    } else if (this.mTimeUpperBoundary < now) {
      // Display "NOW" on right hand side
      right = 10;
      arrowRight = -11;
    }

    return (
      <div
        style={{
          display: "flex",
          position: "absolute",
          top,
          left,
          right,
          height: `${this.mTimeNowIndicatorHeight}px`,
          backgroundColor: this.mTimeBarLineColor,
          justifyContent: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: arrowLeft,
            right: arrowRight,
            top: "50%",
            transform: "translateY(-50%)",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderTopWidth: 10,
            borderBottomWidth: 10,
            borderRightWidth: arrowLeft && 11,
            borderLeftWidth: arrowRight && 11,
            borderTopColor: "transparent",
            borderBottomColor: "transparent",
            borderLeftColor: arrowRight
              ? this.mTimeBarLineColor
              : "transparent",
            borderRightColor: arrowLeft
              ? this.mTimeBarLineColor
              : "transparent",
          }}
        />

        <span
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: this.mTimeBarTextSize * 0.7,
            fontFamily: this.fontName,
            color: this.mEventLayoutTextColor,
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {text}
        </span>
      </div>
    );
  }

  drawEvents() {
    const firstPos = this.getFirstVisibleChannelPosition();
    const lastPos = this.getLastVisibleChannelPosition();
    const channels = [];

    for (let pos = firstPos; pos <= lastPos; pos++) {
      const epgEvents = this.epgData.getEvents(pos);
      const events = [];
      // Draw each event
      let foundFirst = false;

      for (const event of epgEvents) {
        if (this.isEventVisible(event.getStart(), event.getEnd())) {
          events.push(this.drawEvent(pos, event));
          foundFirst = true;
        } else if (foundFirst) {
          break;
        }
      }

      channels.push(
        <div
          key={pos}
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            left: 0,
            top: this.getTopFrom(pos),
            width: `${this.getScrollX() + this.getBoundaryWidth()}px`,
            height: `${this.mChannelLayoutHeight}px`,
            overflow: "hidden",
          }}
        >
          {events}
        </div>
      );
    }

    return (
      <div
        style={{
          position: "absolute",
          left:
            this.getScrollX() +
            this.mChannelLayoutWidth +
            this.mChannelLayoutMargin,
          top: this.getScrollY(),
          width: `${this.getScrollX() + this.getBoundaryWidth()}px`,
          height: `${this.getHeight()}px`,
          overflow: "hidden",
        }}
      >
        {channels}
      </div>
    );
  }

  // textEllipsis(c: CanvasRenderingContext2D, str: string, maxWidth: number) {
  //   let width = c.measureText(str).width;
  //   const ellipsis = '...';
  //   const ellipsisWidth = c.measureText(ellipsis).width;
  //   if (width <= maxWidth || width <= ellipsisWidth) {
  //     return str;
  //   } else {
  //     let len = str.length;
  //     while (width >= maxWidth - ellipsisWidth && len-- > 0) {
  //       str = str.substring(0, len);
  //       width = c.measureText(str).width;
  //     }
  //     return str + ellipsis;
  //   }
  // }

  drawEvent(channelPosition: number, event: EPGEvent) {
    let backgroundColor = this.mEventLayoutBackground;
    const left = this.getXFrom(event.getStart());
    const width =
      this.getXFrom(event.getEnd()) -
      this.getXFrom(event.getStart()) -
      this.mChannelLayoutMargin;
    let opacity = 1;
    let isFocused = false;

    if (channelPosition == this.getFocusedChannelPosition()) {
      const focusedEventPosition = this.getFocusedEventPosition();
      if (focusedEventPosition != -1) {
        const focusedEvent = this.epgData.getEvent(
          channelPosition,
          focusedEventPosition
        );
        if (focusedEvent == event) {
          backgroundColor = this.mEventLayoutBackgroundFocus;
          isFocused = true;
          opacity = this.state.isActive ? 1 : 0.6;
        }
      } else if (event.isCurrent()) {
        this.focusedEventPosition = this.epgData.getEventPosition(
          channelPosition,
          event
        );
        this.focusedEvent = event;
        backgroundColor = this.mEventLayoutBackgroundFocus;
        isFocused = true;
      }
    }

    return (
      <div
        key={event.getId()}
        style={{
          display: "flex",
          position: "absolute",
          top: 0,
          left: left,
          width: `${width}px`,
          height: `${this.mChannelLayoutHeight}px`,
          backgroundColor: backgroundColor,
          opacity: opacity,
          paddingLeft: `${this.mChannelLayoutPadding}px`,
          paddingRight: `${this.mChannelLayoutPadding}px`,
        }}
      >
        {event.isCurrent() && !isFocused && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: `${
                this.getXFrom(Date.now()) - this.getXFrom(event.getStart())
              }px`,
              height: `${this.mChannelLayoutHeight}px`,
              backgroundColor: this.mEventLayoutBackgroundCurrent,
              opacity: 0.6,
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: this.mEventLayoutTextColor,
            fontSize: this.mTimeBarTextSize,
            fontFamily: this.fontName,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {event.getTitle()}
        </div>
      </div>
    );
  }

  drawFocusEvent() {
    let info;
    let image;

    if (this.focusedEvent) {
      info = this.focusedEvent.isCurrent()
        ? "Teraz"
        : `${this.epgUtils.getWeekdayName(this.focusedEvent.getStart())}`;
      image = this.focusedEvent.getImage();
      const availableFrom = dayjs(this.focusedEvent.getStart(), {
        locale: "pl",
      });
      const availableTo = dayjs(this.focusedEvent.getEnd(), { locale: "pl" });

      if (!this.focusedEvent.isCurrent()) {
        info += `, ${availableFrom.format("D")} ${availableTo.format("MMMM")}`;
      }

      info += `, ${availableFrom.format("HH:mm")} - ${availableTo.format(
        "HH:mm"
      )}`;
    }

    return (
      <div
        style={{
          position: "absolute",
          left: this.getScrollX(),
          top: this.getScrollY() + this.getHeight() + this.mChannelLayoutMargin,
          width: `${this.getWidth()}px`,
          height: `${this.mEventFocusInfoHeight}px`,
        }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            left: 0,
            top: 0,
            width: `${this.mChannelLayoutWidth}px`,
            height: `${this.mEventFocusInfoHeight}px`,
            backgroundColor: this.mChannelLayoutBackground,
            alignItems: "center",
          }}
        >
          <img
            src={image ? image : resources.framePlaceholder}
            style={{
              width: `${this.mChannelLayoutWidth}px`,
              height: `${this.mEventFocusInfoHeight}px`,
            }}
          />
        </div>

        <div
          style={{
            position: "absolute",
            left: this.mChannelLayoutWidth + this.mChannelLayoutMargin,
            top: 0,
            width: `${this.getBoundaryWidth()}px`,
            height: `${this.mEventFocusInfoHeight}px`,
            backgroundColor: this.mChannelLayoutBackground,
          }}
        >
          {this.focusedEvent && (
            <div
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <div
                style={{
                  color: this.mEventLayoutTextColor,
                  fontSize: this.mTimeBarTextSize * 1.2,
                  fontFamily: this.fontName,
                  paddingTop: "15px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {this.focusedEvent.getTitle()}
              </div>

              <div
                style={{
                  color: this.mEventLayoutTextColor,
                  fontSize: this.mTimeBarTextSize * 0.8,
                  fontFamily: this.fontName,
                  paddingTop: "10px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {info}
              </div>

              <div
                style={{
                  color: "#999999",
                  fontSize: this.mTimeBarTextSize * 0.8,
                  fontFamily: this.fontName,
                  paddingTop: "10px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {this.focusedEvent.getDescription()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  drawChannelListItems() {
    const firstPos = this.getFirstVisibleChannelPosition();
    const lastPos = this.getLastVisibleChannelPosition();
    const channelItems = [];

    for (let pos = firstPos; pos <= lastPos; pos++) {
      channelItems.push(this.drawChannelItem(pos));
    }

    return (
      <div
        style={{
          position: "absolute",
          top: this.getScrollY(),
          left: this.getScrollX(),
          width: `${this.mChannelLayoutWidth}px`,
          height: `${this.getHeight()}px`,
          overflow: "hidden",
          backgroundColor: this.timeBarBackgroundColor,
        }}
      >
        {channelItems}
      </div>
    );
  }

  drawChannelItem(position: number) {
    const channel = this.epgData.getChannel(position);
    const id = channel.getChannelID();
    const title = channel.getName();
    const image = channel.getImageURL();

    return (
      <div
        key={id}
        style={{
          display: "flex",
          position: "absolute",
          top: this.getTopFrom(position),
          left: this.getScrollX(),
          width: `${this.mChannelLayoutWidth}px`,
          height: `${this.mChannelLayoutHeight}px`,
          backgroundColor: this.mChannelLayoutBackground,
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: this.mEventLayoutTextColor,
              fontSize: this.mTimeBarTextSize,
              fontFamily: this.fontName,
              paddingLeft: "15px",
            }}
          >
            {position + 1}
          </span>
          <span
            style={{
              color: this.mEventLayoutTextColor,
              fontSize: this.mTimeBarTextSize,
              fontFamily: this.fontName,
              paddingLeft: "15px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {title}
          </span>
          {!!image && (
            <div
              style={{
                marginLeft: "auto",
                marginRight: "10px",
              }}
            >
              <ImageWithRatio
                imageSrc={image}
                width={this.mChannelLayoutHeight}
                ratio={ImageRatio["16:9"]}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  // handleClick(event: EPGEvent) {
  //   this.scrollX = this.getScrollX(false) + EpgConsts.TIME_LABEL_SPACING_MILLIS / this.mMillisPerPixel;
  //   //this.scroller.scrollTo(this.scrollX, this.scrollY);
  //   //window.scrollTo(this.scrollX, this.scrollY);
  //
  //   if (this.ctx) {
  //     //this.ctx.fillStyle = 'red';
  //     this.ctx.clearRect(0, 0, this.getWidth(), this.getHeight());
  //     this.clear();
  //     this.onDraw(this.ctx);
  //     //this.updateCanvas();
  //   }
  // }

  recalculateAndRedraw() {
    if (this.epgData != null && this.epgData.hasData()) {
      this.resetBoundaries();

      this.calculateMaxVerticalScroll();
      this.calculateMaxHorizontalScroll();

      this.scrollX =
        this.getScrollX() + this.getXPositionStart() - this.getScrollX();
      this.scrollY = this.getScrollY();
    }
  }

  handleScroll() {
    console.log("scrolling...");
  }

  handleRightPress() {
    const { source, getMediaListForEpg, component } = this.props;

    let programPosition = this.getFocusedEventPosition();
    //let programPosition = this.getProgramPosition(this.getFocusedChannelPosition(), this.getTimeFrom(this.getScrollX(false) ));
    programPosition += 1;

    if (
      programPosition != -1 &&
      programPosition <
        this.epgData.getEventCount(this.getFocusedChannelPosition())
    ) {
      this.focusedEvent = this.epgData.getEvent(
        this.getFocusedChannelPosition(),
        programPosition
      );

      if (this.focusedEvent) {
        this.focusedEventPosition = programPosition;

        this.scrollX =
          this.scrollToTime(this.focusedEvent.getStart()) -
          this.getBoundaryWidth() / 3;

        this.forceUpdate();
      }

      // Check source range
      const currentDate = TimeHelper.getDate(Date.now());
      const lowerDate = TimeHelper.getDate(this.mTimeLowerBoundary);
      const upperDate = TimeHelper.getDate(this.mTimeUpperBoundary);
      const currentBoundaryDiff = TimeHelper.diff(upperDate, lowerDate);
      const currentDiff = TimeHelper.diff(upperDate, currentDate);

      if (currentBoundaryDiff > 0 && currentDiff < EpgConsts.DAYS_FORWARD) {
        const nextSourceDate = TimeHelper.getDateWithOffset(
          upperDate,
          1,
          "day"
        );
        const nextSourceKey = TimeHelper.getDateKey(nextSourceDate);

        if (
          source &&
          source.Sources &&
          getMediaListForEpg &&
          !source.Sources[nextSourceKey]
        ) {
          getMediaListForEpg(component.SourceId, {
            DateFrom: nextSourceDate,
            DayTime: MediaDayTimeFilter.AllDay,
          });
        }
      }

      return true;
    }

    return false;
  }

  handleLeftPress() {
    const { source, getMediaListForEpg, component } = this.props;

    let programPosition = this.getFocusedEventPosition();
    programPosition -= 1;

    if (programPosition != -1 && programPosition > -1) {
      this.focusedEvent = this.epgData.getEvent(
        this.getFocusedChannelPosition(),
        programPosition
      );

      if (this.focusedEvent) {
        this.focusedEventPosition = programPosition;

        this.scrollX =
          this.scrollToTime(this.focusedEvent.getStart()) -
          this.getBoundaryWidth() / 3;

        this.forceUpdate();
      }

      // Check source range
      const currentDate = TimeHelper.getDate(Date.now());
      const lowerDate = TimeHelper.getDate(this.mTimeLowerBoundary);
      const upperDate = TimeHelper.getDate(this.mTimeUpperBoundary);
      const currentBoundaryDiff = TimeHelper.diff(upperDate, lowerDate);
      const currentDiff = TimeHelper.diff(currentDate, lowerDate);

      if (currentBoundaryDiff > 0 && currentDiff < EpgConsts.DAYS_BACK) {
        const nextSourceDate = TimeHelper.getDateWithOffset(
          lowerDate,
          -1,
          "day"
        );
        const nextSourceKey = TimeHelper.getDateKey(nextSourceDate);

        if (
          source &&
          source.Sources &&
          getMediaListForEpg &&
          !source.Sources[nextSourceKey]
        ) {
          getMediaListForEpg(component.SourceId, {
            DateFrom: nextSourceDate,
            DayTime: MediaDayTimeFilter.AllDay,
          });
        }
      }

      return true;
    }

    return false;
  }

  handleDownPress() {
    const currenChannelPosition = this.getFocusedChannelPosition();
    const currentProgramPosition = this.getFocusedEventPosition();
    const channelPosition = currenChannelPosition + 1;
    let dy = 0;

    if (channelPosition < this.epgData.getChannelCount()) {
      dy = this.mChannelLayoutHeight + this.mChannelLayoutMargin;

      let currentEvent = this.epgData.getEvent(
        currenChannelPosition,
        currentProgramPosition
      );
      const time = currentEvent
        ? currentEvent.isCurrent()
          ? Date.now()
          : currentEvent.getStart()
        : this.getTimeFrom(this.getScrollX(false) + this.getWidth() / 2);

      this.focusedEventPosition = this.getProgramPosition(
        channelPosition,
        time
      );

      if (
        channelPosition >
        EpgConsts.VISIBLE_CHANNEL_COUNT -
          EpgConsts.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM
      ) {
        if (channelPosition < this.epgData.getChannelCount() - 1) {
          this.scrollY = this.getScrollY(false) + dy;
        }
      }

      currentEvent = this.epgData.getEvent(
        channelPosition,
        this.focusedEventPosition
      );

      if (currentEvent) {
        this.scrollX =
          this.scrollToTime(currentEvent.getStart()) -
          (this.getWidth() -
            this.mChannelLayoutWidth -
            this.mChannelLayoutMargin) /
            3;
      }

      this.focusedChannelPosition = channelPosition;
      this.focusedEvent = this.epgData.getEvent(
        channelPosition,
        this.focusedEventPosition
      );

      this.forceUpdate();
      return true;
    }

    return false;
  }

  handleUpPress() {
    const currenChannelPosition = this.getFocusedChannelPosition();
    const currentProgramPosition = this.getFocusedEventPosition();
    const channelPosition = currenChannelPosition - 1;
    let dy = 0;

    if (channelPosition >= 0) {
      dy = -1 * (this.mChannelLayoutHeight + this.mChannelLayoutMargin);

      let currentEvent = this.epgData.getEvent(
        currenChannelPosition,
        currentProgramPosition
      );
      const time = currentEvent
        ? currentEvent.isCurrent()
          ? Date.now()
          : currentEvent.getStart()
        : this.getTimeFrom(this.getScrollX(false) + this.getWidth() / 2);

      this.focusedEventPosition = this.getProgramPosition(
        channelPosition,
        time
      );

      if (
        channelPosition >=
        EpgConsts.VISIBLE_CHANNEL_COUNT -
          EpgConsts.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM
      ) {
        if (
          this.epgData.getChannelCount() - channelPosition !=
          EpgConsts.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM
        ) {
          this.scrollY = this.getScrollY(false) + dy;
        }
      }

      currentEvent = this.epgData.getEvent(
        channelPosition,
        this.focusedEventPosition
      );

      if (currentEvent) {
        this.scrollX =
          this.scrollToTime(currentEvent.getStart()) -
          (this.getWidth() -
            this.mChannelLayoutWidth -
            this.mChannelLayoutMargin) /
            3;
      }

      this.focusedChannelPosition = channelPosition;
      this.focusedEvent = this.epgData.getEvent(
        channelPosition,
        this.focusedEventPosition
      );

      this.forceUpdate();
      return true;
    }

    return false;
  }

  handleEnterPress() {
    // TODO Use RouteHelper
    if (this.focusedEvent && this.props.changeRoute) {
      this.props.changeRoute(
        `${ROUTES.MOVIE_DETAILS_SCREEN}/${this.focusedEvent.getId()}`
      );

      return true;
    }

    return false;
  }

  handleKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    let stopPropagation = false;
    const keyCode = event.keyCode;

    /*keyCode = this.isRTL() && (keyCode == 39) ? 37 : 39;
   keyCode = this.isRTL() && (keyCode == 37) ? 39 : 37;*/

    switch (keyCode) {
      case 39:
        stopPropagation = this.handleRightPress();
        break;
      case 37:
        stopPropagation = this.handleLeftPress();
        break;
      case 40:
        stopPropagation = this.handleDownPress();
        break;
      case 38:
        stopPropagation = this.handleUpPress();
        break;
      case 13:
        stopPropagation = this.handleEnterPress();
        break;
    }

    if (stopPropagation) {
      event.preventDefault();
      event.stopPropagation();

      return false;
    }
  }

  componentDidMount() {
    const themeProvider = this.context.themeProvider;
    const branding = themeProvider.getBranding();
    this.width =
      document.body.clientWidth -
      branding.AppPaddingLeft -
      branding.AppPaddingRight;

    // this.recalculateAndRedraw(false);
    this.focusEPG();
    this.fetchEPGData();
  }

  fetchEPGData() {
    const { source, getMediaListForEpg, component } = this.props;

    if ((!source || !source.Sources) && getMediaListForEpg) {
      const startDate = this.getStartDate();

      // Get data for current day
      getMediaListForEpg(component.SourceId, {
        DateFrom: startDate,
        DayTime: MediaDayTimeFilter.AllDay,
      });

      // Get data for next day
      getMediaListForEpg(component.SourceId, {
        DateFrom: TimeHelper.getDateWithOffset(startDate, 1, "day"),
        DayTime: MediaDayTimeFilter.AllDay,
      });

      // Get data for previos day
      getMediaListForEpg(component.SourceId, {
        DateFrom: TimeHelper.getDateWithOffset(startDate, -1, "day"),
        DayTime: MediaDayTimeFilter.AllDay,
      });
    } else {
      this.updateEpgData(source);
    }
  }

  updateEpgData(source?: IMediaListModel) {
    if (!source || !source.Sources) {
      return;
    }

    const data = Object.values(source.Sources);
    const channels = data.flatMap((source) => {
      if (source.Entities) {
        return source.Entities;
      }
      return [];
    });

    const epgChannels: IMediaModel[] = channels.reduce(
      (epgChannels: IMediaModel[], channel) => {
        const epgChannel = epgChannels.find(
          (epgChannel) => epgChannel.Id === channel.Id
        );

        if (epgChannel && epgChannel.Media && channel.Media) {
          epgChannel.Media = epgChannel.Media.concat(channel.Media);
        } else {
          epgChannels.push(channel);
        }

        return epgChannels;
      },
      []
    );

    for (const channel of epgChannels) {
      const assets = [];
      const map = new Map();

      if (!channel.Media) {
        continue;
      }

      // Distinct channel assets
      for (const asset of channel.Media) {
        if (!map.has(asset.Id)) {
          map.set(asset.Id, true);
          assets.push(asset);
        }
      }

      // Sort channel assets
      channel.Media = assets.sort((asset1: IMediaModel, asset2: IMediaModel) =>
        TimeHelper.compare(
          asset1.StartDateTime || "1970-01-01",
          asset2.StartDateTime || "1970-01-01"
        )
      );
    }

    this.epgData = new EPGData(epgChannels);

    if (this.focusedEvent) {
      this.focusedEventPosition = this.epgData.getEventPosition(
        this.focusedChannelPosition,
        this.focusedEvent
      );
    }

    if (this.epgData != null && this.epgData.hasData()) {
      this.recalculateAndRedraw();

      this.calculateMaxVerticalScroll();
      this.calculateMaxHorizontalScroll();

      if (this.focusedEvent) {
        this.scrollX =
          this.scrollToTime(this.focusedEvent.getStart()) -
          (this.getWidth() -
            this.mChannelLayoutWidth -
            this.mChannelLayoutMargin) /
            3;
      } else {
        this.scrollX =
          this.getScrollX() + this.getXPositionStart() - this.getScrollX();
        this.scrollY = this.getScrollY();
      }
    }
  }

  componentDidUpdate(prevProps: IEpgComponentProps) {
    if (
      prevProps.source !== this.props.source &&
      this.props.source &&
      this.props.source.Sources
    ) {
      this.updateEpgData(this.props.source);
      this.forceUpdate();
    }
  }

  focusEPG() {
    const element = this.epgRef;

    if (element) {
      element.focus();
      this.setState({ isActive: true });
    }
  }

  blurEPG() {
    const element = this.epgRef;

    if (element) {
      element.blur();
      this.setState({ isActive: false });
    }
  }

  renderCurrentItem() {
    return null;
  }

  render() {
    const { t } = this.props;
    const themeProvider = this.context.themeProvider;
    const branding = themeProvider.getBranding();

    const wrapperStyle: React.CSSProperties = {
      // position: 'relative',
      width: `${this.width}px`,
      maxHeight: "720px",
      marginTop: "50px",
      paddingLeft: `0px`,
      paddingRight: "0px",
      opacity: 0.99,
      outline: "none",
    };

    const epgStyle: React.CSSProperties = {
      position: "relative",
      paddingLeft: `${branding.AppPaddingLeft}px`,
      marginTop: `${Math.round(branding.AppPaddingTop / 2)}px`,
    };

    return (
      <React.Fragment>
        <div style={epgStyle} className="Epg">
          <div className="Epg-header">
            <div className="Epg-header-options">
              <div>
                <MediaButton
                  className="Epg-header-button"
                  variant="primary"
                  onClick={() => this.scrollToStart()}
                >
                  {t("COMMON__TODAY", "Today")}
                </MediaButton>
              </div>
            </div>
            <div className="Epg-header-info">
              <div className="Epg-header-info-title">
                {t("EPG__TITLE", "TV Program")}
              </div>
              <Clock style={{ paddingLeft: "30px", display: "inline-block" }} />
            </div>
          </div>

          <div
            id="wrapper"
            ref={(ref) => (this.epgRef = ref)}
            style={wrapperStyle}
            tabIndex={0}
            onKeyDown={this.handleKeyPress}
          >
            {this.onDraw()}
          </div>
        </div>
        {(!this.epgData || !this.epgData.hasData()) && (
          <LoaderSpinner className="Epg-loader" />
        )}
      </React.Fragment>
    );
  }
}
