/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { CellType } from "@bms/common";
import * as React from "react";
import { IListComponentItemProps } from "../../models";
import { ListComponentItemCover } from "../ListComponentItemCover";
import { ListComponentItemFrame } from "../ListComponentItemFrame";
import { ListComponentItemHighlight } from "../ListComponentItemHighlight";
import { ListComponentItemPromo } from "../ListComponentItemPromo";
import cx from "classnames";
import "./ListComponentItem.scss";
import { ListComponentItemSquare } from "../ListComponentItemSquare";
import { ListComponentItemRound } from "../ListComponentItemRound";
import { ListComponentItemHeroLandscape } from "../ListComponentItemHeroLandscape";

/**
 * Component to generate current cell in horizontal list. For example a film from category list
 * @exports ListComponentItem [default]
 */
export const ListComponentItem = ({
  media,
  isActive,
  isPlaceholder = false,
  width = -1,
  withProgress,
  index,
  readOnly,
  cellType,
  style,
}: IListComponentItemProps) => {
  const className = React.useMemo(
    () =>
      cx("ListComponentItem", {
        "ListComponentItem--active": isActive,
      }),
    [isActive]
  );

  const renderView = () => {
    const itemKey = `list-item-${index}_${media ? media.Id : ""}`;

    switch (cellType) {
      case CellType.Cover:
        return (
          <ListComponentItemCover
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
            withProgress={withProgress}
          />
        );
      case CellType.Highlights:
      case CellType.HighlightsWidescreen:
        return (
          <ListComponentItemHighlight
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
          />
        );
      case CellType.Promo:
        return (
          <ListComponentItemPromo
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
          />
        );

      case CellType.Square:
        return (
          <ListComponentItemSquare
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
            withProgress={withProgress}
            cellType={cellType}
          />
        );
      case CellType.Round:
        return (
          <ListComponentItemRound
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            readOnly={readOnly}
            withProgress={withProgress}
            cellType={cellType}
          />
        );
      case CellType.HeroLandscape:
        return (
          <ListComponentItemHeroLandscape
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
          />
        );
      case CellType.Event:
      case CellType.Frame:
      default:
        return (
          <ListComponentItemFrame
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            readOnly={readOnly}
            withProgress={withProgress}
          />
        );
    }
  };

  return (
    <div className={className} style={style}>
      {renderView()}
    </div>
  );
};
