/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { CellType } from "@bms/common";
import * as React from "react";

import { ListComponentAddItemFrame } from "../ListComponentAddItemFrame";

import "./ListComponentAddItem.scss";

type ListComponentAddItemProps = {
  cellType?: CellType;
  style?: React.CSSProperties;
};

export const ListComponentAddItem = ({
  cellType,
  style,
}: ListComponentAddItemProps) => {
  const renderView = () => {
    switch (cellType) {
      default:
        return <ListComponentAddItemFrame />;
    }
  };

  return (
    <div className="ListComponentAddItem" style={style}>
      {renderView()}
    </div>
  );
};
