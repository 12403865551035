/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ImageHelper, RouteHelper, ThemeContext } from "@bms/common";
import Color from "color";
import React, { useContext } from "react";
import { SkeletonTheme } from "react-loading-skeleton";

import { ImageWithPlaceholder } from "components/ImageWithPlaceholder";
import resources from "resources/list";

import { IListComponentItemProps } from "../../models";

import "./ListComponentItemHeroLandscape.scss";

export const ListComponentItemHeroLandscape = ({
  media,
}: IListComponentItemProps) => {
  const contextType = useContext(ThemeContext);
  const themeProvider = contextType.themeProvider;

  const overlayStyles: React.CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: `linear-gradient(
      to bottom,
      ${Color(themeProvider.getColor("AppBackgroundColor"))
        .alpha(0)
        .darken(0)
        .string()},
      ${Color(themeProvider.getColor("AppBackgroundColor"))
        .alpha(0.8)
        .darken(0.8)
        .string()}
    )`,
  };

  const renderSkeleton = () => {
    const skeletonColor = themeProvider.getSkeletonColor();
    return (
      <div className="ListComponentItemHeroLandscape-container ListComponentItemHeroLandscape-container--skeleton">
        <SkeletonTheme color={skeletonColor} highlightColor={skeletonColor}>
          <div className="ListComponentItemHeroLandscape-skeleton" />
        </SkeletonTheme>
      </div>
    );
  };
  if (!media) {
    return renderSkeleton();
  }

  const landscapeImageUrl = ImageHelper.getLandscapeImageUrl(media?.Images);

  return (
    <div
      className="ListComponentItemHeroLandscape"
      onClick={() => RouteHelper.goToDetails(media)}
    >
      <div style={overlayStyles} />
      <div className="ListComponentItemHeroLandscape__overlay" />
      <div className="ListComponentItemHeroLandscape-image-container">
        <ImageWithPlaceholder
          imageSrc={landscapeImageUrl}
          imageContainerClassName="ListComponentItemHeroLandscape-image"
          placeholderSrc={resources.framePlaceholder}
        />
      </div>
    </div>
  );
};
