/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IMediaModel,
  MediaStreamType,
  RouteHelper,
  useIsLoggedIn,
} from "@bms/common";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { LoginRequiredModal } from "components";
import { Button } from "components/DesignSystem/Button";
import PlayIcon from "resources/icons/player/Play.svg";

interface CompareButtonProps {
  media?: IMediaModel;
  enabled?: boolean;
  loading?: boolean;
}

export const CompareButton = (props: CompareButtonProps) => {
  const { media, enabled, loading } = props;

  const isLoggedIn = useIsLoggedIn();
  const { t } = useTranslation();

  const externalIdAssigned = Boolean(media?.ExternalId);

  const isDisabled = !enabled || !externalIdAssigned;

  const showCompareStream = useCallback(() => {
    media &&
      RouteHelper.goToPlayer(
        { media, streamType: MediaStreamType.Trial },
        false
      );
  }, [media]);

  const renderButton = useCallback(
    (onClick?: () => void) => {
      return (
        <Button
          variant="secondary"
          onClick={onClick}
          // disabled={isDisabled}
          tooltip={t("NOT_IN_DEMO_VERSION", "Not in demo version")}
          loading={loading}
          shadow
          disabled
          tooltipOnDisabled
        >
          <PlayIcon />
          {t("DETAILS_SCREEN__COMPARE", "Compare")}
        </Button>
      );
    },
    [isDisabled, loading, t]
  );

  if (!isLoggedIn) {
    return (
      <LoginRequiredModal>
        {({ openModal }) => renderButton(openModal)}
      </LoginRequiredModal>
    );
  }

  return renderButton(showCompareStream);
};
