/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import background from "./background.jpg";
import logo from "./logo.png";

export default {
  background,
  logo,
};
