/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import * as React from "react";
import "./ImageOverlay.scss";

export interface ImageOverlayProps {
  src?: string;
  imageStyle?: React.CSSProperties;
}

export interface ImageOverlayState {
  imageSrc?: string;
  mounted?: boolean;
}

/**
 * Background image for example page with films
 * @exports ImageOverlay [default]
 */
export class ImageOverlay extends React.PureComponent<
  ImageOverlayProps,
  ImageOverlayState
> {
  private _imageLoader: HTMLImageElement = new Image();
  private _mounted = false;

  public state: Readonly<ImageOverlayState> = {
    imageSrc: "",
  };

  public UNSAFE_componentWillReceiveProps(nextProps: ImageOverlayProps) {
    if (nextProps.src !== this.props.src) {
      this._loadImage(nextProps.src);
    }
  }

  public componentDidMount() {
    const { src } = this.props;
    this._mounted = true;
    this._loadImage(src);
  }

  public componentWillUnmount() {
    this._mounted = false;
  }

  public render() {
    const { imageStyle } = this.props;
    const { imageSrc } = this.state;

    return (
      <div className="ImageOverlay-container">
        {imageSrc ? (
          <img src={imageSrc} style={imageStyle} />
        ) : (
          <div className="ImageOverlay-overlay" />
        )}
      </div>
    );
  }

  private _loadImage(src?: string) {
    if (!src) {
      return;
    }

    this._imageLoader.src = src;
    this._imageLoader.onload = () => {
      if (!this._mounted) {
        return;
      }
      this.setState({ imageSrc: this.props.src });
    };
  }
}
