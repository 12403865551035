/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import cx from "classnames";
import React, { useCallback, useEffect, useRef } from "react";

import { useSeekHandler } from "./useSeekHandler";

import "./Slider.scss";

type SliderBase = {
  value: number;
  vertical?: boolean;
};

type SliderRead =
  | {
      onChange: (value: number) => void;
      readOnly?: never;
    }
  | {
      onChange?: never;
      readOnly: true;
    };

type SliderProps = SliderBase & SliderRead;

export const Slider = (props: SliderProps) => {
  const { value, onChange, readOnly = false, vertical = false } = props;

  const sliderRef = useRef<HTMLDivElement>(null);

  const setProgressPosition = useCallback((value: number) => {
    if (sliderRef.current) {
      sliderRef.current.style.setProperty(
        "--progress-position",
        value.toString()
      );
    }
  }, []);

  const calculateProgress = useCallback(
    (position: number) => {
      if (!sliderRef.current) {
        return 0;
      }

      const rect = sliderRef.current.getBoundingClientRect();

      const value = vertical
        ? 1 -
          Math.min(Math.max(0, position - rect.y), rect.height) / rect.height
        : Math.min(Math.max(0, position - rect.x), rect.width) / rect.width;

      return Number(Number(value).toFixed(2));
    },
    [vertical]
  );

  const onProgressUpdate = useCallback(
    (position: number, isScrubbing: boolean) => {
      const progress = calculateProgress(position);

      if (isScrubbing) {
        setProgressPosition(progress);
        onChange?.(progress);
      }
    },
    [calculateProgress, onChange, setProgressPosition]
  );

  const {
    onMouseMove,
    onMouseDown,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onContextMenu,
  } = useSeekHandler({
    onProgressUpdate,
    vertical,
    readOnly,
  });

  useEffect(() => {
    setProgressPosition(value);
  }, [setProgressPosition, value]);

  const generateClasses = (name: string) =>
    cx(name, {
      ["vertical"]: vertical,
      ["horizontal"]: !vertical,
      ["read-only"]: readOnly,
    });

  return (
    <div
      className={generateClasses("Slider")}
      ref={sliderRef}
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onContextMenu={onContextMenu}
    >
      <div className={generateClasses("Slider__Progress")}>
        <div className={generateClasses("Slider__Progress__ThumbIndicator")} />{" "}
      </div>
    </div>
  );
};
