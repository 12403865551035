/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { LoaderSpinner, Step, Steps } from "components";

import { CreatorMediaProvider, useCreatorMedia } from "./CreatorMediaContext";
import { getStepComponent, STEPS } from "./Step";
import { WizardWrapper } from "./WizardWrapper";

interface WizardContextValue {
  nextStep: () => void;
  previousStep: () => void;
  setLoadingState: (value: boolean) => void;
}

interface WizardProviderProps {
  assetId: string;
}

const WizardContext = createContext<WizardContextValue>(
  {} as WizardContextValue
);

const NUMBER_OF_STEPS = STEPS.length - 1;
const MIN_STEP_INDEX = 1;

export const WizardProvider = (props: WizardProviderProps) => {
  const { assetId } = props;

  const [stepIndex, setStepIndex] = useState(MIN_STEP_INDEX);
  const [isLoading, setLoadingState] = useState(false);

  const { t } = useTranslation();
  const { refresh } = useCreatorMedia();

  const nextStep = useCallback(
    () => setStepIndex((prev) => Math.min(prev + 1, NUMBER_OF_STEPS)),
    []
  );

  const previousStep = useCallback(
    () => setStepIndex((prev) => Math.max(prev - 1, MIN_STEP_INDEX)),
    []
  );

  const onStepChange = useCallback(
    (index: number) => refresh().then(() => setStepIndex(index)),
    [refresh]
  );

  const StepComponent = getStepComponent(stepIndex);

  const stepsHeader = useMemo(() => {
    return (
      <Steps
        className="media-steps"
        current={stepIndex}
        onChange={onStepChange}
      >
        {STEPS.map((step, index: number) => (
          <Step
            stepNumber={index}
            key={index}
            stepIndex={index - 1}
            subTitle={t(step.title)}
            disabled
          />
        ))}
      </Steps>
    );
  }, [onStepChange, stepIndex, t]);

  return (
    <WizardContext.Provider value={{ nextStep, previousStep, setLoadingState }}>
      <h1>{t("VIDEO_EMBEDDER_WIZARD_HEADER", "Video embedder wizard")}</h1>
      {stepsHeader}
      <CreatorMediaProvider assetId={assetId}>
        <WizardWrapper>
          {isLoading ? (
            <LoaderSpinner className="WizardSpinner" />
          ) : (
            <StepComponent />
          )}
        </WizardWrapper>
      </CreatorMediaProvider>
    </WizardContext.Provider>
  );
};

export const useWizard = (): WizardContextValue => {
  const context = useContext(WizardContext);

  if (!context) {
    throw new Error("Component beyond WizardContext");
  }

  return context;
};
