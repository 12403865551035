/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import dayjs from "dayjs";
import "dayjs/locale/pl";
import { ImageHelper } from "../../../helpers";
import { IMediaModel } from "../../../models";
import { EPGEvent } from "./EPGEvent";

export class EPGChannel {
  id: number | undefined;
  name: string;
  icon: string;
  number?: number;
  events: EPGEvent[];

  constructor(
    icon = "",
    name = "",
    id: number | undefined,
    number?: number,
    eventsData: IMediaModel[] = []
  ) {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.number = number;
    this.events = this.createEvents(eventsData);
  }

  getChannelID() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getImageURL() {
    return this.icon;
  }

  getEvents() {
    return this.events;
  }

  getNumber() {
    return this.number;
  }

  createEvents(eventsData: IMediaModel[]) {
    return eventsData.map((event: IMediaModel) => {
      const eventStart = dayjs(event.StartDateTime, { locale: "pl" }).valueOf();
      const eventEnd = dayjs(event.EndDateTime, { locale: "pl" }).valueOf();
      const eventId = event.Id;
      const eventTitle = event.Title || "";
      const eventDescription = event.Description || "";
      const eventImage = ImageHelper.getFrameImageUrl(event.Images) || "";
      const epgEvent = new EPGEvent(
        eventStart,
        eventEnd,
        eventId,
        eventTitle,
        eventDescription,
        eventImage
      );

      return epgEvent;
    });
  }
}
