/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ROUTES, useIsLoggedIn } from "@bms/common";
import React, { ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Dialog, Message } from "components";
import { VideoEmbedderForm } from "components/Forms";

export interface VideoEmbedderDialogProps {
  children: ReactNode;
}

export const VideoEmbedderDialog = (props: VideoEmbedderDialogProps) => {
  const { children } = props;

  const [isVisible, setIsVisible] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const isLoggedIn = useIsLoggedIn();

  const openModal = useCallback(() => setIsVisible(true), []);
  const closeModal = useCallback(() => setIsVisible(false), []);

  const onAssetInserted = useCallback(
    (asset) => {
      closeModal();
      history.push(`${ROUTES.EMBEDDER_CREATOR}/${asset.AssetId}`);
    },
    [closeModal, history]
  );

  const onUploadError = useCallback(() => {
    Message.error(
      t(
        "VIDEO_EMBEDDER__UPLOAD_ERROR",
        "Unable to upload asset video file. Asset not created."
      )
    );

    closeModal();
    history.push(ROUTES.HOME);
  }, [closeModal, history, t]);

  if (!isLoggedIn) {
    return <>{children}</>;
  }

  return (
    <>
      <div onClick={isVisible ? undefined : openModal}>{children}</div>
      <Dialog
        wrapClassName="add-asset-dialog"
        destroyOnClose
        visible={isVisible}
        onCancel={closeModal}
        footer={null}
      >
        <h1 className="text-upper text-center">
          {t("VIDEO_EMBEDDER_WIZARD__ADD_CONTENT_HEADER", "Add asset")}
        </h1>

        <VideoEmbedderForm
          onAssetInserted={onAssetInserted}
          onUploadError={onUploadError}
        />
      </Dialog>
    </>
  );
};
