/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum MediaAdvertisementType {
  VAST = "VAST",
  VPAID = "VPAID",
  SIMID = "SIMID",
  VMAP = "VMAP",
}
