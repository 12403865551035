/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ApiErrors,
  AppConfig,
  AuthStore,
  buildEmailRule,
  buildNameMaxLengthRule,
  buildNamePatternRule,
  buildPasswordMatchRule,
  buildPasswordMinimumRule,
  buildPasswordPatternRule,
  buildPasswordWhitespacesRule,
  buildRequiredRule,
  IFormValues,
  IRegisterRequestEmailModel,
  IStateModel,
  IUserConsentModel,
  ROUTES,
  updateApiErrors,
  UserStore,
} from "@bms/common";
import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ActionCreator } from "redux";
import { Consent } from "../../Consent";
import { Form, FormButton, LabelField } from "../../Form";
import "../../Form/Form.scss";
import { FormLine } from "../../Form/FormLine";
import { Input, InputPassword } from "../../Input";
import { Switch } from "../../Switch";
import "../AuthForm.scss";
import "./RegisterForm.scss";

interface IRegisterFormProps extends WithTranslation {
  authState: AuthStore.Types.IAuthState;
  initialValues?: IRegisterRequestEmailModel;
  consents: IStateModel<IUserConsentModel[]>;
  onSubmit: (values: IRegisterRequestEmailModel) => void;
  getConsents: ActionCreator<UserStore.Types.IGetUserConsentsAction>;
}

interface IRegisterFormState {
  consentsStates: { [id: string]: { Accepted: boolean; Required: boolean } };
  isFormSent: boolean;
  apiErrors: ApiErrors;
}

export class RegisterForm extends React.PureComponent<
  IRegisterFormProps,
  IRegisterFormState
> {
  public state: IRegisterFormState = {
    consentsStates: {},
    isFormSent: false,
    apiErrors: {},
  };

  static getDerivedStateFromProps(
    nextProps: IRegisterFormProps,
    prevState: IRegisterFormState
  ) {
    if (
      prevState.isFormSent &&
      nextProps.authState.error !== prevState.apiErrors
    ) {
      return {
        apiErrors: { ...nextProps.authState.error },
      };
    }

    if (
      nextProps.consents.Data &&
      Object.keys(prevState.consentsStates).length === 0
    ) {
      return {
        consentsStates: Object.assign(
          {},
          ...nextProps.consents.Data.map((consent: IUserConsentModel) => ({
            [`${consent.ConsentId}`]: {
              Accepted: false,
              Required: consent.ConsentRequired,
            },
          }))
        ),
      };
    }

    return null;
  }

  componentDidMount() {
    const { getConsents } = this.props;

    getConsents();
  }

  private onFinish = (values: IFormValues) => {
    const { consents } = this.props;
    const { consentsStates } = this.state;
    const requiredConsents = consents.Data;

    const consentsForRegistration = requiredConsents
      ?.filter((consent: IUserConsentModel) => {
        const consentsState = consentsStates[`${consent.ConsentId}`];

        return consentsState?.Accepted;
      })
      .map((consent: IUserConsentModel) => {
        return { ...consent, Accepted: true };
      });

    const payload: IRegisterRequestEmailModel = {
      Email: values["email"],
      Password: values["password"],
      FullName: values["name"],
      Consents: consentsForRegistration,
    };

    this.setState({ isFormSent: true });
    this.props.onSubmit(payload);
  };

  private onValuesChange = (changedValues: IFormValues) => {
    const { apiErrors } = this.state;
    const [isUpdated, newApiErrors] = updateApiErrors(apiErrors, changedValues);

    if (isUpdated) {
      this.setState({ isFormSent: false, apiErrors: newApiErrors });
    }
  };

  private onChange = (
    checked: boolean,
    _: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string
  ) => {
    const newStates = { ...this.state.consentsStates };
    newStates[id].Accepted = checked;

    this.setState({
      consentsStates: newStates,
    });
  };

  private renderConsents() {
    const { consents } = this.props;

    return consents.Data?.map((consent: IUserConsentModel) => {
      return (
        <div className="RegisterConsents__Option" key={consent.ConsentId}>
          <label>
            <Switch
              id={`${consent.ConsentId}`}
              onChange={this.onChange}
              checked={
                this.state.consentsStates[consent.ConsentId]?.Accepted ?? false
              }
            />
            <span className="RegisterConsents__Option__Description">
              <Consent consent={consent} />
            </span>
          </label>
        </div>
      );
    });
  }

  public render() {
    const { t, authState } = this.props;
    const allConsentsAccepted = Object.values(this.state.consentsStates)
      .filter((consentsState) => consentsState.Required)
      .every((consentsState) => consentsState.Accepted);

    return (
      <div className="RegisterForm AuthForm">
        <Form
          initialValues={this.props.initialValues}
          onFinish={this.onFinish}
          onValuesChange={this.onValuesChange}
        >
          <h1 className="text-upper text-center">
            {t("REGISTER__TITLE", "Create account")}
          </h1>
          <p className="text-center text-large">
            {t(
              "REGISTER__SUBTITLE",
              "Register to enjoy free access to selected content and gain the opportunity to purchase access to paid packages."
            )}
          </p>
          <FormLine style={{ marginTop: "25px" }} />
          <LabelField
            name="name"
            label={t("REGISTER__NAME_LABEL", "Name")}
            rules={[
              buildRequiredRule(),
              buildNameMaxLengthRule(),
              buildNamePatternRule(),
            ]}
            apiErrors={this.state.apiErrors.FullName || []}
          >
            <Input className="FormInput" autoFocus={true} />
          </LabelField>
          <LabelField
            name="email"
            label={t("REGISTER__EMAIL_LABEL", "Email")}
            rules={[buildRequiredRule(), buildEmailRule()]}
            apiErrors={this.state.apiErrors.Email || []}
          >
            <Input className="FormInput" />
          </LabelField>
          <LabelField
            name="password"
            label={t("REGISTER__PASSWORD_LABEL", "Password")}
            rules={[
              buildRequiredRule(),
              buildPasswordMinimumRule(),
              buildPasswordPatternRule(),
              buildPasswordWhitespacesRule(),
            ]}
            apiErrors={this.state.apiErrors.Password || []}
          >
            <InputPassword className="FormInput" visibilityToggle={false} />
          </LabelField>
          <LabelField
            name="confirm_password"
            label={t("REGISTER__RE_PASSWORD_LABEL", "Re-enter password")}
            dependencies={["password"]}
            rules={[
              buildRequiredRule(),
              (context) => buildPasswordMatchRule(context, "password"),
            ]}
          >
            <InputPassword className="FormInput" visibilityToggle={false} />
          </LabelField>
          <div className="text-normal">
            <p className="text-large">
              {t(
                "REGISTER__CONSENTS_AGREEMENT",
                "By creating an account, I accept:"
              )}
            </p>
            {this.renderConsents()}
          </div>
          <div className="Error">{this.state.apiErrors.Message}</div>
          <div className="ButtonLine">
            <FormButton
              disabled={!allConsentsAccepted}
              loading={authState.isProcessing}
            >
              {t("REGISTER__SUBMIT", "Create")}
            </FormButton>
          </div>
          {!AppConfig.IsLoginDisabled && (
            <>
              <FormLine />
              <p className="text-center text-large">
                <Trans i18nKey="REGISTER__SIGN_IN">
                  <span>You already have an account? </span>
                  <Link to={ROUTES.LOGIN}>
                    <span className="text-link text-bold">{"Sign in"}</span>
                  </Link>
                </Trans>
              </p>
            </>
          )}
        </Form>
      </div>
    );
  }
}
