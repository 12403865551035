/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ImageHelper, MediaType, RouteHelper, ThemeContext } from "@bms/common";
import Color from "color";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { LGUp, PlayButton } from "components";
import { ImageRatio, ImageWithRatio } from "components/ImageWithRatio";
import { MediaAdditionalInfo } from "components/Media";
import { MediaStartInfo } from "components/Media/MediaStartInfo";
import { MediaButton } from "components/MediaButton";
import { useIsMobile } from "components/Responsive";
import InfoIcon from "resources/icons/info.svg";
import resources from "resources/list";

import { IListComponentItemProps } from "../../models";

import "./ListComponentItemHighlight.scss";

export type IListComponentItemHighlightProps = IListComponentItemProps;

export const ListComponentItemHighlight: React.FC<
  IListComponentItemHighlightProps
> = ({ media, isPlaceholder = false }) => {
  const { t } = useTranslation();
  const { themeProvider } = useContext(ThemeContext);
  const isMobile = useIsMobile();
  const ratio = useMemo(
    () => (isMobile ? ImageRatio["16:9"] : ImageRatio["16:6"]),
    [isMobile]
  );

  const goToDetails = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      RouteHelper.goToDetails(media);
    },
    [media]
  );

  const renderSkeleton = () => {
    const skeletonColor = themeProvider.getSkeletonColor();

    if (!media) {
      return (
        <div className="ListComponentItemHighlight ListComponentItemHighlight--skeleton">
          <SkeletonTheme color={skeletonColor} highlightColor={skeletonColor}>
            <div className="ListComponentItemHighlight__Skeleton">
              <div style={{ marginBottom: "14px" }}>
                <Skeleton width={"20%"} height="18px" />
              </div>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"40%"} height="49px" />
              </div>
              <div style={{ marginBottom: "30px" }}>
                <Skeleton width={"30%"} height="24px" />
              </div>

              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"50%"} height="18px" />
              </div>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"48%"} height="18px" />
              </div>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"49%"} height="18px" />
              </div>
              <div>
                <Skeleton width={"35%"} height="18px" />
              </div>
            </div>
          </SkeletonTheme>
        </div>
      );
    }

    return null;
  };

  if (!media || isPlaceholder) {
    return renderSkeleton();
  }

  const linearGradientColor = Color(
    themeProvider.getColor("AppDetailsBackgroundColor")
  );

  const isAdvertisement = media.MediaTypeCode === MediaType.Advertisement;

  return (
    <div className="ListComponentItemHighlight">
      <ImageWithRatio
        key={media?.Id}
        width={null}
        height={null}
        ratio={ratio}
        contentClassName="ListComponentItemHighlight__Background"
        imageSrc={
          ImageHelper.getHighlightsImageUrl(media?.Images) ??
          resources.framePlaceholder
        }
        contentStyle={{
          background: `linear-gradient(90deg, ${linearGradientColor
            .alpha(0.85)
            .string()} 0%, ${linearGradientColor
            .alpha(0.6978)
            .string()} 50%, ${linearGradientColor.alpha(0).string()} 100%)`,
        }}
      >
        {!isAdvertisement && (
          <div className="ListComponentItemHighlight__Container">
            <div className="ListComponentItemHighlight__Content">
              <MediaStartInfo media={media} />
              <h1 className="ListComponentItemHighlight__Title">
                {media?.Title}
              </h1>
              <LGUp>
                <MediaAdditionalInfo media={media} />
                <p
                  className="ListComponentItemHighlight__Description"
                  dangerouslySetInnerHTML={{
                    __html: media?.ShortDescription || "",
                  }}
                ></p>
              </LGUp>
              <div>
                <PlayButton media={media} />
                <MediaButton
                  variant="plain"
                  icon={<InfoIcon />}
                  onClick={goToDetails}
                >
                  {t("COMMON__BUTTON_MORE_INFO", "More info")}
                </MediaButton>
              </div>
            </div>
          </div>
        )}
      </ImageWithRatio>
    </div>
  );
};
