/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  DataProvider,
  IAppState,
  IErrorModel,
  InfoDetailsScreenCode,
  IScreenModel,
  IUserConsentModel,
  ScreenType,
  useDataLoader,
  UserStore,
} from "@bms/common";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ConsentModal } from "components/ConsentModal";

import "./InfoDetailsScreen.scss";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const srcDoc = require("srcdoc-polyfill");

export interface IInfoDetailsScreenProps {
  screenTypeCode?: ScreenType;
  isConsent?: boolean;
  textCode: InfoDetailsScreenCode | string;
}

export const InfoDetailsScreen = ({
  screenTypeCode,
  isConsent,
  textCode,
}: IInfoDetailsScreenProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userConsents = useSelector<IAppState, IUserConsentModel[] | undefined>(
    (state) => state.user.consents.Data
  );
  const contentRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const consent =
    userConsents &&
    userConsents.find((consent) => consent.ConsentId.toString() === textCode);

  const screenLoader = useDataLoader<string | undefined, IErrorModel>({
    loader: () => {
      if (!consent) {
        return Promise.resolve({
          ok: true,
          data: "",
          error: undefined,
        });
      }

      if (consent && !consent.ConsentContentUrl) {
        return Promise.resolve({
          ok: false,
          error: {
            MessageKey: "CONSENT_FILE_IS_MISSING",
          },
        });
      }

      return DataProvider.getUserConsent(consent.ConsentContentUrl)
        .then((response) => {
          return {
            ok: true,
            data: response,
            error: {},
          };
        })
        .catch((error) => {
          return {
            ok: false,
            error: {
              ...error,
              MessageKey: "CONSENT_FILE_LOADING_FAILED",
            },
          };
        });
    },
    deps: [consent],
  });

  useEffect(() => {
    if (!userConsents) {
      dispatch(UserStore.Actions.getUserConsents());
    }
  }, []);

  const getScreenName = useMemo(() => {
    switch (screenTypeCode) {
      case ScreenType.PrivacyPolicy:
        return t(
          `${consent?.ConsentNameTranslationKey}`,
          `${consent?.ConsentName}`
        );
      default:
        // TODO: get MEDIA READ MORE SCREEN TITLE DATA
        return "";
    }
  }, [screenTypeCode]);

  const screen: IScreenModel = {
    Id: -1,
    ScreenTypeCode: screenTypeCode,
    Name: getScreenName,
    // TODO: MEDIA READ MORE SCREEN DATA
    Data: isConsent ? screenLoader.data : "",
  };

  const renderDocumentHandler = () => {
    if (iframeRef.current) {
      let height = 0;
      const doc =
        iframeRef.current.contentDocument ??
        iframeRef.current.contentWindow?.document;

      if (doc) {
        const style = document.createElement("style");
        style.innerHTML = `
            body{
              height: 100%;
              padding: 2rem;
            }

            @media screen and (max-width: 1100px) {
              body {
                padding-right: 4rem;
              }

              span {
                font-size: 26px;
              }

              p {
                font-size: 16px;
              }

              li {
                font-size: 16px;
              }

              ol>li:before {
                font-size: 16px;
              }
            }

            @media screen and (max-width: 499px) {

              h1 {
                font-size: 21px;
              }

              span {
                font-size: 18px;
              }

              p {
                font-size: 16px;
              }

              li {
                font-size: 16px;
              }

              ol>li {
                display: block;
              }
            }
          `;
        doc.head.appendChild(style);

        const body = doc.body,
          html = doc.documentElement;
        height = Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        );
      }

      if (height) {
        height += 150;
        iframeRef.current.height = `${height}px`;
        iframeRef.current.style.height = `${height}px`;
      } else {
        iframeRef.current.height = "100%";
      }
    }
  };

  const renderContent = (): React.ReactNode => {
    if (screen.Data && iframeRef.current) {
      srcDoc.set(iframeRef.current, screen.Data);
    }

    const renderError = (): React.ReactNode => {
      if (screenLoader.error) {
        return (
          <ConsentModal
            refresh={screenLoader.refresh}
            error={screenLoader.error}
          />
        );
      }

      return null;
    };

    return (
      <div className="InfoDetailsScreen__Container">
        <div className="InfoDetailsScreen__Content" ref={contentRef}>
          {renderError()}
          {!screenLoader.error && (
            <>
              <iframe
                ref={iframeRef}
                className="InfoDetailsScreen__IFrame"
                sandbox="allow-same-origin"
                width="85%"
                onLoad={renderDocumentHandler}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  return <div className="InfoDetailsScreen">{renderContent()}</div>;
};
