/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AuthStore,
  IAppState,
  IForgotPasswordModel,
  IResetForgotPasswordModel,
} from "@bms/common";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ForgotPasswordSuccess as ForgotPasswordSuccessPresentational } from "././ForgotPasswordSuccess";
import { ForgotPasswordForm as ForgotPasswordFormPresentational } from "./ForgotPasswordForm";
import { ResetPasswordForm as ResetPasswordFormPresentational } from "./ResetPasswordForm";

const mapStateToProps = (state: IAppState) => {
  return {
    authState: state.auth,
  };
};

const mapDispatchToPropsForgot = (dispatch: Dispatch) => ({
  onSubmit: (data: IForgotPasswordModel) => {
    return dispatch(AuthStore.Actions.forgotPassword(data));
  },
});

const mapDispatchToPropsReset = (dispatch: Dispatch) => ({
  onSubmit: (data: IResetForgotPasswordModel) => {
    return dispatch(AuthStore.Actions.resetForgotPassword(data));
  },
  onValidate: (token: string) => {
    return dispatch(AuthStore.Actions.validateToken(token));
  },
});

export const ForgotPasswordForm = connect(
  mapStateToProps,
  mapDispatchToPropsForgot
)(withTranslation()(ForgotPasswordFormPresentational));
export const ResetPasswordForm = connect(
  mapStateToProps,
  mapDispatchToPropsReset
)(withTranslation()(ResetPasswordFormPresentational));
export const ForgotPasswordSuccess = withTranslation()(
  ForgotPasswordSuccessPresentational
);
