/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum AlignType {
  Left = "LEFT",
  Center = "CENTER",
  Right = "RIGHT",
}
