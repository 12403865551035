/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAppState, useSelector } from "../../store";
import { useIsLoggedIn } from "../auth/useIsLoggedIn";

export const useIsLoginToPlayRequired = (): boolean => {
  const isLoggedIn = useIsLoggedIn();
  const isFreeAssetsOnlyForLoggedUsers = useSelector(
    (state: IAppState) =>
      state.configuration.configuration?.System?.Media
        ?.FreeAssetOnlyForLoggedInUsers ?? false
  );

  return isFreeAssetsOnlyForLoggedUsers && !isLoggedIn;
};
