/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaPersonModel, PersonInAssetType } from "@bms/common";
import { useRTL } from "hooks/useRTL";
import React from "react";
import { useTranslation } from "react-i18next";
import "./MediaPeople.scss";

export interface MediaPeopleProps {
  people?: IMediaPersonModel[];
  loading?: boolean;
}

export const MediaPeople: React.FC<MediaPeopleProps> = ({
  people,
  loading,
}) => {
  const { t } = useTranslation();
  const isRTL = useRTL();

  function reverseArrayIfRTL(peopleArray: string[]) {
    return peopleArray && peopleArray?.length > 0
      ? isRTL
        ? peopleArray.reverse().join(", ")
        : peopleArray.join(", ")
      : "";
  }
  const mediaDirector =
    people && people?.length > 0
      ? people
          .filter(
            (person: IMediaPersonModel) =>
              person.PersonRoleCode === PersonInAssetType.Director
          )
          .map((person: IMediaPersonModel) => person.PersonFullName)
      : "";

  const mediaWriter =
    people && people?.length > 0
      ? people
          .filter(
            (person: IMediaPersonModel) =>
              person.PersonRoleCode === PersonInAssetType.Writer
          )
          .map((person: IMediaPersonModel) => person.PersonFullName)
      : "";

  const mediaCast =
    people && people?.length > 0
      ? people
          .filter(
            (person: IMediaPersonModel) =>
              person.PersonRoleCode === PersonInAssetType.Cast
          )
          .map((person: IMediaPersonModel) => person.PersonFullName)
      : "";

  if (!people && loading) {
    return (
      <>
        <div className="MediaPeople__skeleton" style={{ width: "12rem" }} />
        <div className="MediaPeople__skeleton" style={{ width: "9.5rem" }} />
        <div className="MediaPeople__skeleton" style={{ width: "14.5rem" }} />
      </>
    );
  }

  if (!mediaDirector && !mediaWriter && !mediaCast) {
    return null;
  }

  return (
    <div className="MediaPeople">
      {mediaDirector && (
        <div className="MediaPeople__director">
          <span>{t("MEDIA__DIRECTOR")}</span>
          <span>{reverseArrayIfRTL(mediaDirector)}</span>
        </div>
      )}
      {mediaWriter && (
        <div className="MediaPeople__writer">
          <span>{t("MEDIA__WRITER")}</span>
          <span>{reverseArrayIfRTL(mediaWriter)}</span>
        </div>
      )}
      {mediaCast && (
        <div className="MediaPeople__cast">
          <span>{t("MEDIA__CAST")}</span>
          <span>{reverseArrayIfRTL(mediaCast)}</span>
        </div>
      )}
    </div>
  );
};
