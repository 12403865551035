/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import "./wdyr";
import {
  DataProvider,
  ReduxStoreConfigurator,
  IAppLocationState,
} from "@bms/common";
import {
  BrowserHistoryBuildOptions,
  createBrowserHistory,
  History,
} from "history";

import { createLogger } from "redux-logger";

import "./i18n";
import { firebaseApp } from "./firebase";
import { withSentry } from "./sentry";

// Set up HttpFactory
DataProvider.initHttpFactory();

export const browserHistoryOptions: BrowserHistoryBuildOptions = {};

if (process.env.PUBLIC_URL) {
  browserHistoryOptions.basename = process.env.PUBLIC_URL;
}

export const appHistory: History<IAppLocationState> = createBrowserHistory(
  browserHistoryOptions
);
const middlewares = [];

if (process.env.NODE_ENV === "development") {
  middlewares.push(createLogger({ diff: true, collapsed: true }));
}
export const reduxStoreConfigurator = new ReduxStoreConfigurator(
  appHistory,
  middlewares
);
export const appStore = reduxStoreConfigurator.initStore();

export { firebaseApp, withSentry };
