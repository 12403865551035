/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import loadable from "@loadable/component";
import React, { memo } from "react";

import { PlayerUIType, VideoPlayerProps } from "./types";

const CustomVideoPlayer = loadable(() => import("./Custom/CustomVideoPlayer"));

const StandaloneVideoPlayer = loadable(
  () => import("./Standalone/StandaloneVideoPlayer")
);

const VideoPlayerRaw = (props: VideoPlayerProps) => {
  switch (process.env.REACT_APP_PLAYER_UI_TYPE as PlayerUIType) {
    case PlayerUIType.Standalone:
      return <StandaloneVideoPlayer {...props} />;
    case PlayerUIType.Custom:
      return <CustomVideoPlayer key={props.media.Media?.Id} {...props} />;
    default:
      throw new Error("Unsupported player UI type");
  }
};

export const VideoPlayer = memo(VideoPlayerRaw);

VideoPlayer.displayName = "VideoPlayer";
