/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  CellType,
  ComponentType,
  IListComponentModel,
  MediaStore,
  Orientation,
  useDispatch,
  useMediaPlayInfoSelector,
} from "@bms/common";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ListComponent, LoaderSpinner } from "../../components";
import {
  MediaDetailsScreen,
  useMediaDetailsMediaSelector,
} from "../MediaDetailsScreen";

import "./ArticleDetailsScreen.scss";

export const ArticleDetailsScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const media = useMediaDetailsMediaSelector();
  const { Data: mediaPlayInfo, IsProcessing } = useMediaPlayInfoSelector(
    media?.Id
  );

  useEffect(() => {
    if (media?.Id) {
      dispatch(
        MediaStore.Actions.getMediaPlayInfo({
          MediaId: media?.Id,
        })
      );
    }
  }, [media?.Id]);

  const renderRecommendationsList = () => {
    if (media?.SimilarMedia && media?.SimilarMedia.length > 0) {
      const recommendationsList: IListComponentModel = {
        ComponentTypeCode: ComponentType.List,
        MediaList: media?.SimilarMedia,
        CellType: CellType.Frame,
        Orientation: Orientation.Horizontal,
        VisibleItemsCount: 4,
        Title: t("COMMON__MORE_LIKE_THIS"),
      };

      return (
        <ListComponent
          component={recommendationsList}
          className="ArticleDetailsScreen__recommendations"
        />
      );
    }

    return null;
  };

  const renderContent = () => {
    if (IsProcessing) {
      return (
        <div className="ArticleDetailsScreen__loader">
          <LoaderSpinner />
        </div>
      );
    }

    if (mediaPlayInfo?.ContentHtml) {
      return (
        <>
          <article
            className="ArticleDetailsScreen__article"
            dangerouslySetInnerHTML={{ __html: mediaPlayInfo.ContentHtml }}
          ></article>
          {renderRecommendationsList()}
        </>
      );
    }

    return null;
  };

  return (
    <MediaDetailsScreen className="ArticleDetailsScreen">
      <>{renderContent()}</>
    </MediaDetailsScreen>
  );
};
