/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AuthStore,
  IAppState,
  IAuthRequestModel,
  UserStore,
} from "@bms/common";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { LoginForm as LoginFormPresentational } from "./LoginForm";

const mapStateToProps = (state: IAppState) => {
  return {
    authState: state.auth,
    consents: state.user.consents,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (data: IAuthRequestModel) => {
    return dispatch(AuthStore.Actions.signIn(data));
  },
  getConsents: () => {
    return dispatch(UserStore.Actions.getUserConsents());
  },
});

export const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LoginFormPresentational));
