/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { LoginForm } from "../../../components/Forms/LoginForm";
import { AuthScreen, IAuthScreenProps } from "../AuthScreen";
import "../AuthScreen.scss";

type ILoginScreenProps = IAuthScreenProps;

interface ILoginScreenState {
  screenKey?: string;
}

export class LoginScreen extends React.PureComponent<
  ILoginScreenProps,
  ILoginScreenState
> {
  render() {
    return (
      <>
        <AuthScreen {...this.props}>
          <LoginForm />
        </AuthScreen>
      </>
    );
  }
}
