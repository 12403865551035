/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  buildRequiredRule,
  buildWhitespacesRule,
  IFormValues,
  RecordStatus,
  RouteHelper,
} from "@bms/common";
import { useForm } from "rc-field-form";
import Textarea from "rc-textarea";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Input, Message } from "components";
import { Form, LabelField } from "components/Form";

import { WizardFooter } from "../../Component";
import { useCreatorMedia } from "../../CreatorMediaContext";
import { useWizard } from "../../WizardContext";

import "./Metadata.scss";

export const Metadata = () => {
  const { asset, update, refresh } = useCreatorMedia();
  const { nextStep, setLoadingState } = useWizard();

  const [form] = useForm();
  const { t } = useTranslation();

  const onFormFinish = useCallback(
    async (values: IFormValues) => {
      setLoadingState(true);

      try {
        await update({
          ...asset,
          Title: values.Title,
          LongDescription: values.Description,
          RecordStatus: RecordStatus.Updated,
        });

        nextStep();
      } catch (e) {
        Message.error(
          t(
            "VIDEO_EMBEDDER_WIZARD__ASSET_UPDATE_ERROR",
            "Unable to update asset data"
          )
        );
      } finally {
        await refresh();
        setLoadingState(false);
      }
    },
    [asset, nextStep, refresh, setLoadingState, t, update]
  );

  const renderTitleField = () => {
    return (
      <LabelField
        name="Title"
        initialValue={asset.Title}
        label={t("VIDEO_EMBEDDER_WIZARD__ASSET_TITLE", "Title")}
        rules={[buildRequiredRule(), buildWhitespacesRule()]}
        requiredIcon
      >
        <Input
          className="FormInput"
          placeholder={t(
            "VIDEO_EMBEDDER_WIZARD__ASSET_TITLE_PLACEHOLDER",
            "Title"
          )}
        />
      </LabelField>
    );
  };

  const renderDescriptionField = () => {
    return (
      <LabelField
        name="Description"
        initialValue={asset.LongDescription}
        label={t("VIDEO_EMBEDDER_WIZARD__ASSET_DESCRIPTION", "Description")}
        errorClass={"FormInputError FormTextarea"}
        rules={[buildRequiredRule(), buildWhitespacesRule()]}
        requiredIcon
      >
        <span className="FormTextarea FormInput">
          <Textarea
            defaultValue={asset.LongDescription}
            autoSize={{ minRows: 4, maxRows: 6 }}
            placeholder={t(
              "VIDEO_EMBEDDER_WIZARD__ASSET_DESCRIPTION_PLACEHOLDER",
              "Description"
            )}
          />
        </span>
      </LabelField>
    );
  };

  const renderForm = () => {
    return (
      <div className="Metadata__Form">
        {renderTitleField()}
        {renderDescriptionField()}
      </div>
    );
  };

  return (
    <Form onFinish={onFormFinish} form={form}>
      <div className="Metadata">{renderForm()}</div>
      <WizardFooter onPrevious={RouteHelper.goBack} nextButtonType="submit" />
    </Form>
  );
};
