/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum CellTypePaddingDivider {
  FRAME = 1.2,
  COVER = 1.7,
  PROMO = 1.5,
  ROUND = 1.1,
  SQUARE = 2,
  HIGHLIGHTS = 1,
  LANDSCAPE = 1,
  CHANNEL = 1.7,
  PODCAST = 1.7,
  DEFAULT = 1.5,
}
