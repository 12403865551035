/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { HTTP_METHOD } from "../../../../../constants";
import { IVestigitDemoUserModel } from "../../../../../models";
import { AxiosSubscriber } from "../../../../../services";

import { VestigitBaseService } from "./VestigitBaseService";

export class VestigitDemoUserService extends VestigitBaseService {
  public getUsers = (): Observable<IVestigitDemoUserModel[]> =>
    new Observable(
      (observer: Observer<IVestigitDemoUserModel[]>) =>
        new AxiosSubscriber(observer, {
          isExternal: true,
          method: HTTP_METHOD.GET,
          baseURL: this.baseURL,
          url: "/demo-users",
        })
    );
}
