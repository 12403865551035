/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IMediaModel,
  IVestigitContentMetadataModel,
  useIsLoggedIn,
} from "@bms/common";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { DownloadSampleDialog, LoginRequiredModal } from "components";
import { Button } from "components/DesignSystem/Button";
import DownloadIcon from "resources/icons/general/Download.svg";

interface DownloadSampleButtonProps {
  media?: IMediaModel;
  metadata?: IVestigitContentMetadataModel;
  enabled?: boolean;
  loading?: boolean;
}

export const DownloadSampleButton = (props: DownloadSampleButtonProps) => {
  const { media, metadata, enabled, loading } = props;

  const isLoggedIn = useIsLoggedIn();
  const { t } = useTranslation();

  const externalIdAssigned = Boolean(media?.ExternalId);

  const isDisabled = !enabled || !externalIdAssigned;

  const renderButton = useCallback(
    (onClick?: () => void) => {
      return (
        <Button
          variant="secondary"
          onClick={onClick}
          disabled={isDisabled}
          loading={loading}
          shadow
        >
          <DownloadIcon />
          {t("DOWNLOAD_SAMPLE_BUTTON", "Download sample")}
        </Button>
      );
    },
    [isDisabled, loading, t]
  );

  if (!isLoggedIn) {
    return (
      <LoginRequiredModal>
        {({ openModal }) => renderButton(openModal)}
      </LoginRequiredModal>
    );
  }

  return (
    <DownloadSampleDialog metadata={metadata} disabled={isDisabled}>
      {renderButton()}
    </DownloadSampleDialog>
  );
};
