/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { memo } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { MediaButton } from "../MediaButton";
import ClipboardIcon from "../../resources/icons/clipboard.svg";
import { Tooltip } from "../Tooltip";

interface IClipboardCopyButtonProps extends WithTranslation {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customIcon?: any;
  className?: string;
  placement?: string;
  onClick?: () => void;
}

export const ClipboardCopyButton = withTranslation()(
  memo(
    ({
      t,
      customIcon,
      className,
      placement,
      onClick,
    }: IClipboardCopyButtonProps) => {
      const _onClick = () => {
        navigator.clipboard.writeText(window.location.href);
      };

      return (
        <Tooltip
          title={t("ASSET_COPY_LINK_TO_CLIPBOARD")}
          trigger="click"
          placement={placement}
        >
          <MediaButton
            variant="transparent"
            className={className}
            icon={customIcon || <ClipboardIcon />}
            iconElevated={true}
            onClick={onClick || _onClick}
          />
        </Tooltip>
      );
    }
  )
);
