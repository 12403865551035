/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAppState, IMediaPlayInfoOptionsModel, MediaStore } from "@bms/common";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
  IWatchScreenDispatchProps,
  IWatchScreenRouteProps,
  IWatchScreenStateProps,
  Watch as WatchDefinition,
} from "./Watch";

const mapStateToProps = (state: IAppState, props: IWatchScreenRouteProps) => {
  const mediaId = props.match.params.id ?? -1;

  return {
    configuration: state.configuration.configuration,
    media: state.media.media[mediaId] ?? {},
    mediaPlayInfo: state.media.mediaPlayInfo[mediaId] ?? {},
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getMedia: (id: number) => {
    return dispatch(
      MediaStore.Actions.getMedia({
        MediaId: id,
        IncludeCategories: true,
        IncludeImages: true,
        IncludePeople: true,
        IncludeSimilarMedia: true,
      })
    );
  },
  getMediaPlayInfo: (options: IMediaPlayInfoOptionsModel) => {
    return dispatch(MediaStore.Actions.getMediaPlayInfo(options));
  },
});

export const Watch = connect<
  IWatchScreenStateProps,
  IWatchScreenDispatchProps,
  IWatchScreenRouteProps,
  IAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(WatchDefinition));
