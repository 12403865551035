/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import {
  ActionType,
  ComponentType,
  ISettingsMenuItemComponentModel,
  useIsLoggedIn,
  useSettingsMenuConfiguration,
} from "@bms/common";
import { SettingsMenuItem } from "../SettingsMenuItem";

import "./SettingsMenu.scss";

export const SettingsMenu = () => {
  const settingsMenuScreen = useSettingsMenuConfiguration();
  const isLoggedIn = useIsLoggedIn();

  return (
    <div className="SettingsMenu__Container">
      <ul className="SettingsMenu">
        {settingsMenuScreen?.Components?.filter(
          (component: ISettingsMenuItemComponentModel) => component.IsVisible
        ).map((component: ISettingsMenuItemComponentModel) => {
          return <SettingsMenuItem component={component} key={component.Id} />;
        })}
        {isLoggedIn && (
          <SettingsMenuItem
            component={{
              Id: 999,
              ComponentTypeCode: ComponentType.SettingsMenuItem,
              TitleTranslationKey: "COMMON__LOGOUT",
              IconResourceKey: "ICON_LOGOUT",
              IsVisible: true,
              Action: {
                ActionType: ActionType.SignInOut,
              },
            }}
            key="logout"
          />
        )}
      </ul>
    </div>
  );
};
