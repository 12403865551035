/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { dispatch } from "../../../store";
import { IPaymentProvider } from "../IPaymentProvider";
import { ICheckoutRequestModel, IConfirmPaymentRequestModel } from "../models";
import { push } from "connected-react-router";
import { DataProvider } from "../../DataProvider";

export class CryptoCoinPaymentProvider implements IPaymentProvider {
  async init(): Promise<void> {
    return Promise.resolve();
  }

  async checkout(data: ICheckoutRequestModel): Promise<void> {
    dispatch(
      push(
        `/buy-confirmation?mediaId=${data.MediaId}&priceId=${data.PriceId}&paymentId=${data.SessionId}`
      )
    );
    return Promise.reject("Not implemented");
  }

  async confirm(data: IConfirmPaymentRequestModel): Promise<void> {
    DataProvider.confirmPayment(data);
    if (data.Action === "CANCEL") {
      dispatch(push("/"));
    }
    return Promise.resolve();
  }
}
