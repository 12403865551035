/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { SourceType } from "../../enums";
import { TimeHelper } from "../../helpers";
import { IMediaModel } from "../../models";

import * as Consts from "./consts";
import { IMediaState, MediaActionsTypes } from "./types";

export const initialState: IMediaState = {
  action: undefined,
  media: {},
  mediaPlayInfo: {},
  mediaList: {},
  mediaCategories: {
    Entities: [],
    TotalCount: 0,
  },
  mediaSearch: {
    Entities: [],
    TotalCount: 0,
  },
  mediaPurchaseOffers: {},
};

export const mediaReducer = (
  state = initialState,
  action: MediaActionsTypes
): IMediaState => {
  state.action = action;

  switch (action.type) {
    case Consts.GET_MEDIA: {
      return {
        ...state,
        media: {
          ...state.media,
          [action.options.MediaId]: {
            Media: {
              ...state.media[action.options.MediaId]?.Media,
              Id: action.options.MediaId,
            },
            IsLoading: true,
          },
        },
      };
    }
    case Consts.GET_MEDIA_SUCCESS: {
      return {
        ...state,
        media: {
          ...state.media,
          [action.payload.Id]: {
            ...state.media[action.payload.Id],
            Media: action.payload,
            Error: undefined,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.SEARCH_MEDIA_IN_MEDIA_SUCCESS: {
      if (!action.filter.MediaParentId) return state;

      const pageNumber = action.payload?.Filter?.PageNumber || 1;
      let entities: IMediaModel[] =
        state.mediaList?.[action.filter.MediaParentId]?.Entities || [];

      if (pageNumber === 1) {
        entities = action.payload.Entities;
      } else if (pageNumber > 1) {
        entities = [...entities, ...action.payload.Entities];
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.filter.MediaParentId]: {
            IsLoading: false,
            TotalCount: action.payload.TotalCount,
            Filter: action.filter,
            Entities: entities,
          },
        },
      };
    }
    case Consts.GET_MEDIA_FAILURE: {
      return {
        ...state,
        media: {
          ...state.media,
          [action.mediaId]: {
            ...state.media[action.mediaId],
            Media: undefined,
            Error: action.error,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_PLAY_INFO: {
      return {
        ...state,
        mediaPlayInfo: {
          ...state.mediaPlayInfo,
          [action.options.MediaId]: {
            Data: undefined,
            IsProcessing: true,
          },
        },
      };
    }
    case Consts.GET_MEDIA_PLAY_INFO_SUCCESS: {
      return {
        ...state,
        mediaPlayInfo: {
          ...state.mediaPlayInfo,
          [action.options.MediaId]: {
            ...state.mediaPlayInfo[action.options.MediaId],
            Data: action.payload,
            Error: undefined,
            IsProcessing: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_PLAY_INFO_FAILURE: {
      return {
        ...state,
        mediaPlayInfo: {
          ...state.mediaPlayInfo,
          [action.options.MediaId]: {
            ...state.mediaPlayInfo[action.options.MediaId],
            Data: undefined,
            Error: action.error,
            IsProcessing: false,
          },
        },
      };
    }
    case Consts.SEARCH_MEDIA: {
      let mediaList = state.mediaList[Consts.MEDIA_LIST_SEARCH_KEY];

      if (!mediaList) {
        mediaList = {
          Entities: [],
          TotalCount: 0,
          SourceType: SourceType.MediaList,
        };
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [Consts.MEDIA_LIST_SEARCH_KEY]: {
            ...mediaList,
            Filter: action.filter,
            IsLoading: true,
          },
        },
        mediaSearch: {
          ...state.mediaSearch,
          Filter: action.filter,
          Error: undefined,
          IsLoading: true,
        },
      };
    }
    case Consts.SEARCH_MEDIA_SUCCESS: {
      const pageNumber = action.payload?.Filter?.PageNumber;
      let entities: IMediaModel[] = [];

      if (pageNumber === 1) {
        entities = action.payload.Entities;
      } else if (pageNumber && pageNumber > 1) {
        // entities = [
        //     ...(state.mediaList[Consts.MEDIA_LIST_SEARCH_KEY]?.Entities || []),
        //     ...action.payload.Entities,
        // ];
        entities = [...state.mediaSearch.Entities, ...action.payload.Entities];
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [Consts.MEDIA_LIST_SEARCH_KEY]: {
            ...state.media[Consts.MEDIA_LIST_SEARCH_KEY],
            SourceType: SourceType.MediaList,
            Filter: action.payload.Filter,
            TotalCount: action.payload.TotalCount,
            Entities: entities,
            Error: undefined,
            IsLoading: false,
          },
        },
        mediaSearch: {
          ...state.mediaSearch,
          ...action.payload,
          Suggestions:
            action.payload.Suggestions ?? state.mediaSearch.Suggestions,
          Entities: entities,
          Error: undefined,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_MEDIA_FAILURE: {
      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [Consts.MEDIA_LIST_SEARCH_KEY]: {
            ...state.media[Consts.MEDIA_LIST_SEARCH_KEY],
            SourceType: SourceType.MediaList,
            Entities: [],
            TotalCount: 0,
            Error: action.error,
            IsLoading: false,
          },
        },
        mediaSearch: {
          ...state.mediaSearch,
          Entities: [],
          TotalCount: 0,
          Error: action.error,
          IsLoading: false,
        },
      };
    }
    case Consts.GET_MEDIA_LIST:
    case Consts.GET_MEDIA_LIST_FROM_CACHE: {
      let mediaList = state.mediaList[action.options.MediaListId];

      if (!mediaList) {
        mediaList = {
          Entities: [],
          TotalCount: 0,
          SourceType: SourceType.MediaList,
        };
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.options.MediaListId]: {
            ...mediaList,
            Filter: action.options,
            IsLoading: true,
          },
        },
      };
    }
    case Consts.GET_MEDIA_LIST_SUCCESS:
    case Consts.GET_MEDIA_LIST_FROM_CACHE_SUCCESS: {
      const pageNumber = action.payload?.Filter?.PageNumber;
      let entities: IMediaModel[] = [];

      if (pageNumber === 1) {
        entities = action.payload.Entities;
      } else if (pageNumber && pageNumber > 1) {
        entities = [
          ...(state.mediaList[action.mediaListId]?.Entities || []),
          ...action.payload.Entities,
        ];
      }
      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.mediaListId]: {
            ...state.media[action.mediaListId],
            ...action.payload,
            Entities: entities,
            Error: undefined,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_LIST_FAILURE:
    case Consts.GET_MEDIA_LIST_FROM_CACHE_FAILURE: {
      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.mediaListId]: {
            ...state.media[action.mediaListId],
            Entities: [],
            TotalCount: 0,
            Error: action.error,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_CATEGORIES: {
      return {
        ...state,
        mediaCategories: {
          ...state.mediaCategories,
          IsLoading: true,
          Error: undefined,
        },
      };
    }
    case Consts.GET_MEDIA_CATEGORIES_SUCCESS: {
      return {
        ...state,
        mediaCategories: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.GET_MEDIA_CATEGORIES_FAILURE: {
      return {
        ...state,
        mediaCategories: {
          ...state.mediaCategories,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.GET_MEDIA_LIST_FOR_EPG: {
      let mediaList = state.mediaList[action.options.MediaListId];

      if (!mediaList) {
        mediaList = {
          Entities: [],
          TotalCount: 0,
          SourceType: SourceType.MediaList,
        };
      }

      if (!mediaList.Sources) {
        mediaList.Sources = {};
      }

      const dateKey = action.options?.MediaOptions?.DateFrom
        ? TimeHelper.format(action.options.MediaOptions.DateFrom, "YYYY-MM-DD")
        : "ALL";

      mediaList.Sources[dateKey] = {
        ...mediaList.Sources[dateKey],
        Error: undefined,
        Filter: action.options,
        IsLoading: true,
      };

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.options.MediaListId]: {
            ...mediaList,
          },
        },
      };
    }
    case Consts.GET_MEDIA_LIST_FOR_EPG_SUCCESS: {
      let mediaList = state.mediaList[action.options.MediaListId];

      if (!mediaList) {
        mediaList = {
          Entities: [],
          TotalCount: 0,
          SourceType: SourceType.MediaList,
        };
      }

      if (!mediaList.Sources) {
        mediaList.Sources = {};
      }

      const dateKey = action.options?.MediaOptions?.DateFrom
        ? TimeHelper.format(action.options.MediaOptions.DateFrom, "YYYY-MM-DD")
        : "ALL";

      const source = {
        ...mediaList.Sources[dateKey],
        Filter: action.payload?.Filter,
        Error: undefined,
        IsLoading: false,
        TotalCount: action.payload.TotalCount,
      };

      const pageNumber = action.payload?.Filter?.PageNumber;

      if (pageNumber === 1) {
        source.Entities = action.payload.Entities;
      } else if (pageNumber && pageNumber > 1) {
        source.Entities = [
          ...(source?.Entities || []),
          ...action.payload.Entities,
        ];
      }

      mediaList.Sources[dateKey] = source;

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.mediaListId]: {
            ...state.mediaList[action.mediaListId],
            ...mediaList,
          },
        },
      };
    }
    case Consts.GET_MEDIA_LIST_FOR_EPG_FAILURE: {
      let mediaList = state.mediaList[action.options.MediaListId];

      if (!mediaList) {
        mediaList = {
          Entities: [],
          TotalCount: 0,
          SourceType: SourceType.MediaList,
        };
      }

      if (!mediaList.Sources) {
        mediaList.Sources = {};
      }

      const dateKey = action.options?.MediaOptions?.DateFrom
        ? TimeHelper.format(action.options.MediaOptions.DateFrom, "YYYY-MM-DD")
        : "ALL";

      const source = {
        ...mediaList.Sources[dateKey],
        IsLoading: false,
        Error: action.error,
      };

      mediaList.Sources[dateKey] = source;

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.mediaListId]: {
            ...state.mediaList[action.mediaListId],
            ...mediaList,
          },
        },
      };
    }
    case Consts.SELECT_MEDIA_PURCHASE_OFFERS: {
      return {
        ...state,
        mediaPurchaseOffers: {
          ...state.mediaPurchaseOffers,
          [action.mediaId]: {
            ...(state.mediaPurchaseOffers[action.mediaId] || []),
            IsProcessing: true,
            Error: undefined,
          },
        },
      };
    }
    case Consts.SELECT_MEDIA_PURCHASE_OFFERS_SUCCESS: {
      return {
        ...state,
        mediaPurchaseOffers: {
          ...state.mediaPurchaseOffers,
          [action.mediaId]: {
            ...(state.mediaPurchaseOffers[action.mediaId] || []),
            Data: action.payload,
            IsProcessing: false,
            Error: undefined,
          },
        },
      };
    }
    case Consts.SELECT_MEDIA_PURCHASE_OFFERS_FAILURE: {
      return {
        ...state,
        mediaPurchaseOffers: {
          ...state.mediaPurchaseOffers,
          [action.mediaId]: {
            ...(state.mediaPurchaseOffers[action.mediaId] || []),
            IsProcessing: false,
            Error: action.error,
          },
        },
      };
    }
    case Consts.CLEAR_MEDIA:
      return { ...initialState };
    default:
      return state;
  }
};
