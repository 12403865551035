/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ROUTES } from "@bms/common";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../../Button";
import "../../Form/Form.scss";
import { FormLine } from "../../Form/FormLine";
import "../AuthForm.scss";

type ForgotPasswordSuccessProps = WithTranslation;

export class ForgotPasswordSuccess extends React.Component<ForgotPasswordSuccessProps> {
  public render() {
    const { t } = this.props;
    return (
      <div className="ForgotPasswordSuccess AuthForm">
        <div className="form">
          <h1 className="text-upper text-center">
            {t("FORGOT_PASSWORD_SUCCESS__TITLE", "Check your email")}
          </h1>
          <FormLine style={{ marginTop: "25px" }} />

          <p className="text-center text-large">
            {t(
              "FORGOT_PASSWORD_SUCCESS__SUBTITLE",
              "If you are an existing user, we will immediately send you an email with a link to reset your password."
            )}
          </p>

          <div className="ButtonLine">
            <Link to={ROUTES.MAIN_SCREEN}>
              <Button buttonClassName="FormButton" type="button">
                {t("FORGOT_PASSWORD_SUCCESS__CONFIRM", "OK")}
              </Button>
            </Link>
          </div>

          <p className="text-center text-normal">
            {t(
              "FORGOT_PASSWORD_SUCCESS__HINT",
              "Can't find the message in your inbox? Check spam."
            )}
          </p>
        </div>
      </div>
    );
  }
}
