/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IApplicationRegisterScreenModel,
  IRegisterRequestEmailModel,
  ScreenType,
  useScreenConfiguration,
} from "@bms/common";
import React from "react";
import { RouteComponentProps } from "react-router";
import { AppHeaderVisibility } from "../../../components";
import { RegisterForm } from "../../../components/Forms/RegisterForm";
import { AuthScreen, IAuthScreenProps } from "../AuthScreen";
import "../AuthScreen.scss";

interface IRegisterScreenProps
  extends IAuthScreenProps,
    RouteComponentProps<
      Record<string, never>,
      Record<string, never>,
      IRegisterRequestEmailModel
    > {}

export const RegisterScreen: React.FC<IRegisterScreenProps> = (props) => {
  const screen = useScreenConfiguration<IApplicationRegisterScreenModel>(
    ScreenType.Register
  );

  const authScreenStyles: React.CSSProperties = {};

  if (screen.BackgroundUrl) {
    authScreenStyles.background = `url(${screen.BackgroundUrl}) no-repeat center center fixed`;
    authScreenStyles.backgroundSize = "cover";
    authScreenStyles.height = "auto";
  }

  return (
    <AuthScreen
      {...props}
      visibility={AppHeaderVisibility.Logo | AppHeaderVisibility.SignIn}
      authScreenStyles={authScreenStyles}
    >
      <RegisterForm initialValues={props.location.state} />
    </AuthScreen>
  );
};
