/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IApplicationRegisterSuccessScreenModel,
  ScreenType,
  useScreenConfiguration,
} from "@bms/common";
import React from "react";
import { AppHeaderVisibility } from "../../../components";
import { RegisterSuccess } from "../../../components/Forms/RegisterForm";
import { AuthScreen, IAuthScreenProps } from "../AuthScreen";
import "../AuthScreen.scss";

type IRegisterSuccessScreenProps = IAuthScreenProps;

export const RegisterSuccessScreen: React.FC<IRegisterSuccessScreenProps> = (
  props
) => {
  const screen = useScreenConfiguration<IApplicationRegisterSuccessScreenModel>(
    ScreenType.RegisterSuccess
  );

  const authScreenStyles: React.CSSProperties = {};

  if (screen.BackgroundUrl) {
    authScreenStyles.background = `url(${screen.BackgroundUrl}) no-repeat center center fixed`;
    authScreenStyles.backgroundSize = "cover";
  }

  return (
    <AuthScreen
      {...props}
      visibility={AppHeaderVisibility.Logo | AppHeaderVisibility.SignIn}
      authScreenStyles={authScreenStyles}
    >
      <RegisterSuccess />
    </AuthScreen>
  );
};
