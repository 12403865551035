/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useContext } from "react";
import { MediaButton } from "components/MediaButton";
import RcTooltip from "rc-tooltip";

import cx from "classnames";
import "./AudioSpeedButton.scss";
import { AudioContext } from "context";

interface IAudioSpeedButtonProps {
  className?: string;
}

const playbackRateValues = [3, 2, 1.5, 1, 0.5];

export const AudioSpeedButton = ({ className }: IAudioSpeedButtonProps) => {
  const { playbackRate = 1, setPlaybackRate } = useContext(AudioContext);
  return (
    <RcTooltip
      animation="zoom"
      trigger="hover"
      placement="top"
      overlayClassName="AudioSpeedButton__overlay"
      overlay={
        <div className="AudioSpeedButton__choice-container">
          {playbackRateValues.map((it) => (
            <MediaButton
              key={`audio-speed-button-choice--${it}`}
              variant="transparent"
              className={cx(
                "AudioSpeedButton__choice",
                it !== playbackRate ? "AudioSpeedButton__choice--inactive" : ""
              )}
              onClick={() => setPlaybackRate?.(it)}
            >
              {it}X
            </MediaButton>
          ))}
        </div>
      }
    >
      <MediaButton
        className={cx("AudioSpeedButton", className)}
        variant="transparent"
        onClick={() => setPlaybackRate?.(1)}
      >
        {playbackRate}X
      </MediaButton>
    </RcTooltip>
  );
};
