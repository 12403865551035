/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  DeviceHelper,
  IRegisterConfirmEmailModel,
  IUserDeviceModel,
  UrlHelper,
} from "@bms/common";
import { IAuthState } from "@bms/common/src/store/auth/types";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Form, FormButton, FormLine } from "../../Form";
import "../../Form/Form.scss";
import { LoaderSpinner } from "../../LoaderSpinner";
import "../AuthForm.scss";
import "./ConfirmEmail.scss";

interface IConfirmEmailProps extends RouteComponentProps, WithTranslation {
  onSubmit: (values: IRegisterConfirmEmailModel) => void;
  onValidate: (token: string) => void;
  authState: IAuthState;
}

interface IConfirmEmailState {
  isFormSent: boolean;
  hasErrors?: boolean;
}

interface IConfirmEmailQueryParams {
  email?: string;
  token?: string;
}

export class ConfirmEmail extends React.PureComponent<
  IConfirmEmailProps,
  IConfirmEmailState
> {
  _queryParams: IConfirmEmailQueryParams;
  _device: IUserDeviceModel | undefined = undefined;

  constructor(props: IConfirmEmailProps) {
    super(props);
    this._queryParams = UrlHelper.parse(
      this.props.location.search
    ) as IConfirmEmailQueryParams;
    this.state = {
      isFormSent: false,
      hasErrors: undefined,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IConfirmEmailProps,
    prevState: IConfirmEmailState
  ) {
    if (prevState.isFormSent && !nextProps.authState.isProcessing) {
      return {
        hasErrors: !!nextProps.authState.error,
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.onValidate(this._queryParams.token || "");

    DeviceHelper.getDeviceInfo().then((device) => {
      this._device = device;
    });
  }

  private onFinish = () => {
    const payload: IRegisterConfirmEmailModel = {
      Email: this._queryParams.email || "",
      Token: this._queryParams.token || "",
      Device: this._device,
    };

    this.setState({ isFormSent: true });
    this.props.onSubmit(payload);
  };

  private renderForm() {
    const { t } = this.props;
    return (
      <Form onFinish={this.onFinish}>
        <h1 className="text-upper text-center">
          {t("CONFIRM_EMAIL__TITLE", "Account confirmation")}
        </h1>
        <p className="text-center text-large">
          {t(
            "CONFIRM_EMAIL__SUBTITLE",
            "Please click the button below to activate your account."
          )}
        </p>
        <FormLine />

        <div className="ButtonLine">
          <FormButton>{t("CONFIRM_EMAIL__SUBMIT", "Activate")}</FormButton>
        </div>
      </Form>
    );
  }

  private renderError() {
    const { t } = this.props;
    return (
      <div className="form">
        <h1 className="text-upper text-center">
          {t("CONFIRM_EMAIL__INVALID_TOKEN", "The token is not valid")}
        </h1>
      </div>
    );
  }

  private renderLoader() {
    return (
      <div className="form" style={{ textAlign: "center" }}>
        <LoaderSpinner />
      </div>
    );
  }

  public render() {
    const { isTokenValid } = this.props.authState;
    const { hasErrors } = this.state;
    const isProcessing = isTokenValid === undefined && hasErrors === undefined;
    const isValidated = !isProcessing && isTokenValid && !hasErrors;

    return (
      <div className="ConfirmEmail AuthForm">
        {isProcessing
          ? this.renderLoader()
          : isValidated
          ? this.renderForm()
          : this.renderError()}
      </div>
    );
  }
}
