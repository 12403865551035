/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useCallback, useEffect, useRef } from "react";

interface useSeekHandlerParams {
  onStartScrubbing?: () => void;
  onEndScrubbing?: (position: number) => void;
  onProgressUpdate: (position: number, isScrubbing: boolean) => void;
  vertical?: boolean;
  readOnly?: boolean;
}

export const useSeekHandler = (params: useSeekHandlerParams) => {
  const {
    onStartScrubbing,
    onEndScrubbing,
    onProgressUpdate,
    vertical = false,
    readOnly = false,
  } = params;

  const lastMousePositionRef = useRef<number>(0);
  const scrubbingRef = useRef<boolean>(false);

  const onMouseUp = useCallback(
    (event: React.MouseEvent | MouseEvent) => {
      if (scrubbingRef.current && !readOnly) {
        lastMousePositionRef.current = vertical ? event.clientY : event.clientX;
        scrubbingRef.current = (event.buttons & 1) === 1;

        onEndScrubbing?.(lastMousePositionRef.current);
        onProgressUpdate(lastMousePositionRef.current, scrubbingRef.current);
      }
    },
    [onEndScrubbing, onProgressUpdate, readOnly, vertical]
  );

  const onMouseMove = useCallback(
    (event: React.MouseEvent | MouseEvent) => {
      if (!readOnly) {
        lastMousePositionRef.current = vertical ? event.clientY : event.clientX;

        if (scrubbingRef.current) {
          event.preventDefault();
        }

        onProgressUpdate(lastMousePositionRef.current, scrubbingRef.current);
      }
    },
    [onProgressUpdate, readOnly, vertical]
  );

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (!readOnly) {
        lastMousePositionRef.current = vertical ? event.clientY : event.clientX;
        scrubbingRef.current = (event.buttons & 1) === 1;

        scrubbingRef.current
          ? onStartScrubbing?.()
          : onEndScrubbing?.(lastMousePositionRef.current);

        onProgressUpdate(lastMousePositionRef.current, scrubbingRef.current);
      }
    },
    [onEndScrubbing, onProgressUpdate, onStartScrubbing, readOnly, vertical]
  );

  const onTouchStart = useCallback(
    (event: React.TouchEvent) => {
      if (!readOnly) {
        const { clientX, clientY } = event.touches[0];

        lastMousePositionRef.current = vertical ? clientY : clientX;
        scrubbingRef.current = true;

        onStartScrubbing?.();
      }
    },
    [onStartScrubbing, readOnly, vertical]
  );

  const onTouchMove = useCallback(
    (event: React.TouchEvent) => {
      if (!readOnly) {
        const { clientX, clientY } = event.changedTouches[0];

        lastMousePositionRef.current = vertical ? clientY : clientX;

        onProgressUpdate(lastMousePositionRef.current, scrubbingRef.current);
      }
    },
    [onProgressUpdate, readOnly, vertical]
  );

  const onTouchEnd = useCallback(
    (event: React.TouchEvent) => {
      if (!readOnly) {
        event.preventDefault();

        if (scrubbingRef.current) {
          scrubbingRef.current = false;
          onEndScrubbing?.(lastMousePositionRef.current);
          onProgressUpdate(lastMousePositionRef.current, scrubbingRef.current);
        }
      }
    },
    [onEndScrubbing, onProgressUpdate, readOnly]
  );

  const onContextMenu = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    if (readOnly) {
      return;
    }

    const documentOnMouseMove = (event: MouseEvent) => {
      scrubbingRef.current && onMouseMove(event);
    };

    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", documentOnMouseMove);

    return () => {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", documentOnMouseMove);
    };
  }, [onMouseMove, onMouseUp]);

  return {
    onMouseDown,
    onMouseMove,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onContextMenu,
  };
};
