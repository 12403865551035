/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetType,
  IAppState,
  IMediaModel,
  MediaHelper,
  MediaType,
  RouteHelper,
  TimeHelper,
  useIsLoggedIn,
  useIsLoginToPlayRequired,
} from "@bms/common";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { LoginRequiredModal, MediaIcon } from "components";
import PlayFilledIcon from "resources/icons/play-filled.svg";
import CartIcon from "resources/icons/shopping-cart.svg";

export interface IPlayIconButtonProps {
  media?: IMediaModel;
  style?: React.CSSProperties;
  className?: string;
}

export const PlayIconButton = ({
  media,
  style,
  className,
}: IPlayIconButtonProps) => {
  const isLoggedIn = useIsLoggedIn();

  const isProductOwnedByUser = useSelector((state: IAppState) =>
    MediaHelper.isUserOwnMedia(state.auth.user, media)
  );

  const isLoginToPlayRequired = useIsLoginToPlayRequired();

  const onPlayClick = useCallback(
    () => RouteHelper.goToDetails(media),
    [media]
  );

  const onBuyClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();

      if (media) {
        RouteHelper.goToBuyAsset(media?.Id);
      }
    },
    [media]
  );

  if (
    !media ||
    media?.MediaTypeCode === MediaType.Article ||
    media?.MediaTypeCode === AssetType.Package ||
    media?.MediaTypeCode === MediaType.Advertisement
  ) {
    return null;
  }

  const currentDate = TimeHelper.getCurrentDate();

  const { StartDateTime, EndDateTime } = media;

  const isBeforeOrAfterAvailableDate =
    (StartDateTime && currentDate.isBefore(StartDateTime)) ||
    (EndDateTime && currentDate.isAfter(EndDateTime));

  const isAvailable = !isBeforeOrAfterAvailableDate;

  const playButton =
    media?.MediaTypeCode !== AssetType.Album ? (
      <LoginRequiredModal>
        {({ toggleModal }) => (
          <MediaIcon
            style={style}
            className={className}
            onIconClick={isLoginToPlayRequired ? toggleModal : onPlayClick}
            onBackgroundClick={() => RouteHelper.goToDetails(media)}
          >
            <PlayFilledIcon />
          </MediaIcon>
        )}
      </LoginRequiredModal>
    ) : null;

  if (isProductOwnedByUser) {
    return isAvailable ? playButton : null;
  }

  return (
    <LoginRequiredModal>
      {({ toggleModal }) => (
        <MediaIcon
          style={style}
          className={className}
          onIconClick={isLoggedIn ? onBuyClick : toggleModal}
          onBackgroundClick={() => RouteHelper.goToDetails(media)}
        >
          <CartIcon />
        </MediaIcon>
      )}
    </LoginRequiredModal>
  );
};
