/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  buildRequiredRule,
  DataProvider,
  IErrorModel,
  IFormValues,
  IVestigitDemoUserModel,
  useDataLoader,
} from "@bms/common";
import React from "react";
import { useTranslation } from "react-i18next";

import { Form, FormButton, LabelField, Option, Select } from "components";

import "./PlayButtonForm.scss";
interface PlayButtonFormProps {
  onPlay: (userId: string) => void;
}

export const PlayButtonForm = (props: PlayButtonFormProps) => {
  const { onPlay } = props;

  const { t } = useTranslation();

  const { data: demoUsers } = useDataLoader<
    IVestigitDemoUserModel[],
    IErrorModel
  >({
    loader: () =>
      DataProvider.vestigitGetDemoUsers().then((data) => ({
        ok: true,
        data,
      })),
    deps: [],
  });

  const onFinish = (values: IFormValues) => onPlay(values.userId);

  return (
    <Form name="PlayButtonForm" onFinish={onFinish}>
      <div className="PlayButtonForm">
        <LabelField
          name="userId"
          errorClass={""}
          rules={[buildRequiredRule("number")]}
          label={t("PLAY_BUTTON_USER_LABEL", "User")}
          requiredIcon
        >
          <Select<number>
            placeholder={t(
              "PLAY_BUTTON_USER_SELECT_PLACEHOLDER",
              "Select user"
            )}
          >
            {demoUsers?.map((user) => (
              <Option value={user.uid} key={user.uid}>
                {user.name}
              </Option>
            ))}
          </Select>
        </LabelField>
        <FormButton>{t("COMMON__BUTTON_PLAY", "Play")}</FormButton>
      </div>
    </Form>
  );
};
