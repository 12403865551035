/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ThemeContext } from "@bms/common";
import Color from "color";
import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

import { RegisterLink } from "components";

import { ReactComponent as ChooseIcon } from "../../resources/choose.svg";
import { ReactComponent as WalletIcon } from "../../resources/wallet.svg";
import { ReactComponent as WatchIcon } from "../../resources/watch.svg";
import { useIsMobile } from "../Responsive";

import "./TokenPaymentInfo.scss";

export const TokenPaymentInfo = () => {
  const { t } = useTranslation();
  const { themeProvider } = useContext(ThemeContext);
  const linearGradientColor = Color(
    themeProvider.getColor("AppBackgroundColor")
  );

  const isMobile = useIsMobile();
  const fontColor = themeProvider.getColor("AppSecondaryTextColor");

  return (
    <div
      className="TokenPaymentInfo"
      style={{
        background: `linear-gradient(0deg, ${linearGradientColor
          .alpha(1)
          .string()} 0%, ${linearGradientColor
          .alpha(0.85)
          .string()} 50%, ${linearGradientColor.alpha(0).string()} 100%)`,
      }}
    >
      <h2 className="TokenPaymentInfo__title">
        {t("TOKEN_PAYMENT__HOW_IT_WORKS")}
      </h2>
      <p style={{ color: fontColor }}>
        <Trans i18nKey="TOKEN_PAYMENT__REGISTER_TO_WATCH">
          <RegisterLink>
            <span className="TokenPaymentInfo__link">register</span>
          </RegisterLink>
        </Trans>
      </p>
      {isMobile ? (
        <div className="TokenPaymentInfo__steps">
          <div className="TokenPaymentInfo__step-mobile">
            <ChooseIcon className="TokenPaymentInfo__image" />
            <div className="TokenPaymentInfo__step-title">
              <div className="TokenPaymentInfo__number">1</div>
              <p className="TokenPaymentInfo__text">
                {t("MEDIA_CREATOR__CATCHUP__ADD_SUBSCRIPTIONS")}
              </p>
            </div>
            <p className="text-center" style={{ color: fontColor }}>
              {t("TOKEN_PAYMENT__TOKEN_RENEVAL_INFO")}
            </p>
          </div>

          <div className="TokenPaymentInfo__step-mobile">
            <WalletIcon className="TokenPaymentInfo__image" />
            <div className="TokenPaymentInfo__step-title">
              <div className="TokenPaymentInfo__number">2</div>
              <p className="TokenPaymentInfo__text">
                {t("TOKEN_PAYMENT__ADDED_TO_WALLET_INFO")}
              </p>
            </div>
            <p className="text-center">
              {t("TOKEN_PAYMENT__TOKEN_NOT_EXPIRE_INFO")}
            </p>
          </div>

          <div className="TokenPaymentInfo__step-mobile">
            <WatchIcon className="TokenPaymentInfo__image" />
            <div className="TokenPaymentInfo__step-title">
              <div className="TokenPaymentInfo__number">3</div>
              <p className="TokenPaymentInfo__text">
                {t("TOKEN_PAYMENT__USE_TOKENS_INFO")}
              </p>
            </div>
            <p className="text-center" style={{ color: fontColor }}>
              {t("TOKEN_PAYMENT__TOKENS_EARNING_INFO")}
            </p>
          </div>
        </div>
      ) : (
        <div className="TokenPaymentInfo__steps">
          <ChooseIcon className="TokenPaymentInfo__image" />
          <WalletIcon className="TokenPaymentInfo__image" />
          <WatchIcon className="TokenPaymentInfo__image" />
          <div className="TokenPaymentInfo__step-title">
            <div className="TokenPaymentInfo__number">1</div>
            <p className="TokenPaymentInfo__text">
              {t("MEDIA_CREATOR__CATCHUP__ADD_SUBSCRIPTIONS")}
            </p>
          </div>
          <div className="TokenPaymentInfo__step-title">
            <div className="TokenPaymentInfo__number">2</div>
            <p className="TokenPaymentInfo__text">
              {t("TOKEN_PAYMENT__ADDED_TO_WALLET_INFO")}
            </p>
          </div>
          <div className="TokenPaymentInfo__step-title">
            <div className="TokenPaymentInfo__number">3</div>
            <p className="TokenPaymentInfo__text">
              {t("TOKEN_PAYMENT__USE_TOKENS_INFO")}
            </p>
          </div>
          <p style={{ color: fontColor }}>
            {t("TOKEN_PAYMENT__TOKEN_RENEVAL_INFO")}
          </p>
          <p style={{ color: fontColor }}>
            {t("TOKEN_PAYMENT__TOKEN_NOT_EXPIRE_INFO")}
          </p>
          <p style={{ color: fontColor }}>
            {t("TOKEN_PAYMENT__TOKENS_EARNING_INFO")}
          </p>
        </div>
      )}
    </div>
  );
};
