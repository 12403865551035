/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IntroScreen as IntroScreenDefinition } from "./IntroScreen";
import { IntroVideoScreen as IntroVideoScreenDefinition } from "./IntroVideoScreen";
import { SCREEN_VARIANTS } from "../../constants";

export const IntroScreen =
  process.env.REACT_APP_INTRO_SCREEN_TYPE === SCREEN_VARIANTS.INTRO.VIDEO
    ? IntroVideoScreenDefinition
    : IntroScreenDefinition;
