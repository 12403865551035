/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { HTTP_METHOD } from "../../../../../constants";
import {
  IInsertAssetRequestModel,
  IInsertAssetResponseModel,
  IVestigitPaginatedSamplesModel,
} from "../../../../../models";
import { AxiosSubscriber } from "../../../../../services";

import { VestigitBaseService } from "./VestigitBaseService";

export class VestigitSampleService extends VestigitBaseService {
  public getSamples = (): Observable<IVestigitPaginatedSamplesModel> =>
    new Observable(
      (observer: Observer<IVestigitPaginatedSamplesModel>) =>
        new AxiosSubscriber(observer, {
          isExternal: true,
          method: HTTP_METHOD.GET,
          baseURL: this.baseURL,
          url: "/samples",
        })
    );

  public createSamples = (
    data: IInsertAssetRequestModel
  ): Observable<IInsertAssetResponseModel> =>
    new Observable(
      (observer: Observer<IInsertAssetResponseModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `Assets/Insert`,
        })
    );
}
