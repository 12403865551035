/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AuthStore,
  IAppState,
  IRegisterRequestEmailModel,
  ROUTES,
  UserStore,
} from "@bms/common";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RegisterForm as RegisterFormPresentational } from "./RegisterForm";
import { RegisterSuccess as RegisterSuccessPresentational } from "../RegisterSuccess";

const mapStateToProps = (state: IAppState) => {
  return {
    authState: state.auth,
    consents: state.user.consents,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (data: IRegisterRequestEmailModel) => {
    return dispatch(
      AuthStore.Actions.registerEmail(data, ROUTES.REGISTER_SUCCESS)
    );
  },
  getConsents: () => {
    return dispatch(UserStore.Actions.getUserConsents());
  },
});

export const RegisterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RegisterFormPresentational));
export const RegisterSuccess = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RegisterSuccessPresentational));
