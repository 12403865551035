/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { createContext, useContext, useState } from "react";

import { TimelineProgram } from "../types";

interface EpgFocusedProgramContextValue {
  focusedProgram: TimelineProgram | undefined;
}

interface EpgFocusedProgramDispatchContextValue {
  setFocusedProgram: (program: TimelineProgram) => void;
}

interface EpgFocusedProgramProviderProps {
  children: React.ReactNode;
}

const EpgFocusedProgramContext = createContext<EpgFocusedProgramContextValue>(
  {} as EpgFocusedProgramContextValue
);

const EpgFocusedProgramDispatchContext =
  createContext<EpgFocusedProgramDispatchContextValue>(
    {} as EpgFocusedProgramDispatchContextValue
  );

export const EpgFocusedProgramProvider = (
  props: EpgFocusedProgramProviderProps
) => {
  const { children } = props;

  const [focusedProgram, setFocusedProgram] = useState<
    TimelineProgram | undefined
  >(undefined);

  return (
    <EpgFocusedProgramContext.Provider value={{ focusedProgram }}>
      <EpgFocusedProgramDispatchContext.Provider value={{ setFocusedProgram }}>
        {children}
      </EpgFocusedProgramDispatchContext.Provider>
    </EpgFocusedProgramContext.Provider>
  );
};

export const useEpgFocusedProgram = () => {
  const context = useContext(EpgFocusedProgramContext);

  if (!context) {
    throw new Error("Component beyond EpgFocusedProgramContext");
  }

  return context;
};

export const useEpgFocusedProgramDispatch = () => {
  const context = useContext(EpgFocusedProgramDispatchContext);

  if (!context) {
    throw new Error("Component beyond EpgFocusedProgramDispatchContext");
  }

  return context;
};
