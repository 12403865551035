/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useConfigurationSelector } from "@bms/common";
import React from "react";
import { useParams } from "react-router";

import { AppHeader } from "components";

import { WizardProvider } from "./WizardContext";

import "./EmbedderCreator.scss";

interface RouterParams {
  id: string;
}

export const EmbedderCreator = () => {
  const { id } = useParams<RouterParams>();
  const configuration = useConfigurationSelector();

  return (
    <>
      <AppHeader configuration={configuration} />
      <div className="EmbedderCreator">
        <WizardProvider assetId={id} />
      </div>
    </>
  );
};
