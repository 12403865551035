/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";
export const GET_USER_ASSETS_PROPERTIES = "GET_USER_ASSETS_PROPERTIES";
export const GET_USER_ASSETS_PROPERTIES_SUCCESS =
  "GET_USER_ASSETS_PROPERTIES_SUCCESS";
export const GET_USER_ASSETS_PROPERTIES_FAILURE =
  "GET_USER_ASSETS_PROPERTIES_FAILURE";
export const GET_USER_CONSENTS = "GET_USER_CONSENTS";
export const GET_USER_CONSENTS_SUCCESS = "GET_USER_CONSENTS_SUCCESS";
export const GET_USER_CONSENTS_FAILURE = "GET_USER_CONSENTS_FAILURE";
export const UPDATE_USER_CONSENT = "UPDATE_USER_CONSENT";
export const UPDATE_USER_CONSENT_SUCCESS = "UPDATE_USER_CONSENT_SUCCESS";
export const UPDATE_USER_CONSENT_FAILURE = "UPDATE_USER_CONSENT_FAILURE";
export const UPDATE_USER_CONSENTS = "UPDATE_USER_CONSENTS";
export const UPDATE_USER_CONSENTS_SUCCESS = "UPDATE_USER_CONSENTS_SUCCESS";
export const UPDATE_USER_CONSENTS_FAILURE = "UPDATE_USER_CONSENTS_FAILURE";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";
export const SEARCH_USER_ASSET_PURCHASES = "SEARCH_USER_ASSET_PURCHASES";
export const SEARCH_USER_ASSET_PURCHASES_SUCCESS =
  "SEARCH_USER_ASSET_PURCHASES_SUCCESS";
export const SEARCH_USER_ASSET_PURCHASES_FAILURE =
  "SEARCH_USER_ASSET_PURCHASES_FAILURE";
export const GET_USER_PURCHASES_AGGREGATED = "GET_USER_PURCHASES_AGGREGATED";
export const GET_USER_PURCHASES_AGGREGATED_SUCCESS =
  "GET_USER_PURCHASES_AGGREGATED_SUCCESS";
export const GET_USER_PURCHASES_AGGREGATED_FAILURE =
  "GET_USER_PURCHASES_AGGREGATED_FAILURE";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";
export const REACTIVATE_SUBSCRIPTION = "REACTIVATE_SUBSCRIPTION";
export const REACTIVATE_SUBSCRIPTION_SUCCESS =
  "REACTIVATE_SUBSCRIPTION_SUCCESS";
export const REACTIVATE_SUBSCRIPTION_FAILURE =
  "REACTIVATE_SUBSCRIPTION_FAILURE";
export const CHANGE_SUBSCRIPTION_PAYMENT_METHOD =
  "CHANGE_SUBSCRIPTION_PAYMENT_METHOD";
export const CHANGE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS =
  "CHANGE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS";
export const CHANGE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE =
  "CHANGE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE";
export const GET_USER_WALLET = "GET_USER_WALLET";
export const GET_USER_WALLET_SUCCESS = "GET_USER_WALLET_SUCCESS";
export const GET_USER_WALLET_FAILURE = "GET_USER_WALLET_FAILURE";
export const UPDATE_USER_WALLET = "UPDATE_USER_WALLET";
export const UPDATE_USER_WALLET_SUCCESS = "UPDATE_USER_WALLET_SUCCESS";
export const UPDATE_USER_WALLET_FAILURE = "UPDATE_USER_WALLET_FALIRE";
