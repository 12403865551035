/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { VideoEmbedderDialog } from "components";
import UploadIcon from "resources/icons/general/Add.svg";

import "./ListComponentAddItemFrame.scss";

export const ListComponentAddItemFrame = () => {
  const { t } = useTranslation();

  return (
    <div className="ListComponentAddItemFrame">
      <VideoEmbedderDialog>
        <div className={cx("ListComponentAddItemFrame__container")}>
          <div className="ListComponentAddItemFrame__image-container" />
          <div className="ListComponentAddItemFrame__overlay" />
          <div className="ListComponentAddItemFrame__upload">
            <UploadIcon />
            <div className="ListComponentAddItemFrame__upload-title">
              {t("VIDEO_EMBEDDER_WIZARD__ADD_ASSET", "Add asset")}
            </div>
          </div>
        </div>
      </VideoEmbedderDialog>
    </div>
  );
};
