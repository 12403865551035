/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useState, useRef, useEffect } from "react";
import "./LoginCodeInput.scss";
import { DataProvider, ILoginCodeModel } from "@bms/common";
import { useTranslation } from "react-i18next";
import { Input } from "../Input";
import { MediaButton } from "../MediaButton";
import { Form, LabelField } from "../Form";
import { LoaderSpinner } from "components";

export const LoginCodeInput: React.FC = () => {
  const { t } = useTranslation();
  const refElements = useRef<Input[]>([]);
  const [responseMessage, setResponseMessage] = useState<
    React.ReactNode | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const inputEl = [0, 1, 2, 3, 4, 5];

  useEffect(() => {
    refElements.current && refElements.current[0].focus();
  }, []);

  const keyHandler = (
    e: React.FormEvent<HTMLInputElement> & React.KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    if (e.key.length !== 1 && e.keyCode !== 8) {
      return;
    }
    const currentInputIdx = +e.currentTarget.name;

    setResponseMessage(undefined);

    if (e.keyCode === 8) {
      refElements.current[currentInputIdx].setValue("");

      if (currentInputIdx - 1 >= 0) {
        refElements.current[currentInputIdx - 1].focus();
      }
      return;
    }

    if (/^[A-Za-z0-9]*$/.test(e.currentTarget.value)) {
      refElements.current[currentInputIdx].setValue(e.key);
      if (e.currentTarget.value === "") {
        refElements.current[currentInputIdx].focus();
      } else if (currentInputIdx + 1 < refElements.current.length) {
        refElements.current[currentInputIdx + 1].focus();
      }
    }
  };

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentInputIdx = +e.currentTarget.name;

    if (!/^[A-Za-z0-9]*$/.test(e.currentTarget.value)) {
      refElements.current[currentInputIdx].input.value = "";
      e.currentTarget.value.replace(/[^a-z0-9]/gi, "");
    }
  };

  const onFinish = () => {
    const payload: ILoginCodeModel = {
      Code: refElements.current.map((value) => value.input.value).join(""),
    };

    if (payload.Code.length < inputEl.length) {
      setResponseMessage(
        <p className="LoginCodeResponse LoginCodeResponse__error">
          {t(
            "LINK__ERROR_SHORT_MESSAGE",
            "The code you have entered is too short. Please fill up all inputs"
          )}
        </p>
      );

      return;
    }

    setIsLoading(true);
    DataProvider.linkLoginCode(payload)
      .then(() => {
        setResponseMessage(
          <p className="LoginCodeResponse LoginCodeResponse__success">
            {t("LINK__SUCCESS_MESSAGE", "Your account has been linked")}
          </p>
        );
        setIsLoading(false);
      })
      .catch(() => {
        setResponseMessage(
          <p className="LoginCodeResponse LoginCodeResponse__error">
            {t(
              "LINK__ERROR_MESSAGE",
              "The code you have entered is incorrect. Please try again"
            )}
          </p>
        );
        setIsLoading(false);
      });
  };

  return (
    <Form className="LoginCodeContainer" onFinish={onFinish}>
      <div className="LoginCodeInputs">
        {inputEl &&
          inputEl.map((element) => (
            <div className="LoginCodeLabel" key={element}>
              <LabelField name={`input_${element}`} errorClass="CodeInput">
                <Input
                  onKeyUp={keyHandler}
                  onInput={inputHandler}
                  className="LoginCodeInput"
                  ref={(el: Input) => (refElements.current[element] = el)}
                  maxLength={1}
                  name={String(element)}
                />
              </LabelField>
            </div>
          ))}
      </div>

      {responseMessage}

      {isLoading ? (
        <LoaderSpinner width={50} height={50} />
      ) : (
        <MediaButton
          type="submit"
          className="LoginCodeButton"
          variant="primary"
        >
          {t("LINK__BUTTON", "Link")}
        </MediaButton>
      )}
    </Form>
  );
};
