/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { DeviceHelper } from "@bms/common";
import cx from "classnames";
import { useMemo } from "react";
import * as React from "react";

import LeftArrowIcon from "resources/icons/left-arrow.svg";
import RightArrowIcon from "resources/icons/right-arrow.svg";

import "./ListComponentArrow.scss";

export interface IListComponentArrowProps {
  containerStyle?: React.CSSProperties;
  onClick?: (event?: React.MouseEvent) => void;
  direction: "left" | "right";
  className?: string;
}

export const ListComponentArrow = ({
  onClick,
  containerStyle,
  direction,
  className = "",
}: IListComponentArrowProps) => {
  const isDisabled = useMemo(
    () => className.includes("slick-disabled"),
    [className]
  );

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => onClick?.(e);

  if (DeviceHelper.isMobileOrTablet()) {
    return null;
  }

  return (
    <div
      className={cx("ListComponentArrow", `ListComponentArrow--${direction}`, {
        disabled: isDisabled,
      })}
      style={containerStyle}
      onClick={handleClick}
    >
      {direction === "left" ? <LeftArrowIcon /> : <RightArrowIcon />}
    </div>
  );
};
