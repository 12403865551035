/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ITextWidgetComponentModel } from "@bms/common";
import React, { useMemo } from "react";

import "./TextWidgetComponent.scss";

import cx from "classnames";

interface ITextWidgetComponentProps {
  component: ITextWidgetComponentModel;
  isRenderedFirst: boolean;
}

export const TextWidgetComponent = (component: ITextWidgetComponentProps) => {
  const { IconUrl, Title, Content, FontColor, BackgroundColor } =
    component.component;
  const isRenderedFirst = component.isRenderedFirst;

  const TextWidgetClassName = cx("TextWidget", {
    TextWidget__WithImage: IconUrl,
    TextWidget__WithoutImage: !IconUrl,
  });
  const renderContent = useMemo(
    () => (
      <div
        className={
          IconUrl && isRenderedFirst
            ? `${TextWidgetClassName} isRenderedFirst`
            : `${TextWidgetClassName}`
        }
        style={{
          background: `Url(${IconUrl})`,
          backgroundColor: BackgroundColor,
        }}
      >
        <div className={`${TextWidgetClassName}-gradient`}></div>
        {Title && (
          <h1
            className={`${TextWidgetClassName}-header`}
            style={{ color: FontColor }}
          >
            {Title}
          </h1>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: Content || "" }}
          className={`${TextWidgetClassName}-content`}
          style={{ color: FontColor }}
        ></div>
      </div>
    ),
    [IconUrl, Title, Content, FontColor, BackgroundColor, TextWidgetClassName]
  );

  return renderContent;
};
