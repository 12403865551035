/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { StorageHelper } from "@bms/common";
import React, { useState, Suspense } from "react";
import { LoaderSpinner } from "../../components";
import defaultSvgLogo from "resources/app/logo.svg";
import "./Splash.scss";

export const Splash = () => {
  const brandingLogo = StorageHelper.getBrandingLogo();
  const [isLogoImagePainted, setIsLogoImagePainted] = useState(false);

  const suspenseFallback = (
    <div className="Splash__container">
      <img
        className="Splash__logo"
        src={defaultSvgLogo}
        alt="app logo"
        onLoad={() => setIsLogoImagePainted(true)}
      />
      {isLogoImagePainted && <LoaderSpinner width={75} height={75} />}
    </div>
  );

  return (
    <div className="Splash">
      <Suspense fallback={suspenseFallback}>
        {brandingLogo && (
          <div className="Splash__container">
            <div className="SplashBranding">
              <img
                className="Splash__logo"
                src={brandingLogo}
                alt="app logo"
                onLoad={() => setIsLogoImagePainted(true)}
              />
              <div className="Splash__spinner">
                {isLogoImagePainted && <LoaderSpinner width={75} height={75} />}
              </div>
            </div>
          </div>
        )}
      </Suspense>
    </div>
  );
};
