/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ISettingsMenuScreenModel } from "../../models";
import {
  ActionType,
  ComponentType,
  PlatformType,
  ScreenType,
} from "../../enums";
import { IAppState, useSelector } from "../../store";
import { PlatformHelper } from "../../helpers/platformHelper";
import { useScreenConfiguration } from "./useScreenConfiguration";

export const useSettingsMenuConfiguration = (): ISettingsMenuScreenModel => {
  const configuration = useSelector(
    (state: IAppState) => state.configuration.configuration
  );
  const isPaymentEnabled = configuration?.System?.Payment?.Enabled ?? true;
  let settingsMenu = useScreenConfiguration<ISettingsMenuScreenModel>(
    ScreenType.SettingsMenu
  );

  if (settingsMenu && settingsMenu?.Components) {
    if (PlatformHelper.Platform === PlatformType.Web) {
      return {
        ScreenTypeCode: ScreenType.SettingsMenu,
        Components: settingsMenu?.Components.filter((component) => {
          return component.Action?.ScreenTypeCode !== ScreenType.Help;
        }),
      };
    }
    return settingsMenu;
  }

  settingsMenu = {
    ScreenTypeCode: ScreenType.SettingsMenu,
    Components: [
      {
        Id: 1,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        TitleTranslationKey: "COMMON__PROFILE",
        IconResourceKey: "ICON_PROFILE",
        IsVisible: true,
        Action: {
          ActionType: ActionType.OpenScreen,
          ScreenTypeCode: ScreenType.Profile,
        },
      },
      {
        Id: 2,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        TitleTranslationKey: "COMMON__MY_ORDERS",
        IconResourceKey: "ICON_USER_ORDERS",
        IsVisible: isPaymentEnabled,
        Action: {
          ActionType: ActionType.OpenScreen,
          ScreenTypeCode: ScreenType.UserOrders,
        },
      },
      {
        Id: 3,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        TitleTranslationKey: "COMMON__TERMS_AND_CONDITIONS",
        IconResourceKey: "ICON_CONSENTS",
        IsVisible: true,
        Action: {
          ActionType: ActionType.OpenScreen,
          ScreenTypeCode: ScreenType.Consents,
        },
      },
      {
        Id: 4,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        TitleTranslationKey: "COMMON__ABOUT",
        IconResourceKey: "ICON_INFO",
        IsVisible: true,
        Action: {
          ActionType: ActionType.OpenScreen,
          ScreenTypeCode: ScreenType.About,
        },
      },
    ],
  };

  switch (PlatformHelper.Platform) {
    case PlatformType.AndroidPhone:
    case PlatformType.AndroidTablet:
    case PlatformType.iOSPhone:
    case PlatformType.iPad:
      settingsMenu.Components?.push({
        Id: 5,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        TitleTranslationKey: "COMMON__HELP",
        IconResourceKey: "ICON_HELP",
        IsVisible: true,
        Action: {
          ActionType: ActionType.OpenScreen,
          ScreenTypeCode: ScreenType.Help,
        },
      });
      break;
    case PlatformType.Web:
      settingsMenu.Components?.push({
        Id: 5,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        IsVisible: true,
      });
      break;
  }

  settingsMenu.Components?.push({
    Id: 6,
    ComponentTypeCode: ComponentType.SettingsMenuItem,
    TitleTranslationKey: "COMMON__LANGUAGE",
    IconResourceKey: "ICON_PREFERENCES",
    IsVisible: true,
    Action: {
      ActionType: ActionType.OpenScreen,
      ScreenTypeCode: ScreenType.Preferences,
    },
  });

  return settingsMenu;
};
