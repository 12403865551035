/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { RouteComponentProps } from "react-router";
import { ResetPasswordForm } from "../../../components/Forms";
import { AuthScreen, IAuthScreenProps } from "../AuthScreen";
import "../AuthScreen.scss";

interface IResetPasswordScreenProps
  extends IAuthScreenProps,
    RouteComponentProps {}

export class ResetPasswordScreen extends React.PureComponent<IResetPasswordScreenProps> {
  render() {
    return (
      <AuthScreen {...this.props}>
        <ResetPasswordForm {...this.props} />
      </AuthScreen>
    );
  }
}
