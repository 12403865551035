/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { FormLine, MediaButton } from "components";

import "./WizardFooter.scss";

interface WizardFooterProps {
  onPrevious: () => void;
  onNext?: () => void;
  nextButtonTitle?: string;
  nextButtonType: "button" | "submit" | "reset";
}

export const WizardFooter = (props: WizardFooterProps) => {
  const {
    onPrevious,
    onNext,
    nextButtonTitle = "Next",
    nextButtonType,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="WizardFooter">
      <div className="WizardFooter__Container">
        <FormLine />
        <div className="WizardFooter__Container__Actions">
          <MediaButton
            type="button"
            className="FormButton previous-button"
            iconElevated
            variant="plain"
            onClick={onPrevious}
          >
            {t("COMMON__BUTTON_PREVIOUS", "Previous")}
          </MediaButton>

          <MediaButton
            type={nextButtonType}
            className="FormButton"
            iconElevated
            variant="primary"
            onClick={onNext}
          >
            {t(
              `COMMON__BUTTON_PREVIOUS_${nextButtonTitle.toUpperCase()}`,
              nextButtonTitle
            )}
          </MediaButton>
        </div>
      </div>
    </div>
  );
};
