/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IVestigitSampleModel, TimeHelper } from "@bms/common";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { LoaderSpinner } from "components";
import { Button } from "components/DesignSystem/Button";
import { useDetector } from "context";
import RefreshIcon from "resources/icons/player/Repeat.svg";

import "./DetectionResults.scss";

export const DetectionResults = () => {
  const { t } = useTranslation();

  const { samples, demoUsers, refresh, loading, haveResults } = useDetector();

  const getDetectedUser = useCallback(
    (sample: IVestigitSampleModel): string =>
      demoUsers?.find((user) => user.uid === sample.result?.detected_user)
        ?.name || "-",
    [demoUsers]
  );

  const renderSample = useCallback(
    (sample: IVestigitSampleModel) => {
      return (
        <div key={sample.sid} className="DetectionResults__Row result">
          <div className="DetectionResults__Row__Container">
            <div className="DetectionResults__Row__Container__Column">
              <div className="DetectionResults__Row__Container__Column__Content">
                {sample.sid}
              </div>
            </div>
            <div className="DetectionResults__Row__Container__Column">
              <div className="DetectionResults__Row__Container__Column__Content">
                {sample.created_at &&
                  TimeHelper.format(sample.created_at, "DD-MM-YYYY")}
              </div>
            </div>
            <div className="DetectionResults__Row__Container__Column">
              <div className="DetectionResults__Row__Container__Column__Content">
                {sample.status}
              </div>
            </div>
            <div className="DetectionResults__Row__Container__Column">
              <div className="DetectionResults__Row__Container__Column__Content">
                {getDetectedUser(sample)}
              </div>
            </div>
            <div className="DetectionResults__Row__Container__Column">
              <div className="DetectionResults__Row__Container__Column__Content">
                {sample.result?.detected_data || "-"}
              </div>
            </div>
            <div className="DetectionResults__Row__Container__Column">
              <div className="DetectionResults__Row__Container__Column__Content">
                {sample.result?.detected_sequence || "-"}
              </div>
            </div>
          </div>
        </div>
      );
    },
    [getDetectedUser]
  );

  return (
    <div className="DetectionResults">
      <div className="DetectionResults__Header">
        <h2>{t("DETECTION_RESULTS_HEADER", "Detection results")}</h2>
        <Button
          variant="secondary"
          onClick={refresh}
          loading={loading}
          tooltip={t(
            "DETECTION_RESULTS_REFRESH_BUTTON_TOOLTIP",
            "Refresh results"
          )}
          iconOnly
        >
          <RefreshIcon />
        </Button>
      </div>

      <div className="DetectionResults__Row header">
        <div className="DetectionResults__Row__Container">
          <div className="DetectionResults__Row__Container__Column">
            {t("DETECTION_RESULTS_TABLE_ID", "ID")}
          </div>
          <div className="DetectionResults__Row__Container__Column">
            {t("DETECTION_RESULTS_TABLE_CREATED_AT", "Created At")}
          </div>
          <div className="DetectionResults__Row__Container__Column">
            {t("DETECTION_RESULTS_TABLE_STATUS", "Status")}
          </div>
          <div className="DetectionResults__Row__Container__Column">
            {t("DETECTION_RESULTS_TABLE_DETECTED_USER", "Detected user")}
          </div>
          <div className="DetectionResults__Row__Container__Column">
            {t("DETECTION_RESULTS_TABLE_DETECTED_DATA", "Detected data")}
          </div>
          <div className="DetectionResults__Row__Container__Column">
            {t(
              "DETECTION_RESULTS_TABLE_DETECTED_SEQUENCE",
              "Detected sequence"
            )}
          </div>
        </div>
      </div>

      {!haveResults && !loading && (
        <div className="DetectionResults__Empty">
          {t("DETECTION_RESULTS_EMPTY", "No results")}
        </div>
      )}

      {loading && (
        <div className="DetectionResults__Empty">
          <LoaderSpinner />
        </div>
      )}

      {haveResults &&
        !loading &&
        samples?.results.map((sample) => renderSample(sample))}
    </div>
  );
};
