/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  buildEmailRule,
  buildRequiredRule,
  IApplicationIntroVideoScreenModel,
  ROUTES,
  ScreenType,
  ThemeContext,
  useConfigurationSelector,
  useScreenConfiguration,
} from "@bms/common";
import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import {
  AppFooter,
  AppHeader,
  AppHeaderVisibility,
  Form,
  Input,
  LabelField,
  MediaButton,
  TokenPaymentInfo,
} from "../../components";

import "./IntroVideoScreen.scss";

export const IntroVideoScreen = ({ history }: RouteComponentProps) => {
  const { themeProvider } = useContext(ThemeContext);
  const backgroundDetailsColor = themeProvider.getColor("AppBackgroundColor");
  const configuration = useConfigurationSelector();
  const { t } = useTranslation();

  const externalRegistrationUrl = configuration?.System?.RegistrationUrl;

  const screen = useScreenConfiguration<IApplicationIntroVideoScreenModel>(
    ScreenType.Intro
  );

  const investInWeevLink =
    "https://app.seedlegals.com/en/companies/c_gkKRZ6AOHb/pitch";
  const fontColor = themeProvider.getColor("AppSecondaryTextColor");

  return (
    <div className="IntroVideoScreen">
      <video autoPlay muted loop className="IntroVideoScreen__video">
        <source src={screen.BackgroundVideoUrl} type="video/mp4" />
      </video>
      <div className="IntroVideoScreen__overlay" />
      <section className="IntroVideoScreen__header-section">
        <AppHeader
          configuration={configuration}
          visibility={AppHeaderVisibility.Logo | AppHeaderVisibility.SignIn}
        />
      </section>
      <section className="IntroVideoScreen__main-section">
        <h1 className="IntroVideoScreen__main-section__title">
          {t("LANDING_PAGE__TITLE")}
        </h1>
        <h2
          className="IntroVideoScreen__main-section__description"
          style={{ color: fontColor }}
        >
          <Trans i18nKey="LANDING_PAGE__DESCRIPTION">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={investInWeevLink}
              className="IntroVideoScreen__main-section__link"
            >
              Invest in Weev.
            </a>
          </Trans>
        </h2>
        <div className="IntroVideoScreen__main-section__additional-info">
          {t("LANDING_PAGE__PROVIDE_EMAIL_BELOW")}
        </div>
        <Form
          className="EmailForm"
          onFinish={(values) => {
            if (externalRegistrationUrl) {
              window.location.href = externalRegistrationUrl;
            } else
              history.push({
                pathname: ROUTES.REGISTER,
                state: values,
              });
          }}
        >
          <LabelField
            name="email"
            rules={[buildRequiredRule(), buildEmailRule()]}
            validateTrigger="onSubmit"
          >
            <Input
              autoFocus
              className="FormInput"
              placeholder={t("COMMON__EMAIL_PLACEHOLDER")}
            />
          </LabelField>
          <MediaButton variant="primary">
            {t("LANDING_PAGE__REGISTER")}
          </MediaButton>
        </Form>
      </section>
      <section>
        <TokenPaymentInfo />
      </section>
      <div style={{ background: backgroundDetailsColor, color: fontColor }}>
        <AppFooter />
      </div>
    </div>
  );
};
