/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { withTranslation } from "react-i18next";
import { ListComponentItemFrame as ListComponentItemFrameDefinition } from "./ListComponentItemFrame";

export const ListComponentItemFrame = withTranslation()(
  ListComponentItemFrameDefinition
);
