/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import cx from "classnames";
import React, { CSSProperties } from "react";

import { LoaderSpinner } from "components/LoaderSpinner";

import { ButtonProps } from "./types";

import "./Button.scss";

interface CSSPropertiesWithVariables extends CSSProperties {
  "--progress": number;
}

export const Button = (props: ButtonProps) => {
  const {
    variant,
    iconOnly = false,
    elevated = false,
    loading = false,
    progress = 0,
    forceHoverStyle = false,
    shadow = false,

    tooltip,
    tooltipAlign = "center",
    tooltipOffset = -40,
    tooltipOnDisabled = false,

    children,
    className,
    disabled,
    ...rest
  } = props;

  const buttonClasses = cx("Button", className, `variant-${variant}`, {
    ["icon-only"]: iconOnly,
    ["elevated"]: elevated,
    ["loading"]: loading,
    ["progress"]: progress > 0,
    ["hover"]: forceHoverStyle,
    ["shadow"]: shadow,
  });

  const style: CSSPropertiesWithVariables = {
    "--progress": progress,
  };

  const showTooltip = tooltip && (!disabled || tooltipOnDisabled) && !loading;

  return (
    <div className="Button__Wrapper">
      {showTooltip && (
        <span
          className="Button__Tooltip"
          data-align={tooltipAlign}
          style={{
            top: `${tooltipOffset}px`,
          }}
        >
          {tooltip}
        </span>
      )}
      <button
        className={buttonClasses}
        disabled={disabled || loading}
        {...rest}
      >
        <div className="Button__Content" style={progress ? style : undefined}>
          {loading ? (
            <LoaderSpinner
              className="Button__LoaderSpinner"
              width={24}
              height={24}
            />
          ) : (
            children
          )}
        </div>
      </button>
    </div>
  );
};
