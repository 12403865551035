/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ActionType,
  AssetType,
  AuthorizationHelper,
  dispatch,
  IActionModel,
  IAssetModel,
  RouteHelper as CommonRouteHelper,
  ScreenType,
  AuthStore,
} from "@bms/common";
import { push, replace } from "connected-react-router";
import { ROUTES } from "../constants";

export class RouteHelper {
  static goToDetails(asset: IAssetModel, replaceRoute?: boolean): void {
    let path: string;
    switch (asset.AssetTypeCode) {
      case AssetType.Series:
        path = `${ROUTES.SERIES_DETAILS_SCREEN}/${asset.Id}`;
        break;
      default:
        path = `${ROUTES.MOVIE_DETAILS_SCREEN}/${asset.Id}`;
        break;
    }

    dispatch(replaceRoute ? replace(path) : push(path));
  }

  static goToPlayer(asset: IAssetModel, replaceRoute?: boolean): void {
    const path = `${ROUTES.PLAYER_SCREEN}/${asset.Id}`;
    dispatch(replaceRoute ? replace(path) : push(path));
  }

  static async performAction(action?: IActionModel): Promise<void> {
    if (!action) {
      return Promise.resolve();
    }

    switch (action.ActionType) {
      case ActionType.SignInOut:
        const isAnonymous = await AuthorizationHelper.isAnonymous();

        isAnonymous
          ? CommonRouteHelper.goToLogin()
          : dispatch(AuthStore.Actions.signOut());
        break;
      case ActionType.OpenScreen:
        if (action?.ScreenId) {
          return dispatch(push(`${action?.ScreenId}`));
        }

        switch (action.ScreenTypeCode) {
          case ScreenType.Profile:
          case ScreenType.UserOrders:
          case ScreenType.Consents:
          case ScreenType.About:
          case ScreenType.Preferences:
            return dispatch(
              push(
                `${
                  ROUTES.SETTINGS
                }/${action?.ScreenTypeCode?.toLowerCase().replace("_", "-")}`
              )
            );
          default:
            return dispatch(
              push(
                `/${action?.ScreenTypeCode?.toLowerCase().replace("_", "-")}`
              )
            );
        }
        break;
      case ActionType.OpenUrl:
        if (action.Url) {
          window.open(action.Url, "_blank");
        }
        break;
    }
  }
}
