/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { GuidHelper, StorageService, UploadFileInfoModel } from "@bms/common";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
} from "react";

import { UploadAssistant } from "components";

import { reducer, UploadAssistantFileMetadata } from "./reducer";

type UploadContext = {
  title: string;
};

type UploadMetadata = {
  file: File | Blob;
  uploadInfo: UploadFileInfoModel;
};

type UploadAssistantDispatchContextValue = {
  uploadFile: (
    context: UploadContext,
    metadata: UploadMetadata
  ) => Promise<void>;
};

type UploadAssistantDataContextValue = {
  files: UploadAssistantFileMetadata[];
};

type UploadAssistantProviderProps = {
  children: ReactNode;
};

const UploadAssistantDispatchContext =
  createContext<UploadAssistantDispatchContextValue>(
    {} as UploadAssistantDispatchContextValue
  );

const UploadAssistantDataContext =
  createContext<UploadAssistantDataContextValue>(
    {} as UploadAssistantDataContextValue
  );

const FILE_UNREGISTER_TIMEOUT_MS = 5 * 1000;

export const UploadAssistantProvider = (
  props: UploadAssistantProviderProps
) => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, {
    files: [],
  });

  const uploadFile = useCallback(
    async (context: UploadContext, metadata: UploadMetadata) => {
      const guid = GuidHelper.newGuid();

      dispatch({ type: "register", guid, title: context.title });

      try {
        await StorageService.getInstance().uploadFile(
          metadata.file,
          metadata.uploadInfo,
          ({ percent }) => dispatch({ type: "progress", guid, percent })
        );
      } catch (e) {
        // we can add custom action if we want store error and show it on
        // UI, for now just unregister file on error
        dispatch({ type: "unregister", guid });
        throw e;
      }

      setTimeout(
        () => dispatch({ type: "unregister", guid }),
        FILE_UNREGISTER_TIMEOUT_MS
      );
    },
    []
  );

  return (
    <UploadAssistantDispatchContext.Provider value={{ uploadFile }}>
      <UploadAssistantDataContext.Provider value={{ files: state.files }}>
        {children}
        <UploadAssistant />
      </UploadAssistantDataContext.Provider>
    </UploadAssistantDispatchContext.Provider>
  );
};

export const useUploadAssistantDispatch =
  (): UploadAssistantDispatchContextValue => {
    const context = useContext(UploadAssistantDispatchContext);

    if (!context) {
      throw new Error("Component beyond UploadAssistantDispatchContext");
    }

    return context;
  };

export const useUploadAssistantData = (): UploadAssistantDataContextValue => {
  const context = useContext(UploadAssistantDataContext);

  if (!context) {
    throw new Error("Component beyond UploadAssistantDataContext");
  }

  return context;
};
