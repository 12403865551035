/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum MediaListType {
  Custom = "CUSTOM",
  MyList = "MY_LIST",
  Epg = "EPG",
}
