/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { MainScreen as MainScreenDefinition } from "./MainScreen";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IAppState } from "@bms/common";
import { push } from "connected-react-router";

const mapStateToProps = (state: IAppState) => {
  return {
    configuration: state.configuration.configuration,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeRoute: (route: string) => dispatch(push(route)),
});

export const MainScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainScreenDefinition);
