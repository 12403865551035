/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ImageHelper, MediaType, RouteHelper, ThemeContext } from "@bms/common";
import Color from "color";
import React from "react";
import { WithTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { LGUp, MDUp, PlayButton } from "components";
import { ImageRatio, ImageWithRatio } from "components/ImageWithRatio";
import { MediaAdditionalInfo } from "components/Media";
import { MediaStartInfo } from "components/Media/MediaStartInfo";
import { MediaButton } from "components/MediaButton";
import InfoIcon from "resources/icons/info.svg";
import resources from "resources/list";

import { IListComponentItemProps } from "../../models";

import "./ListComponentItemPromo.scss";

export interface IListComponentItemPromoProps
  extends IListComponentItemProps,
    WithTranslation {}

export class ListComponentItemPromo extends React.PureComponent<IListComponentItemPromoProps> {
  static contextType = ThemeContext;

  public static defaultProps: Partial<IListComponentItemPromoProps> = {
    width: 307,
    isPlaceholder: false,
  };

  render() {
    const { isPlaceholder, media, t } = this.props;
    if (!media || isPlaceholder) {
      const skeletonColor = this.context.themeProvider.getSkeletonColor();

      return (
        <div className="ListComponentItemPromo ListComponentItemPromo--skeleton">
          <div className="ListComponentItemPromo__Image ListComponentItemPromo__Image--skeleton" />
          <div className="ListComponentItemPromo__Container ListComponentItemPromo__Container--skeleton">
            <SkeletonTheme color={skeletonColor} highlightColor={skeletonColor}>
              <div className="ListComponentItemPromo__Skeleton">
                <div style={{ marginBottom: "5px" }}>
                  <Skeleton width={"50%"} height="45px" />
                </div>
                <div style={{ marginBottom: "24px" }}>
                  <Skeleton width={"50%"} height="16px" />
                </div>

                <div style={{ marginBottom: "5px" }}>
                  <Skeleton width={"70%"} height="21px" />
                </div>
                <div style={{ marginBottom: "5px" }}>
                  <Skeleton width={"70%"} height="21px" />
                </div>
                <div style={{ marginBottom: "5px" }}>
                  <Skeleton width={"60%"} height="21px" />
                </div>
              </div>
            </SkeletonTheme>
          </div>
        </div>
      );
    }
    const containerBackgroundStyle: React.CSSProperties = {};
    const frameImageUrl = ImageHelper.getFrameImageUrl(media?.Images);
    const isAdvertisement = media.MediaTypeCode === MediaType.Advertisement;

    const goToDetails = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.stopPropagation();

      RouteHelper.goToDetails(media);
    };

    if (frameImageUrl) {
      containerBackgroundStyle.backgroundImage = `url(${ImageHelper.getFrameImageUrl(
        media?.Images
      )})`;
    }

    return (
      <div className="ListComponentItemPromo">
        {/* This div is temporary solution to problem with overlaping image from neighbour slide to active one link to issue at react-slick repository https://github.com/akiran/react-slick/issues/655 */}
        <div className="ListComponentItemPromo__overlapping-cover-box" />
        <div className="ListComponentItemPromo__Background">
          <ImageWithRatio
            key={media?.Id}
            width={null}
            height={null}
            ratio={ImageRatio["16:9"]}
            className="ListComponentItemPromo__Image"
            imageSrc={
              ImageHelper.getFrameImageUrl(media?.Images) ??
              resources.framePlaceholder
            }
          />
          <div className="ListComponentItemPromo__Container">
            <div
              style={containerBackgroundStyle}
              className="ListComponentItemPromo__ContainerBackgroundImage"
            />
            <div
              className="ListComponentItemPromo__ContainerBackground"
              style={{
                background: `${Color(
                  this.context.themeProvider.getColor(
                    "AppDetailsBackgroundColor"
                  )
                )
                  .alpha(0.9)
                  .string()}`,
              }}
            />
            {!isAdvertisement && (
              <div className="ListComponentItemPromo__Content">
                <h1 className="ListComponentItemPromo__Title">
                  {media?.Title}
                </h1>
                <MDUp>
                  <MediaStartInfo media={media} />

                  <MediaAdditionalInfo media={media} />
                </MDUp>

                <LGUp>
                  <p
                    className="ListComponentItemPromo__Description"
                    dangerouslySetInnerHTML={{
                      __html: media?.ShortDescription || "",
                    }}
                  ></p>
                </LGUp>

                <div className="ListComponentItemPromo__ActionButtons">
                  <PlayButton media={media} />
                  <MediaButton
                    variant="plain"
                    icon={<InfoIcon />}
                    onClick={goToDetails}
                  >
                    {t("COMMON__BUTTON_MORE_INFO", "More info")}
                  </MediaButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
