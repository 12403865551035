/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  DataProvider,
  IVestigitAttackSampleParams,
  IVestigitContentMetadataModel,
} from "@bms/common";
import React, { ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, LoaderSpinner, Message } from "components";
import { DownloadSampleForm } from "components/Forms";

import "./DownloadSampleDialog.scss";

interface DownloadSampleDialogProps {
  metadata?: IVestigitContentMetadataModel;
  disabled?: boolean;
  children: ReactNode;
}

export const DownloadSampleDialog = (props: DownloadSampleDialogProps) => {
  const { metadata, children, disabled = false } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [isDownloading, setDownloading] = useState(false);

  const { t } = useTranslation();

  const openModal = useCallback(() => setIsVisible(true), []);
  const closeModal = useCallback(() => setIsVisible(false), []);

  const sampleFileName = metadata ? metadata.cid : "sample";

  const onDownload = useCallback(
    async (params: IVestigitAttackSampleParams) => {
      setDownloading(true);
      try {
        const response = await DataProvider.vestigitGetAttackSample(params);

        const href = window.URL.createObjectURL(response);

        const anchorElement = document.createElement("a");

        anchorElement.href = href;
        anchorElement.download = `${sampleFileName}.mp4`;

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);

        setDownloading(false);
      } catch (error) {
        setDownloading(false);
        Message.error(
          t("DETAILS_SCREEN__DOWNLOAD_SAMPLE_ERROR", "Unable to download data")
        );
        console.error(error);
      }
    },
    [sampleFileName, t]
  );

  return (
    <>
      <div onClick={isVisible || disabled ? undefined : openModal}>
        {children}
      </div>
      <Dialog
        visible={isVisible}
        onCancel={closeModal}
        footer={null}
        closable
        destroyOnClose
      >
        <div className="DownloadSampleDialog">
          <div className="DownloadSampleDialog__Header">
            <h2 className="text-upper text-center">
              {t("DOWNLOAD_SAMPLE_DIALOG__HEADER", "Download sample")}
            </h2>
          </div>
          <div className="DownloadSampleDialog__Content">
            {metadata ? (
              <DownloadSampleForm
                metadata={metadata}
                onDownload={onDownload}
                isDownloading={isDownloading}
              />
            ) : (
              <LoaderSpinner />
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};
