/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AssetImageType, ROUTES } from "@bms/common";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ImageWithPlaceholder } from "components";
import { useNavigation } from "hooks/useNavigation";
import resources from "resources/list";

import { WizardFooter } from "../../Component";
import { useCreatorMedia } from "../../CreatorMediaContext";
import { useWizard } from "../../WizardContext";

import "./Summary.scss";

interface DetailsSectionProps {
  title: string;
  content: string | number | undefined;
}
const DetailsSection = (props: DetailsSectionProps) => {
  const { title, content } = props;

  return (
    <div className="Summary__Details__Section">
      <p className="Summary__Details__Section__Title">{title}</p>
      <p className="Summary__Details__Section__Content">{content || "-"}</p>
    </div>
  );
};

export const Summary = () => {
  const { asset, refresh, getImage } = useCreatorMedia();
  const { previousStep, setLoadingState } = useWizard();
  const goTo = useNavigation();
  const { t } = useTranslation();

  const frameImageUrl = getImage(AssetImageType.Frame, asset)?.Url;

  const onPrevious = useCallback(async () => {
    setLoadingState(true);

    await refresh();

    previousStep();
    setLoadingState(false);
  }, [previousStep, refresh, setLoadingState]);

  const onNext = useCallback(() => {
    goTo(ROUTES.HOME);
  }, [goTo]);

  return (
    <>
      <div className="Summary">
        <div className="Summary__Details">
          <h1>{t("VIDEO_EMBEDDER_WIZARD__SUMMARY", "SUMMARY")}</h1>

          <DetailsSection
            title={t("VIDEO_EMBEDDER_WIZARD__ASSET_TITLE", "Title")}
            content={asset.Title}
          />

          <DetailsSection
            title={t("VIDEO_EMBEDDER_WIZARD__ASSET_CREATOR", "Creator")}
            content={asset.CreatorUserFullName}
          />

          <DetailsSection
            title={t("VIDEO_EMBEDDER_WIZARD__ASSET_DESCRIPTION", "Description")}
            content={asset.LongDescription}
          />
        </div>

        <div className="Summary__Preview">
          <ImageWithPlaceholder
            imageSrc={frameImageUrl}
            placeholderSrc={resources.framePlaceholder}
          />
        </div>
      </div>
      <WizardFooter
        onPrevious={onPrevious}
        onNext={onNext}
        nextButtonType="button"
        nextButtonTitle={t("COMMON__BUTTON_FINISH", "Finish")}
      />
    </>
  );
};
