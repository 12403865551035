/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useMemo, useEffect } from "react";
import { createPortal } from "react-dom";

interface IPortalProps {
  children?: React.ReactNode;
  portalRootId: string;
}

export const Portal = ({ children, portalRootId }: IPortalProps) => {
  const mountPoint = useMemo(() => {
    let _mountPoint = document.getElementById(portalRootId);
    if (!_mountPoint) {
      _mountPoint = document.createElement("div");
      _mountPoint.id = portalRootId;
    }
    return _mountPoint;
  }, [portalRootId]);

  const el = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    mountPoint.appendChild(el);
    return () => {
      mountPoint.removeChild(el);
    };
  }, [el, mountPoint]);

  return createPortal(children, el);
};
