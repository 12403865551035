/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum ImageType {
  Banner = "BANNER",
  ScreenBanner = "SCREEN_BANNER",
  Image = "IMAGE",
}
