/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import RcDrawer from "rc-drawer";
import { IDrawerProps as IRcDrawerProps } from "rc-drawer/lib/IDrawerPropTypes";
import XIcon from "../../resources/icons/x.svg";
import "./Drawer.scss";
import Color from "color";
import { ThemeContext } from "@bms/common";

type IDrawerProps = IRcDrawerProps;
export class Drawer extends React.Component<IDrawerProps> {
  static contextType = ThemeContext;

  render() {
    const { children, ...props } = this.props;
    return (
      <RcDrawer handler={false} level={null} showMask={true} {...props}>
        <div
          className="drawer-wrapper-body"
          style={{
            background: `${Color(
              this.context.themeProvider.getColor("AppModalsBackgroundColor")
            ).string()}`,
          }}
        >
          <div className="drawer-header">
            {this.props.onClose && (
              <div
                aria-label="Close"
                className="drawer-close"
                onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                  this.props.onClose?.(event)
                }
              >
                <i className="drawer-close-icon">
                  <XIcon />
                </i>
              </div>
            )}
          </div>

          <div
            className="drawer-body"
            style={{
              background: `${Color(
                this.context.themeProvider.getColor("AppModalsBackgroundColor")
              ).string()}`,
            }}
          >
            {children}
          </div>
        </div>
      </RcDrawer>
    );
  }
}
