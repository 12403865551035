/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ImageHelper } from "../../../helpers";
import { IMediaModel } from "../../../models";
import { EPGChannel } from "../models/EPGChannel";
import { EPGEvent } from "../models/EPGEvent";

export class EPGData {
  channels: EPGChannel[] = [];

  constructor(epgData?: IMediaModel[]) {
    if (epgData) {
      this.channels = this.createChannels(epgData);
    }
  }

  createChannels(epgData: IMediaModel[]): EPGChannel[] {
    return epgData.map(
      (channel: IMediaModel) =>
        new EPGChannel(
          ImageHelper.getFrameImageUrl(channel.Images),
          channel.Title,
          channel.Id,
          channel.ParentMediaId,
          channel.Media
        )
    );
  }

  getChannel(position: number) {
    return this.channels[position];
  }

  getEvents(channelPosition: number) {
    const channel = this.channels[channelPosition];
    const events = channel.getEvents();
    return events;
  }

  getEventCount(channelPosition: number) {
    return this.getEvents(channelPosition).length;
  }

  getEvent(channelPosition: number, programPosition: number) {
    const channel = this.channels[channelPosition];
    const events = channel.getEvents();
    return events[programPosition];
  }

  getEventPosition(channelPosition: number, event: EPGEvent) {
    const events = this.channels[channelPosition].getEvents();
    for (let i = 0; i < events.length; i++) {
      if (this.isEventSame(event, events[i])) {
        return i;
      }
    }

    return 0;
  }

  getChannelCount() {
    return this.channels.length;
  }

  isEventSame(event1: EPGEvent, event2: EPGEvent) {
    if (
      event1.getStart() == event2.getStart() &&
      event1.getEnd() == event2.getEnd()
    ) {
      return true;
    }
    return false;
  }

  hasData() {
    return this.getChannelCount() > 0;
  }
}
