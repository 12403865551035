/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export class EPGEvent {
  id: number | undefined;
  image: string;
  title: string;
  description: string;
  start: number;
  end: number;

  constructor(
    start: number,
    end: number,
    id: number | undefined,
    title: string,
    description: string,
    image: string
  ) {
    this.id = id;
    this.image = image;
    this.title = title;
    this.description = description;
    this.start = start;
    this.end = end;
  }

  getId() {
    return this.id;
  }

  getImage() {
    return this.image;
  }

  getTitle() {
    return this.title;
  }

  getDescription() {
    return this.description;
  }

  getStart() {
    return this.start;
  }

  getEnd() {
    return this.end;
  }

  isCurrent() {
    const now = Date.now();
    return now >= this.start && now <= this.end;
  }
}
