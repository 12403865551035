/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AuthStore,
  buildEmailRule,
  buildRequiredRule,
  IForgotPasswordModel,
  IFormValues,
} from "@bms/common";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Form, FormButton, LabelField } from "../../Form";
import "../../Form/Form.scss";
import { FormLine } from "../../Form/FormLine";
import { Input } from "../../Input";
import "../AuthForm.scss";
import "./ForgotPasswordForm.scss";

interface IForgotPasswordFormProps extends WithTranslation {
  onSubmit: (values: IForgotPasswordModel) => void;
  authState: AuthStore.Types.IAuthState;
}

export class ForgotPasswordForm extends React.PureComponent<IForgotPasswordFormProps> {
  private onFinish = (values: IFormValues) => {
    const payload: IForgotPasswordModel = {
      Email: values["email"],
    };

    this.props.onSubmit(payload);
  };

  private onCancel = () => {
    window.history.back();
  };

  public render() {
    const { t } = this.props;
    return (
      <div className="ForgotPasswordForm AuthForm">
        <Form onFinish={this.onFinish}>
          <h1 className="text-upper text-center">
            {t("FORGOT_PASSWORD__TITLE", "Forgot password")}
          </h1>
          <p className="text-center text-large">
            {t(
              "FORGOT_PASSWORD__SUBTITLE",
              "Please enter your requested data below to proceed with recovering/resetting your password."
            )}
          </p>
          <FormLine />

          <LabelField
            name="email"
            label="Email"
            rules={[buildRequiredRule(), buildEmailRule()]}
          >
            <Input className="FormInput" />
          </LabelField>

          <div className="ButtonLine">
            <FormButton>
              {t("FORGOT_PASSWORD__SUBMIT", "Send reset link")}
            </FormButton>
          </div>

          <p className="text-center text-normal" onClick={this.onCancel}>
            <span className="text-link text-underline">
              {t("FORGOT_PASSWORD__CANCEL", "Cancel")}
            </span>
          </p>
        </Form>
      </div>
    );
  }
}
