/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ApiErrors,
  buildEmailRule,
  buildNameMaxLengthRule,
  buildPhoneCountryCodeRule,
  buildPhoneMaxLengthRule,
  buildPhoneMinLengthRule,
  buildPhonePatternRule,
  buildRequiredRule,
  dispatch,
  IAppState,
  IFormValues,
  IStateModel,
  IUserModel,
  UserStore,
} from "@bms/common";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LoaderSpinner, MediaButton, Message } from "components";
import { Form, FormButton, LabelField } from "components/Form";
import { Input } from "components/Input";

import "./ContactsForm.scss";

export interface ContactsFormProps {
  avatarError?: string;
  profile: IStateModel<IUserModel>;
  avatar?: File;
}

export const ContactsForm = (props: ContactsFormProps) => {
  const { avatar, avatarError, profile } = props;
  const [apiErrors, setApiErrors] = useState<ApiErrors>({});

  const { t } = useTranslation();

  const action = useSelector<
    IAppState,
    UserStore.Types.UserActionsTypes | undefined
  >((state) => state.user?.action);

  const externalProfileEditUrl = useSelector(
    (state: IAppState) =>
      state.configuration.configuration?.System?.ProfileEditUrl
  );

  const updateProfile = (data: IUserModel) => {
    return dispatch(UserStore.Actions.updateProfile(data));
  };

  useEffect(() => {
    if (!action || !action.type) {
      return;
    }

    switch (action.type) {
      case UserStore.Consts.UPDATE_PROFILE_SUCCESS:
        Message.success(t("MESSAGE__SAVE_SUCCESS", "Successfully saved"));
        break;
      case UserStore.Consts.UPDATE_PROFILE_FAILURE:
        Message.error(t("MESSAGE__SAVE_ERROR", "Error during save"));
        break;
    }
  }, [action, t]);

  const onFinish = (values: IFormValues) => {
    if (!profile.Data || !profile.Data?.Id || avatarError) {
      return;
    }

    const payload: IUserModel = {
      ...profile.Data,
      FullName: values["name"],
      Email: values["email"],
      PhoneNumber: values["phone"],
      AvatarFile: avatar,
    };

    delete payload.UserName;

    updateProfile(payload);

    Message.info("Sent");
  };

  const onValuesChange = (changedValues: IFormValues, _: IFormValues) => {
    for (const field in changedValues) {
      if (field in apiErrors) {
        const newApiErrors = { ...apiErrors };
        delete newApiErrors[field];
        setApiErrors(newApiErrors);
      }
    }
  };

  const renderActionButton = () => {
    if (externalProfileEditUrl) {
      return null; // TODO: temporary solution, will be available later

      return (
        <MediaButton
          className="FormButton"
          type="button"
          variant="primary"
          onClick={() => window.open(externalProfileEditUrl, "_blank")}
        >
          {t("COMMON__BUTTON_EDIT_PROFILE")}
        </MediaButton>
      );
    }

    return (
      <div className="ButtonLine">
        <FormButton>{t("CONTACTS_FORM__SUBMIT", "Save changes")}</FormButton>
      </div>
    );
  };

  if (!profile || profile.IsProcessing) {
    const spinnerStyle: React.CSSProperties = {
      marginLeft: "20%",
    };
    return <LoaderSpinner style={spinnerStyle} />;
  }

  return (
    <div className="ContactsForm">
      <Form onFinish={onFinish} onValuesChange={onValuesChange}>
        <LabelField
          name="name"
          label={t("CONTACTS_FORM__NAME_LABEL", "Name")}
          rules={[buildRequiredRule(), buildNameMaxLengthRule()]}
          apiErrors={apiErrors.name || []}
          initialValue={profile?.Data?.FullName}
        >
          <Input
            className={
              !!externalProfileEditUrl ? "FormDisabledInput" : "FormInput"
            }
            disabled={!!externalProfileEditUrl}
          />
        </LabelField>
        <LabelField
          name="email"
          label={t("CONTACTS_FORM__EMAIL_LABEL", "Your current E-mail")}
          rules={[buildRequiredRule(), buildEmailRule()]}
          apiErrors={apiErrors.email || []}
          initialValue={profile?.Data?.Email}
        >
          <Input
            className={
              !!externalProfileEditUrl ? "FormDisabledInput" : "FormInput"
            }
            readOnly
            disabled={!!externalProfileEditUrl}
          />
        </LabelField>
        <LabelField
          name="phone"
          label={t("CONTACTS_FORM__PHONE_LABEL", "Phone")}
          rules={[
            buildPhonePatternRule(),
            buildPhoneCountryCodeRule(),
            buildPhoneMaxLengthRule(),
            buildPhoneMinLengthRule(),
          ]}
          apiErrors={apiErrors.phone || []}
          errorClass={"FormInputError phone"}
          initialValue={profile?.Data?.PhoneNumber}
        >
          <Input
            className={`${
              !!externalProfileEditUrl ? "FormDisabledInput" : "FormInput"
            } phone`}
            placeholder={t(
              "CONTACTS_FORM__PHONE_PLACEHOLDER",
              "Enter a phone number"
            )}
            disabled={!!externalProfileEditUrl}
          />
        </LabelField>
        {renderActionButton()}
      </Form>
    </div>
  );
};
