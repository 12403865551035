/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAppState, UrlHelper } from "@bms/common";
import cx from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { Drawer, MediaButton } from "../..";
import ChevronDown from "../../../resources/icons/chevron-down.svg";
import GridIcon from "../../../resources/icons/grid.svg";

import "./MediaCategoryDrawer.scss";

import { useSelector } from "react-redux";

const PAGE_SIZE = 20;

export const MediaCategoryDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const mediaCategories = useSelector(
    (state: IAppState) => state.media.mediaSearch.CategoriesSuggestions
  );

  const onCategoryChange = (categoryId?: number) => {
    setIsOpen(false);

    history.replace({
      search: UrlHelper.joinQueries(history.location.search, {
        category: categoryId,
      }),
    });
  };

  const handleShowMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const query = UrlHelper.parse(location.search) as { category?: string };
  const categoriesToShow = pageNumber * PAGE_SIZE;

  return (
    <div>
      <div
        className="MediaCategoryDrawer__trigger"
        onClick={() => setIsOpen(true)}
      >
        <i className="MediaCategoryDrawer__trigger__icon">
          <GridIcon />
        </i>
        <span className="MediaCategoryDrawer__trigger__text">
          {t(
            "MEDIA_CATEGORY_DRAWER__ALL_GENRES_COLLECTIONS",
            "All Genres & Collections"
          )}
        </span>
      </div>

      <Drawer
        className="MediaCategoryDrawer"
        open={isOpen}
        width={700}
        placement="left"
        level={null}
        onClose={() => setIsOpen(false)}
      >
        <div className="MediaCategoryDrawer__body">
          <div className="MediaCategoryDrawer__header">
            {t("MEDIA_CATEGORY_DRAWER__HEADER", "Genres")}
          </div>

          <div className="MediaCategoryList">
            <div
              className={cx("MediaCategory", {
                "MediaCategory--active": !query.category,
              })}
              onClick={() => onCategoryChange()}
            >
              {!query.category && <div className="MediaCategory__indicator" />}
              {t("MEDIA_CATEGORY_DRAWER__ALL_GENRES", "All genres")}
            </div>

            {mediaCategories &&
              mediaCategories
                ?.slice(0, categoriesToShow - 1)
                .map((category) => (
                  <div
                    key={category.CategoryId}
                    className={cx("MediaCategory", {
                      "MediaCategory--active":
                        query.category &&
                        category.CategoryId === parseInt(query.category, 10),
                    })}
                    title={category.CategoryName}
                    onClick={() => onCategoryChange(category.CategoryId)}
                  >
                    {query.category &&
                      category.CategoryId === parseInt(query.category, 10) && (
                        <div className="MediaCategory__indicator" />
                      )}
                    <div className="MediaCategory__name">
                      {category.CategoryName}
                    </div>
                  </div>
                ))}

            {mediaCategories && mediaCategories?.length > categoriesToShow && (
              <div className="MediaCategoryList__more">
                <MediaButton
                  icon={<ChevronDown />}
                  iconElevated={true}
                  variant="transparent"
                  onClick={handleShowMore}
                >
                  {t("COMMON__BUTTON_MORE", "Show more")}
                </MediaButton>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};
