/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum JustifyType {
  Start = "START",
  End = "END",
  Center = "CENTER",
  Stretch = "STRETCH",
}
