/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IDownloadSource } from "../models";
import { StorageHelperBase } from "./storageHelperBase";

export class StorageHelper extends StorageHelperBase {
  static async getPodcasts(): Promise<IDownloadSource[]> {
    return Promise.resolve([]);
  }

  static async updatePodcast(_: IDownloadSource): Promise<IDownloadSource[]> {
    return Promise.resolve([]);
  }

  static async deletePodcast(_: number): Promise<IDownloadSource[]> {
    return Promise.resolve([]);
  }
}
