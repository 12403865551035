/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  DataProvider,
  IAssetAgeRestrictionModel,
  IErrorModel,
  useDataLoader,
} from "@bms/common";
import { minBy } from "lodash";
import { useMemo } from "react";

export const useLowestAgeRestriction = () => {
  const { data } = useDataLoader<IAssetAgeRestrictionModel[], IErrorModel>({
    loader: () =>
      DataProvider.selectAgeRestriction().then((data) => ({
        ok: true,
        data,
      })),
    deps: [],
  });

  return useMemo(() => minBy(data, (item) => item.ValueMin), [data]);
};
