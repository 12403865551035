/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  CellType,
  ComponentType,
  dispatch,
  IAppState,
  IMediaSearchFilterModel,
  MediaStore,
  MIN_SEARCH_LENGTH,
  Orientation,
  UrlHelper,
  useSelector,
} from "@bms/common";
import { debounce } from "debounce";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  AppFooter,
  AppHeader,
  GlobalSearch,
  GridComponent,
  LoaderSpinner,
  MediaButton,
  MediaCategoryDrawer,
  PageHeader,
  XS,
} from "components";
import ChevronDown from "resources/icons/chevron-down.svg";

import "./SearchScreen.scss";

export interface SearchScreenQueryParams {
  category?: string;
  query?: string;
  suggestion?: boolean;
}

const searchMedia = (filter: IMediaSearchFilterModel) => {
  return dispatch(
    MediaStore.Actions.searchMedia({
      IncludeCategories: true,
      IncludeImages: true,
      ExcludeChildren: true,
      IncludeCategoriesSuggestions: true,
      PageSize: 12,
      IncludeSuggestions: true,
      ...filter,
    })
  );
};

export const SearchScreen = () => {
  const { t } = useTranslation();

  const configuration = useSelector(
    (state: IAppState) => state.configuration.configuration
  );

  const mediaList = useSelector((state: IAppState) => {
    return state.media.mediaList[MediaStore.Consts.MEDIA_LIST_SEARCH_KEY] || {};
  });

  const getSearchTextFilter = useSelector(
    (state: IAppState) => state.media.mediaSearch.Filter?.FullTextSearch
  );

  const currentParams = useMemo(() => {
    return UrlHelper.parse(location.search) as SearchScreenQueryParams;
  }, [location.search]);

  const previousParams = useRef(
    UrlHelper.parse(location.search) as SearchScreenQueryParams
  );

  const debouncedSearchMedia = useCallback(
    debounce((query?: string, suggestion?: boolean) => {
      searchMedia({
        ...mediaList?.Filter,
        FullTextSearch: query,
        PageNumber: 1,
        IncludeCategoriesSuggestions: query !== getSearchTextFilter,
        IncludeSuggestions: suggestion,
      });
    }, 400),
    []
  );

  useEffect(() => {
    searchMedia({
      IncludeCategories: true,
      IncludeImages: true,
      Categories: currentParams.category
        ? [parseInt(currentParams.category)]
        : undefined,
      FullTextSearch: currentParams.query,
      PageNumber: 1,
    });
  }, []);

  useEffect(() => {
    if (currentParams.suggestion) {
      debouncedSearchMedia(currentParams.query, !currentParams.suggestion);
    }

    if (currentParams.category !== previousParams.current.category) {
      searchMedia({
        ...mediaList?.Filter,
        Categories: currentParams.category
          ? [parseInt(currentParams.category)]
          : undefined,
        IncludeCategories: true,
        IncludeImages: true,
        IncludeCategoriesSuggestions:
          currentParams.query !== getSearchTextFilter,
        PageSize: 12,
        PageNumber: 1,
      });
    }

    if (
      currentParams.query !== previousParams.current.query &&
      !currentParams.suggestion &&
      (!currentParams.query ||
        (currentParams.query &&
          currentParams.query.length >= MIN_SEARCH_LENGTH))
    ) {
      debouncedSearchMedia(currentParams.query, !currentParams.suggestion);
    }

    return () => {
      previousParams.current = {
        category: currentParams.category,
        query: currentParams.query,
      };
    };
  }, [currentParams]);

  const hasItems = useMemo(
    () => mediaList.Entities?.length > 0,
    [mediaList?.Entities]
  );

  const hasMoreItems = mediaList?.TotalCount > mediaList?.Entities?.length;
  const isOnFirstPage =
    !mediaList?.Filter?.PageNumber || mediaList?.Filter?.PageNumber === 1;
  const isOnNextPage =
    mediaList?.Filter?.PageNumber && mediaList?.Filter?.PageNumber > 1;

  const getMore = () => {
    if (mediaList?.Filter?.PageNumber) {
      searchMedia({
        ...mediaList.Filter,
        PageNumber: mediaList.Filter.PageNumber + 1,
      });
    }
  };

  return (
    <div className="SearchScreen">
      <AppHeader configuration={configuration} />

      <section>
        <XS>
          <div className="SearchScreen__InputContainer">
            <GlobalSearch isMobile={true} />
          </div>
        </XS>

        <PageHeader title={t("SEARCH__HEADER", "Search results")} />

        <MediaCategoryDrawer />
        {!mediaList.IsLoading && !hasItems ? (
          <div className="SearchScreen__NoResults">
            {t("SEARCH__NO_RESULTS", "No search results")}
          </div>
        ) : mediaList.IsLoading && isOnFirstPage ? (
          <div className="SearchScreen__Loader">
            <LoaderSpinner />
          </div>
        ) : (
          <>
            <GridComponent
              rows={0}
              component={{
                ComponentTypeCode: ComponentType.List,
                CellType: CellType.Frame,
                Orientation: Orientation.Grid,
                MediaList: mediaList.Entities,
              }}
            />

            {hasMoreItems && (
              <div className="SearchScreen__Loader">
                {mediaList?.IsLoading && isOnNextPage ? (
                  <LoaderSpinner />
                ) : (
                  <MediaButton
                    icon={<ChevronDown />}
                    iconElevated={true}
                    variant="transparent"
                    onClick={getMore}
                  >
                    {t("COMMON__BUTTON_MORE", "Show more")}
                  </MediaButton>
                )}
              </div>
            )}
          </>
        )}
      </section>

      <AppFooter />
    </div>
  );
};
