/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import cx from "classnames";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useUploadAssistantData } from "context";
import ChevronDownIcon from "resources/icons/navigation/Chevron_down.svg";
import ChevronUpIcon from "resources/icons/navigation/Chevron_up.svg";
import WarningIcon from "resources/icons/notification/warning.svg";

import { Slider } from "../DesignSystem/Slider";

import "./UploadAssistant.scss";
export const UploadAssistant = () => {
  const [collapsed, setCollapsed] = useState(false);

  const { files } = useUploadAssistantData();
  const { t } = useTranslation();

  const shouldShowAssistant = files.length > 0;

  const onCollapseToggle = useCallback(
    () => setCollapsed((prevState) => !prevState),
    []
  );

  const contentClasses = cx("UploadAssistant__Content", {
    collapsed,
  });

  if (!shouldShowAssistant) {
    return null;
  }

  return (
    <div className="UploadAssistant">
      <div className="UploadAssistant__Header">
        {t("UPLOAD_ASSISTANT_HEADER", "Uploading files...")}
        <div
          className="UploadAssistant__Header__Colapse-Button"
          onClick={onCollapseToggle}
        >
          {collapsed ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </div>
      <div className={contentClasses}>
        <div className="UploadAssistant__Content__Warning">
          <WarningIcon />
          {t(
            "UPLOAD_ASSISTANT_WARNING",
            "Uploading will be terminated when you close browser tab."
          )}
        </div>

        {files.map((file) => {
          return (
            <div className="UploadAssistant__Content__Item" key={file.guid}>
              <p>{file.title}</p>
              <Slider value={file.percent / 100} readOnly />
            </div>
          );
        })}
      </div>
    </div>
  );
};
