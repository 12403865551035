/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  buildAssetUploadRule,
  buildRequiredRule,
  DataProvider,
  GuidHelper,
  IFormValues,
} from "@bms/common";
import { FormInstance } from "rc-field-form/lib/interface";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, FormButton, LabelField, Message, UploadFile } from "components";
import { useDetector, useUploadAssistantDispatch } from "context";

import "./SampleUploader.scss";

export const SampleUploader = () => {
  const { t } = useTranslation();

  const { uploadFile } = useUploadAssistantDispatch();
  const { refresh: reloadSamples } = useDetector();

  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidFile, setInvalidFile] = useState(false);
  const mountRef = useRef(false);

  const form = useRef<FormInstance>(null);

  const onInvalidFile = (correctAsset: boolean) => setInvalidFile(correctAsset);

  const updateInvalidFile = useCallback(() => {
    if (isInvalidFile) {
      return [buildAssetUploadRule(isInvalidFile)];
    }
    return [];
  }, [isInvalidFile]);

  useEffect(() => {
    mountRef.current = true;

    return () => {
      mountRef.current = false;
    };
  }, []);

  useEffect(() => {
    updateInvalidFile();
  }, [updateInvalidFile, isInvalidFile]);

  const onSubmit = async (file: Blob) => {
    setIsLoading(true);

    const response = await DataProvider.getStorageUploadInfo({
      path: `samples/${GuidHelper.newGuid()}`,
      provider: "Vestigit",
    });

    uploadFile(
      { title: t("SAMPLE_UPLOADER_FILE_TITLE", "Sample video for detection") },
      { file, uploadInfo: response }
    )
      .catch(() => {
        Message.error(
          t(
            "SAMPLE_UPLOADER_UPLOAD_ERROR",
            "Unable to upload detection sample video file."
          )
        );
      })
      .finally(() => mountRef.current && reloadSamples());
  };

  const onFinish = (values: IFormValues) =>
    onSubmit(values.File).finally(() => {
      form.current?.resetFields();
      setIsLoading(false);
    });

  return (
    <div className="SampleUploader">
      <h2>{t("SAMPLE_UPLOADER_HEADER", "Upload sample")}</h2>
      <Form name="SampleUploader" onFinish={onFinish} ref={form}>
        <LabelField
          name="File"
          rules={
            (updateInvalidFile()?.length > 0 && updateInvalidFile()) || [
              buildRequiredRule("object"),
            ]
          }
        >
          <UploadFile
            accept="video/*"
            getWrongAsset={onInvalidFile}
            withoutDescription
          />
        </LabelField>
        <FormButton loading={isLoading}>
          {t("SAMPLE_UPLOADER_UPLOAD_BUTTON", "Upload")}
        </FormButton>
      </Form>
    </div>
  );
};
