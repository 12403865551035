/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetType,
  DataProvider,
  definePaymentProvider,
  IAppState,
  IErrorModel,
  IFormValues,
  ImageHelper,
  IMediaModel,
  IPaymentTypeMappingAndOptionsModel,
  PaymentProvider,
  PaymentStatus,
  StorageManager,
  useDataLoader,
  useServiceCaller,
} from "@bms/common";
import { ROUTES } from "../../constants";
import { AnalyticsContext } from "context/analytics";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  Checkbox,
  Form,
  FormButton,
  ImageWithPlaceholder,
  LoginRequiredModal,
  MediaAdditionalInfo,
  MediaButton,
  Message,
  SMUp,
  Dialog,
} from "../../components";
import resources from "../../resources/list";
import { MediaDetailsScreen } from "../MediaDetailsScreen";
import { Splash } from "../Splash";
import "./PaymentScreen.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const INITIAL_OFFER_NUMBER = 3;

export const PaymentScreen = () => {
  const [seeAllOffers, setSeeAllOffers] = useState(false);
  const [checkUserId, setCheckUserId] = useState();
  const id = parseInt(useParams<{ id: string }>()["id"]);
  const { t } = useTranslation();
  const { trackEvent } = useContext(AnalyticsContext);
  const [showCoinModal, setShowCoinModal] = useState(false);
  const state = useSelector((state: IAppState) => state);

  const userWallet = state.user.wallet
    ? state.user.wallet.Data?.find((el) => el.CurrencyCode === "CC")?.Amount
    : 0;

  const getUserId = async () => {
    const userId = await StorageManager.getValue("userId");
    setCheckUserId(userId);
  };

  const { data: paymentProviders, loading: paymentProvidersLoading } =
    useDataLoader<IPaymentTypeMappingAndOptionsModel, IErrorModel>({
      loader: () =>
        DataProvider.getPaymentTypeMappingAndOptions().then((response) => {
          return {
            ok: true,
            data: response,
            error: {},
          };
        }),
      deps: [id],
    });

  useEffect(() => {
    getUserId();
    //TODO: based on call wit Tomek, this is a temp solution only for payment screen. This should be moved to a higher lever as a global part for whole app
    window.addEventListener("storage", getUserId);

    return () => {
      window.removeEventListener("storage", getUserId);
    };
  }, []);

  const { data: media, loading } = useDataLoader<IMediaModel, IErrorModel>({
    loader: () =>
      DataProvider.getMedia({
        MediaId: id,
        IncludeImages: true,
        IncludePurchaseOffers: true,
      }).then((data) => ({
        ok: true,
        data,
      })),
    deps: [id],
  });

  const [buyMedia, buyMediaState] = useServiceCaller(
    async (mediaId: number, mediaPriceId: number, mediaCode: string) => {
      if (paymentProvidersLoading || !paymentProviders) {
        Message.error(t("PAYMENT__PROVIDER_NOT_READY"));
        return;
      }

      const result = await DataProvider.buyMedia({
        MediaId: mediaId,
        MediaPriceId: mediaPriceId,
        PreferredProvider: mediaCode,
      })
        .then((data) => ({
          ok: true,
          data,
        }))
        .catch((error) => {
          const { MessageKey } = error;
          Message.error(t(MessageKey ?? "PAYMENT__BUY_ERROR_MESSAGE"));
        });

      if (result && result?.ok) {
        if (!checkUserId || checkUserId < 0) {
          return;
        }
        const options = paymentProviders.Options.find(
          (o) => o.Provider === result.data.Provider
        );
        if (options) {
          await definePaymentProvider(options);
          await PaymentProvider.init();
          await PaymentProvider.checkout({
            SessionId: result.data.PaymentId,
            RedirectUrl: result.data.RedirectUrl,
            MediaId: id,
            PriceId: mediaPriceId,
            Provider: result.data.Provider,
          });
        }
      }
    },
    [checkUserId, paymentProviders]
  );

  const mediaCoverImageSrc = ImageHelper.getCoverImageUrl(media?.Images) ?? "";

  const onFinish = (values: IFormValues) => {
    getUserId();
    if (!media || !checkUserId || checkUserId < 0) {
      return;
    }

    const selectedOffer = media?.PurchaseOffers?.find(
      ({ PriceId }) => values.MediaPriceId === PriceId
    );

    const paymentType = selectedOffer?.PurchasePeriodTypeCode;
    const paymentCode = selectedOffer?.CurrencyCode;
    const price = selectedOffer?.Price;

    const preferedProvider = paymentCode === "CC" ? "CRYPTO_COIN" : "";

    trackEvent?.("purchase", "buy_media", `${paymentType} ${media?.Title}`);

    if (paymentCode === "CC" && price && userWallet && userWallet < price) {
      setShowCoinModal(true);
    } else {
      buyMedia(media.Id, values.MediaPriceId, preferedProvider);
    }
  };

  const renderPaymentOffers = useMemo(() => {
    if (!media) {
      return null;
    }
    let offersToDisplay = media.PurchaseOffers || [];

    if (!seeAllOffers) {
      offersToDisplay = offersToDisplay.slice(0, INITIAL_OFFER_NUMBER);
    }

    let priceId: number | null = null;

    if (media.PurchaseOffers && media.PurchaseOffers.length > 0) {
      priceId = media.PurchaseOffers[0].PriceId;
    }

    return (
      <div className="Payment__offers">
        <Form.Field name="MediaPriceId" initialValue={priceId}>
          {({ onChange, value }) =>
            offersToDisplay.map((offer, index) => {
              return (
                <div
                  key={index}
                  className="Payment__offer"
                  onClick={() => onChange(offer.PriceId)}
                >
                  <div className="Payment__offer__content">
                    {!checkUserId ||
                      (checkUserId > 0 && (
                        <Checkbox checked={offer.PriceId === value} />
                      ))}
                    <span className="Payment__offer__name">
                      {offer.MediaTypeCode === AssetType.Package &&
                        `${offer.MediaTitle} - `}
                      {`${t(offer.PurchasePeriodTypeCode)} - ${
                        offer.MediaTypeDisplayName
                      } `}
                    </span>
                  </div>
                  <div className="Payment__offer__price">
                    {offer.Price} {offer.CurrencyCode}
                  </div>
                </div>
              );
            })
          }
        </Form.Field>
      </div>
    );
  }, [media, checkUserId, seeAllOffers]);

  const paymentInProgress = useMemo(() => {
    if (!media) {
      return true;
    }

    return (
      media.NotCompletedPayment?.Status === PaymentStatus.Processing ||
      media.NotCompletedPayment?.Status === PaymentStatus.Created
    );
  }, [media]);

  if (loading) {
    return <Splash />;
  }

  if (!checkUserId) {
    <div className="Payment__offers_title">{t("PAYMENT__OFFERS_TITLE")}</div>;
  }

  const renderOffers = () => {
    if (media?.IsGeoBlocked) {
      return <div className="Payment__empty">{t("ASSET_IS_GEOBLOCKED")}</div>;
    }

    if (!media?.PurchaseOffers?.length) {
      return <div className="Payment__empty">{t("PAYMENT__NO_OFFERS")}</div>;
    }

    return (
      <Form name="Payment" className="Payment__form" onFinish={onFinish}>
        {renderPaymentOffers}
        {media && media.PurchaseOffers && media.PurchaseOffers.length > 0 && (
          <div className="Payment__actions">
            {!checkUserId || checkUserId < 0 ? (
              <LoginRequiredModal>
                {({ openModal }) => (
                  <MediaButton
                    iconElevated
                    variant="primary"
                    onClick={openModal}
                    disabled={paymentInProgress}
                  >
                    {paymentInProgress
                      ? t("PAYMENT__BUTTON_IN_PROGRESS")
                      : t("PAYMENT__BUTTON_PAY")}
                  </MediaButton>
                )}
              </LoginRequiredModal>
            ) : (
              <FormButton
                loading={buyMediaState.processing}
                disabled={paymentInProgress}
              >
                {paymentInProgress
                  ? t("PAYMENT__BUTTON_IN_PROGRESS")
                  : t("PAYMENT__BUTTON_PAY")}
              </FormButton>
            )}
          </div>
        )}
        {media?.PurchaseOffers?.length > INITIAL_OFFER_NUMBER && !seeAllOffers && (
          <div className="Payment__more" onClick={() => setSeeAllOffers(true)}>
            <Trans
              i18nKey="PAYMENT__MORE_OFFERS"
              components={{
                underline: <span className="underline" />,
              }}
            />
          </div>
        )}
      </Form>
    );
  };

  return (
    <MediaDetailsScreen>
      <div className="Payment">
        <Dialog
          visible={showCoinModal}
          wrapClassName="Payment-upgrade-wallet"
          destroyOnClose
          onCancel={() => setShowCoinModal(false)}
          footer={null}
          centered
        >
          <h2>{t("PAYMENTSCREEN__UPGRADE_COINS_TITLE")}</h2>
          <div>{t("PAYMENTSCREEN__UPGRADE_COINS_DESCRIPTION")}</div>
          {/* TODO: Change link for link to purchase coins when functionality is done */}
          <Link to={ROUTES.MAIN_SCREEN}>
            <MediaButton variant="primary">
              {t("PAYMENTSCREEN__UPGRADE_COINS_BUTTON")}
            </MediaButton>
          </Link>
        </Dialog>
        <SMUp>
          <div className="Payment__product">
            <div className="Payment__product__image">
              <ImageWithPlaceholder
                imageSrc={mediaCoverImageSrc}
                imageContainerClassName="ImageWithPlaceholder"
                placeholderSrc={resources.coverPlaceholder}
              />
            </div>
          </div>
        </SMUp>
        <div className="Payment__content">
          <div className="Payment__title">{media?.Title}</div>
          {media && <MediaAdditionalInfo media={media} />}
          <div className="Payment__offers_title">
            {t("PAYMENT__OFFERS_TITLE")}
          </div>
          {renderOffers()}
        </div>
      </div>
    </MediaDetailsScreen>
  );
};
