/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ImageRatio, ImageWithRatio } from "components/ImageWithRatio";
import { MediaButton } from "components/MediaButton";
import PlayIcon from "../../../resources/icons/play.svg";
import ReplayIcon from "../../../resources/icons/replay.svg";
import React, { useEffect, useMemo, useState } from "react";

import "./ContinueListeningDialog.scss";
import { useTranslation } from "react-i18next";

interface IContinueListeningDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  imageSrc?: string;
}

export const ContinueListeningDialog = ({
  onCancel,
  onConfirm,
  imageSrc,
}: IContinueListeningDialogProps) => {
  const { t } = useTranslation();
  const [remainingTime, setRemainingTime] = useState(10);
  const timeoutId = useMemo(() => setTimeout(onConfirm, 10000), [onConfirm]);

  const handleCancel = () => {
    onCancel();
    clearTimeout(timeoutId);
  };

  const handleConfirm = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onConfirm();
    clearTimeout(timeoutId);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return (
    <div className="ContinueListeningDialog">
      <div className="ContinueListeningDialog__popup">
        {imageSrc && (
          <ImageWithRatio
            ratio={ImageRatio["8:5"]}
            disableAutoWH
            className="ContinueListeningDialog__image"
            imageSrc={imageSrc}
          />
        )}
        <div className="ContinueListeningDialog__action-buttons">
          <MediaButton
            onClick={handleCancel}
            icon={<PlayIcon />}
            iconElevated
            variant="transparent"
          >
            {t("LISTEN__START_FROM_BEGINNING")}
          </MediaButton>
          <MediaButton
            onClick={handleConfirm}
            icon={<ReplayIcon />}
            iconElevated
            variant="primary"
          >
            {timeoutId && `(${remainingTime}s) `}
            {t("LISTEN__CONTINUE_LISTENING")}
          </MediaButton>
        </div>
      </div>
    </div>
  );
};
