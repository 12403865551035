/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  CellType,
  IAppState,
  IListComponentModel,
  IMediaListModel,
  MediaListType,
  MediaStore,
  ROUTES,
  SourceType,
  ThemeContext,
} from "@bms/common";
import loadable from "@loadable/component";
import cx from "classnames";
import React, { ReactElement, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InView } from "react-intersection-observer";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LoaderSpinner, useListItemCount } from "components";
import { useRTL } from "hooks/useRTL";
import RightArrowIcon from "resources/icons/right-arrow.svg";

import { IListComponentProps } from "../../types";
import { ListComponentArrow } from "../ListComponentArrow";
import { ListComponentAddItem, ListComponentItem } from "../ListComponentItem";

import "./ListComponentHorizontal.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slider = loadable(() => import("react-slick"));

const useSourceSelector = (
  component: IListComponentModel
): IMediaListModel | undefined => {
  const mediaList =
    useSelector((state: IAppState) => state.media.mediaList) || {};

  if (component.SourceId) {
    return mediaList[component.SourceId];
  }

  if (component.MediaList) {
    return {
      SourceType: SourceType.MediaList,
      Entities: component.MediaList,
      TotalCount: component.MediaList.length,
    };
  }
};

const useGetMediaList = () => {
  const dispatch = useDispatch();
  const getMediaList = useCallback(
    (options: IListComponentModel) =>
      dispatch(
        MediaStore.Actions.getMediaListFromCache({
          MediaListId: options.SourceId!,
          Type:
            options.SourceType === SourceType.MyList
              ? MediaListType.MyList
              : undefined,
          IncludeCategories: true,
          IncludeImages: true,
          IncludeMedia: false,
          IncludeCount: true,
          PageNumber: 1,
          PageSize: 12,
        })
      ),
    [dispatch]
  );

  return getMediaList;
};

export interface IListComponentHorizontalProps extends IListComponentProps {
  placeholder?: React.ReactElement;
}

export const ListComponentHorizontal = ({
  className,
  component,
  loading: isLoading,
  placeholder,
  readOnly,
}: IListComponentHorizontalProps) => {
  const { themeProvider } = useContext(ThemeContext);
  const { t } = useTranslation();
  const source = useSourceSelector(component);
  const itemCount = useListItemCount(component);
  const getMediaList = useGetMediaList();
  const isListNotEmpty = source?.Entities
    ? source?.Entities?.length > 0
    : false;
  const isListLoading = source?.IsLoading || isLoading;
  const isRTL = useRTL();

  const isMyList = component.SourceType === SourceType.MyList;

  const inViewGetMediaList = useCallback(
    (inView: boolean) => {
      if (inView && component.SourceId && getMediaList) {
        getMediaList(component);
      }
    },
    [getMediaList, component.SourceId]
  );

  const isSourceEmpty =
    (!component.SourceId && !component.MediaList) ||
    (component.SourceId &&
      source &&
      !isListLoading &&
      source.Entities.length < 1 &&
      !isMyList);

  const listClassName = cx(`ListComponentHorizontal`, className, {
    "source-empty": isSourceEmpty,
  });

  const renderItems = useMemo(() => {
    if ((source && isListNotEmpty && !isListLoading) || isMyList) {
      if (isRTL) {
        source?.Entities.reverse();
      }

      const items: ReactElement[] = [];

      if (isMyList && component.CellType === CellType.Frame) {
        items.push(
          <ListComponentAddItem
            key={`add-asset-${component.Id}`}
            cellType={component.CellType}
            style={{
              fontSize: `${
                themeProvider.getFontSize() *
                themeProvider.getListItemCountFactor(itemCount)
              }px`,
            }}
          />
        );
      }

      if (source) {
        return items.concat(
          source.Entities.map((media) => (
            <ListComponentItem
              key={`${media.Id}`}
              cellType={component.CellType}
              media={media}
              isPlaceholder={false}
              readOnly={readOnly}
              withProgress={component.SourceType === SourceType.RecentlyWatched} // FIXME temporary for demo only
              parentItems={itemCount}
              style={{
                fontSize: `${
                  themeProvider.getFontSize() *
                  themeProvider.getListItemCountFactor(itemCount)
                }px`,
              }}
            />
          ))
        );
      }

      return items;
    }

    return (
      placeholder ||
      Array(itemCount)
        .fill(undefined)
        .map((_, idx) => (
          <ListComponentItem
            key={`placeholder_${idx}`}
            cellType={component.CellType}
            isPlaceholder
          />
        ))
    );
  }, [source, isListNotEmpty, isListLoading, isMyList]);

  const getArrowContainerStyle = useMemo(() => {
    switch (component.CellType) {
      case CellType.Frame:
      case CellType.Cover:
        return { background: "rgba(0, 0, 0, 0.6 )" };
    }
  }, [component.CellType]);

  const renderHeader = useMemo(() => {
    const skeletonColor = themeProvider.getColor("AppCellsBackgroundColor");
    const title = t(component.TitleTranslationKey || "", component.Title);
    const hasRedirectLink = !readOnly && component.SourceId;
    const showSeeAll =
      (source?.TotalCount && source.TotalCount >= 12) || isMyList;

    if (title || component.SourceId) {
      return (
        <SkeletonTheme color={skeletonColor} highlightColor={skeletonColor}>
          <header className="ListComponentHorizontal-title-container">
            <p className="ListComponentHorizontal-title">
              {isListNotEmpty || (isMyList && !isListLoading) ? (
                title
              ) : (
                <Skeleton width="25rem" />
              )}
            </p>
            {Boolean(
              isListNotEmpty ||
                (showSeeAll && !isListLoading && hasRedirectLink)
            ) ? (
              <Link
                className="ListComponentHorizontal-see-all"
                to={`${ROUTES.PLAYLIST_SCREEN}/${component.SourceId}/${component.Title}`}
              >
                {t("LIST__SEE_ALL", "See all")}
                <RightArrowIcon />
              </Link>
            ) : (
              Boolean(hasRedirectLink && showSeeAll) && (
                <p className="ListComponentHorizontal-title">
                  <Skeleton width="8rem" />
                </p>
              )
            )}
          </header>
        </SkeletonTheme>
      );
    }
  }, [
    themeProvider,
    t,
    component.TitleTranslationKey,
    component.Title,
    component.SourceId,
    readOnly,
    source?.TotalCount,
    isMyList,
    isListNotEmpty,
    isListLoading,
  ]);
  let headerVisible = true;

  switch (component.CellType) {
    case CellType.Highlights:
    case CellType.HighlightsWidescreen:
    case CellType.Promo:
    case CellType.HeroLandscape:
      headerVisible = false;
      break;
  }

  const renderContent = () => {
    let initialSlide = 0;
    let sliderKey = `slider-${component.Id}`;

    if (isSourceEmpty) {
      return null;
    }

    if (isRTL) {
      if (source && isListNotEmpty && !isListLoading) {
        sliderKey += "-rtl";
      }

      initialSlide = itemCount > 2 ? itemCount - 1 : 0;
    }

    return (
      <>
        {headerVisible && renderHeader}
        <div
          dir={isRTL ? "rtl" : "ltr"}
          className="ListComponentHorizontal-container"
        >
          <Slider
            key={sliderKey}
            rtl={isRTL}
            slidesToShow={itemCount}
            slidesToScroll={itemCount}
            initialSlide={initialSlide}
            infinite={
              component.CellType === CellType.HighlightsWidescreen ||
              (isRTL && React.Children.count(renderItems) >= itemCount)
            }
            dots={
              component.CellType === CellType.Highlights ||
              component.CellType === CellType.HighlightsWidescreen
            }
            speed={500}
            draggable={
              component.CellType === CellType.HighlightsWidescreen && false
            }
            arrows={component.CellType !== CellType.HighlightsWidescreen}
            nextArrow={
              <ListComponentArrow
                direction="right"
                containerStyle={getArrowContainerStyle}
              />
            }
            prevArrow={
              <ListComponentArrow
                direction="left"
                containerStyle={getArrowContainerStyle}
              />
            }
            autoplay={component.CellType === CellType.HighlightsWidescreen}
            autoplaySpeed={5000}
          >
            {renderItems}
          </Slider>
          {isListLoading && (
            <div className="ListComponentHorizontal-loader">
              <LoaderSpinner width={50} height={50} />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <InView
      as="div"
      id={`list-${component.Id}`}
      className={listClassName}
      root={document.body}
      rootMargin="25% 0px"
      triggerOnce
      onChange={inViewGetMediaList}
    >
      {renderContent()}
    </InView>
  );
};
