/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
const DAYS_BACK = 3; // 3 days
const DAYS_FORWARD = 3; // 3 days
const DAYS_BACK_MILLIS = DAYS_BACK * 24 * 60 * 60 * 1000; // 3 days
const DAYS_FORWARD_MILLIS = DAYS_FORWARD * 24 * 60 * 60 * 1000; // 3 days
const HOURS_IN_VIEWPORT_MILLIS = 2 * 60 * 60 * 1000; // 2 hours
const TIME_LABEL_SPACING_MILLIS = 30 * 60 * 1000; // 30 minutes

const VISIBLE_CHANNEL_COUNT = 6; // No of channel to show at a time
const VERTICAL_SCROLL_BOTTOM_PADDING_ITEM = 2;
const VERTICAL_SCROLL_TOP_PADDING_ITEM = 2;

export const EpgConsts = {
  DAYS_BACK,
  DAYS_FORWARD,
  DAYS_BACK_MILLIS,
  DAYS_FORWARD_MILLIS,
  HOURS_IN_VIEWPORT_MILLIS,
  TIME_LABEL_SPACING_MILLIS,
  VISIBLE_CHANNEL_COUNT,
  VERTICAL_SCROLL_BOTTOM_PADDING_ITEM,
  VERTICAL_SCROLL_TOP_PADDING_ITEM,
};
