/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AudioStore,
  IAppState,
  IMediaModel,
  MediaHelper,
  MediaImageType,
  ROUTES,
  ThemeContext,
  useDispatch,
  useIsLoggedIn,
  useSelector,
  TimeHelper,
  useMediaProgress,
} from "@bms/common";
import cx from "classnames";
import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import { ImageRatio, ImageWithRatio } from "components/ImageWithRatio";
import { LoginRequiredModal } from "components/LoginRequiredModal";
import { MediaButton } from "components/MediaButton";
import { AudioContext } from "context";
import { ReactComponent as PodcastFallback } from "resources/default-podcast.svg";
import { ReactComponent as AudioBuyArrowIcon } from "resources/player/audio_arrow.svg";
import { ReactComponent as PauseIcon } from "resources/player/pause.svg";
import { ReactComponent as PlayIcon } from "resources/player/play.svg";

import "./PodcastDetailsCard.scss";

import { ProgressBar } from "../../ProgressBar";

interface IPodcastDetailsCardProps {
  media: IMediaModel;
}

export const PodcastDetailsCard = ({ media }: IPodcastDetailsCardProps) => {
  const { themeProvider } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const progress = useMediaProgress(media);
  const {
    playing,
    pauseAudio,
    playAudio,
    setIsLoading,
    currentTime,
    duration,
    isLoading,
  } = useContext(AudioContext);
  const audioMedia = useSelector(AudioStore.Selectors.audioMediaSelector);
  const assetDetailsUrl = useMemo(
    () => `${ROUTES.PODCAST_DETAILS_SCREEN}/${media?.Id}`,
    [media]
  );
  const isOwnedByUser = useSelector((state: IAppState) =>
    MediaHelper.isUserOwnMedia(state.auth.user, media)
  );
  const isLoggedIn = useIsLoggedIn();

  const isActive = useMemo(
    () => isLoggedIn && audioMedia?.Id === media.Id,
    [audioMedia, isLoggedIn, media]
  );
  const textStyle = useMemo(
    () =>
      isActive ? { color: themeProvider.getColor("AppPrimaryColor") } : {},
    [isActive, themeProvider]
  );
  const isPlaying = useMemo(
    () => playing && audioMedia?.Id === media.Id,
    [media, audioMedia, playing]
  );

  const liveProgress = useMemo(() => {
    if (
      currentTime &&
      currentTime > 0 &&
      media.Id === audioMedia?.Id &&
      duration &&
      !isLoading
    ) {
      return (currentTime / duration) * 100;
    }
    return progress;
  }, [currentTime, media.Id, audioMedia?.Id, progress]);

  const imageSrc = useMemo(() => {
    let src: string | undefined;
    if (media.Images) {
      const squareImg = media.Images?.find(
        (it) => it.ImageTypeCode === MediaImageType.Square
      )?.Url;
      if (squareImg) {
        src = squareImg;
      } else if (media.Images && media.Images.length > 0) {
        src = media.Images[0].Url;
      }
    }
    return src;
  }, [media.Images]);

  const renderImage = useMemo(() => {
    if (!imageSrc) {
      return (
        <PodcastFallback
          className="PodcastDetailsCard__image"
          style={{ color: themeProvider.getColor("AppPrimaryTextColor") }}
        />
      );
    }
    return (
      <ImageWithRatio
        ratio={ImageRatio["1:1"]}
        disableAutoWH
        className="PodcastDetailsCard__image"
        imageSrc={imageSrc}
      />
    );
  }, [imageSrc]);

  const playMedia = () => {
    if (audioMedia?.Id === media.Id) {
      playAudio?.();
    } else {
      pauseAudio?.();
      setIsLoading?.(true);
      dispatch(AudioStore.Actions.setAudioMedia({ media }));
    }
  };

  return (
    <div
      className={cx("PodcastDetailsCard", {
        "PodcastDetailsCard--active": isActive,
      })}
    >
      {renderImage}
      <div className="PodcastDetailsCard__details">
        <span
          className="PodcastDetailsCard__text PodcastDetailsCard__text--small"
          style={{ color: themeProvider.getColor("AppPrimaryColor") }}
        >
          <Link to={assetDetailsUrl}>#{media.OrderInParent}</Link>
        </span>
        <span className="PodcastDetailsCard__text" style={textStyle}>
          <Link to={assetDetailsUrl}>
            <strong>{media.Title}</strong>
          </Link>
        </span>
        <span className="PodcastDetailsCard__text">
          {TimeHelper.formatDuration(media.Duration)}
        </span>
        <span
          className="PodcastDetailsCard__text PodcastDetailsCard__text--small PodcastDetailsCard__description"
          dangerouslySetInnerHTML={{
            __html:
              media.Description ||
              media.ShortDescription ||
              media.LongDescription ||
              "&nbsp",
          }}
        ></span>

        {liveProgress != -1 && progress != -1 && (
          <ProgressBar progress={liveProgress} />
        )}
      </div>
      {isLoggedIn && isOwnedByUser && (
        <MediaButton
          className={cx("PodcastDetailsCard__button", {
            "PodcastDetailsCard__button--isPlaying": isPlaying,
          })}
          variant="unfilled"
          icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
          onClick={isPlaying ? pauseAudio : playMedia}
        />
      )}
      {isLoggedIn && !isOwnedByUser && (
        <Link
          className="PodcastDetailsCard__a"
          to={`${ROUTES.BUY_SCREEN}/${media.Id}`}
        >
          <MediaButton
            className="PodcastDetailsCard__button"
            icon={<AudioBuyArrowIcon />}
            iconElevated
            variant="unfilled"
          />
        </Link>
      )}
      {!isLoggedIn && (
        <LoginRequiredModal>
          {({ openModal }) => (
            <MediaButton
              className="PodcastDetailsCard__button"
              icon={<AudioBuyArrowIcon />}
              iconElevated
              variant="unfilled"
              onClick={openModal}
            />
          )}
        </LoginRequiredModal>
      )}
    </div>
  );
};
