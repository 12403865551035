/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AudioStore,
  DataProvider,
  MediaStreamType,
  ROUTES,
  useConfigurationSelector,
  useDispatch,
  useMedia,
  useSelector,
} from "@bms/common";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";

import { AudioContext } from "context";

import { Portal } from "../Portal";

import { ContinueListeningDialog } from "./ContinueListeningDialog";

let audioProgressIntervalId: NodeJS.Timeout | undefined;

export const AudioPlayer = memo(() => {
  const [previousSrc, setPreviousSrc] = useState<string | undefined>(undefined);
  const playerRef = useRef<ReactPlayer | null>(null);

  const {
    mediaPlayInfo,
    streamType,
    seekTo,
    setPlayer,
    playAudio,
    coverSrc,
    handlers,
    pauseAudio,
    setIsLoading,
  } = useContext(AudioContext);
  const src = useSelector(AudioStore.Selectors.audioSourceSelector);
  const configuration = useConfigurationSelector();
  const dispatch = useDispatch();

  const lastTime = useMemo(() => {
    const hours = mediaPlayInfo?.Timestamp?.Hour || 0;
    const minutes = mediaPlayInfo?.Timestamp?.Minute || 0;
    const seconds = mediaPlayInfo?.Timestamp?.Second || 0;
    return hours * 60 * 60 + minutes * 60 + seconds;
  }, [mediaPlayInfo?.Timestamp]);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const { media: nextMedia } = useMedia({
    mediaId: mediaPlayInfo?.NextMediaId,
    disableAutoFetch: !Boolean(mediaPlayInfo?.NextMediaId),
  });

  const loadNextMedia = useCallback(() => {
    nextMedia &&
      dispatch(
        AudioStore.Actions.setAudioMedia({
          media: nextMedia,
          isFromAutoPlay: true,
        })
      );
  }, [dispatch, nextMedia]);

  const isPlayerScreen = useMemo(() => {
    return location.pathname.includes(ROUTES.PLAYER_SCREEN);
  }, [location.pathname]);

  const startWatchProgressMonitoring = () => {
    if (streamType === MediaStreamType.Trial) {
      return;
    }

    stopWatchProgressMonitoring();

    audioProgressIntervalId = setInterval(
      setWatchProgress,
      configuration?.System?.Media?.ProgressSyncInterval ?? 60000
    );
  };

  const stopWatchProgressMonitoring = () => {
    if (audioProgressIntervalId) {
      clearInterval(audioProgressIntervalId);
      audioProgressIntervalId = undefined;
    }
  };

  const setWatchProgress = useCallback(() => {
    const currentTime = playerRef.current?.getCurrentTime();

    if (
      mediaPlayInfo?.MediaId &&
      currentTime != undefined &&
      streamType != "TRIAL"
    ) {
      DataProvider.setWatchProgress(mediaPlayInfo?.MediaId, currentTime);
    }
  }, [mediaPlayInfo]);

  const onResume = () => {
    playAudio?.();
    streamType !== MediaStreamType.Trial && seekTo?.(lastTime);
    setPreviousSrc(src);
    setOpenResumeModal(false);
  };

  const onReady = (player: ReactPlayer) => {
    setPlayer?.(player);
    if (streamType === MediaStreamType.Trial || lastTime === 0) {
      setIsPlaying(true);
      setOpenResumeModal(false);
    } else {
      setIsPlaying(false);
    }
  };

  const onPlay = () => {
    const { onPlay } = handlers;
    setWatchProgress();
    startWatchProgressMonitoring();
    onPlay?.();
  };

  const onPause = () => {
    const { onPause } = handlers;
    stopWatchProgressMonitoring();
    setWatchProgress();
    onPause?.();
  };

  const onEnded = () => {
    const { onEnded } = handlers;
    stopWatchProgressMonitoring();
    setWatchProgress();
    onEnded?.();
    loadNextMedia();
  };

  const onCancelResume = () => {
    playAudio?.();
    seekTo?.(0);
    setPreviousSrc(src);
    setOpenResumeModal(false);
  };

  useEffect(() => {
    if (window.location.href.indexOf("buy") > -1) {
      dispatch(AudioStore.Actions.resetStore());
    }
  }, [window.location.href]);

  useEffect(() => {
    if (openResumeModal) {
      setIsLoading?.(false);
      pauseAudio?.();
    }
  }, [openResumeModal]);

  useEffect(() => {
    setIsPlaying(false);
    stopWatchProgressMonitoring();
    setWatchProgress();

    if (lastTime || (!!src && src !== previousSrc)) {
      setOpenResumeModal(
        streamType !== MediaStreamType.Trial && lastTime !== 0
      );
      setIsLoading?.(false);
      return;
    }
  }, [src]);

  if (!src || isPlayerScreen) {
    return null;
  }

  return (
    <>
      {openResumeModal && (
        <ContinueListeningDialog
          onCancel={onCancelResume}
          onConfirm={onResume}
          imageSrc={coverSrc}
        />
      )}
      <Portal portalRootId="audio-player-portal">
        <ReactPlayer
          ref={playerRef}
          url={src}
          progressInterval={100}
          onReady={onReady}
          onPlay={onPlay}
          onPause={onPause}
          onDuration={handlers["onDuration"]}
          onEnded={onEnded}
          onProgress={handlers["onProgress"]}
          autoPlay={true}
          preload="auto"
          playing={isPlaying}
          onBuffer={() => {
            console.log("Buffering...");
          }}
        />
      </Portal>
    </>
  );
});
