/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { createContext, useContext } from "react";

export interface EpgDaySwitcherConfiguration {
  daysBefore: number;
  daysAfter: number;
}

interface EpgConfigurationContextValue {
  daySwitcher?: EpgDaySwitcherConfiguration;
  baseDayWidth: number;
  sidebarWidth: number;
  lineHeight: number;
}

export interface EpgConfigurationProviderProps {
  daySwitcher?: EpgDaySwitcherConfiguration;
  baseDayWidth: number;
  sidebarWidth: number;
  lineHeight: number;
  baseContainerWidth?: number;
  baseContainerHeight?: number;
  fullHeightOffset?: number;
  children: React.ReactNode;
}

const EpgConfigurationContext = createContext<EpgConfigurationContextValue>(
  {} as EpgConfigurationContextValue
);

export const EpgConfigurationProvider = (
  props: EpgConfigurationProviderProps
) => {
  const { children, ...configurationProps } = props;

  return (
    <EpgConfigurationContext.Provider value={{ ...configurationProps }}>
      {children}
    </EpgConfigurationContext.Provider>
  );
};

export const useEpgConfiguration = () => {
  const context = useContext(EpgConfigurationContext);

  if (!context) {
    throw new Error("Component beyond EpgConfigurationContext");
  }

  return context;
};
