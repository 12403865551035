/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetType,
  buildAssetUploadRule,
  buildRequiredRule,
  buildWhitespacesRule,
  DataProvider,
  GuidHelper,
  IFormValues,
  IInsertAssetRequestModel,
  IInsertAssetResponseModel,
  TimeHelper,
} from "@bms/common";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, FormButton, Input, LabelField, UploadFile } from "components";
import { useUploadAssistantDispatch } from "context";

import { useLowestAgeRestriction } from "./useLowestAgeRestriction";

import "./VideoEmbedderForm.scss";

interface VideoEmbedderFormProps {
  onAssetInserted: (asset: IInsertAssetResponseModel) => void;
  onUploadError?: () => void;
}

export const VideoEmbedderForm = (props: VideoEmbedderFormProps) => {
  const { onAssetInserted, onUploadError } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [wrongAsset, setWrongAsset] = useState(false);

  const { uploadFile } = useUploadAssistantDispatch();
  const { t } = useTranslation();

  const minimumAgeRestriction = useLowestAgeRestriction();

  const onSubmit = async (payload: IInsertAssetRequestModel) => {
    setIsLoading(true);

    const response = await DataProvider.createAsset(payload);
    const asset = await DataProvider.getAsset(response.AssetId);

    await DataProvider.updateAsset({
      ...asset,
      AvailableFrom: TimeHelper.getCurrentDateTime(),
      IsFree: true,
    });

    await DataProvider.addToMyList(response.AssetId);

    uploadFile(
      { title: `Video: ${asset.Title}` },
      { file: payload.File, uploadInfo: response.Content.UploadInfo }
    ).catch(async () => {
      const asset = await DataProvider.getAsset(response.AssetId);
      await DataProvider.deleteAsset(asset);

      onUploadError?.();
    });

    return response;
  };

  const onFinish = (values: IFormValues) => {
    const { Title, File } = values;

    if (!minimumAgeRestriction) {
      return;
    }

    const payload: IInsertAssetRequestModel = {
      Title,
      File,
      AssetAgeRestrictionValueMin: minimumAgeRestriction?.ValueMin,
      AssetTypeCode: AssetType.Video,
      ExternalSource: "Vestigit",
      ExternalId: GuidHelper.newGuid(),
    };

    onSubmit(payload).then((asset) => {
      setIsLoading(false);
      onAssetInserted(asset);
    });
  };

  const getWrongAsset = (correctAsset: boolean) => {
    setWrongAsset(correctAsset);
  };

  const updateWrongAsset = useCallback(() => {
    if (wrongAsset) {
      return [buildAssetUploadRule(wrongAsset)];
    }
    return [];
  }, [wrongAsset]);

  useEffect(() => {
    updateWrongAsset();
  }, [updateWrongAsset, wrongAsset]);

  return (
    <Form name="CreateEventForm" onFinish={onFinish}>
      <div className="AddAssetForm">
        <LabelField
          name="Title"
          label={t("VIDEO_EMBEDDER_WIZARD__ASSET_TITLE", "Title")}
          rules={[buildRequiredRule(), buildWhitespacesRule()]}
        >
          <Input
            className="FormInput"
            placeholder={t(
              "VIDEO_EMBEDDER_WIZARD__ASSET_TITLE_PLACEHOLDER",
              "Title"
            )}
          />
        </LabelField>

        <LabelField
          name="File"
          label={t("VIDEO_EMBEDDER_WIZARD__ASSET_FILE", "Upload file")}
          rules={
            (updateWrongAsset()?.length > 0 && updateWrongAsset()) || [
              buildRequiredRule("object"),
            ]
          }
        >
          <UploadFile accept="video/*" getWrongAsset={getWrongAsset} />
        </LabelField>

        <div className="text-center">
          <FormButton loading={isLoading}>
            {t("VIDEO_EMBEDDER_WIZARD__ADD_CONTENT_HEADER", "Add asset")}
          </FormButton>
        </div>
      </div>
    </Form>
  );
};
