/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ScreenType } from "@bms/common";
import React from "react";
import { InfoDetailsScreen } from "../InfoDetailsScreen";
import { useParams } from "react-router";

export interface IPrivacyPolicyScreenParams {
  id: string;
}
export const PrivacyPolicyScreen = () => {
  const params = useParams<IPrivacyPolicyScreenParams>();
  return (
    <InfoDetailsScreen
      screenTypeCode={ScreenType.PrivacyPolicy}
      isConsent
      textCode={params.id}
    />
  );
};
