/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum PlayerStateTypes {
  Playing = "PLAYING",
  Paused = "PAUSED",
  Ended = "ENDED",
  None = "NONE",
}
