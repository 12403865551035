/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useContext, useMemo } from "react";
import { ThemeContext } from "@bms/common";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "./ListComponentItemFrame.scss";

export const ListComponentItemFrameSkeleton = () => {
  const { themeProvider } = useContext(ThemeContext);

  const skeletonColor = useMemo(
    () => themeProvider.getSkeletonColor(),
    [themeProvider]
  );

  return (
    <div className="ListComponentItemFrame__container ListComponentItemFrame__container-skeleton">
      <SkeletonTheme color={skeletonColor} highlightColor={skeletonColor}>
        <div className="ListComponentItemFrame__skeleton">
          <div>
            <Skeleton width={"20%"} height="10px" />
          </div>
          <div>
            <Skeleton width={"40%"} height="17px" />
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};
