/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum ElevationLevel {
  Small = "SMALL",
  Medium = "MEDIUM",
  Large = "LARGE",
}
