/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../../../../constants";
import { IUserSettingsModel } from "../../../../../models";
import { AxiosSubscriber } from "../../../../../services";

export class UserSettingsService {
  public get url(): string {
    return "/UserSettings";
  }

  public get = (): Observable<IUserSettingsModel> =>
    new Observable(
      (observer: Observer<IUserSettingsModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Get`,
        })
    );

  public update = (data: IUserSettingsModel): Observable<IUserSettingsModel> =>
    new Observable(
      (observer: Observer<IUserSettingsModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Update`,
        })
    );
}
