/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppConfig, RouterState, ROUTES } from "@bms/common";
import "./HeaderSignIn.scss";

export const HeaderSignIn = () => {
  const { t } = useTranslation();

  if (AppConfig.IsLoginDisabled) {
    return null;
  }

  return (
    <div className="HeaderSignIn">
      <Link
        to={{
          pathname: ROUTES.LOGIN,
          state: new RouterState(location.pathname + location.search),
        }}
      >
        <span className="text-link text-bold text-upper text-large">
          {t("COMMON__SIGN_IN", "Sign in")}
        </span>
      </Link>
    </div>
  );
};
