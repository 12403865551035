/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ComponentType, SourceType, useIsLoggedIn } from "@bms/common";
import React from "react";

import {
  EpgComponent,
  ImageComponent,
  ListComponent,
  SectionMenuListComponent,
  TextWidgetComponent,
  WebViewComponent,
} from "components";

import { ComponentContainer } from "./components/ComponentContainer";
import {
  BaseScreenComponentRenderer,
  BaseScreenCustomComponentRenderer,
  BaseScreenRenderProp,
} from "./types";

interface IBaseScreenProps {
  children: BaseScreenRenderProp;
  renderComponent?: BaseScreenCustomComponentRenderer;
}

export const BaseScreen = ({ children, renderComponent }: IBaseScreenProps) => {
  const isLoggedIn = useIsLoggedIn();

  const renderScreenComponent: BaseScreenComponentRenderer = (
    component,
    screenComponents
  ) => {
    const index = screenComponents?.findIndex(({ Id }) => component.Id === Id);
    switch (component.ComponentTypeCode) {
      case ComponentType.List:
        if (
          (component.SourceType === SourceType.MyList ||
            component.SourceType === SourceType.RecentlyWatched ||
            component.SourceType === SourceType.Purchased) &&
          !isLoggedIn
        ) {
          return null;
        } else {
          return <ListComponent key={component.Id} component={component} />;
        }
      case ComponentType.SectionMenu:
        return (
          <SectionMenuListComponent key={component.Id} component={component} />
        );
      case ComponentType.Image:
        return <ImageComponent key={component.Id} component={component} />;
      case ComponentType.WebView:
        return <WebViewComponent key={component.Id} component={component} />;
      case ComponentType.Epg:
        return <EpgComponent key={component.Id} component={component} />;
      case ComponentType.TextWidget:
        return (
          <TextWidgetComponent
            key={component.Id}
            component={component}
            isRenderedFirst={!index}
          />
        );
      default:
        return null;
    }
  };

  const renderScreen = (screen: any) => {
    if (screen && screen.Components && screen.Components.length > 0) {
      return (
        <ComponentContainer>
          {screen.Components.map((component: any) => {
            if (!component.IsVisible) {
              return false;
            }

            const componentView = renderComponent
              ? renderComponent(
                  component,
                  screen.Components as any,
                  renderScreenComponent
                )
              : renderScreenComponent(component, screen.Components);

            if (componentView) {
              return componentView;
            }
          })}
        </ComponentContainer>
      );
    }

    return null;
  };

  return (children as BaseScreenRenderProp)({
    renderScreen: renderScreen,
  });
};
