/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ActionsObservable, ofType } from "redux-observable";
import { mergeMap, switchMap } from "rxjs/operators";

import { MediaListType, MediaStreamType } from "../../enums";
import { TimeHelper } from "../../helpers";
import {
  IErrorModel,
  IMediaCategoryListModel,
  IMediaListModel,
  IMediaModel,
  IMediaSearchStateModel,
  SourceStorage,
} from "../../models";
import { DataProvider } from "../../providers/DataProvider";
import { StorageManager } from "../../services";

import * as Actions from "./actions";
import * as Consts from "./consts";
import {
  IGetMediaAction,
  IGetMediaCategoriesAction,
  IGetMediaListAction,
  IGetMediaListForEpgAction,
  IGetMediaListFromCacheAction,
  IGetMediaPlayInfoAction,
  ISearchMediaAction,
  ISearchMediaInMediaAction,
  ISelectMediaPurchaseOffersAction,
} from "./types";

const getMediaEpic = (action$: ActionsObservable<IGetMediaAction>) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA),
    switchMap((action: IGetMediaAction) =>
      DataProvider.getMedia(action.options)
        .then((data: IMediaModel) => {
          return Actions.getMediaSuccess(data);
        })
        .catch((error) => {
          return Actions.getMediaFailure(action.options.MediaId, error);
        })
    )
  );

const getMediaPlayInfoEpic = (
  action$: ActionsObservable<IGetMediaPlayInfoAction>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_PLAY_INFO),
    switchMap((action: IGetMediaPlayInfoAction) => {
      const { MediaId, QueryParams, StreamType, UserId } = action.options;

      return DataProvider.getMediaPlayInfo({
        MediaId,
        StreamType,
        QueryParams,
      })
        .then((baseMediaInfo) => {
          const { ExternalId } = baseMediaInfo;

          if (!ExternalId) {
            return Actions.getMediaPlayInfoFailure(action.options, {
              Title: "ExternalId is not defined",
            });
          }

          switch (StreamType) {
            // Play action
            case MediaStreamType.Main: {
              if (!UserId) {
                return Actions.getMediaPlayInfoFailure(action.options, {
                  Title: "User is required",
                });
              }

              return DataProvider.vestigitGetContentFileUrl({
                cid: ExternalId,
                impersonate_user_id: UserId,
              })
                .then(({ manifest_url }) =>
                  Actions.getMediaPlayInfoSuccess(action.options, {
                    ...baseMediaInfo,
                    ContentUrl: manifest_url,
                  })
                )
                .catch((error) =>
                  Actions.getMediaPlayInfoFailure(action.options, error)
                );
            }

            // Compare action
            case MediaStreamType.Trial: {
              return Actions.getMediaPlayInfoSuccess(action.options, {
                ...baseMediaInfo,
              });
            }

            // Unsupported stream type
            default: {
              return Actions.getMediaPlayInfoFailure(action.options, {
                Title: "Unsupported stream type",
              });
            }
          }
        })
        .catch((error) =>
          Actions.getMediaPlayInfoFailure(action.options, error)
        );
    })
  );

const searchMediaEpic = (action$: ActionsObservable<ISearchMediaAction>) =>
  action$.pipe(
    ofType(Consts.SEARCH_MEDIA),
    switchMap((action: ISearchMediaAction) =>
      DataProvider.searchMedia(action.filter)
        .then((data: IMediaSearchStateModel) => {
          data.Filter = action.filter;

          return Actions.searchMediaSuccess(data);
        })
        .catch((error) => {
          return Actions.searchMediaFailure(error);
        })
    )
  );

const searchMediaInMediaEpic = (
  action$: ActionsObservable<ISearchMediaInMediaAction>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_MEDIA_IN_MEDIA),
    switchMap((action: ISearchMediaInMediaAction) =>
      DataProvider.searchMediaInMedia(action.filter)
        .then((data: IMediaListModel) => {
          data.Filter = action.filter;

          return Actions.searchMediaInMediaSuccess(action.filter, data);
        })
        .catch((error: IErrorModel) => {
          return Actions.searchMediaInMediaFailure(action.filter, error);
        })
    )
  );

const getMediaListEpic = (action$: ActionsObservable<IGetMediaListAction>) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_LIST),
    switchMap((action: IGetMediaListAction) =>
      DataProvider.getMediaList(action.options)
        .then((data: IMediaListModel) => {
          data.Filter = action.options;

          return Actions.getMediaListSuccess(action.options.MediaListId, data);
        })
        .catch((error) => {
          return Actions.getMediaListFailure(action.options.MediaListId, error);
        })
    )
  );

export const getMediaListForEpgEpic = (
  action$: ActionsObservable<IGetMediaListForEpgAction>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_LIST_FOR_EPG),
    mergeMap((action: IGetMediaListForEpgAction) =>
      DataProvider.getMediaList(action.options)
        .then((data: IMediaListModel) => {
          data.Filter = action.options;

          return Actions.getMediaListForEpgSuccess(
            action.options.MediaListId,
            action.options,
            data
          );
        })
        .catch((error: IErrorModel) =>
          Actions.getMediaListForEpgFailure(
            action.options.MediaListId,
            action.options,
            error
          )
        )
    )
  );

const getMediaCategoriesEpic = (
  action$: ActionsObservable<IGetMediaCategoriesAction>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_CATEGORIES),
    switchMap((_action: IGetMediaCategoriesAction) =>
      DataProvider.getMediaCategories()
        .then((data: IMediaCategoryListModel) => {
          return Actions.getMediaCategoriesSuccess(data);
        })
        .catch((error) => {
          return Actions.getMediaCategoriesFailure(error);
        })
    )
  );

const getMediaListFromCacheEpic = (
  action$: ActionsObservable<IGetMediaListFromCacheAction>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_LIST_FROM_CACHE),
    mergeMap(async (action: IGetMediaListFromCacheAction) => {
      const { MediaListId, Type } = action.options;

      try {
        const sourcesFromCache: SourceStorage = await StorageManager.getValue(
          "source"
        );

        const shouldGetSource =
          !sourcesFromCache ||
          !sourcesFromCache[MediaListId] ||
          (sourcesFromCache[MediaListId].CacheDataValidTo &&
            TimeHelper.isAfter(
              sourcesFromCache[MediaListId].CacheDataValidTo!
            ));

        const shouldFetchMore =
          sourcesFromCache &&
          sourcesFromCache[MediaListId]?.Entities.length <
            sourcesFromCache[MediaListId]?.TotalCount;

        if (
          shouldGetSource ||
          shouldFetchMore ||
          Type === MediaListType.MyList
        ) {
          const newSource = await DataProvider.getMediaList(action.options);
          const previousSources = await StorageManager.getValue("source");
          newSource.Filter = action.options;

          StorageManager.setValue("source", {
            ...previousSources,
            [MediaListId]: newSource,
          });
          return Actions.getMediaListFromCacheSuccess(MediaListId, newSource);
        }

        sourcesFromCache[MediaListId].Filter = action.options;
        return Actions.getMediaListFromCacheSuccess(
          MediaListId,
          sourcesFromCache[MediaListId]
        );
      } catch (error) {
        return Actions.getMediaListFromCacheFailure(MediaListId, error as any);
      }
    })
  );

export const selectMediaPurchaseOffersEpic = (
  action$: ActionsObservable<ISelectMediaPurchaseOffersAction>
) =>
  action$.pipe(
    ofType(Consts.SELECT_MEDIA_PURCHASE_OFFERS),
    switchMap((action: ISelectMediaPurchaseOffersAction) =>
      DataProvider.selectMediaPurchaseOffers(action.mediaId)
        .then((data) =>
          Actions.selectMediaPurchaseOffersSuccess(action.mediaId, data)
        )
        .catch((error) =>
          Actions.selectMediaPurchaseOffersFailure(action.mediaId, error)
        )
    )
  );

export const mediaEpics = [
  getMediaEpic,
  getMediaPlayInfoEpic,
  searchMediaEpic,
  searchMediaInMediaEpic,
  getMediaListEpic,
  getMediaCategoriesEpic,
  getMediaListFromCacheEpic,
  getMediaListForEpgEpic,
  selectMediaPurchaseOffersEpic,
];
