/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAssetModel, useGetUserId } from "@bms/common";
import { useEffect } from "react";

interface useAllowEditForCreatorParams {
  asset: IAssetModel | undefined;
  onNotAllowedAccess: () => void;
}

export const useAllowOnlyAssetCreator = (
  params: useAllowEditForCreatorParams
) => {
  const { asset, onNotAllowedAccess } = params;

  const currentUserId = useGetUserId();

  useEffect(() => {
    if (asset == undefined || asset.CreatorUserId == undefined) {
      return;
    }

    const isAuthor = asset.CreatorUserId == currentUserId;

    !isAuthor && onNotAllowedAccess();
  }, [asset, currentUserId, onNotAllowedAccess]);
};
