/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ThemeContext } from "@bms/common";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "./ListComponentItemSquare.scss";

export const ListComponentItemSquareSkeleton = () => {
  const { themeProvider } = useContext(ThemeContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerStyles, setContainerStyles] = useState<React.CSSProperties>(
    {}
  );

  const skeletonColor = useMemo(
    () => themeProvider.getSkeletonColor(),
    [themeProvider]
  );

  useEffect(() => {
    if (containerRef?.current) {
      const styles = {
        height: containerRef?.current?.getBoundingClientRect()?.width,
        width: containerRef?.current?.getBoundingClientRect()?.width,
      };
      setContainerStyles(styles);
    }
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      style={containerStyles}
      className="ListComponentItemSquare__skeleton-container"
    >
      <SkeletonTheme color={skeletonColor} highlightColor={skeletonColor}>
        <div className="ListComponentItemSquare__skeleton">
          <div>
            <Skeleton height="10px" />
          </div>
          <div>
            <Skeleton width={"40%"} height="17px" />
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};
