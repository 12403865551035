/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ActionType,
  ConfigurationHelper,
  ISectionMenuItemModel,
} from "@bms/common";
import React from "react";
import { Link } from "react-router-dom";

import "./SectionMenuListComponentItem.scss";

interface SectionMenuListComponentItemProps {
  item: ISectionMenuItemModel;
}
export const SectionMenuListComponentItem = ({
  item,
}: SectionMenuListComponentItemProps) => {
  let button: JSX.Element | undefined;

  switch (item.Action?.ActionType) {
    case ActionType.OpenScreen:
      const screenKey =
        ConfigurationHelper.getApplicationMenuItemScreenKey(item);
      button = (
        <Link to={`/${screenKey}`}>
          {
            <button className="SectionMenuListComponentItem">
              {item.Title}
            </button>
          }
        </Link>
      );
      break;
    case ActionType.OpenUrl:
      button = (
        <a href={item.Action?.Url}>
          {
            <button className="SectionMenuListComponentItem">
              {item.Title}
            </button>
          }
        </a>
      );
      break;
    default:
      button = (
        <button className="SectionMenuListComponentItem">{item.Title}</button>
      );
  }

  return button;
};
