/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { RouteHelper, ROUTES } from "@bms/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { MediaButton } from "components";

import "./DeleteAccountSuccessScreen.scss";

import { Link } from "react-router-dom";

export const DeleteAccountSuccessScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="DeleteAccountSuccessScreen">
      <div className="DeleteAccountSuccessScreen_content">
        <h1> {t("DELETE_ACCOUNT_SUCCESS__TITLE")}</h1>
        <p> {t("DELETE_ACCOUNT_SUCCESS__INFO")}</p>

        <div className="DeleteAccountSuccessScreen__buttonsContainer">
          <MediaButton
            variant="primary"
            onClick={() => dispatch(RouteHelper.goToRegister(true))}
          >
            {t("DELETE_ACCOUNT_SUCCESS__BUTTON_CREATE_ACCOUNT")}
          </MediaButton>
        </div>

        <Link to={ROUTES.HOME}>
          <span className="link underline">
            {t("DELETE_ACCOUNT_SUCCESS__BUTTON_HOME")}
          </span>
        </Link>
      </div>
    </div>
  );
};
