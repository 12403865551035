/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useScript } from "@bms/common";
import { PlayerControllerType } from "components/VideoPlayer/types";

export const usePlayerLoader = () => {
  let playerSrc = "";

  if (process.env.REACT_APP_PLAYER_TYPE === PlayerControllerType.JWPlayer) {
    playerSrc = "https://cdn.jwplayer.com/libraries/6cWCXvWu.js";
  }

  useScript(playerSrc);
};
