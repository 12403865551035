/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import cx from "classnames";
import React, { useContext } from "react";

import { ReactComponent as PauseIcon } from "../../../resources/player/pause.svg";
import { ReactComponent as PlayIcon } from "../../../resources/player/play.svg";
import { ReactComponent as RewindBackwardIcon } from "../../../resources/player/rewind-backward.svg";
import { ReactComponent as RewindForwardIcon } from "../../../resources/player/rewind-forward.svg";
import { AudioPlayerProgressBar } from "../AudioPlayerProgressBar";

import "./AudioPlayerPlaybackControls.scss";

import { AudioContext } from "context";

export const AudioPlayerPlaybackControls = ({ isMobile = false }) => {
  const {
    playing,
    currentTime = 0,
    seekTo,
    pauseAudio,
    playAudio,
  } = useContext(AudioContext);

  const rewind = (direction: "forward" | "backward") => {
    seekTo?.(currentTime + (direction === "backward" ? -15 : 15));
  };

  return (
    <div className="AudioPlayerPlaybackControls">
      <div className="AudioPlayerPlaybackControls__buttons">
        <div
          className={cx(
            "AudioPlayerPlaybackControls__button",
            "AudioPlayerPlaybackControls--transparent-bg"
          )}
          onClick={() => rewind("backward")}
        >
          <RewindBackwardIcon
            className={cx(
              "AudioPlayerPlaybackControls__button__icon",
              "AudioPlayerPlaybackControls__button__icon--backward"
            )}
          />
          <span className="AudioPlayerPlaybackControls__button__text">15</span>
        </div>

        <div
          className={cx(
            "AudioPlayerPlaybackControls__button",
            "AudioPlayerPlaybackControls__button--big"
          )}
          onClick={playing ? pauseAudio : playAudio}
        >
          {playing ? <PauseIcon fill="#fff" /> : <PlayIcon fill="#fff" />}
        </div>

        <div
          className={cx(
            "AudioPlayerPlaybackControls__button",
            "AudioPlayerPlaybackControls--transparent-bg"
          )}
          onClick={() => rewind("forward")}
        >
          <RewindForwardIcon
            className={cx(
              "AudioPlayerPlaybackControls__button__icon",
              "AudioPlayerPlaybackControls__button__icon--forward"
            )}
          />
          <span className="AudioPlayerPlaybackControls__button__text">15</span>
        </div>
      </div>
      {!isMobile && <AudioPlayerProgressBar />}
    </div>
  );
};
