/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IApplicationHeaderScreensModel,
  IApplicationMenuScreenModel,
  ScreenType,
} from "@bms/common";
import { IAuthState } from "@bms/common/src/store/auth/types";
import {
  AppHeaderVisibility,
  ApplicationHeader,
  ApplicationMenu,
} from "components";
import React from "react";
import { HeaderLogo, HeaderSignIn, HeaderUserInfo } from "..";

import "./DesktopHeader.scss";

interface IDesktopHeader {
  configurationScreens?: IApplicationHeaderScreensModel;
  visibility?: AppHeaderVisibility;
  auth?: IAuthState;
}

export const DesktopHeader = ({
  configurationScreens,
  visibility = AppHeaderVisibility.Default,
  auth,
}: IDesktopHeader) => {
  return (
    <header className="DesktopHeader">
      <div className="DesktopHeader__Left">
        <HeaderLogo />
        {!!(visibility & AppHeaderVisibility.Menu) && (
          <ApplicationMenu
            screen={
              configurationScreens?.[
                ScreenType.ApplicationMenu
              ] as IApplicationMenuScreenModel
            }
          />
        )}
      </div>
      <div className="DesktopHeader__Right">
        {!!(visibility & AppHeaderVisibility.Header) && (
          <ApplicationHeader
            items={
              configurationScreens?.[ScreenType.ApplicationHeader]?.Components
            }
          />
        )}
        {!!(visibility & AppHeaderVisibility.UserInfo) && (
          <HeaderUserInfo user={auth?.user} />
        )}
        {!!(visibility & AppHeaderVisibility.SignIn) && <HeaderSignIn />}
      </div>
    </header>
  );
};
