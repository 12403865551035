/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";

import { Images } from "./Images";
import { Metadata } from "./Metadata";
import { Summary } from "./Summary";
import { MappedStepType, StepMap } from "./types";

export const STEPS: StepMap = [
  {
    id: 0,
    type: "initial",
    title: "VIDEO_EMBEDDER_WIZARD_STEP_START",
  },
  {
    id: 1,
    type: "metadata",
    title: "VIDEO_EMBEDDER_WIZARD_STEP_DETAILS",
  },
  {
    id: 2,
    type: "images",
    title: "VIDEO_EMBEDDER_WIZARD_STEP_IMAGES",
  },
  {
    id: 3,
    type: "summary",
    title: "VIDEO_EMBEDDER_WIZARD_STEP_SUMMARY",
  },
];

const stepComponentMap: Record<MappedStepType, React.ElementType> = {
  metadata: Metadata,
  images: Images,
  summary: Summary,
};

export const getStepComponent = (index: number) => {
  const step = STEPS[index];

  if (step.type === "initial") {
    throw new Error("invalid step");
  }

  return stepComponentMap[step.type];
};
