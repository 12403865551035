/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  buildEmailRule,
  buildRequiredRule,
  ComponentType,
  ConfigurationHelper,
  IApplicationIntroVideoScreenModel,
  ROUTES,
  ScreenType,
  useConfigurationSelector,
  useScreenConfiguration,
} from "@bms/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";

import {
  AppFooter,
  AppHeader,
  AppHeaderVisibility,
  Form,
  FormLine,
  Input,
  LabelField,
  ListComponent,
  MediaButton,
  SMUp,
  XS,
} from "../../components";
import { BaseScreen } from "../BaseScreen";
import { BaseScreenCustomComponentRenderer } from "../BaseScreen/types";

import "./IntroScreen.scss";

export const IntroScreen = ({ history }: RouteComponentProps) => {
  const configuration = useConfigurationSelector();
  const { t } = useTranslation();

  const screen = useScreenConfiguration<IApplicationIntroVideoScreenModel>(
    ScreenType.Intro
  );

  const externalRegistrationUrl = configuration?.System?.RegistrationUrl;

  const renderComponent: BaseScreenCustomComponentRenderer = (
    component,
    components,
    originalRenderer
  ) => {
    switch (component.ComponentTypeCode) {
      case ComponentType.List:
        return (
          <ListComponent
            key={component.Id}
            component={component}
            readOnly={true}
          />
        );
      default:
        return originalRenderer(component, components);
    }
  };

  return (
    <div className="IntroScreen">
      <AppHeader
        configuration={configuration}
        visibility={AppHeaderVisibility.Logo | AppHeaderVisibility.SignIn}
      />
      <section>
        <div className="IntroScreen__text text-center">
          {screen.BackgroundVideoUrl ? (
            <video autoPlay muted loop className="IntroScreen__video">
              <source src={screen.BackgroundVideoUrl} type="video/mp4" />
            </video>
          ) : (
            <h1 className="IntroScreen__title">
              {t("WELCOME__TITLE", "Watch movies and TV shows")}
            </h1>
          )}

          <Form
            className="EmailForm"
            onFinish={(values) => {
              if (externalRegistrationUrl) {
                window.location.href = externalRegistrationUrl;
              } else
                history.push({
                  pathname: ROUTES.REGISTER,
                  state: values,
                });
            }}
          >
            {!!externalRegistrationUrl ? (
              <SMUp>
                <MediaButton
                  variant="primary"
                  className="MediaButton__externalRegister"
                >
                  {t("COMMON__BUTTON_REGISTER", "Register")}
                </MediaButton>
              </SMUp>
            ) : (
              <LabelField
                name="email"
                rules={[buildRequiredRule(), buildEmailRule()]}
                validateTrigger="onSubmit"
              >
                <Input
                  autoFocus
                  className="FormInput"
                  placeholder={t(
                    "COMMON__EMAIL_PLACEHOLDER",
                    "Enter Your E-Mail"
                  )}
                  suffix={
                    <SMUp>
                      <MediaButton variant="primary">
                        {t("COMMON__BUTTON_REGISTER", "Register")}
                      </MediaButton>
                    </SMUp>
                  }
                />
              </LabelField>
            )}
            <XS>
              <MediaButton variant="primary">
                {t("COMMON__BUTTON_REGISTER", "Register")}
              </MediaButton>
            </XS>
            <Link className="text-normal text-link text-bold" to={ROUTES.HOME}>
              {t("WELCOME__CONTINUE", "Continue without account")}
            </Link>
          </Form>
        </div>
        <FormLine />
      </section>
      <BaseScreen renderComponent={renderComponent}>
        {({ renderScreen }) =>
          renderScreen(
            ConfigurationHelper.getScreenByType(configuration, ScreenType.Intro)
          )
        }
      </BaseScreen>
      <AppFooter />
    </div>
  );
};
