/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { ActionsObservable, ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import { IDownloadSource } from "models";

import { StorageHelper } from "../../helpers";

import { Actions } from "./actions";
import { ActionTypes } from "./consts";
import { OfflineModeActionTypes } from "./types";

const getDownloadedPodcasts = (
  action$: ActionsObservable<OfflineModeActionTypes>
) =>
  action$.pipe(
    ofType(ActionTypes.GET_DOWNLOADED_PODCASTS),
    switchMap(async () => {
      try {
        const podcasts = await StorageHelper.getPodcasts();
        return Actions.getDownloadedPodcastsSuccess(podcasts);
      } catch (e) {
        return Actions.getDownloadedPodcastsFailure();
      }
    })
  );

const updatePodcast = (action$: ActionsObservable<OfflineModeActionTypes>) =>
  action$.pipe(
    ofType(ActionTypes.UPDATE_PODCAST),
    switchMap(async (action) => {
      try {
        const updatedPodcasts = await StorageHelper.updatePodcast(
          action.payload as IDownloadSource
        );
        return Actions.updatePodcastSuccess(updatedPodcasts);
      } catch (e) {
        return Actions.updatePodcastFailure();
      }
    })
  );

const deletePodcast = (action$: ActionsObservable<OfflineModeActionTypes>) =>
  action$.pipe(
    ofType(ActionTypes.DELETE_PODCAST),
    switchMap(async (action) => {
      try {
        const mediaId = (action.payload as { mediaId: number })?.mediaId;
        const updatedPodcasts = await StorageHelper.deletePodcast(mediaId);
        return Actions.deletePodcastSuccess(updatedPodcasts);
      } catch (e) {
        return Actions.deletePodcastFailure();
      }
    })
  );

export const OfflineModeEpics = [
  getDownloadedPodcasts,
  updatePodcast,
  deletePodcast,
];
