/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { Observable, Observer } from "rxjs";

import { HTTP_METHOD } from "../../../../../constants";
import {
  IVestigitContentFileParams,
  IVestigitContentFileResponse,
  IVestigitContentMetadataModel,
  IVestigitContentMetadataParams,
} from "../../../../../models";
import { AxiosSubscriber } from "../../../../../services";

import { VestigitBaseService } from "./VestigitBaseService";

export class VestigitContentService extends VestigitBaseService {
  public getContentMetadata = (
    params: IVestigitContentMetadataParams
  ): Observable<IVestigitContentMetadataModel> => {
    const { cid } = params;

    return new Observable(
      (observer: Observer<IVestigitContentMetadataModel>) =>
        new AxiosSubscriber(observer, {
          isExternal: true,
          method: HTTP_METHOD.GET,
          baseURL: this.baseURL,
          url: `/content/${cid}/meta`,
        })
    );
  };

  public getContentFileUrl = (
    params: IVestigitContentFileParams
  ): Observable<IVestigitContentFileResponse> => {
    const { cid, impersonate_user_id } = params;

    return new Observable(
      (observer: Observer<IVestigitContentFileResponse>) =>
        new AxiosSubscriber(observer, {
          isExternal: true,
          method: HTTP_METHOD.GET,
          baseURL: this.baseURL,
          url: `/content/${cid}/manifest`,
          axiosConfig: {
            headers: {
              "X-ImpersonateUser": impersonate_user_id,
            },
          },
        })
    );
  };
}
