/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ApiErrors,
  AuthStore,
  buildEmailRule,
  buildRequiredRule,
  IFormValues,
  InfoDetailsScreenCode,
  IStateModel,
  IUserConsentModel,
  updateApiErrors,
  UserStore,
} from "@bms/common";
import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { ActionCreator } from "redux";

import { AnalyticsContext } from "context/analytics";

import { Form, FormButton, FormLine, LabelField } from "../../Form";
import "../../Form/Form.scss";
import { Input, InputPassword } from "../../Input";

import { ForgotPasswordLink, RegisterLink } from "components";

import "../AuthForm.scss";

import "./LoginForm.scss";

interface ILoginFormProps extends WithTranslation {
  onSubmit: (values: ILoginFormValues) => void;
  authState: AuthStore.Types.IAuthState;
  getConsents: ActionCreator<UserStore.Types.IGetUserConsentsAction>;
  consents: IStateModel<IUserConsentModel[]>;
}

interface ILoginFormState {
  consentsStates: { [id: string]: boolean };
  isFormSent: boolean;
  apiErrors: ApiErrors;
}

interface ILoginFormValues {
  Username: string;
  Password: string;
}

export class LoginForm extends React.PureComponent<
  ILoginFormProps,
  ILoginFormState
> {
  static contextType = AnalyticsContext;

  public state: ILoginFormState = {
    consentsStates: {},
    isFormSent: false,
    apiErrors: {},
  };

  static getDerivedStateFromProps(
    nextProps: ILoginFormProps,
    prevState: ILoginFormState
  ) {
    if (prevState.isFormSent && nextProps.authState.error) {
      return {
        apiErrors: {
          email: [
            nextProps.authState.error.MessageKey
              ? nextProps.authState.error.MessageKey
              : nextProps.authState.error.Message,
          ],
        },
      };
    }

    if (
      nextProps.consents.Data &&
      Object.keys(prevState.consentsStates).length === 0
    ) {
      return {
        consentsStates: Object.assign(
          {},
          ...nextProps.consents.Data.filter(
            (consent) => consent.ConsentRequired
          ).map((consent) => ({ [`${consent.ConsentId}`]: false }))
        ),
      };
    }

    return null;
  }

  componentDidMount() {
    const { getConsents } = this.props;

    getConsents();
  }

  private onFinish = (values: IFormValues) => {
    const payload: ILoginFormValues = {
      Username: values["email"],
      Password: values["password"],
    };

    const { logEvent } = this.context;
    logEvent("login", {
      method: "email",
    });

    this.setState({ isFormSent: true });
    this.props.onSubmit(payload);
  };

  private onValuesChange = (changedValues: IFormValues) => {
    const { apiErrors } = this.state;
    const [isUpdated, newApiErrors] = updateApiErrors(apiErrors, changedValues);

    if (isUpdated) {
      this.setState({ isFormSent: false, apiErrors: newApiErrors });
    }
  };

  private renderConsents(consentType: InfoDetailsScreenCode) {
    const { consents } = this.props;
    const filteredConsent = consents.Data?.find(
      (consent) => consent.ConsentCode === consentType
    );
    return filteredConsent?.ConsentId;
  }

  public render() {
    const { t } = this.props;

    return (
      <div className="LoginForm AuthForm">
        <Form onFinish={this.onFinish} onValuesChange={this.onValuesChange}>
          <h1 className="text-upper text-center">
            {t("LOGIN__TITLE", "Sign In")}
          </h1>
          <FormLine style={{ marginTop: 0 }} />
          <LabelField
            name="email"
            label={t("LOGIN__EMAIL_LABEL", "Email")}
            rules={[buildRequiredRule(), buildEmailRule()]}
            apiErrors={this.state.apiErrors.email || []}
          >
            <Input className="FormInput" autoFocus={true} />
          </LabelField>

          <LabelField
            name="password"
            label={t("LOGIN__PASSWORD_LABEL", "Password")}
            rules={[buildRequiredRule()]}
            tip={
              <ForgotPasswordLink>
                <span className="text-link text-small">
                  <Trans i18nKey="LOGIN__FORGOT_PASSWORD">
                    Forgot your password?
                  </Trans>
                </span>
              </ForgotPasswordLink>
            }
          >
            <InputPassword className="FormInput" visibilityToggle={false} />
          </LabelField>

          <div className="ButtonLine">
            <FormButton>{t("LOGIN__SUBMIT", "Sign In")}</FormButton>
          </div>
          {/* TODO: map all the required consents */}

          {/* <p className="text-center text-normal">
            <Trans i18nKey="LOGIN__TERMS_AND_CONDITIONS">
              <span>By continuing, you agree to The Better </span>
              <Link
                to={`${ROUTES.PRIVACY_POLICY_SCREEN}/${this.renderConsents(
                  InfoDetailsScreenCode.PRIVACYPOLICY
                )}`}
              >
                <span className="text-link text-underline">
                  Conditions of Use
                </span>
              </Link>
              <br />
              <span> and </span>
              <Link
                to={`${ROUTES.PRIVACY_POLICY_SCREEN}/${this.renderConsents(
                  InfoDetailsScreenCode.PRIVACYPOLICY
                )}`}
              >
                <span className="text-link text-underline">Privacy Notice</span>
              </Link>
              .
            </Trans>
          </p> */}
          <FormLine />
          <p className="text-center text-large">
            <Trans i18nKey="LOGIN__CREATE_ACCOUNT">
              <span>New to The Better?</span>
              <br />
              <u>
                <RegisterLink>
                  <span className="text-link text-bold">Create</span>
                </RegisterLink>
              </u>
              <span> Your The Better account.</span>
            </Trans>
          </p>
        </Form>
      </div>
    );
  }
}
