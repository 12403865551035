/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { withTranslation } from "react-i18next";
import { ListComponentItemSquare as ListComponentItemSquareDefinition } from "./ListComponentItemSquare";

export const ListComponentItemSquare = withTranslation()(
  ListComponentItemSquareDefinition
);
