/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import fire from "./fire.png";
import lol from "./lol.png";
import love from "./love.png";
import sad from "./sad.png";
import wow from "./wow.png";

export default {
  fire,
  lol,
  love,
  sad,
  wow,
};
