/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { HeaderLogo } from "components/AppHeader/components";
import React from "react";

import "./HamburgerMenu.scss";

interface IHamburgerMenuProps {
  children?: React.ReactNode;
}

export const HamburgerMenu = ({ children }: IHamburgerMenuProps) => {
  return (
    <nav className="HamburgerMenu" role="navigation">
      <div id="HamburgerMenu__Toggle">
        <input type="checkbox" />

        <span></span>
        <span></span>
        <span></span>

        <div className="HamburgerMenu__Content">
          <HeaderLogo className="HamburgerMenu" />
          <div className="HamburgerMenu__Items">{children}</div>
        </div>
      </div>
    </nav>
  );
};
