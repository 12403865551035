/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetType,
  CellType,
  ComponentType,
  IListComponentModel,
  IMediaModel,
  Orientation,
  StringHelper,
  useIsLoggedIn,
} from "@bms/common";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ListComponent,
  ListComponentHorizontal,
  MediaAdditionalInfo,
  MediaEpisodeInfo,
  MediaPeople,
  MyListButton,
  Option,
  PlayButton,
  Select,
  TrailerButton,
} from "components";

import {
  MediaDetailsScreen,
  useMediaDetailsLoadingSelector,
  useMediaDetailsMediaSelector,
} from "../MediaDetailsScreen";

import "./SeriesDetailsScreen.scss";

const ALL = 0;

export const SeriesDetailsScreen = () => {
  const [selectedSeasonId, setSelectedSeasonId] = useState(ALL);
  const { t } = useTranslation();
  const media = useMediaDetailsMediaSelector();
  const isLoggedIn = useIsLoggedIn();
  const loading = useMediaDetailsLoadingSelector();
  const seasons = media?.Media;

  const renderEpisodesList = () => {
    if (!media) {
      return null;
    }

    const episodesList: IListComponentModel = {
      ComponentTypeCode: ComponentType.List,
      CellType: CellType.Frame,
      VisibleItemsCount: 4,
      MediaList: [],
    };

    if (selectedSeasonId === ALL) {
      for (const season of media.Media || []) {
        episodesList.MediaList = episodesList.MediaList?.concat(
          season.Media || []
        );
      }
      const seasonEpisodes = media.Media?.filter((media) => {
        return media.MediaTypeCode === AssetType.Episode;
      });
      episodesList.MediaList = episodesList.MediaList?.concat(
        seasonEpisodes || []
      );
    } else {
      const season = media.Media?.find(
        (row: IMediaModel) => row.Id === selectedSeasonId
      );

      if (season) {
        episodesList.MediaList = season.Media || [];
      }
    }
    if (episodesList.MediaList && episodesList.MediaList?.length > 0) {
      return (
        <ListComponentHorizontal
          className="SeriesDetails__episode-list"
          component={episodesList}
          loading={!media.Media && loading}
        />
      );
    } else {
      return null;
    }
  };

  const renderRecommendationsList = () => {
    if ((media?.SimilarMedia && media?.SimilarMedia.length > 0) || loading) {
      const recommendationsList: IListComponentModel = {
        ComponentTypeCode: ComponentType.List,
        MediaList: media?.SimilarMedia,
        CellType: CellType.Frame,
        Orientation: Orientation.Horizontal,
        VisibleItemsCount: 4,
        Title: t("COMMON__MORE_LIKE_THIS"),
      };

      return <ListComponent component={recommendationsList} />;
    }

    return null;
  };

  const renderOptions = () => {
    const seasons = media?.Media?.filter((media) => {
      return media.MediaTypeCode === AssetType.Season;
    });

    if (seasons?.length && seasons.length > 0) {
      return (
        <Select<number>
          placeholder={t("COMMON__SEASON")}
          defaultValue={media?.ParentMediaId || ALL}
          onChange={(value) => setSelectedSeasonId(value)}
          disabled={seasons === undefined}
        >
          <Option value={ALL}>{t("COMMON__ALL")}</Option>
          {seasons?.map((season: IMediaModel) => (
            <Option value={season.Id} key={season.Id}>
              {StringHelper.truncate(season.Title, 100)}
            </Option>
          ))}
        </Select>
      );
    }
  };

  return (
    <MediaDetailsScreen className="SeriesDetails">
      {media && (
        <>
          <div className="SeriesDetails__info">
            <div className="SeriesDetails__title">{media.Title}</div>

            <MediaAdditionalInfo media={media} />

            <MediaEpisodeInfo media={media} />
            {/* TODO: Perhaps will be implemented later on */}
            {/* <Rating /> */}

            <div className="SeriesDetails__actions">
              <PlayButton media={media} />
              {isLoggedIn && <MyListButton media={media} />}
              <TrailerButton media={media} loading={loading} />
            </div>

            <div
              className="SeriesDetails__description"
              dangerouslySetInnerHTML={{
                __html: media.LongDescription || "",
              }}
            ></div>

            <MediaPeople people={media.People} loading={loading} />
          </div>
          {seasons && (
            <>
              <div className="SeriesDetails__options">{renderOptions()}</div>
              {renderEpisodesList()}
            </>
          )}
          {renderRecommendationsList()}
        </>
      )}
    </MediaDetailsScreen>
  );
};
