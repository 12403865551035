/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AnalyticsCallOptions,
  logEvent as firebaseLogEvent,
  getAnalytics,
  setCurrentScreen,
} from "firebase/analytics";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactGA from "react-ga";

import { appHistory, firebaseApp } from "config";

import { AnalyticsContext, LogEventParams } from "./AnalyticsContext";

interface IAnalyticsProviderProps {
  children?: ReactNode;
}

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
const trackingEnabled = trackingId !== undefined && trackingId.length > 0;
const COOKIE_ACCEPTANCE_KEY_NAME = "cookies-accepted";

export const AnalyticsProvider = ({ children }: IAnalyticsProviderProps) => {
  const [isCookiesAccepted, setCookiesAccepted] = useState(
    localStorage.getItem(COOKIE_ACCEPTANCE_KEY_NAME) === "true"
  );

  const firebaseAnalytics = useMemo(
    () => (firebaseApp ? getAnalytics(firebaseApp) : undefined),
    []
  );

  const initGA = useCallback(
    () =>
      trackingEnabled &&
      isCookiesAccepted &&
      ReactGA.initialize(trackingId as string, {
        debug: process.env.NODE_ENV === "development",
        titleCase: false,
      }),
    [isCookiesAccepted]
  );

  const logEvent = useCallback(
    (
      eventName: string,
      eventParams?: LogEventParams | undefined,
      options?: AnalyticsCallOptions | undefined
    ) => {
      if (!isCookiesAccepted) {
        return;
      }
      if (firebaseAnalytics) {
        firebaseLogEvent(firebaseAnalytics, eventName, eventParams, options);
      }
    },
    [firebaseAnalytics, isCookiesAccepted]
  );

  const trackEvent = useCallback(
    (category: string, action: string, label: string) => {
      if (!isCookiesAccepted) {
        return;
      }

      if (trackingEnabled) {
        ReactGA.event({
          category,
          action,
          label,
        });
      }

      if (firebaseAnalytics) {
        logEvent(action, {
          category,
          label,
        });
      }
    },
    [firebaseAnalytics, isCookiesAccepted, logEvent]
  );

  const trackPageView = useCallback(() => {
    if (!isCookiesAccepted) {
      return;
    }

    const page = window.location.pathname + window.location.search;

    if (trackingEnabled) {
      ReactGA.set({
        page,
      });

      ReactGA.pageview(page);
    }

    if (firebaseAnalytics) {
      setCurrentScreen(firebaseAnalytics, page);
      logEvent("screen_view");
    }
  }, [firebaseAnalytics, isCookiesAccepted, logEvent]);

  const enableAnalytics = useCallback(() => {
    setCookiesAccepted(true);

    // We need to init GA again here since cookies flag changed
    initGA();
    trackPageView();

    appHistory.listen(() => {
      trackPageView();
    });
  }, [initGA, trackPageView]);

  // Update cookie flag in localstorage
  useEffect(() => {
    isCookiesAccepted &&
      localStorage.setItem(COOKIE_ACCEPTANCE_KEY_NAME, "true");
  }, [isCookiesAccepted]);

  // Init GA on load
  useEffect(() => {
    initGA();
  }, [initGA]);

  return (
    <AnalyticsContext.Provider
      value={{
        firebaseAnalytics,
        isCookiesAccepted,
        enableAnalytics,
        trackEvent,
        trackPageView,
        logEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
