/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaModel, useIsLoggedIn } from "@bms/common";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { LoginRequiredModal, PlayButtonDialog } from "components";
import { Button } from "components/DesignSystem/Button";
import { AudioContext } from "context";
import PauseIcon from "resources/icons/player/Pause.svg";
import PlayIcon from "resources/icons/player/Play.svg";

export interface IPlayButtonProps {
  media?: IMediaModel;
  enabled?: boolean;
  loading?: boolean;
}

export const PlayButton = (props: IPlayButtonProps) => {
  const { media, enabled = true, loading = false } = props;

  const { playing, mediaPlayInfo: rememberedMedia } = useContext(AudioContext);
  const isLoggedIn = useIsLoggedIn();

  const { t } = useTranslation();

  const IconComponent =
    playing && media?.Id === rememberedMedia?.MediaId ? PauseIcon : PlayIcon;

  const externalIdAssigned = Boolean(media?.ExternalId);

  const isDisabled = !enabled || !externalIdAssigned;

  const renderButton = useCallback(
    (onClick?: () => void) => {
      return (
        <Button
          variant="primary"
          onClick={onClick}
          disabled={isDisabled}
          loading={loading}
          elevated
          shadow
        >
          <IconComponent />
          {t("COMMON__BUTTON_PLAY", "Play")}
        </Button>
      );
    },
    [IconComponent, isDisabled, loading, t]
  );

  if (!isLoggedIn) {
    return (
      <LoginRequiredModal>
        {({ openModal }) => renderButton(openModal)}
      </LoginRequiredModal>
    );
  }

  return (
    <PlayButtonDialog media={media} disabled={isDisabled}>
      {renderButton()}
    </PlayButtonDialog>
  );
};
