/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useContext, useMemo } from "react";
import cx from "classnames";
import "./AudioPlayerController.scss";
import { ThemeContext } from "@bms/common";

import { AudioInfo } from "./AudioInfo";
import { AudioPlayerPlaybackControls } from "./AudioPlayerPlaybackControls";
import { AudioPlayerAdditionalControls } from "./AudioPlayerAdditionalControls";
import { useIsMobile } from "../Responsive";
import { AudioContext } from "context";
import { AudioPlayerProgressBar } from "./AudioPlayerProgressBar";
import { LoaderSpinner } from "../LoaderSpinner";

interface IAudioPlayerControllerProps {
  mode?: "standalone" | "mounted";
  src?: string;
}

export const AudioPlayerController = ({
  mode = "mounted",
}: IAudioPlayerControllerProps) => {
  const { themeProvider } = useContext(ThemeContext);
  const isMobile = useIsMobile();
  const borderTopColor = useMemo(
    () => themeProvider.getColor("AppSecondaryTextColor"),
    [themeProvider]
  );

  const { media, isLoading } = useContext(AudioContext);

  if (!media) {
    return null;
  }

  if (isMobile) {
    return (
      <div
        className={cx(
          "AudioPlayerController",
          `AudioPlayerController--${mode}`,
          "AudioPlayerController--mobile"
        )}
        style={{
          borderTopColor,
          background: themeProvider.getColor("AppBackgroundColor"),
        }}
      >
        <AudioPlayerProgressBar />
        <div className="AudioPlayerController__controllers">
          <AudioInfo isMobile={isMobile} />
          <AudioPlayerPlaybackControls isMobile={isMobile} />
          <AudioPlayerAdditionalControls isMobile={isMobile} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx("AudioPlayerController", `AudioPlayerController--${mode}`)}
      style={{
        borderTopColor,
        background: themeProvider.getColor("AppBackgroundColor"),
      }}
    >
      {isLoading ? (
        <div className="AudioPlayerController--loader">
          <LoaderSpinner />
        </div>
      ) : (
        <>
          <AudioInfo />
          <AudioPlayerPlaybackControls />
          <AudioPlayerAdditionalControls />
        </>
      )}
    </div>
  );
};
