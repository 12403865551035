/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ConfigurationHelper,
  IConfigurationModel,
  IScreenModel,
  RouteHelper,
} from "@bms/common";
import React from "react";
import { match as RouterMatch } from "react-router";
import { AppFooter, AppHeader } from "../../components";
import { BaseScreen } from "../BaseScreen";
import "./MainScreen.scss";

interface IMainScreenProps {
  configuration?: IConfigurationModel;
  match: RouterMatch<{ id?: string }>;
  changeRoute: (route: string) => void;
}

interface IMainScreenState {
  screenKey?: string;
}

export class MainScreen extends React.PureComponent<
  IMainScreenProps,
  IMainScreenState
> {
  state: Readonly<IMainScreenState> = {
    screenKey: RouteHelper.getScreenRouteKey(this.props.match),
  };

  public UNSAFE_componentWillUpdate(nextProps: IMainScreenProps) {
    const { match } = nextProps;
    const { screenKey } = this.state;

    const newScreenKey = RouteHelper.getScreenRouteKey(match);

    if (screenKey !== newScreenKey) {
      this.setState(() => ({ screenKey: newScreenKey }));
    }
  }

  render() {
    const { configuration } = this.props;
    const { screenKey } = this.state;

    if (!configuration || !configuration.Screens) {
      return null;
    }

    let screen: IScreenModel | undefined = undefined;

    if (screenKey) {
      screen = ConfigurationHelper.getScreenByRouteKey(
        configuration,
        screenKey
      );
    }

    if (!screen || screen.ScreenTypeCode === undefined) {
      return null;
    }

    return (
      <BaseScreen>
        {({ renderScreen }) => (
          <div className="MainScreen">
            <AppHeader configuration={this.props.configuration} />
            <section>{renderScreen(screen)}</section>
            <AppFooter />
          </div>
        )}
      </BaseScreen>
    );
  }
}
