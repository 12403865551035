/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum SourceType {
  MediaList = "MediaList",
  MyList = "MY_FAVORITES",
  RecentlyWatched = "RECENTLY_WATCHED",
  Purchased = "PURCHASED",
}
