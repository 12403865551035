/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { memo, useContext, useMemo } from "react";
import {
  MediaImageType,
  MediaStreamType,
  useDispatch,
  ROUTES,
  AssetType,
  ThemeContext,
} from "@bms/common";
import { ImageRatio, ImageWithRatio } from "components/ImageWithRatio";
import "./AudioInfo.scss";
import { AudioContext } from "context";
import { push } from "connected-react-router";
import { ReactComponent as PodcastFallback } from "resources/default-podcast.svg";

export const AudioInfoContent = ({ isMobile = false }) => {
  const dispatch = useDispatch();
  const { themeProvider } = useContext(ThemeContext);
  const { media, streamType } = useContext(AudioContext);
  const episodeNumber =
    streamType === MediaStreamType.Trial
      ? "Trailer"
      : `#${media?.OrderInParent || 1}`;

  const imageSrc = useMemo(() => {
    let src: string | undefined;
    if (media?.Images) {
      const squareImg = media?.Images?.find(
        (it) => it.ImageTypeCode === MediaImageType.Square
      )?.Url;
      if (squareImg) {
        src = squareImg;
      } else if (media?.Images && media?.Images.length > 0) {
        src = media?.Images[0].Url;
      }
    }
    return src;
  }, [media?.Images]);

  const renderImage = useMemo(() => {
    if (!imageSrc) {
      return (
        <PodcastFallback
          className="AudioInfo__image"
          style={{ color: themeProvider.getColor("AppPrimaryTextColor") }}
          onClick={() =>
            dispatch(
              push(
                `${
                  MediaTypeCode === AssetType.Podcast
                    ? ROUTES.PODCAST_DETAILS_SCREEN
                    : ROUTES.ALBUM_DETAILS_SCREEN
                }/${media?.Id}`
              )
            )
          }
        />
      );
    }

    return (
      <ImageWithRatio
        ratio={ImageRatio["1:1"]}
        disableAutoWH
        className="AudioInfo__image"
        imageSrc={imageSrc}
        isSpinner={false}
        onClick={() =>
          dispatch(
            push(
              `${
                MediaTypeCode === AssetType.Podcast
                  ? ROUTES.PODCAST_DETAILS_SCREEN
                  : ROUTES.ALBUM_DETAILS_SCREEN
              }/${media?.Id}`
            )
          )
        }
      />
    );
  }, [imageSrc]);

  if (!media) {
    return null;
  }

  const { MediaTypeCode, Title, Year } = media;

  if (isMobile) {
    return (
      <div className="AudioInfo">
        <div className="AudioInfo__texts">
          <span>{episodeNumber}</span>
          <span className="AudioInfo__texts--title">{Title}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="AudioInfo">
      {renderImage}
      <div className="AudioInfo__texts">
        <span>
          {episodeNumber}:&nbsp;<strong>{Title}</strong>&nbsp;
        </span>
        <span className="AudioInfo__texts--details">{Year}</span>
      </div>
    </div>
  );
};

export const AudioInfo = memo(AudioInfoContent);
