/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ApiErrors,
  AuthStore,
  buildPasswordMatchRule,
  buildPasswordMinimumRule,
  buildPasswordPatternRule,
  buildRequiredRule,
  combineApiErrors,
  IFormValues,
  IResetForgotPasswordModel,
  updateApiErrors,
  UrlHelper,
} from "@bms/common";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Form, FormButton, LabelField } from "../../Form";
import "../../Form/Form.scss";
import { FormLine } from "../../Form/FormLine";
import { InputPassword } from "../../Input";
import { LoaderSpinner } from "../../LoaderSpinner";
import "../AuthForm.scss";
import "./ForgotPasswordForm.scss";

interface IResetPasswordFormProps extends RouteComponentProps, WithTranslation {
  onSubmit: (values: IResetForgotPasswordModel) => void;
  onValidate: (token: string) => void;
  authState: AuthStore.Types.IAuthState;
}

interface IResetPasswordFormState {
  isFormSent: boolean;
  apiErrors: ApiErrors;
}

interface IResetPasswordQueryParams {
  email?: string;
  token?: string;
}

export class ResetPasswordForm extends React.PureComponent<
  IResetPasswordFormProps,
  IResetPasswordFormState
> {
  public state: IResetPasswordFormState = {
    isFormSent: false,
    apiErrors: {},
  };
  private queryParams: IResetPasswordQueryParams;

  constructor(props: IResetPasswordFormProps) {
    super(props);
    this.queryParams = UrlHelper.parse(
      this.props.location.search
    ) as IResetPasswordQueryParams;
  }

  static getDerivedStateFromProps(
    nextProps: IResetPasswordFormProps,
    prevState: IResetPasswordFormState
  ) {
    if (prevState.isFormSent && nextProps.authState.error) {
      if (!("Password" in nextProps.authState.error)) {
        return {
          apiErrors: {
            confirm_password: combineApiErrors(nextProps.authState.error),
          },
        };
      } else {
        return {
          apiErrors: Object.assign({}, nextProps.authState.error),
        };
      }
    }

    return null;
  }

  public componentDidMount() {
    this.props.onValidate(this.queryParams.token || "");
  }

  private onFinish = (values: IFormValues) => {
    const payload: IResetForgotPasswordModel = {
      Email: this.queryParams.email || "",
      Password: values["password"],
      Token: this.queryParams.token || "",
    };

    this.setState({ isFormSent: true });
    this.props.onSubmit(payload);
  };

  private onValuesChange = (changedValues: IFormValues) => {
    const { apiErrors } = this.state;
    const [isUpdated, newApiErrors] = updateApiErrors(apiErrors, changedValues);

    if (isUpdated) {
      this.setState({ isFormSent: false, apiErrors: newApiErrors });
    }
  };

  private renderForm() {
    const { t } = this.props;
    return (
      <Form onFinish={this.onFinish} onValuesChange={this.onValuesChange}>
        <h1 className="text-upper text-center">
          {t("RESET_PASSWORD__TITLE", "Reset password")}
        </h1>
        <p className="text-center text-large">
          {t(
            "RESET_PASSWORD__SUBTITLE",
            "Please enter your requested data below to proceed with recovering/resetting your password."
          )}
        </p>
        <FormLine />

        <LabelField
          name="password"
          label={t("RESET_PASSWORD__PASSWORD_LABEL", "Password")}
          rules={[
            buildRequiredRule(),
            buildPasswordMinimumRule(),
            buildPasswordPatternRule(),
          ]}
          apiErrors={this.state.apiErrors.Password || []}
        >
          <InputPassword className="FormInput" visibilityToggle={false} />
        </LabelField>

        <LabelField
          name="confirm_password"
          label={t("RESET_PASSWORD__RE_PASSWORD_LABEL", "Re-enter password")}
          dependencies={["password"]}
          rules={[
            buildRequiredRule(),
            (context) => buildPasswordMatchRule(context, "password"),
          ]}
          apiErrors={this.state.apiErrors.confirm_password || []}
        >
          <InputPassword className="FormInput" visibilityToggle={false} />
        </LabelField>

        <div className="ButtonLine">
          <FormButton>{t("RESET_PASSWORD__SUBMIT", "Reset")}</FormButton>
        </div>
      </Form>
    );
  }

  private renderError() {
    const { t } = this.props;
    return (
      <div className="form">
        <h1 className="text-upper text-center">
          {t("RESET_PASSWORD__TOKEN_EXPIRED", "The token has expired")}
        </h1>
      </div>
    );
  }

  private renderLoader() {
    return (
      <div className="form" style={{ textAlign: "center" }}>
        <LoaderSpinner />
      </div>
    );
  }

  public render() {
    const { isTokenValid } = this.props.authState;

    return (
      <div className="ResetForgotPasswordForm AuthForm">
        {isTokenValid === undefined
          ? this.renderLoader()
          : isTokenValid
          ? this.renderForm()
          : this.renderError()}
      </div>
    );
  }
}
