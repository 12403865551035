/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ImageHelper, MediaType, RouteHelper } from "@bms/common";
import cx from "classnames";
import React, { useMemo } from "react";
import { WithTranslation } from "react-i18next";

import { ImageWithPlaceholder, PlayIconButton } from "components";
import resources from "resources/list";

import { IListComponentItemProps } from "../../models";

import { ListComponentItemRoundSkeleton } from "./ListComponentItemRoundSkeleton";

import "./ListComponentItemRound.scss";

export interface IListComponentItemRoundProps
  extends IListComponentItemProps,
    WithTranslation {}

export const ListComponentItemRound = (props: IListComponentItemRoundProps) => {
  const { media, readOnly, isPlaceholder } = props;

  const frameImageUrl = useMemo(
    () => ImageHelper.getFrameImageUrl(media?.Images),
    [media?.Images]
  );

  if (!media || isPlaceholder) {
    return <ListComponentItemRoundSkeleton />;
  }

  const isAdvertisement = media.MediaTypeCode === MediaType.Advertisement;
  const isArticle = media.MediaTypeCode === MediaType.Article;
  const showOnHoverOverlay = isArticle || isAdvertisement;

  return (
    <div className="ListComponentItemRound">
      <div
        className={cx("ListComponentItemRound__container")}
        onClick={() => {
          RouteHelper.goToDetails(media);
        }}
      >
        <div className="ListComponentItemRound__image-container">
          <ImageWithPlaceholder
            imageSrc={frameImageUrl}
            imageContainerClassName="ListComponentItemRound__image"
            placeholderSrc={resources.framePlaceholder}
          />
        </div>

        {showOnHoverOverlay && (
          <div className="ListComponentItemRound__overlay" />
        )}

        {!readOnly && (
          <PlayIconButton
            media={media}
            className="ListComponentItemRound__play-button"
          />
        )}
      </div>
    </div>
  );
};
