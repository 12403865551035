/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IApplicationHeaderScreensModel,
  IApplicationMenuScreenModel,
  ScreenType,
} from "@bms/common";
import { IAuthState } from "@bms/common/src/store/auth/types";
import { AppHeaderVisibility, ApplicationMenu } from "components";
import React from "react";
import { HeaderLogo, HeaderSignIn, HeaderUserInfo } from "..";

import "./MobileHeader.scss";

interface IMobileHeader {
  configurationScreens?: IApplicationHeaderScreensModel;
  visibility?: AppHeaderVisibility;
  auth?: IAuthState;
}

export const MobileHeader = ({
  configurationScreens,
  visibility = AppHeaderVisibility.Default,
  auth,
}: IMobileHeader) => {
  return (
    <header className="MobileHeader">
      <div className="MobileHeader__Left">
        <HeaderLogo />
      </div>
      <div className="MobileHeader__Right">
        {!!(visibility & AppHeaderVisibility.UserInfo) && (
          <HeaderUserInfo user={auth?.user} isMobile={true} />
        )}

        {!!(visibility & AppHeaderVisibility.SignIn) && <HeaderSignIn />}

        {!!(visibility & AppHeaderVisibility.Menu) && (
          <ApplicationMenu
            isMobile={true}
            screen={
              configurationScreens?.[
                ScreenType.ApplicationMenu
              ] as IApplicationMenuScreenModel
            }
          />
        )}
      </div>
    </header>
  );
};
