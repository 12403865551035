/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import "intersection-observer";
import * as React from "react";
import { render } from "react-dom";
import { Provider, ReactReduxContext } from "react-redux";

import { AnalyticsProvider } from "context/analytics";
import { ScreenOrientationProvider } from "context/browser";

import { appHistory, appStore, withSentry } from "./config";
import "./polyfill";
import { RootScreen } from "./screens";
import { usePlayerLoader } from "hooks/usePlayerLoader";

console.log(
  `%cVERSION: %c${process.env.REACT_APP_VERSION}`,
  "color: green;font-weight: bold;",
  "color: red;font-weight: bold;"
);

const App: React.FC = ({ children }) => {
  usePlayerLoader();

  return (
    <ScreenOrientationProvider>
      <Provider store={appStore} context={ReactReduxContext}>
        <AnalyticsProvider>{children}</AnalyticsProvider>
      </Provider>
    </ScreenOrientationProvider>
  );
};

const Root: React.FC = () => <RootScreen history={appHistory} />;

const AppRoot = withSentry({
  App,
  Root,
});

window.onload = () => render(<AppRoot />, document.getElementById("root"));
