/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetImageType,
  DataProvider,
  IAssetImageModel,
  IAssetModel,
  IErrorModel,
  PlatformType,
  RecordStatus,
  useDataLoader,
} from "@bms/common";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
} from "react";

import { useUploadAssistantDispatch } from "context";

import { LoaderSpinner } from "../LoaderSpinner";

interface CreatorMediaContextValue {
  asset: IAssetModel;
  refresh: () => Promise<void>;
  update: (payload: IAssetModel) => Promise<any>;
  getImage: (
    type: AssetImageType,
    asset: IAssetModel
  ) => IAssetImageModel | undefined;
  insertImage: (
    file: File | undefined,
    type: AssetImageType,
    asset: IAssetModel
  ) => Promise<void>;
}

interface CreatorMediaProviderProps {
  assetId: string;
  children: ReactNode;
}

const CreatorMediaContext = createContext<CreatorMediaContextValue>(
  {} as CreatorMediaContextValue
);

export const CreatorMediaProvider = (props: CreatorMediaProviderProps) => {
  const { assetId, children } = props;

  const { uploadFile } = useUploadAssistantDispatch();

  const { data: asset, refresh } = useDataLoader<IAssetModel, IErrorModel>({
    loader: () =>
      DataProvider.getAsset(Number(assetId)).then((data) => ({
        ok: true,
        data,
      })),
    deps: [assetId],
  });

  const update = useCallback((payload: IAssetModel) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { Contents, Payment, Images, ...rest } = payload;

    return DataProvider.updateAsset({ ...rest, IsFree: true });
  }, []);

  const getImage = useCallback(
    (type: AssetImageType, asset: IAssetModel) =>
      asset.Images?.find((item) => {
        if (item.AssetImageTypeCode === type) {
          return item;
        }
      }),
    []
  );

  const insertImage = useCallback(
    async (
      file: File | undefined,
      type: AssetImageType,
      asset: IAssetModel
    ) => {
      if (file && file.type) {
        const existingImage = getImage(type, asset);

        const response = existingImage
          ? await DataProvider.updateAssetImage(existingImage)
          : await DataProvider.insertAssetImage({
              AssetId: asset.Id,
              PlatformCode: PlatformType.Any,
              PlatformDisplayName: "Any",
              AssetImageTypeCode: type,
              AssetImageTypeDisplayName: type,
              RecordStatus: RecordStatus.Inserted,
            });

        if (response.UploadInfo) {
          const title = `${type.toLowerCase()}: ${asset.Title}`;

          await uploadFile(
            { title },
            { file, uploadInfo: response.UploadInfo }
          );
        }

        return Promise.resolve();
      }

      return Promise.resolve();
    },
    [getImage, uploadFile]
  );

  if (asset === undefined) {
    return <LoaderSpinner className="WizardSpinner" />;
  }

  return (
    <CreatorMediaContext.Provider
      value={{ asset, refresh, update, getImage, insertImage }}
    >
      {children}
    </CreatorMediaContext.Provider>
  );
};

export const useCreatorMedia = (): CreatorMediaContextValue => {
  const context = useContext(CreatorMediaContext);

  if (!context) {
    throw new Error("Component beyond CreatorMediaContext");
  }

  return context;
};
