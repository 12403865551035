/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import {
  ISettingsMenuItemComponentModel,
  ConfigurationHelper,
  ActionType,
  useIsLoggedIn,
} from "@bms/common";
import cx from "classnames";
import { RouteHelper } from "../../../helpers";
import { ROUTES } from "../../../constants";

import "./SettingsMenuItem.scss";

export interface ISettingsMenuItemProps {
  component: ISettingsMenuItemComponentModel;
  onClick?: () => void;
}

export const SettingsMenuItem: React.FC<ISettingsMenuItemProps> = ({
  component,
  onClick,
}: ISettingsMenuItemProps) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const isLoggedIn = useIsLoggedIn();
  const screenPath = ConfigurationHelper.getScreenPathByType(
    component?.Action?.ScreenTypeCode
  );
  const isActive = `/${ROUTES.SETTINGS}/${screenPath}` === match.url;
  const isEmpty = !component.Action;

  const onActionClick = async () => {
    if (onClick) {
      return onClick();
    }
    await RouteHelper.performAction(component.Action);
  };

  const renderTitle = () => {
    if (component.Action?.ActionType === ActionType.SignInOut) {
      return t(isLoggedIn ? "COMMON__LOGOUT" : "COMMON__SIGN_IN");
    }

    return t(`${component.TitleTranslationKey}`, component.Title ?? "");
  };

  return (
    <li
      className={cx("SettingsMenuItem", {
        "SettingsMenuItem--active": isActive,
        "SettingsMenuItem--empty": isEmpty,
      })}
      onClick={onActionClick}
    >
      {renderTitle()}
      {isActive && <div className="SettingsMenuItem--active__indicator" />}
    </li>
  );
};
