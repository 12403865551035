/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { HTTP_METHOD } from "../../../../../constants";
import {
  IVestigitAttackSampleParams,
  IVestigitPaginatedAttacksModel,
} from "../../../../../models";
import { AxiosSubscriber } from "../../../../../services";

import { VestigitBaseService } from "./VestigitBaseService";

export class VestigitAttackService extends VestigitBaseService {
  public getAttacks = (): Observable<IVestigitPaginatedAttacksModel> =>
    new Observable(
      (observer: Observer<IVestigitPaginatedAttacksModel>) =>
        new AxiosSubscriber(observer, {
          isExternal: true,
          method: HTTP_METHOD.GET,
          baseURL: this.baseURL,
          url: "/attacks",
        })
    );

  public getAttackSampleFile = (
    params: IVestigitAttackSampleParams
  ): Observable<Blob> => {
    const { aid, impersonate_user_id, ...rest } = params;

    return new Observable(
      (observer: Observer<Blob>) =>
        new AxiosSubscriber(observer, {
          isExternal: true,
          method: HTTP_METHOD.GET,
          baseURL: this.baseURL,
          url: `/attack/${aid}/sample`,
          axiosConfig: {
            headers: {
              "X-ImpersonateUser": impersonate_user_id,
            },
            responseType: "blob",
            // payload as query params in url
            params: rest,
          },
        })
    );
  };
}
