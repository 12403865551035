/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import framePlaceholder from "./frame-placeholder.svg";
import coverPlaceholder from "./cover-placeholder.svg";

export default {
  coverPlaceholder,
  framePlaceholder,
};
