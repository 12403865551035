/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AppConfig,
  AuthStore,
  ConfigurationHelper,
  ConfigurationStore,
  DataProvider,
  DeviceHelper,
  IAppState,
  IScreenModel,
  IUserDeviceModel,
  PlatformHelper,
  PlatformType,
  ROUTES,
  ScreenFrameType,
  ScreenType,
  ThemeContext,
  ThemeProvider,
  TimeHelper,
  useDispatch,
  useIsLoggedIn,
  useSelector,
} from "@bms/common";
import Color from "color";
import { ConnectedRouter } from "connected-react-router";
import cssVars from "css-vars-ponyfill";
import { History } from "history";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactReduxContext } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { AudioProvider, UploadAssistantProvider } from "context";

import {
  AudioPlayer,
  AudioPlayerController,
  AuthorizatedRoute,
  CookieConsentBar,
  EmbedderCreator,
  LicensedRoute,
} from "../components";
import { MediaCreator } from "../components/MediaCreator";

import App from "./App";
import { ArticleDetailsScreen } from "./ArticleDetailsScreen";
import {
  ConfirmAccountWithPasswordScreen,
  ConfirmEmailScreen,
  ForgotPasswordScreen,
  ForgotPasswordSuccessScreen,
  LoginScreen,
  RegisterScreen,
  RegisterSuccessScreen,
  ResetPasswordScreen,
} from "./AuthScreen";
import { CustomScreen } from "./CustomScreen";
import { DeleteAccountScreen } from "./DeleteAccountScreen";
import { DeleteAccountSuccessScreen } from "./DeleteAccountSuccessScreen";
import { Events } from "./EventsScreen";
import { IntroScreen } from "./IntroScreen";
import { LinkScreen } from "./LinkScreen/LinkScreen";
import { MainScreen } from "./MainScreen";
import { MovieDetailsScreen } from "./MovieDetailsScreen";
import { PackageDetailsScreen } from "./PackageDetailsScreen";
import { PaymentChangeStatusScreen } from "./PaymentChangeStatusScreen";
import { PaymentConfirmationScreen } from "./PaymentConfirmationScreen";
import { PaymentScreen } from "./PaymentScreen";
import { PaymentStatusScreen } from "./PaymentStatusScreen";
import { PlaylistScreen } from "./PlaylistScreen";
import { PodcastDetailsScreen } from "./PodcastDetailsScreen";
import { PrivacyPolicyScreen } from "./PrivacyPolicyScreen";
import { SearchScreen } from "./SearchScreen";
import { SeriesDetailsScreen } from "./SeriesDetailsScreen";
import { SettingsScreen } from "./SettingsScreen";
import { Splash } from "./Splash";
import { Watch } from "./Watch";
import { DetectorScreen } from "./DetectorScreen";

const themeProvider: ThemeProvider = new ThemeProvider();

export interface IRootProps {
  history: History;
}

export const RootScreen: React.FC<IRootProps> = ({ history }) => {
  const dispatch = useDispatch();
  const configuration = useSelector(
    (state: IAppState) => state.configuration.configuration
  );
  const isConfigurationLoading = useSelector(
    (state: IAppState) => state.configuration.isLoading
  );
  const { i18n } = useTranslation();
  const isUserLoggedIn = useIsLoggedIn();
  const [device, setDevice] = useState<IUserDeviceModel>();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const isWebMobileSize =
    device?.PlatformCode === PlatformType.Web && windowWidth < 500;

  const initializeTranslations = () => {
    if (configuration?.Translations) {
      Object.entries(configuration.Translations).forEach(
        ([language, translations]) => {
          i18n.addResourceBundle(language, "translation", translations);
        }
      );

      const currentLanguage = i18n.language.split("-")[0];
      const supportedLanguages = Object.keys(configuration?.Translations);

      // Change language to current browser language if supported, otherwise
      // set default english language
      i18n.changeLanguage(
        supportedLanguages.includes(currentLanguage) ? currentLanguage : "en"
      );
    } else {
      // To always fulfill condition in render
      i18n.addResourceBundle("en", "translation", {});
      i18n.changeLanguage("en");
    }
  };

  const createRoute = (screen: IScreenModel): React.ReactElement => {
    switch (screen.ScreenFrameTypeCode) {
      case ScreenFrameType.Custom:
        return (
          <LicensedRoute
            exact
            key={screen.Id}
            path={`/${ConfigurationHelper.getScreenRouteKey(screen)}`}
            component={CustomScreen}
          />
        );
      default:
        return (
          <LicensedRoute
            exact
            key={screen.Id}
            path={`/${ConfigurationHelper.getScreenRouteKey(screen)}`}
            component={MainScreen}
          />
        );
    }
  };

  const createRoutes = () => {
    if (!configuration) {
      return null;
    }

    const routes: React.ReactElement[] = [];

    if (configuration && configuration.Screens) {
      for (const screenKey in configuration.Screens) {
        switch (screenKey) {
          case ScreenType.Custom:
            const customScreens = configuration.Screens.CUSTOM;
            for (const customScreenKey in customScreens) {
              const customScreen = customScreens[customScreenKey];
              routes.push(createRoute(customScreen));
            }
            break;
          case ScreenType.Intro:
          case ScreenType.Search:
          case ScreenType.ApplicationHeader:
          case ScreenType.ApplicationFooter:
          case ScreenType.ApplicationMenu:
          case ScreenType.Register:
          case ScreenType.RegisterSuccess:
            // Skip creating route for predefined screens;
            continue;
          default:
            const screen = configuration.Screens[screenKey];
            routes.push(createRoute(screen));
            break;
        }
      }
    }

    return routes;
  };

  const handleSiteResize = () => {
    document.documentElement.style.fontSize = `${themeProvider.getFontSize()}px`;
    setWindowWidth(window.innerWidth);
  };
  const onLanguageChanged = async (lng: string) => {
    await TimeHelper.setLanguage(lng);
  };

  useEffect(() => {
    window.addEventListener("resize", handleSiteResize);
    i18n.on("languageChanged", onLanguageChanged);

    DataProvider.initSession();

    dispatch(AuthStore.Actions.syncUser());
    dispatch(
      ConfigurationStore.Actions.getConfiguration(PlatformHelper.Platform)
    );

    DeviceHelper.getDeviceInfo().then((device) => setDevice(device));

    return () => {
      window.removeEventListener("resize", handleSiteResize);
      i18n.off("languageChanged", onLanguageChanged);
    };
  }, []);

  useEffect(() => {
    if (!configuration) {
      return;
    }

    themeProvider.setBrandings(configuration, isWebMobileSize);
    document.documentElement.style.fontSize = `${themeProvider.getFontSize()}px`;

    initializeTranslations();

    const branding = themeProvider.getBranding();

    const cssVariables = {
      // design system (available in studio)
      "--bg-color": themeProvider.getColor("AppBackgroundColor"),
      "--primary-color": themeProvider.getColor("AppPrimaryColor"),
      "--secondary-color": themeProvider.getColor("AppSecondaryColor"),
      "--primary-text-color": themeProvider.getColor("AppPrimaryTextColor"),
      "--secondary-text-color": themeProvider.getColor("AppSecondaryTextColor"),
      "--header-bg-color": themeProvider.getColor("HeaderBackgroundColor"),
      "--header-link-color": themeProvider.getColor("HeaderLinkColor"),
      "--header-link-hover-color": themeProvider.getColor(
        "HeaderLinkHoverColor"
      ),
      "--footer-bg-color": themeProvider.getColor("FooterBackgroundColor"),
      "--footer-link-color": themeProvider.getColor("FooterLinkColor"),
      "--footer-link-hover-color": themeProvider.getColor(
        "FooterLinkHoverColor"
      ),

      // design system (not available in studio)
      "--focus-color": Color(themeProvider.getColor("AppPrimaryColor"))
        .darken(0.3)
        .hex(),
      "--bg-secondary-color": Color(
        themeProvider.getColor("AppBackgroundColor")
      )
        .negate()
        .darken(0.921)
        .hex(),
      "--bg-tertiary-color": Color(themeProvider.getColor("AppBackgroundColor"))
        .negate()
        .darken(0.878)
        .hex(),

      // custom (available in studio - should be removed?)
      "--cell-background-color": themeProvider.getColor(
        "AppCellsBackgroundColor"
      ),

      // custom (not available in studio - should be removed?)
      "--primary-color-d-50": Color(themeProvider.getColor("AppPrimaryColor"))
        .darken(0.5)
        .hex(),
      "--primary-text-color-d-30": Color(
        themeProvider.getColor("AppPrimaryTextColor")
      )
        .darken(0.3)
        .hex(),
      "--bg-color-o-30": Color(themeProvider.getColor("AppBackgroundColor"))
        .fade(0.3)
        .hex(),

      // others
      "--font-family": `${themeProvider.getFontFamily()}, Helvetica, Arial, sans-serif`,
      "--app-padding-top": `${branding.AppPaddingTop ?? 70}px`,
      "--app-padding-left": `${branding.AppPaddingLeft ?? 55}px`,
      "--app-padding-right": `${branding.AppPaddingRight ?? 63}px`,
      "--app-padding-bottom": `${branding.AppPaddingBottom ?? 64}px`,
    };

    cssVars({
      rootElement: document,
      variables: cssVariables,
    });

    // App favicon
    const faviconUrl =
      themeProvider.WebFaviconUrl() || `${AppConfig.PublicUrl}/favicon.ico`;

    let favicon = document.getElementById("favicon") as HTMLLinkElement;

    if (!favicon) {
      favicon = document.createElement("link");
      favicon.id = "favicon";
      favicon.rel = "shortcut icon";
      favicon.type = "image/x-icon";
    }

    favicon.href = faviconUrl;

    document.head.appendChild(favicon);
    // App title
    document.title = branding.AppTitle || AppConfig.AppName;
    // App description
    if (branding.AppDescription) {
      let metaDescription = document.querySelector(
        'meta[name="description"]'
      ) as HTMLMetaElement;

      if (!metaDescription) {
        metaDescription = document.createElement("meta");
        metaDescription.id = "description";
        metaDescription.name = "description";
      }

      metaDescription.content = branding.AppDescription;

      document.head.appendChild(metaDescription);
    }
  }, [configuration, isWebMobileSize]);

  useEffect(() => {
    if (!i18n.language || !configuration) return;

    const language = configuration.Languages?.find(
      (lang) => lang.Code === i18n.language
    );
    const rtl = language && language.RTL;

    const documentHtml = document.getElementsByTagName("html")[0];

    if (rtl) {
      documentHtml.classList.add("rtl");
      documentHtml.dir = "rtl";
    } else {
      documentHtml.classList.remove("rtl");
      documentHtml.dir = "ltr";
    }
  }, [i18n.language, configuration]);

  if (
    !configuration ||
    isConfigurationLoading ||
    !i18n.hasResourceBundle("en", "translation")
  ) {
    return <Splash />;
  }

  return (
    <ThemeContext.Provider value={{ themeProvider: themeProvider }}>
      <UploadAssistantProvider>
        <AudioProvider>
          <App>
            <ConnectedRouter
              history={history}
              context={ReactReduxContext}
              noInitialPop
            >
              <Switch>
                <Route exact path="/" component={IntroScreen}>
                  {isUserLoggedIn && <Redirect to={ROUTES.HOME} />}
                </Route>
                {createRoutes()}
                <LicensedRoute
                  exact
                  path={`${ROUTES.MOVIE_DETAILS_SCREEN}/:id`}
                  component={MovieDetailsScreen}
                />
                <LicensedRoute
                  exact
                  path={[
                    `${ROUTES.PODCAST_DETAILS_SCREEN}/:id`,
                    `${ROUTES.ALBUM_DETAILS_SCREEN}/:id`,
                  ]}
                  component={PodcastDetailsScreen}
                />
                <LicensedRoute
                  exact
                  path={`${ROUTES.SERIES_DETAILS_SCREEN}/:id`}
                  component={SeriesDetailsScreen}
                />
                <LicensedRoute
                  exact
                  path={`${ROUTES.PACKAGE_DETAILS_SCREEN}/:id`}
                  component={PackageDetailsScreen}
                />
                <LicensedRoute
                  exact
                  path={`${ROUTES.ARTICLE_SCREEN}/:id`}
                  component={ArticleDetailsScreen}
                />
                <AuthorizatedRoute
                  exact
                  path={`${ROUTES.PLAYER_SCREEN}/:id`}
                  component={Watch}
                />
                <LicensedRoute
                  exact
                  path={`${ROUTES.PRIVACY_POLICY_SCREEN}/:id`}
                  component={PrivacyPolicyScreen}
                />
                <LicensedRoute
                  exact
                  path={`${ROUTES.EVENTS}`}
                  component={Events}
                />
                <LicensedRoute
                  exact
                  path={`${ROUTES.MEDIA_CREATOR}/:id`}
                  component={MediaCreator}
                />
                <AuthorizatedRoute
                  exact
                  path={`${ROUTES.EMBEDDER_CREATOR}/:id`}
                  component={EmbedderCreator}
                />
                <LicensedRoute
                  exact
                  path={`${ROUTES.PLAYLIST_SCREEN}/:id/:title`}
                  component={PlaylistScreen}
                />
                <LicensedRoute
                  exact
                  path={ROUTES.SEARCH_SCREEN}
                  component={SearchScreen}
                />
                <AuthorizatedRoute
                  exact
                  path={`${ROUTES.BUY_SCREEN}/:id`}
                  component={PaymentScreen}
                />
                <LicensedRoute
                  exact
                  path={`${ROUTES.BUY_CONFIRMATION_SCREEN}`}
                  component={PaymentConfirmationScreen}
                />
                <LicensedRoute
                  exact
                  path={`${ROUTES.PAYMENT_STATUS_SCREEN}/:id`}
                  component={PaymentStatusScreen}
                />
                <LicensedRoute
                  exact
                  path={`${ROUTES.PAYMENT_CHANGE_STATUS_SCREEN}/:id`}
                  component={PaymentChangeStatusScreen}
                />
                <AuthorizatedRoute
                  exact
                  path={`${ROUTES.DETECTOR_SCREEN}`}
                  component={DetectorScreen}
                />
                <AuthorizatedRoute
                  exact
                  path={ROUTES.SETTINGS_SCREEN}
                  component={SettingsScreen}
                />
                <AuthorizatedRoute
                  exact
                  path={`${ROUTES.SETTINGS_SCREEN}/:submenuKey`}
                  component={SettingsScreen}
                />
                {!AppConfig.IsLoginDisabled && (
                  <Route
                    exact
                    path={`${ROUTES.LOGIN}`}
                    component={LoginScreen}
                  />
                )}
                <Route
                  exact
                  path={ROUTES.REGISTER}
                  component={RegisterScreen}
                />
                <Route
                  exact
                  path={ROUTES.FORGOT_PASSWORD}
                  component={ForgotPasswordScreen}
                />
                <Route
                  exact
                  path={ROUTES.REGISTER_SUCCESS}
                  component={RegisterSuccessScreen}
                />
                <Route
                  exact
                  path={ROUTES.FORGOT_PASSWORD_SUCCESS}
                  component={ForgotPasswordSuccessScreen}
                />
                <Route
                  exact
                  path={ROUTES.RESET_PASSWORD}
                  component={ResetPasswordScreen}
                />
                <Route
                  exact
                  path={ROUTES.REGISTER_CONFIRM}
                  component={ConfirmEmailScreen}
                />
                <Route
                  exact
                  path={ROUTES.REGISTER_CONFIRM_ACCOUNT_WITH_PASSWORD}
                  component={ConfirmAccountWithPasswordScreen}
                />
                <Route exact path={ROUTES.LINK_SCREEN} component={LinkScreen} />
                <Route
                  exact
                  path={`${ROUTES.DELETE_ACCOUNT}`}
                  component={DeleteAccountScreen}
                />
                <Route
                  exact
                  path={`${ROUTES.DELETE_ACCOUNT_SUCCESS}`}
                  component={DeleteAccountSuccessScreen}
                />
              </Switch>
              {configuration && <CookieConsentBar />}
              <AudioPlayer />
              <AudioPlayerController mode="standalone" />
            </ConnectedRouter>
          </App>
        </AudioProvider>
      </UploadAssistantProvider>
    </ThemeContext.Provider>
  );
};
