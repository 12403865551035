/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import cx from "classnames";
import "./MediaIcon.scss";

export interface IMediaIconProps {
  style?: React.CSSProperties;
  onIconClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onBackgroundClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  hideIcon?: boolean;
  className?: string;
  children?: React.ReactElement;
}

export const MediaIcon = ({
  style,
  onIconClick,
  onBackgroundClick,
  hideIcon,
  children,
  className,
}: IMediaIconProps) => {
  const onIconClickListener = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (onIconClick) {
      e.stopPropagation();
      onIconClick(e);
    }
  };
  const onBackgroundClickListener = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (onBackgroundClick) {
      e.stopPropagation();
      onBackgroundClick(e);
    }
  };

  return (
    <div
      className={cx("MediaIcon", className)}
      style={style}
      onClick={onBackgroundClickListener}
    >
      {!hideIcon && <i>{children}</i>}
      <i className="MediaIcon__activeArea" onClick={onIconClickListener}></i>
    </div>
  );
};
