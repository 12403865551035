/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React from "react";
import { useTranslation } from "react-i18next";

interface IComponentContainerProps {
  children: React.ReactNode[] | React.ReactNode;
}

export const ComponentContainer = ({ children }: IComponentContainerProps) => {
  const { t } = useTranslation();

  const isChildNull = (children: React.ReactNode[] | React.ReactNode) => {
    if (Array.isArray(children)) {
      return children
        ?.map((child: React.ReactNode) => {
          return child === undefined;
        })
        .every((isUndefined: boolean) => isUndefined === true);
    }
    return children === undefined;
  };

  const isNull = isChildNull(children);
  if (isNull) {
    return (
      <h1 className="text-upper text-center">
        {t("BASE_SCREEN__NO_DATA_FOUND")}
      </h1>
    );
  }
  return <>{children}</>;
};
