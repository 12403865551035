/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useCallback, useContext } from "react";
import cx from "classnames";
import { ROUTES, useDispatch, AudioStore, DataProvider } from "@bms/common";
import { ClipboardCopyButton } from "components/ClipboardCopyButton";
import { ReactComponent as ShareIcon } from "../../../resources/player/share.svg";
import { VolumeButton } from "../VolumeButton";
import { AudioSpeedButton } from "../AudioSpeedButton";
import { AudioContext } from "context";

import "./AudioPlayerAdditionalControls.scss";

export const AudioPlayerAdditionalControls = ({ isMobile = false }) => {
  const { media, mediaPlayInfo, currentTime } = useContext(AudioContext);
  const dispatch = useDispatch();
  const onClipboardClick = useCallback(() => {
    navigator.clipboard.writeText(
      `${window.location.origin}${ROUTES.PODCAST_DETAILS_SCREEN}/${media?.Id}`
    );
  }, [media]);

  const onExitClick = async () => {
    try {
      if (mediaPlayInfo && mediaPlayInfo.MediaId && currentTime) {
        await DataProvider.setWatchProgress(
          mediaPlayInfo?.MediaId,
          currentTime
        );
      }
      dispatch(AudioStore.Actions.resetStore());
    } catch (error) {
      dispatch(AudioStore.Actions.resetStore());
    }
  };

  return (
    <div className="AudioPlayerAdditionalControls">
      <AudioSpeedButton
        className={cx(
          "AudioPlayerAdditionalControls__button",
          "AudioPlayerAdditionalControls__button--large-text",
          "AudioPlayerAdditionalControls--transparent-bg"
        )}
      />
      {!isMobile && (
        <ClipboardCopyButton
          placement="top"
          className={cx(
            "AudioPlayerAdditionalControls__button",
            "AudioPlayerAdditionalControls--transparent-bg"
          )}
          customIcon={<ShareIcon />}
          onClick={onClipboardClick}
        />
      )}

      {!isMobile && (
        <VolumeButton
          className={cx(
            "AudioPlayerAdditionalControls--transparent-bg",
            "AudioPlayerAdditionalControls__button"
          )}
        />
      )}
      <span
        className={cx(
          "AudioPlayerAdditionalControls__button",
          "AudioPlayerAdditionalControls__button--close-button"
        )}
        onClick={onExitClick}
      >
        +
      </span>
    </div>
  );
};
