/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

const VESTIGIT_API_URL = process.env.REACT_APP_VESTIGIT_API_URL;

export class VestigitBaseService {
  public get baseURL(): string {
    if (!VESTIGIT_API_URL) {
      throw new Error("Vestigit api url is not set");
    }

    return VESTIGIT_API_URL;
  }
}
