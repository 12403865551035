/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import * as React from "react";
import { ThemeContext } from "@bms/common";
import { useImageLoader } from "hooks/useImageLoader";
import { LoaderSpinner } from "../LoaderSpinner";
import cx from "classnames";
import "./ImageWithPlaceholder.scss";

export interface IImageWithPlaceholderProps {
  imageContainerClassName?: string; // custom className for component
  imageContainerStyles?: React.CSSProperties; // custom styles for component
  imageClassName?: string; // custom styles for image element
  imageSrc?: string; // source for the image which will be rendered once it is loaded
  spinner?: boolean;
  gradient?: string;
  placeholderSrc?: string;
  children?: React.ReactElement;
}

/*
 * Component which will display given image based od imageSrc. Until image is not loaded (ready to be rendered)
 * a placeholder image will be rendered
 */
export const ImageWithPlaceholder = ({
  imageClassName,
  imageSrc,
  gradient,
  spinner,
  placeholderSrc,
  imageContainerClassName,
  imageContainerStyles,
  children,
}: IImageWithPlaceholderProps) => {
  const { themeProvider } = React.useContext(ThemeContext);
  const [mounted, setMounted] = React.useState(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const { loadImage } = useImageLoader();

  const viewContent = React.useMemo(() => {
    if (loaded) {
      return (
        <img
          alt="placeholder"
          key="placeholder"
          draggable={false}
          src={imageSrc}
          className={cx("ImageWithPlaceholder-img", imageClassName)}
        />
      );
    } else if (!gradient && placeholderSrc && spinner) {
      return (
        <div key="spinner" className="ImageWithPlaceholder-spinner">
          <LoaderSpinner />
        </div>
      );
    }

    return null;
  }, [gradient, loaded, imageClassName, imageSrc, placeholderSrc, spinner]);

  const backgroundStyle = React.useMemo(() => {
    const bgStyle = { ...imageContainerStyles };
    if (!loaded) {
      if (gradient) {
        bgStyle.backgroundImage = gradient;
      } else if (placeholderSrc) {
        bgStyle.backgroundImage = `url(${placeholderSrc})`;
        bgStyle.opacity = 0.6;
        bgStyle.backgroundColor = themeProvider.getColor(
          "AppCellsBackgroundColor"
        );
        bgStyle.backgroundRepeat = "no-repeat";
        bgStyle.backgroundSize = "cover";
        bgStyle.backgroundPosition = "center";
      }
      return bgStyle;
    }
  }, [gradient, loaded, imageContainerStyles, placeholderSrc, themeProvider]);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  React.useEffect(() => {
    if (imageSrc) {
      setLoaded(false);
    }
  }, [imageSrc]);

  React.useEffect(() => {
    if (imageSrc && !loaded && mounted) {
      loadImage(imageSrc).then((result) => setLoaded(!!result));
    }
  }, [imageSrc, loaded, mounted]);

  return (
    <div
      className={loaded ? imageContainerClassName : "ImageWithPlaceholder"}
      style={backgroundStyle}
    >
      {viewContent}
      {children}
    </div>
  );
};
