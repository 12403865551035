/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaModel, MediaStreamType, RouteHelper } from "@bms/common";
import React, { ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "components";
import { PlayButtonForm } from "components/Forms";

import "./PlayButtonDialog.scss";

interface PlayButtonDialogProps {
  media?: IMediaModel;
  disabled?: boolean;
  children: ReactNode;
}

export const PlayButtonDialog = (props: PlayButtonDialogProps) => {
  const { media, children, disabled = false } = props;

  const [isVisible, setIsVisible] = useState(false);

  const { t } = useTranslation();

  const openModal = useCallback(() => setIsVisible(true), []);
  const closeModal = useCallback(() => setIsVisible(false), []);

  const externalIdAssigned = Boolean(media?.ExternalId);

  const isDisabled = disabled || !externalIdAssigned;

  const onPlay = useCallback(
    (userId: string) => {
      media &&
        RouteHelper.goToPlayer(
          { media, streamType: MediaStreamType.Main, userId },
          false
        );
    },
    [media]
  );

  return (
    <>
      <div onClick={isVisible || isDisabled ? undefined : openModal}>
        {children}
      </div>
      <Dialog
        visible={isVisible}
        onCancel={closeModal}
        footer={null}
        closable
        destroyOnClose
      >
        <div className="PlayButtonDialog">
          <div className="PlayButtonDialog__Header">
            <h2 className="text-upper text-center">
              {t("PLAY_BUTTON_DIALOG__HEADER", "Playback")}
            </h2>
          </div>
          <div className="PlayButtonDialog__Content">
            <PlayButtonForm onPlay={onPlay} />
          </div>
        </div>
      </Dialog>
    </>
  );
};
