/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { RegisterSuccess as RegisterSuccessCommon } from "./RegisterSuccess";
import { RegisterSuccessCustom } from "./RegisterSuccessCustom";
import { SCREEN_VARIANTS } from "../../../constants";

export const RegisterSuccess =
  process.env.REACT_APP_REGISTER_SUCCESS_SCREEN_TYPE ===
  SCREEN_VARIANTS.REGISTER_SUCCESS.CUSTOM
    ? RegisterSuccessCustom
    : RegisterSuccessCommon;
