/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ImageHelper,
  MediaStore,
  PurchaseHelper,
  RouteHelper,
  TimeHelper,
  useMediaByIdFromMediaListSelector,
} from "@bms/common";

import { ITableRowProps } from "./types";
import { useIsMobile } from "../../../Responsive";
import { ImageWithPlaceholder } from "../../../ImageWithPlaceholder";
import icons from "../../../../resources/arrows";
import { ExpandedDetails } from "./ExpandedDetails";
import resources from "../../../../resources/list";

import "./TableRow.scss";

export const TableRow = ({ purchase }: ITableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const media = useMediaByIdFromMediaListSelector(
    purchase.MediaId,
    MediaStore.Consts.MEDIA_LIST_SEARCH_KEY
  );
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const frameImageUrl = useMemo(
    () => ImageHelper.getFrameImageUrl(media?.Images),
    [media]
  );

  const paymentType = useMemo<string>(() => {
    if (PurchaseHelper.isSubscription(purchase)) {
      return t("MY_ORDERS__TYPE_SUBSCRIPTION");
    } else if (PurchaseHelper.isRental(purchase)) {
      return t("MY_ORDERS__TYPE_RENTAL");
    } else {
      return t("MY_ORDERS__TYPE_ONETIME");
    }
  }, [purchase]);

  const paymentDate = useMemo(() => {
    if (PurchaseHelper.isSubscription(purchase)) {
      return purchase.ValidFrom;
    } else if (PurchaseHelper.isRental(purchase)) {
      return purchase.PaymentDate;
    } else {
      return purchase.PaymentDate;
    }
  }, [purchase]);

  const mediaLink = useMemo(
    () => (media ? RouteHelper.getDetailsPath(media) : ""),
    [media]
  );

  return (
    <div className={`TableRow ${isExpanded ? "TableRow-expanded" : ""}`}>
      <div
        className="TableRow__BasicInfoContainer"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        {/* Dropdown icon */}
        <div className="TableRow__Column">
          <div>
            <img
              src={icons.arrowDown}
              alt="Arrow down"
              className={`TableRow__dropdown-icon ${
                isExpanded && "TableRow__dropdown-icon-expanded"
              }`}
            />
          </div>
        </div>
        {/* Frame Image */}
        {!isMobile && (
          <div className="TableRow__Column">
            <div className="TableRow__image-container">
              <Link to={mediaLink}>
                <ImageWithPlaceholder
                  imageSrc={frameImageUrl}
                  placeholderSrc={resources.framePlaceholder}
                  imageContainerClassName="TableRow__image"
                />
              </Link>
            </div>
          </div>
        )}
        {/* Title */}
        <div className="TableRow__Column">
          <Link className="Title" to={mediaLink}>
            {purchase.MediaTitle}
          </Link>
        </div>
        {/* Date */}
        <div className="TableRow__Column">
          <div className="ColumnContent">
            {TimeHelper.format(paymentDate, "DD-MM-YYYY")}
          </div>
        </div>
        {/* Payment type */}
        <div className="TableRow__Column">
          <div className="ColumnContent">{paymentType}</div>
        </div>
        {/* Status */}
        <div className="TableRow__Column">
          <div className="ColumnContent">
            {purchase.Active
              ? t("MY_ORDERS__STATUS_ACTIVE")
              : t("MY_ORDERS__STATUS_INACTIVE")}
          </div>
        </div>
      </div>
      {/*Additional expanded info*/}
      {isExpanded && <ExpandedDetails purchase={purchase} />}
    </div>
  );
};
