/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  DataProvider,
  IErrorModel,
  ImageHelper,
  IMediaModel,
  IPaymentTypeMappingAndOptionsModel,
  useDataLoader,
  useServiceCaller,
  definePaymentProvider,
  PaymentProvider,
  IUserWalletModel,
  ThemeContext,
} from "@bms/common";
import React, { useContext, useState } from "react";
import { Dialog, ImageWithPlaceholder, MediaButton, Message } from "components";
import { MediaDetailsScreen } from "screens/MediaDetailsScreen";
import resources from "../../resources/list";
import { useTranslation } from "react-i18next";
import { Splash } from "../Splash";
import { useHistory } from "react-router-dom";
import { SMUp } from "../../components";

import "./PaymentConfirmationScreen.scss";

export const PaymentConfirmationScreen = () => {
  const { t } = useTranslation();
  const [showCancel, setShowCancel] = useState(false);
  const history = useHistory();
  const { themeProvider } = useContext(ThemeContext);
  const secondaryTextColor = themeProvider.getColor("AppSecondaryTextColor");

  const getSearchParams = () => {
    const { search } = window.location;
    const entries = new URLSearchParams(search).entries();
    return Object.fromEntries(entries);
  };

  const params = getSearchParams();

  const id = parseInt(params.mediaId);
  const price = parseInt(params.priceId);
  const token = params.paymentId;

  const { data: media, loading } = useDataLoader<IMediaModel, IErrorModel>({
    loader: () =>
      DataProvider.getMedia({
        MediaId: id,
        IncludeImages: true,
        IncludePurchaseOffers: true,
      })
        .then((data) => ({
          ok: true,
          data,
          error: {},
        }))
        .catch((error) => ({
          ok: false,
          error,
        })),
    deps: [id],
  });

  const { data: wallet } = useDataLoader<IUserWalletModel[], IErrorModel>({
    loader: () =>
      DataProvider.getUserWallet()
        .then((data) => ({ ok: true, data, error: {} }))
        .catch((error) => ({
          ok: false,
          error,
        })),
    deps: [id],
  });

  const mediaCoverImageSrc = ImageHelper.getCoverImageUrl(media?.Images) ?? "";

  const { data: paymentProviders } = useDataLoader<
    IPaymentTypeMappingAndOptionsModel,
    IErrorModel
  >({
    loader: () =>
      DataProvider.getPaymentTypeMappingAndOptions()
        .then((data) => {
          return {
            ok: true,
            data,
            error: {},
          };
        })
        .catch((error) => ({
          ok: false,
          error,
        })),
    deps: [id],
  });

  const [cancelConfirmBuyMedia, cancelBuyMediaState] = useServiceCaller(
    async (AuthorizationToken: string, Action: string) => {
      const options = paymentProviders?.Options.find(
        (o) => o.Provider === "CRYPTO_COIN"
      );

      if (options) {
        await definePaymentProvider(options);
        const result = await PaymentProvider.confirm({
          Action: Action,
          AuthorizationToken: AuthorizationToken,
          paymentProvider: "CRYPTO_COIN",
        })
          .then((data) => ({
            ok: true,
            data,
            error: {},
          }))
          .catch((error) => {
            const { MessageKey } = error;
            Message.error(t(MessageKey ?? "PAYMENT__BUY_ERROR_MESSAGE"));

            return {
              ok: false,
              error,
            };
          });

        if (Action === "COMPLETE") {
          history.push(
            `/payment-status/${id}?paymentKey=${token}&paymentStatus=${
              result.ok ? "SUCCESSED" : "FAILURE"
            }`
          );
        }
      }
    },
    [paymentProviders]
  );

  const purchase = media?.PurchaseOffers?.find(
    ({ PriceId }) => price === PriceId
  );

  const walletContent = wallet
    ? wallet.find((el) => el.CurrencyCode === "CC")?.Amount
    : 0;

  const walletContentAfterPurchase =
    walletContent && purchase ? (walletContent - purchase.Price).toFixed(1) : 0;

  if (loading) {
    return <Splash />;
  }

  return (
    <MediaDetailsScreen>
      <div className="PaymentConfirmation">
        <div className="PaymentConfirmation__page-title">
          {t("PAYMENT_CONFIRMATION_SCREEN__ORDER_DETAILS")}
        </div>
        <div className="PaymentConfirmation__main">
          <Dialog
            wrapClassName="cancel-payment-dialog"
            visible={showCancel}
            footer={null}
            destroyOnClose
            onCancel={() => setShowCancel(false)}
          >
            <div>
              {t("PAYMENT_CONFIRMATION_SCREEN__CANCEL_PURCHASE_QUESTION")}
            </div>
            <MediaButton
              variant="transparent"
              onClick={() => cancelConfirmBuyMedia(token, "CANCEL")}
              loading={cancelBuyMediaState.processing}
            >
              {t("PAYMENT_CONFIRMATION_SCREEN__CANCEL_PURCHASE")}
            </MediaButton>
          </Dialog>

          <SMUp>
            <div className="PaymentConfirmation__product">
              <div className="PaymentConfirmation__product__image">
                <ImageWithPlaceholder
                  imageContainerClassName="ImageWithPlaceholder"
                  placeholderSrc={resources.coverPlaceholder}
                  imageSrc={mediaCoverImageSrc}
                />
              </div>
            </div>
          </SMUp>
          <div className="PaymentConfirmation__content">
            <div
              className="PaymentConfirmation__item-title"
              style={{ color: secondaryTextColor }}
            >
              {t("PAYMENT_CONFIRMATION_SCREEN__ITEM")}
            </div>
            <div className="PaymentConfirmation__title">{media?.Title}</div>
            <div
              className="PaymentConfirmation__item-title"
              style={{ color: secondaryTextColor }}
            >
              {t("PAYMENT_CONFIRMATION_SCREEN__PAYMENT_TYPE")}
            </div>
            <div className="PaymentConfirmation__item-text">
              {purchase?.PurchasePeriodTypeName}
            </div>
            <div
              className="PaymentConfirmation__item-title"
              style={{ color: secondaryTextColor }}
            >
              {t("PAYMENT_CONFIRMATION_SCREEN__QUANTITY")}
            </div>
            <div className="PaymentConfirmation__coins">{purchase?.Price}</div>

            <div
              className="PaymentConfirmation__item-title"
              style={{ color: secondaryTextColor }}
            >
              {t("PAYMENT_CONFIRMATION_SCREEN__CURRENT_AMOUNT_COINS")}
              <span className="PaymentConfirmation__item-text">
                {" "}
                {walletContent}
              </span>
            </div>
            <div
              className="PaymentConfirmation__item-title"
              style={{ color: secondaryTextColor }}
            >
              {t("PAYMENT_CONFIRMATION_SCREEN__AFTER_PURCHASE_AMOUNT_COINS")}
              <span className="PaymentConfirmation__item-text">
                {" "}
                {walletContentAfterPurchase}
              </span>
            </div>
            <MediaButton
              variant="transparent"
              onClick={() => setShowCancel(true)}
              loading={cancelBuyMediaState.processing}
            >
              {t("PAYMENT_CONFIRMATION_SCREEN__CANCEL_PURCHASE")}
            </MediaButton>
            <MediaButton
              variant="primary"
              onClick={() => cancelConfirmBuyMedia(token, "COMPLETE")}
              loading={cancelBuyMediaState.processing}
            >
              {t("PAYMENT_CONFIRMATION_SCREEN__CONFIRM_PAYMENT")}
            </MediaButton>
          </div>
        </div>
      </div>
    </MediaDetailsScreen>
  );
};
