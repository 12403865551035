/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetType,
  IAssetTimestampModel,
  IConfigurationModel,
  IMediaModel,
  IMediaPlayInfoModel,
  IMediaStateModel,
  IRestrictedContentCookieModel,
  IStateModel,
  MediaStore,
  MediaStreamType,
  RouteHelper,
  StorageHelper,
  UrlHelper,
} from "@bms/common";
import Cookies, { CookieAttributes } from "js-cookie";
import { isEmpty } from "lodash";
import * as React from "react";
import isEqual from "react-fast-compare";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { ActionCreator } from "redux";

import { VideoPlayer } from "components/VideoPlayer";

import "./Watch.scss";

export interface IWatchScreenStateProps {
  configuration?: IConfigurationModel;
  media: IMediaStateModel;
  mediaPlayInfo: IStateModel<IMediaPlayInfoModel>;
}

export interface IWatchScreenDispatchProps {
  getMedia: ActionCreator<MediaStore.Types.IGetMediaAction>;
  getMediaPlayInfo: ActionCreator<MediaStore.Types.IGetMediaPlayInfoAction>;
}

export type IWatchScreenRouteProps = RouteComponentProps<{ id: string }>;

export interface IWatchScreenQueryParams {
  streamType?: MediaStreamType;
  userId?: string;
}

interface Props
  extends IWatchScreenStateProps,
    IWatchScreenDispatchProps,
    IWatchScreenRouteProps,
    WithTranslation {}

interface State {
  continueWatchingTimestamp?: IAssetTimestampModel;
}

export class Watch extends React.Component<Props, State> {
  state = {
    continueWatchingTimestamp: undefined,
  };
  componentDidMount = async () => {
    const { getMedia, getMediaPlayInfo, match, location } = this.props;
    const query = UrlHelper.parse(location.search) as IWatchScreenQueryParams;
    getMedia(+match.params.id);
    getMediaPlayInfo({
      MediaId: +match.params.id,
      StreamType: query.streamType,
      UserId: query.userId,
    });
    const assetProperties = await StorageHelper.getUserAssetsProperties().then(
      (assetsProperties) =>
        assetsProperties.find((item) => item.AssetId == +match.params.id)
    );
    this.setState({ continueWatchingTimestamp: assetProperties?.Timestamp });
  };

  public shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  componentDidUpdate = () => {
    const { media, mediaPlayInfo, getMedia, getMediaPlayInfo, match } =
      this.props;
    const query = UrlHelper.parse(location.search) as IWatchScreenQueryParams;

    if (
      media.Media &&
      media.Media.MediaTypeCode !== AssetType.Video &&
      !media.Media.IsPlayable
    ) {
      // If it isn't VOD, probably it'sSERIES or SEASON - then play nested media.
      this.goToChildMedia(media.Media);
    }

    if (media.Media && media.IsLoading) {
      return;
    }

    if (!media.Error && media.Media?.Id !== +match.params.id) {
      getMedia(+match.params.id);
    }

    if (mediaPlayInfo.Data && mediaPlayInfo.IsProcessing) {
      return;
    }

    if (
      !mediaPlayInfo.Error &&
      mediaPlayInfo.Data?.MediaId !== +match.params.id
    ) {
      getMediaPlayInfo({
        MediaId: +match.params.id,
        StreamType: query.streamType,
        UserId: query.userId,
      });
    }
  };

  private goToChildMedia(parentMedia: IMediaModel) {
    if (parentMedia.Media) {
      const sortedMedia = parentMedia.Media.sort(this.compareByOrderAscending);
      if (sortedMedia[0]) {
        RouteHelper.goToPlayer({ media: sortedMedia[0] }, true);
      }
    }
  }

  private compareByOrderAscending(a: IMediaModel, b: IMediaModel): number {
    if (a.OrderInParent && b.OrderInParent) {
      return a.OrderInParent - b.OrderInParent;
    } else {
      return 0;
    }
  }

  private setRestrictionContentCookies() {
    const { mediaPlayInfo } = this.props;

    if (mediaPlayInfo?.Data?.Cookies && mediaPlayInfo.Data.Cookies.length > 0) {
      mediaPlayInfo.Data.Cookies.forEach(
        (item: IRestrictedContentCookieModel) => {
          Cookies.remove(item.Name);
          const options: CookieAttributes = {
            path: item.Path,
            domain: item.Domain,
            secure: item.IsSecure,
            sameSite: item.SameSite,
          };
          if (item.Expires) {
            options.expires = new Date(item.Expires);
          }
          Cookies.set(item.Name, item.Value || "", options);
        }
      );
    }
  }

  noContentError = () => {
    const { t, mediaPlayInfo } = this.props;

    return (
      <div className="watch">
        <p className="watch__error_message">
          {t(
            `${mediaPlayInfo.Error?.MessageKey || "WATCH__NO_RESOURCE"}`,
            mediaPlayInfo.Error?.Message
          )}
        </p>
      </div>
    );
  };

  render() {
    const { media, mediaPlayInfo } = this.props;
    const query = UrlHelper.parse(location.search) as IWatchScreenQueryParams;

    if (media.IsLoading || mediaPlayInfo.IsProcessing) return null;

    if (
      (!mediaPlayInfo ||
        !mediaPlayInfo.Data ||
        !mediaPlayInfo.Data.ContentUrl) &&
      !(media?.Media?.ExternalSource === process.env.REACT_APP_EXTERNAL_SOURCE)
    ) {
      if (media.IsLoading || mediaPlayInfo.IsProcessing) return null;
      else return this.noContentError();
    }

    this.setRestrictionContentCookies();

    if (!isEmpty(media) && !isEmpty(mediaPlayInfo)) {
      return (
        <VideoPlayer
          media={media}
          mediaPlayInfo={mediaPlayInfo}
          streamType={query.streamType}
          continueWatchingTimestamp={
            query.streamType !== MediaStreamType.Trial
              ? mediaPlayInfo?.Data?.Timestamp ??
                this.state.continueWatchingTimestamp
              : undefined
          }
        />
      );
    }

    return null;
  }
}
