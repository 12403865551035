/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { DownloadMediaStatus } from "../../enums";
import { IDownloadSource, IDownloadSourceFailed, IMediaModel } from "models";
import { createReducers } from "../utils";
import { ActionTypes } from "./consts";
import { IOfflineState, OfflineModeActionTypes } from "./types";
import { StorageManager } from "../../services";

export const initialState: IOfflineState = {
  isOnline: true,
  media: [],
  loadingPodcasts: false,
};

export const offlineModeReducers = createReducers(initialState, {
  [ActionTypes.SET_OFFLINE_MODE]: (state) => ({
    ...state,
    isOnline: false,
  }),
  [ActionTypes.SET_ONLINE_MODE]: (state) => ({
    ...state,
    isOnline: true,
  }),
  [ActionTypes.GET_DOWNLOADED_PODCASTS]: (state) => ({
    ...state,
    loadingPodcasts: true,
  }),
  [ActionTypes.GET_DOWNLOADED_PODCASTS_SUCCESS]: (
    state,
    action: OfflineModeActionTypes
  ) => ({
    ...state,
    loadingPodcasts: false,
    media: (action.payload as IDownloadSource[]) ?? [],
  }),
  [ActionTypes.GET_DOWNLOADED_PODCASTS_FAILURE]: (state) => ({
    ...state,
    loadingPodcasts: false,
    media: [],
  }),
  [ActionTypes.ADD_TO_QUEUE]: (state, action: OfflineModeActionTypes) => {
    const media = action.payload as IMediaModel;

    const isAlreadyQueued = state.media.find((m) => m.media.Id === media.Id);
    if (isAlreadyQueued)
      return {
        ...state,
      };

    state.media.push({
      media: media,
      localFile: {
        downloadProgress: 0,
        ContentUrl: "",
        downloadStatus: DownloadMediaStatus.QUEUED,
      },
    });
    StorageManager.setValue("podcasts", state.media);
    return {
      ...state,
    };
  },
  [ActionTypes.ADD_JOB_ID]: (state, action: OfflineModeActionTypes) => {
    const { media, jobId } = action.payload as IDownloadSource;

    const mediaTmp = state.media.map((downloadSource) =>
      downloadSource.media.Id === media.Id
        ? {
            ...downloadSource,
            jobId,
          }
        : downloadSource
    );
    StorageManager.setValue("podcasts", mediaTmp);

    return {
      ...state,
      media: mediaTmp,
    };
  },
  [ActionTypes.START_DOWNLOAD]: (state, action: OfflineModeActionTypes) => {
    const podcastSource = action.payload as IMediaModel;
    let mediaTmp;
    if (state.media.length > 0) {
      mediaTmp = state.media.map((downloadSource) =>
        downloadSource.media.Id === podcastSource.Id
          ? {
              ...downloadSource,
              media: podcastSource,
              localFile: {
                ContentUrl: "",
                downloadStatus: DownloadMediaStatus.IN_PROGRESS,
                downloadProgress: 0,
              },
            }
          : downloadSource
      );
    } else {
      mediaTmp = [
        {
          media: podcastSource,
          localFile: {
            ContentUrl: "",
            downloadStatus: DownloadMediaStatus.IN_PROGRESS,
            downloadProgress: 0,
          },
        },
      ];
    }
    StorageManager.setValue("podcasts", mediaTmp);

    return {
      ...state,
      media: mediaTmp,
    };
  },
  [ActionTypes.START_DOWNLOAD_SUCCESS]: (
    state,
    action: OfflineModeActionTypes
  ) => {
    const podcastSource = action.payload as IDownloadSource;
    const updatedPodcasts: IDownloadSource[] = state.media.map(
      (downloadSource) =>
        downloadSource.media.Id === podcastSource.media.Id
          ? {
              ...downloadSource,
              media: podcastSource.media,
              mediaPlayInfo: podcastSource.mediaPlayInfo,
              localFile: {
                ContentUrl: podcastSource?.localFile?.ContentUrl ?? "",
                downloadStatus: DownloadMediaStatus.DOWNLOADED,
                downloadProgress: 100,
              },
            }
          : downloadSource
    );
    StorageManager.setValue("podcasts", updatedPodcasts);
    return {
      ...state,
      media: updatedPodcasts,
    };
  },
  [ActionTypes.START_DOWNLOAD_FAILED]: (
    state,
    action: OfflineModeActionTypes
  ) => {
    const { media: m, contentUrl } = action.payload as IDownloadSourceFailed;

    const updatedPodcasts = state.media.map((downloadSource) =>
      downloadSource.media.Id === m.Id
        ? {
            ...downloadSource,
            localFile: {
              ContentUrl: contentUrl || "",
              downloadStatus: DownloadMediaStatus.FAILED,
              downloadProgress: 0,
            },
          }
        : downloadSource
    );

    StorageManager.setValue("podcasts", updatedPodcasts);
    return {
      ...state,
      media: updatedPodcasts,
    };
  },
  [ActionTypes.SET_DOWNLOAD_PROGRESS]: (
    state,
    action: OfflineModeActionTypes
  ) => {
    const podcastSource = action.payload as IDownloadSource;
    const updatedPodcasts = state.media.map((downloadSource) =>
      downloadSource.media.Id === podcastSource.media.Id
        ? {
            ...downloadSource,
            media: podcastSource.media,
            localFile: {
              ContentUrl: podcastSource.localFile?.ContentUrl ?? "",
              downloadStatus: DownloadMediaStatus.IN_PROGRESS,
              downloadProgress: podcastSource.localFile?.downloadProgress,
            },
          }
        : downloadSource
    );
    StorageManager.setValue("podcasts", updatedPodcasts);
    return {
      ...state,
      media: updatedPodcasts,
    };
  },
  [ActionTypes.DELETE_PODCAST]: (state) => {
    return {
      ...state,
    };
  },
  [ActionTypes.DELETE_PODCAST_SUCCESS]: (
    state,
    action: OfflineModeActionTypes
  ) => {
    const updatedPodcasts = action.payload as IDownloadSource[];
    return {
      ...state,
      media: updatedPodcasts,
    };
  },
  [ActionTypes.DELETE_PODCAST_FAILURE]: (state) => {
    return {
      ...state,
    };
  },
  [ActionTypes.UPDATE_PODCAST]: (state) => {
    return {
      ...state,
    };
  },
  [ActionTypes.UPDATE_PODCAST_SUCCESS]: (
    state,
    action: OfflineModeActionTypes
  ) => {
    const updatedPodcasts = action.payload as IDownloadSource[];
    return {
      ...state,
      media: updatedPodcasts,
    };
  },
  [ActionTypes.UPDATE_PODCAST_FAILURE]: (state) => {
    return {
      ...state,
    };
  },
});
