/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ContentStatus, IMediaModel } from "@bms/common";

export const useMediaContentStatus = (media: IMediaModel | undefined) => {
  const isStatusAvailable = media?.ContentStatusCode !== undefined;

  const isContentReady =
    isStatusAvailable && media.ContentStatusCode === ContentStatus.Ready;

  return { isStatusAvailable, isContentReady };
};
