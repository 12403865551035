/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const ROUTES = {
  MAIN_SCREEN: "/",
  MOVIE_DETAILS_SCREEN: "/movie",
  SERIES_DETAILS_SCREEN: "/series",
  PLAYER_SCREEN: "/play",
  SETTINGS: "/settings",
  SETTINGS_PROFILE: "/settings/profile",
  SETTINGS_MY_ORDERS: "/settings/my_orders",
  SETTINGS_TERMS_AND_CONDITIONS: "/settings/terms_and_conditions",
  SETTINGS_PREFERENCES: "/settings/preferences",
  SETTINGS_LOGOUT: "/settings/logout",
  SETTINGS_ABOUT: "/settings/about",
  DELETE_ACCOUNT: "/delete-account",
  DELETE_ACCOUNT_SUCCESS: "/delete-account-success",
};
