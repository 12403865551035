/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import {
  AssetType,
  CellType,
  ComponentType,
  IMediaModel,
  Orientation,
} from "@bms/common";
import { ListComponentHorizontal } from "components";

import "./EpisodesList.scss";
import { PodcastDetailsCard } from "./PodcastDetailsCard";
import { useTranslation } from "react-i18next";

interface EpisodesListProps {
  episodeList?: IMediaModel[];
  media?: IMediaModel;
}

export const EpisodesList = ({ media }: EpisodesListProps) => {
  const { t } = useTranslation();
  if (!media?.Media || media?.Media.length === 0) {
    return null;
  }

  if (media.MediaTypeCode === AssetType.Album) {
    return (
      <div className="EpisodesList__album">
        <span className="EpisodesList__title">{t("COMMON__EPISODES")}</span>
        {media.Media.map((it, index) => (
          <PodcastDetailsCard
            key={`album-element-${index}-${it.Id}`}
            media={it}
          />
        ))}
      </div>
    );
  }

  return (
    <ListComponentHorizontal
      className="SeriesDetails__episode-list"
      component={{
        ComponentTypeCode: ComponentType.List,
        MediaList: media.Media,
        CellType: CellType.Frame,
        Orientation: Orientation.Horizontal,
        VisibleItemsCount: 4,
      }}
    />
  );
};
