/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ROUTES } from "@bms/common";
import React, { useEffect } from "react";
import { WithTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "../../Button";
import "../../Form/Form.scss";
import { FormLine } from "../../Form";
import "../AuthForm.scss";
import "../RegisterForm/RegisterForm.scss";

type RegisterSuccessProps = WithTranslation;

export function RegisterSuccess(props: RegisterSuccessProps) {
  const { t } = props;
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => history.replace(ROUTES.MAIN_SCREEN), 60000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="RegisterSuccess AuthForm">
      <div className="form">
        <h1 className="text-upper text-center">
          {t("REGISTER_SUCCESS__TITLE", "Great!")}
        </h1>
        <p className="text-center text-large">
          {t(
            "REGISTER_SUCCESS__SUBTITLE",
            "There is only one step left to create an account."
          )}
        </p>
        <FormLine style={{ marginTop: "25px" }} />

        <p className="text-center text-large">
          {t(
            "REGISTER_SUCCESS__MESSAGE",
            "We have sent an email with an account activation link to your email address."
          )}
        </p>

        <div className="ButtonLine">
          <Link to={ROUTES.MAIN_SCREEN}>
            <Button buttonClassName="FormButton" type="button">
              {t("REGISTER_SUCCESS__CONFIRM", "OK")}
            </Button>
          </Link>
        </div>

        <p className="text-center text-normal">
          {t(
            "REGISTER_SUCCESS__HINT",
            "Can't find the message in your inbox? Check spam."
          )}
        </p>
      </div>
    </div>
  );
}
