/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useSelector, IAppState } from "@bms/common";
import { useTranslation } from "react-i18next";

export const useRTL = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const languages = useSelector(
    (state: IAppState) => state.configuration.configuration?.Languages
  );
  const currentLang = languages?.find((lang) => lang.Code === language);

  return currentLang?.RTL;
};
