/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ActionType, IApplicationMenuItemComponentModel } from "@bms/common";
import { push } from "connected-react-router";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { MenuClickEventHandler, MenuItem } from "components/Menu/DesktopMenu";

import "./ApplicationMenuItem.scss";

export interface IApplicationMenuItemProps {
  component: IApplicationMenuItemComponentModel;
}

export const ApplicationMenuItem: React.FC<IApplicationMenuItemProps> = ({
  component,
  ...props
}: IApplicationMenuItemProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const title = t(component?.TitleTranslationKey ?? "", component?.Title);

  const onClick: MenuClickEventHandler = ({ key }) => {
    if (component?.Action?.ActionType) {
      switch (component.Action.ActionType) {
        case ActionType.OpenScreen:
          if (key) {
            dispatch(push(`/${key}`));
          }
          break;
        case ActionType.OpenUrl: {
          const actionUrl = component.Action.Url;

          if (actionUrl) {
            actionUrl.startsWith("/")
              ? dispatch(push(actionUrl))
              : window.open(actionUrl, "_blank");
          }
          break;
        }
      }
    }
  };

  return (
    <MenuItem {...props} onClick={onClick}>
      {title}
    </MenuItem>
  );
};
