/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAppState, useSelector } from "../../store";
import { IScreenModel } from "../../models";
import { ScreenType } from "../../enums/Configuration";

export const useScreenConfiguration = <T extends IScreenModel = IScreenModel>(
  screenType: ScreenType
): T => {
  const configuration = useSelector(
    (state: IAppState) => state.configuration.configuration
  );

  let screen: T = {} as T;

  if (
    configuration &&
    configuration.Screens &&
    configuration.Screens[screenType]
  ) {
    screen = configuration.Screens[screenType] as T;
  }

  return screen;
};
