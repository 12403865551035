/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  DataProvider,
  IErrorModel,
  IVestigitDemoUserModel,
  IVestigitPaginatedSamplesModel,
  useDataLoader,
} from "@bms/common";
import React, { createContext, ReactNode, useContext } from "react";

type DetectorContextValue = {
  samples?: IVestigitPaginatedSamplesModel;
  demoUsers?: IVestigitDemoUserModel[];
  refresh: () => void;
  loading: boolean;
  haveResults: boolean;
};

interface DetectorProps {
  children: ReactNode;
}

const DetectorContext = createContext<DetectorContextValue>(
  {} as DetectorContextValue
);

export const DetectorProvider = (props: DetectorProps) => {
  const { children } = props;

  const {
    data: samples,
    loading,
    refresh,
  } = useDataLoader<IVestigitPaginatedSamplesModel, IErrorModel>({
    loader: () =>
      DataProvider.vestigitGetSamples().then((data) => ({
        ok: true,
        data,
      })),
    deps: [],
  });

  const { data: demoUsers } = useDataLoader<
    IVestigitDemoUserModel[],
    IErrorModel
  >({
    loader: () =>
      DataProvider.vestigitGetDemoUsers().then((data) => ({
        ok: true,
        data,
      })),
    deps: [],
  });

  const haveResults = Boolean(samples && samples.count > 0);

  return (
    <DetectorContext.Provider
      value={{ samples, demoUsers, refresh, loading, haveResults }}
    >
      {children}
    </DetectorContext.Provider>
  );
};

export const useDetector = (): DetectorContextValue => {
  const context = useContext(DetectorContext);

  if (!context) {
    throw new Error("Component beyond DetectorContext");
  }

  return context;
};
