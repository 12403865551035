/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetImageType,
  buildAssetUploadRule,
  buildRequiredRule,
} from "@bms/common";
import React, { useState } from "react";

import { LabelField, UploadFile } from "components";

import { useCreatorMedia } from "../../CreatorMediaContext";

interface ImageProps {
  fieldName: string;
  label: string;
  type: AssetImageType;
  required?: boolean;
}

export const Image = (props: ImageProps) => {
  const { fieldName, label, type, required = false } = props;

  const [invalidFile, setInvalidFile] = useState(false);

  const { asset, getImage } = useCreatorMedia();

  const image = getImage(type, asset);
  const imageUrl = image?.Url;

  const onInvalidImageFile = (status: boolean) => setInvalidFile(status);

  return (
    <LabelField
      initialValue={imageUrl}
      name={fieldName}
      label={label}
      requiredIcon={required}
      rules={
        invalidFile && !imageUrl
          ? [buildAssetUploadRule(invalidFile)]
          : imageUrl
          ? [buildAssetUploadRule(invalidFile)]
          : required
          ? [buildRequiredRule("object")]
          : []
      }
    >
      <UploadFile
        accept="image/*"
        imageType={type}
        getWrongAsset={onInvalidImageFile}
      />
    </LabelField>
  );
};
