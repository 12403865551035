/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const useImageLoader = () => {
  const loadImage = (imageSrc?: string) => {
    if (!imageSrc) {
      return Promise.resolve(null);
    }
    const imageLoader = new Image();
    imageLoader.src = imageSrc;
    return new Promise((resolve) => {
      imageLoader.onload = () => {
        resolve(imageSrc);
      };
    });
  };
  return { loadImage };
};
