/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import "./RegisterSucess.scss";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@bms/common";

export const RegisterSuccessCustom = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => history.replace(ROUTES.MAIN_SCREEN), 600000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="RegisterSuccessCustom AuthForm">
      <h1 className="text-upper text-center">
        {t(
          "REGISTER_SUCCESS__TITLE-THANK-YOU",
          "THANK YOU FOR STAYING WITH US!"
        )}
      </h1>
      <p className="text-center RegisterSucessCustom__subtitle">
        {t(
          "REGISTER_SUCCESS__SUBTITLE-ALT",
          "As soon as we launch the platform, we will let you know by sending an e-mail."
        )}
      </p>
    </div>
  );
};
