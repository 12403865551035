/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { DataProvider, IMediaModel } from "@bms/common";
import { LoaderSpinner } from "components/LoaderSpinner";
import * as React from "react";
import { WithTranslation } from "react-i18next";
import PlusIcon from "../../resources/icons/plus.svg";
import RemoveIcon from "../../resources/icons/remove.svg";
import { MediaButton } from "../MediaButton";

interface IMyListButtonProps extends WithTranslation {
  media: IMediaModel;
}

export const MyListButton = React.memo(({ media, t }: IMyListButtonProps) => {
  const [isOnMyList, setIsOnMyList] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const toggleMyList = () => {
    if (media.Id) {
      setIsLoading(true);
      const togglePromise = isOnMyList
        ? DataProvider.removeFromMyList(media.Id)
        : DataProvider.addToMyList(media.Id);

      togglePromise
        .then(() => setIsOnMyList(!isOnMyList))
        .finally(() => setIsLoading(false));
    }
  };

  React.useEffect(() => {
    if (media.Id) {
      setIsLoading(true);
      DataProvider.isOnMyList(media.Id)
        .then((result) => {
          if (isOnMyList === result) {
            return;
          }
          setIsOnMyList(result);
        })
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media.Id]);

  const getIcon = () => {
    if (isLoading) {
      return <LoaderSpinner height={20} width={20} />;
    }
    return isOnMyList ? <RemoveIcon /> : <PlusIcon />;
  };

  return (
    <MediaButton
      variant="transparent"
      icon={getIcon()}
      iconElevated={true}
      onClick={toggleMyList}
    >
      {isOnMyList
        ? t("COMMON__BUTTON_REMOVE_FROM_MY_LIST")
        : t("COMMON__BUTTON_ADD_TO_MY_LIST")}
    </MediaButton>
  );
});
