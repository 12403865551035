/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import ic_arrow_down from "./ic_arrow_down.svg";
import ic_arrow_down_focus from "./ic_arrow_down_focus.svg";
import ic_arrow_left from "./ic_arrow_left.svg";
import ic_arrow_left_focus from "./ic_arrow_left_focus.svg";
import ic_arrow_right from "./ic_arrow_right.svg";
import ic_arrow_right_focus from "./ic_arrow_right_focus.svg";
import ic_arrow_up from "./ic_arrow_up.svg";
import ic_arrow_up_focus from "./ic_arrow_up_focus.svg";

export default {
  arrowDown: ic_arrow_down,
  arrowDownFocus: ic_arrow_down_focus,
  arrowLeft: ic_arrow_left,
  arrowLeftFocus: ic_arrow_left_focus,
  arrowRight: ic_arrow_right,
  arrowRightFocus: ic_arrow_right_focus,
  arrowUp: ic_arrow_up,
  arrowUpFocus: ic_arrow_up_focus,
};
