/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAppState, IUserModel, useDispatch, UserStore } from "@bms/common";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  DeleteAccountModal,
  ForgotPasswordLink,
  Message,
  useBreakpoints,
} from "components";
import { ContactsForm, PasswordForm } from "components/Settings/Forms";
import avatarPlaceholder from "resources/avatar-placeholder.png";

import "./SettingsProfile.scss";

interface AvatarData {
  avatar?: File;
  avatarDataBase64?: string | ArrayBuffer | null;
  avatarError?: string;
}

export const SettingsProfile = () => {
  const avatarUpload = useRef<HTMLInputElement | null>(null);
  const [avatarData, setAvatarData] = useState<AvatarData>({
    avatar: undefined,
    avatarDataBase64: undefined,
    avatarError: undefined,
  });
  const [showDeleteAccountModal, setShowDeleteAccountModal] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isXS, isSM } = useBreakpoints();

  const externalProfileEditUrl = useSelector(
    (state: IAppState) =>
      state.configuration.configuration?.System?.ProfileEditUrl
  );

  const externalChangePasswordUrl = useSelector(
    (state: IAppState) =>
      state.configuration.configuration?.System?.ChangePasswordUrl
  );

  const action = useSelector((state: IAppState) => state.user.action);
  const profile = useSelector((state: IAppState) => state.user.profile);

  const mobileLayout = isXS || isSM;

  const onExternalPasswordChange = () =>
    window.open(externalChangePasswordUrl, "_blank");

  const onDeleteAccount = () => setShowDeleteAccountModal(true);

  useEffect(() => {
    dispatch(UserStore.Actions.getProfile());

    //todo (dependency to delete) -> temporary work around for updating an avatar. updateProfile should return AvatarUploadInfo
  }, [profile.Data?.AvatarUrl]);

  useEffect(() => {
    if (!action || !action.type) {
      return;
    }

    switch (action.type) {
      case UserStore.Consts.GET_PROFILE_FAILURE:
        Message.error(t("SETTINGS_PROFILE__GET_PROFILE_FAILED"));
        break;
    }
  }, [action]);

  const uploadAvatar = () => {
    if (avatarUpload && avatarUpload.current) {
      avatarUpload.current.click();
    }
  };

  const onChangeAvatar = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files[0];
    const pattern = /image-*/;
    if (file.type.match(pattern)) {
      processAvatar(file);
    } else {
      setAvatarData({
        avatar: undefined,
        avatarDataBase64: undefined,
        avatarError: "SETTINGS_PROFILE__AVATAR_INVALID_FORMAT",
      });
    }
  };

  const processAvatar = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setAvatarData({
        avatar: file,
        avatarDataBase64: reader.result,
        avatarError: undefined,
      });

      if (profile.Data && profile.Data?.Id && !!externalProfileEditUrl) {
        const payload: IUserModel = {
          ...profile.Data,
          AvatarFile: file,
        };

        delete payload.UserName;

        dispatch(UserStore.Actions.updateProfile(payload));
      }
    };

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  let avatarSrc = profile.Data?.AvatarUrl;

  if (avatarData.avatarDataBase64 !== undefined) {
    avatarSrc = avatarData.avatarDataBase64 as string;
  }

  if (!avatarSrc) {
    avatarSrc = avatarPlaceholder;
  }

  return (
    <>
      <div className="SettingsProfile-wrapper">
        <div className="contactsDescription">
          <div className="contactsUserInfo">
            <div
              className={cx("circular", {
                "circular--error": avatarData.avatarError,
              })}
              onClick={uploadAvatar}
            >
              <img className="Avatar" alt="image" src={avatarSrc} />
              <input
                id="inputFileAvatar"
                type="file"
                accept="image/*"
                ref={avatarUpload}
                style={{ display: "none" }}
                onChange={onChangeAvatar}
              />
            </div>
            {avatarData.avatarError && (
              <ul className="Error">
                <li>{t(avatarData.avatarError)}</li>
              </ul>
            )}
          </div>
          {t("SETTINGS_PROFILE__CONTACTS_TITLE", "Contacts")}
          <p>
            {t(
              "SETTINGS_PROFILE__CONTACTS_DESCRIPTION",
              "Contact details are needed to receive notification from the service, and interaction with customer support."
            )}
          </p>
        </div>
        <div className="contactsForm">
          <ContactsForm
            avatar={avatarData.avatar}
            profile={profile}
            avatarError={avatarData.avatarError}
          />
        </div>
        <div className="passwordDescription">
          {t("SETTINGS_PROFILE__PASSWORD_TITLE", "Password")}
          <p>
            <Trans i18nKey="SETTINGS_PROFILE__PASSWORD_DESCRIPTION">
              {
                "You can easily change your  password at any time if you know your existing password. If you have forgotten your password, "
              }
              <ForgotPasswordLink>
                <span className="link underline">learn how to reset it.</span>
              </ForgotPasswordLink>
            </Trans>
          </p>
        </div>
        <div className="passwordForm">
          {externalChangePasswordUrl ? (
            <span
              className="passwordForm__button link underline"
              onClick={onExternalPasswordChange}
            >
              {t("PASSWORD_FORM__SUBMIT")}
            </span>
          ) : (
            <PasswordForm />
          )}
        </div>
        {!mobileLayout && <div />}
        <div className="deleteAccountContainer">
          <span
            className="deleteAccountContainer__buttonDelete link underline"
            onClick={onDeleteAccount}
          >
            {t("COMMON__BUTTON_DELETE_ACCOUNT")}
          </span>
        </div>
      </div>
      <DeleteAccountModal
        visible={showDeleteAccountModal}
        closeModal={() => setShowDeleteAccountModal(false)}
      />
    </>
  );
};
