/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IAppState, RouteHelper } from "@bms/common";
import { ROUTES } from "../../constants";
import { Dialog } from "../Dialog";
import { MediaButton } from "../MediaButton";

import "./DeleteAccountModal.scss";

interface DeleteAccountModalProps {
  showModal?: () => void;
  closeModal: () => void;
  visible: boolean;
}

export const DeleteAccountModal = ({
  closeModal,
  visible,
}: DeleteAccountModalProps) => {
  const { t } = useTranslation();
  const deleteAccountUrl = useSelector(
    (state: IAppState) =>
      state.configuration.configuration?.System?.DeleteAccountUrl
  );

  const onAcceptModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (deleteAccountUrl) {
      window.open(deleteAccountUrl, "_blank");
    } else {
      RouteHelper.goTo(ROUTES.DELETE_ACCOUNT);
    }
  };

  return (
    <>
      <Dialog
        wrapClassName="DeleteAccountModal"
        destroyOnClose
        footer={null}
        visible={visible}
        onCancel={closeModal}
        onOk={onAcceptModal}
      >
        <h2>{t("DELETE_ACCOUNT__QUESTION")}</h2>

        <div className="DeleteAccountModal__buttonsContainer">
          <MediaButton variant="primary" onClick={closeModal}>
            {t("DELETE_ACCOUNT__BUTTON_CANCEL")}
          </MediaButton>

          <MediaButton variant="plain" onClick={onAcceptModal}>
            {t("DELETE_ACCOUNT__BUTTON_CONFIRM")}
          </MediaButton>
        </div>
      </Dialog>
    </>
  );
};
