/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Input } from "components/Input";
import { AudioContext } from "context";
import "./AudioPlayerProgressBar.scss";

const padZero = (val: string, length: number) => {
  const padCount = length - val.length;
  let result = val;
  for (let i = 0; i < padCount; i++) {
    result = "0" + result;
  }
  return result;
};

const convertSecondsToDisplayTime = (val: number) => {
  const hours = Math.floor(val / 3600);
  const minutes = Math.floor((val % 3600) / 60);
  const seconds = Math.floor(val % 60);
  if (hours > 0) {
    return `${hours}:${padZero(minutes.toString(), 2)}:${padZero(
      seconds.toString(),
      2
    )}`;
  }
  return `${minutes}:${padZero(seconds.toString(), 2)}`;
};

export const AudioPlayerProgressBar = () => {
  const [internalCurrentTime, setInternalCurrentTime] = useState(0);
  const [isInputInProgress, setIsInputInProgress] = useState(false);
  const { currentTime = 0, duration = 1, seekTo } = useContext(AudioContext);
  const progress = useMemo(
    () => (duration > 0 ? (internalCurrentTime / duration) * 100 : 0),
    [duration, internalCurrentTime]
  );

  const currentTimeString = useMemo(
    () => convertSecondsToDisplayTime(currentTime),
    [currentTime]
  );

  const durationTimeString = useMemo(
    () => convertSecondsToDisplayTime(duration),
    [duration]
  );

  const onInput = (evt: React.FormEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const newVal = +evt.currentTarget.value;
    const calculatedTime = (newVal / 100) * duration;
    setInternalCurrentTime(calculatedTime);
    seekTo?.(calculatedTime);
  };

  useEffect(() => {
    if (!isInputInProgress) {
      setInternalCurrentTime(currentTime);
    }
  }, [currentTime, isInputInProgress]);

  return (
    <div className="AudioPlayerProgressBar">
      <span className="AudioPlayerProgressBar__time AudioPlayerProgressBar__time--left">
        {currentTimeString}
      </span>
      <Input
        type="range"
        className="AudioPlayerProgressBar__progress"
        value={progress}
        onInput={onInput}
        onKeyDown={() => setIsInputInProgress(true)}
        onKeyUp={() => setIsInputInProgress(false)}
        style={{ backgroundSize: `${progress}% 100%` }}
      />
      <span className="AudioPlayerProgressBar__time AudioPlayerProgressBar__time--right">
        {durationTimeString}
      </span>
    </div>
  );
};
