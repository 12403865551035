/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IConfigurationModel } from "@bms/common";
import React from "react";
import { AppFooter, AppHeader, AppHeaderVisibility } from "../../components";
import "./AuthScreen.scss";

export interface IAuthScreenProps {
  configuration?: IConfigurationModel;
  visibility?: AppHeaderVisibility;
  authScreenStyles?: React.CSSProperties;
}

export class AuthScreen extends React.Component<IAuthScreenProps> {
  render() {
    const { children, configuration, authScreenStyles } = this.props;
    const visibility = this.props.visibility || AppHeaderVisibility.Logo;

    return (
      <div className="AuthScreen" style={authScreenStyles}>
        <AppHeader configuration={configuration} visibility={visibility} />
        <section>{children}</section>
        <AppFooter />
      </div>
    );
  }
}
