/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import {
  ConfigurationHelper,
  useConfigurationSelector,
  useIsLoggedIn,
} from "@bms/common";
import { useTranslation } from "react-i18next";
import { BaseScreen } from "../BaseScreen";
import {
  AppHeader,
  AppFooter,
  LoginCodeInput,
  AppHeaderVisibility,
} from "../../components";

import "./LinkScreen.scss";

export const LinkScreen = () => {
  const { t } = useTranslation();
  const configuration = useConfigurationSelector();
  const latestMovies = ConfigurationHelper.getScreenById(configuration, 17);
  const isLoggedIn = useIsLoggedIn();

  return (
    <div className="LinkScreen">
      <AppHeader
        configuration={configuration}
        visibility={AppHeaderVisibility.Logo | AppHeaderVisibility.UserInfo}
      />
      <section className="LinkScreen__section">
        <h1 className="LinkScreen__heading">
          {t("LOGIN_CODE_LINK_YOUR_ACCOUNT")}
        </h1>
        <p className="LinkScreen__desc">
          {isLoggedIn
            ? t(
                "LINK__SIGN_IN",
                "Enter the code display on your device to link it your account"
              )
            : t("LINK__SIGN_OUT", "Please log in before linking your account")}
        </p>
        {isLoggedIn && <LoginCodeInput />}
      </section>
      {latestMovies && (
        <>
          <div className="AppFooter__Line" />
          <BaseScreen
            renderComponent={(component, components, originalRenderer) =>
              originalRenderer(component, components)
            }
          >
            {({ renderScreen }) => renderScreen(latestMovies)}
          </BaseScreen>
        </>
      )}
      <AppFooter />
    </div>
  );
};
