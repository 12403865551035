/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  AppConfig,
  PlatformHelper,
  StorageHelper,
  TimeHelper,
  useConfigurationSelector,
} from "@bms/common";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./SettingsAbout.scss";

export const SettingsAbout = () => {
  const configuration = useConfigurationSelector();
  const { t } = useTranslation();
  const [backendVersion, setBackendVersion] = useState("");
  const [currentDateTime, setDateTime] = useState("");
  const [IP, setIP] = useState("");
  const [platformName, setPlatformName] = useState("");

  useEffect(() => {
    getBackendVersion();
    getIP();
    getDate();
    getPlatformName();
  }, []);

  const getBackendVersion = async () => {
    try {
      const backendVer = await StorageHelper.getBackendVersion();
      if (backendVer) {
        setBackendVersion(backendVer);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getIP = async () => {
    try {
      const response = await axios.get("https://geolocation-db.com/json/");
      if (response.status === 200) {
        setIP(response.data.IPv4);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDate = () => {
    const now = TimeHelper.getCurrentDateKey("YYYY-MM-DD HH:mm:ss");

    setDateTime(now);
  };

  const getPlatformName = () => {
    const platformName = PlatformHelper.Platform;

    setPlatformName(platformName);
  };

  return (
    <div className="SettingsAbout">
      <div className="SettingsAbout__option">
        {t("SETTINGS__SCREEN_PLATFORM", "Platform")} :{" "}
        <span>{platformName}</span>
      </div>

      <div className="SettingsAbout__option">
        {t("SETTINGS_SCREEN__FIRMWARE_VERSION", "Firmware version")} :{" "}
        <span>{AppConfig.PlatformVersion}</span>
      </div>

      <div className="SettingsAbout__option">
        {t(
          "SETTINGS_SCREEN__APPLICATION_VERSION_NUMBER",
          "Application version"
        )}{" "}
        : {<span>{configuration?.VersionNumber}</span>}
      </div>

      <div className="SettingsAbout__option">
        {t("SETTINGS_SCREEN__DEVICE_IP")} : <span>{IP}</span>
      </div>

      <div className="SettingsAbout__option">
        {t("SETTINGS_SCREEN__SCREEN_RESOLUTION", "Platform")} :{" "}
        <span>{`${window.screen.height + "i"}`}</span>
      </div>

      <div className="SettingsAbout__option">
        {t("SETTINGS_SCREEN__TIME_AND_DATE", "Time and date")} :{" "}
        {<span>{currentDateTime}</span>}
      </div>

      <div className="SettingsAbout__option">
        {t("SETTINGS_SCREEN__VERSION_BACKEND", "Backend version")} :{" "}
        <span>{backendVersion.replace(/"/g, "")}</span>
      </div>
    </div>
  );
};
