/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { CellType, Orientation } from "@bms/common";
import React from "react";
import {
  GridComponent,
  ListComponentHeroLandscape,
  ListComponentHighlight,
  ListComponentHighlightsWidescreen,
  ListComponentHorizontal,
  ListComponentPromo,
} from "./components";
import { IListComponentProps } from "./types";

export const ListComponent: React.FC<IListComponentProps> = React.memo(
  ({ readOnly = false, component, loading, className }) => {
    switch (component.Orientation) {
      case Orientation.Grid:
        return (
          <GridComponent
            component={component}
            loading={loading}
            readOnly={readOnly}
            className={className}
          />
        );
    }

    switch (component.CellType) {
      case CellType.HighlightsWidescreen:
        return (
          <ListComponentHighlightsWidescreen
            component={component}
            loading={loading}
            readOnly={readOnly}
            className={className}
          />
        );

      case CellType.Highlights:
        return (
          <ListComponentHighlight
            component={component}
            loading={loading}
            readOnly={readOnly}
            className={className}
          />
        );
      case CellType.Promo:
        return (
          <ListComponentPromo
            component={component}
            loading={loading}
            readOnly={readOnly}
            className={className}
          />
        );
      case CellType.HeroLandscape:
        return (
          <ListComponentHeroLandscape
            component={component}
            loading={loading}
            readOnly={readOnly}
            className={className}
          />
        );
      default:
        return (
          <ListComponentHorizontal
            component={component}
            loading={loading}
            readOnly={readOnly}
            className={className}
          />
        );
    }
  }
);
