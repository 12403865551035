/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  DataProvider,
  IErrorModel,
  IVestigitContentMetadataModel,
  useDataLoader,
} from "@bms/common";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  CompareButton,
  DownloadSampleButton,
  MediaAdditionalInfo,
  PlayButton,
} from "components";
import { useMediaContentStatus } from "hooks/useMediaContentStatus";

import {
  MediaDetailsScreen,
  useMediaDetailsMediaSelector,
} from "../MediaDetailsScreen";

import "./MovieDetailsScreen.scss";

export const MovieDetailsScreen = () => {
  const media = useMediaDetailsMediaSelector();
  const { t } = useTranslation();

  const { isStatusAvailable, isContentReady } = useMediaContentStatus(media);

  const externalId = media?.ExternalId;

  const { data: metadata, error: metadataError } = useDataLoader<
    IVestigitContentMetadataModel,
    IErrorModel
  >({
    loader: () =>
      externalId
        ? DataProvider.vestigitGetContentMetadata({ cid: externalId }).then(
            (data) => ({
              ok: true,
              data,
            })
          )
        : undefined,
    deps: [externalId],
  });

  const actionDisabled =
    (isStatusAvailable && !isContentReady) ||
    !metadata ||
    metadataError !== undefined;

  return (
    <MediaDetailsScreen className="MovieDetails">
      {media && (
        <>
          <div className="MovieDetails__info">
            <div className="MovieDetails__title">{media.Title}</div>

            <MediaAdditionalInfo media={media} />

            <div className="MovieDetails__actions">
              <div className="MovieDetails__actions__buttons">
                <PlayButton media={media} enabled={!actionDisabled} />
                <CompareButton media={media} enabled={!actionDisabled} />
                <DownloadSampleButton
                  media={media}
                  metadata={metadata}
                  enabled={!actionDisabled}
                />
              </div>
              {actionDisabled && (
                <div className="MovieDetails__actions__info">
                  {t(
                    "ASSET_DETAILS_DISABLED_ACTIONS",
                    "Actions will be available after finishing processing media file."
                  )}
                </div>
              )}
            </div>

            <div
              className="MovieDetails__description"
              dangerouslySetInnerHTML={{ __html: media.LongDescription || "" }}
            ></div>
          </div>
        </>
      )}
    </MediaDetailsScreen>
  );
};
