/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { withTranslation } from "react-i18next";
import { ListComponentItemRound as ListComponentItemRoundDefinition } from "./ListComponentItemRound";

export const ListComponentItemRound = withTranslation()(
  ListComponentItemRoundDefinition
);
