/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useCallback, useContext, useState } from "react";
import { MediaButton } from "components/MediaButton";
import RcTooltip from "rc-tooltip";

import cx from "classnames";
import { ReactComponent as VolumeIcon } from "../../../resources/player/volume.svg";
import { ReactComponent as VolumeMuteIcon } from "../../../resources/player/volume-mute.svg";
import "./VolumeButton.scss";
import { Input } from "components/Input";
import { AudioContext } from "context";

interface IVolumeButtonProps {
  className?: string;
}

export const VolumeButton = ({ className }: IVolumeButtonProps) => {
  const { volume, setVolume } = useContext(AudioContext);
  const [prevVolume, setPrevVolume] = useState(volume);
  const onVolumeChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setVolume?.(+evt.target.value);
    },
    [setVolume]
  );

  const handleIconClick = () => {
    setPrevVolume(volume);
    if (volume === 0) {
      setVolume?.(prevVolume || 100);
      return;
    }
    setVolume?.(0);
  };

  return (
    <RcTooltip
      animation="zoom"
      trigger="hover"
      placement="top"
      overlayClassName="VolumeButton__overlay"
      overlay={
        <Input
          type="range"
          value={volume}
          onChange={onVolumeChange}
          className="VolumeButton__slider"
        />
      }
    >
      <MediaButton
        onClick={handleIconClick}
        className={cx("VolumeButton", className)}
        icon={volume ? <VolumeIcon /> : <VolumeMuteIcon />}
        variant="transparent"
      />
    </RcTooltip>
  );
};
