/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  dispatch,
  IAppState,
  IConfigurationModel,
  UserStore,
} from "@bms/common";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { useIsMobile } from "../Responsive";

import { DesktopHeader } from "./components/DesktopHeader";
import { MobileHeader } from "./components/MobileHeader";

export enum AppHeaderVisibility {
  Logo = 0,
  Menu = 1,
  Header = 2,
  UserInfo = 4,
  SignIn = 8,
  Default = 7,
}

export interface IAppHeaderProps {
  configuration?: IConfigurationModel;
  visibility?: AppHeaderVisibility;
}

export const AppHeader: React.FC<IAppHeaderProps> = ({
  configuration,
  visibility = AppHeaderVisibility.Default,
}: IAppHeaderProps) => {
  const authState = useSelector((state: IAppState) => state.auth);
  const isMobile = useIsMobile();

  const getUserWallet = useCallback(
    () => dispatch(UserStore.Actions.getUserWallet()),
    [dispatch]
  );

  useEffect(() => {
    getUserWallet();
  }, []);

  return (
    <>
      {isMobile ? (
        <MobileHeader
          configurationScreens={configuration?.Screens}
          visibility={visibility}
          auth={authState}
        />
      ) : (
        <DesktopHeader
          configurationScreens={configuration?.Screens}
          visibility={visibility}
          auth={authState}
        />
      )}
    </>
  );
};
