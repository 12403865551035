/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ImageHelper,
  MediaType,
  RouteHelper,
  useMediaProgress,
  useTheme,
} from "@bms/common";
import cx from "classnames";
import Color from "color";
import React, { useMemo, useState } from "react";
import { useTranslation, WithTranslation } from "react-i18next";

import {
  ImageWithPlaceholder,
  MediaEpisodeInfo,
  MediaInfo,
  PlayIconButton,
  ProgressBar,
} from "components";
import { MediaStartInfo } from "components/Media/MediaStartInfo";
import { useMediaContentStatus } from "hooks/useMediaContentStatus";
import InfoCircleIcon from "resources/icons/info-circle.svg";
import ProcessingIcon from "resources/icons/notification/info.svg";
import resources from "resources/list";

import { IListComponentItemProps } from "../../models";

import { ListComponentItemFrameSkeleton } from "./ListComponentItemFrameSkeleton";

import "./ListComponentItemFrame.scss";

export interface IListComponentItemFrameProps
  extends IListComponentItemProps,
    WithTranslation {}

export const ListComponentItemFrame = (props: IListComponentItemFrameProps) => {
  const { media, readOnly, isPlaceholder, withProgress, parentItems } = props;
  const [isShowInfo, setShowInfo] = useState(false);

  const progress = useMediaProgress(media);

  const { isStatusAvailable, isContentReady } = useMediaContentStatus(media);
  const { themeProvider } = useTheme();
  const { t } = useTranslation();

  const frameImageUrl = useMemo(
    () => ImageHelper.getFrameImageUrl(media?.Images),
    [media?.Images]
  );

  const title = useMemo(() => media?.Title, [media?.Title]);

  const hideDescription = Boolean(parentItems && parentItems > 4);

  const showInfo = () => setShowInfo(true);
  const hideInfo = () => setShowInfo(false);

  if (!media || isPlaceholder) {
    return <ListComponentItemFrameSkeleton />;
  }

  const isAdvertisement = media.MediaTypeCode === MediaType.Advertisement;
  const isArticle = media.MediaTypeCode === MediaType.Article;
  const showProcessing = isStatusAvailable && !isContentReady;
  const showOnHoverOverlay = isArticle || isAdvertisement || showProcessing;

  return (
    <div className="ListComponentItemFrame" onMouseLeave={hideInfo}>
      <div className="ListComponentItemFrame__container">
        <div
          className="ListComponentItemFrame__image-container"
          onClick={() => RouteHelper.goToDetails(media)}
        >
          <ImageWithPlaceholder
            imageSrc={frameImageUrl}
            imageContainerClassName="ListComponentItemFrame__image"
            placeholderSrc={resources.framePlaceholder}
          />
        </div>

        {showOnHoverOverlay && (
          <div className="ListComponentItemFrame__overlay" />
        )}

        {!readOnly && !showProcessing && (
          <PlayIconButton
            media={media}
            className="ListComponentItemFrame__play-button"
            style={isShowInfo ? { opacity: 0 } : {}}
          />
        )}

        {showProcessing && (
          <div className="ListComponentItemFrame__processing">
            <ProcessingIcon />
            <div className="ListComponentItemFrame__processing-title">
              {t("ASSET_PROCESSING_STATUS", "Processing...")}
            </div>
          </div>
        )}

        {!isAdvertisement && (
          <div
            className={cx("ListComponentItemFrame__content", {
              "ListComponentItemFrame__content--hidden": isShowInfo,
            })}
          >
            <div
              className="ListComponentItemFrame__title-container"
              style={{
                background: `linear-gradient(
                  to bottom,
                  ${Color(themeProvider.getColor("AppDetailsBackgroundColor"))
                    .alpha(0)
                    .string()},
                  ${Color(themeProvider.getColor("AppDetailsBackgroundColor"))
                    .alpha(0.93)
                    .string()}
                )`,
              }}
            >
              <MediaStartInfo media={media} />
              <MediaEpisodeInfo media={media} />

              <div className="ListComponentItemFrame__title-wrapper">
                <div className="ListComponentItemFrame__title">{title}</div>

                {!readOnly && !showProcessing && (
                  <i
                    className="ListComponentItemFrame__info-trigger"
                    onClick={showInfo}
                  >
                    <InfoCircleIcon />
                  </i>
                )}
              </div>
            </div>

            {withProgress && progress != -1 && (
              <ProgressBar progress={progress} />
            )}
          </div>
        )}

        {media && !showProcessing && (
          <div
            className={cx("ListComponentItemFrame__info", {
              "ListComponentItemFrame__info--visible": isShowInfo,
            })}
            style={{
              pointerEvents: isShowInfo ? "auto" : "none",
            }}
            onMouseLeave={hideInfo}
          >
            <MediaInfo media={media} hideDescription={hideDescription} />
          </div>
        )}
      </div>
    </div>
  );
};
