/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { InfoDetailsScreenCode, IUserConsentModel } from "@bms/common";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface ConsentProps {
  consent: IUserConsentModel;
}

export const Consent = ({ consent }: ConsentProps) => {
  const { t } = useTranslation();
  const consetTitle =
    consent.ConsentCode === InfoDetailsScreenCode.COOKIES
      ? t("COOKIES_TITLE", "Cookies")
      : t(`${consent.ConsentNameTranslationKey}`, `${consent.ConsentName}`);

  return (
    <>
      {consent.ConsentRequired ? (
        <span className="RequiredConsent">*</span>
      ) : (
        ""
      )}
      {consent.ConsentContentUrl ? (
        <Link target="_blank" to={{ pathname: consent.ConsentContentUrl }}>
          <span className="link underline">{consetTitle}</span>
        </Link>
      ) : (
        <span className="link">{consetTitle}</span>
      )}
    </>
  );
};
