/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const ARROW_DOWN = "ArrowDown";
export const ARROW_UP = "ArrowUp";
export const ARROW_LEFT = "ArrowLeft";
export const ARROW_RIGHT = "ArrowRight";
export const HORIZONTAL = "HORIZONTAL";
export const VERTICAL = "VERTICAL";
export const ENTER_UP = "EnterUp";
