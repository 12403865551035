/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetType,
  CellType,
  ComponentType,
  IListComponentModel,
  MediaImageType,
  Orientation,
  ThemeContext,
  useGetMedia,
  useIsLoggedIn,
  useMediaByIdSelector,
} from "@bms/common";
import { EpisodesList } from "components/EpisodesList/EpisodesList";
import { ImageRatio, ImageWithRatio } from "components/ImageWithRatio";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ClipboardCopyButton,
  ListComponent,
  MediaAdditionalInfo,
  MediaPeople,
  PlayButton,
  TrailerButton,
} from "../../components";
import { MyListButton } from "../../components/MyListButton";
import {
  MediaDetailsScreen,
  useMediaDetailsLoadingSelector,
  useMediaDetailsMediaSelector,
} from "../MediaDetailsScreen";
import { ReactComponent as PodcastFallback } from "resources/default-podcast.svg";
import "./PodcastDetailsScreen.scss";
import { IMediaModel } from "@bms/common";

export const PodcastDetailsScreen = () => {
  const { t } = useTranslation();
  const { themeProvider } = useContext(ThemeContext);
  const isLoggedIn = useIsLoggedIn();
  const media = useMediaDetailsMediaSelector();
  const loading = useMediaDetailsLoadingSelector();
  const parentMedia = useMediaByIdSelector(media?.ParentMediaId ?? -1);
  const getParentMedia = useGetMedia();

  useEffect(() => {
    if (media?.ParentMediaId && !parentMedia.Media) {
      getParentMedia({
        MediaId: media?.ParentMediaId,
      });
    }
  }, [media]);

  const renderRecommendationsList = () => {
    if ((media?.SimilarMedia && media?.SimilarMedia.length > 0) || loading) {
      const recommendationsList: IListComponentModel = {
        ComponentTypeCode: ComponentType.List,
        MediaList: media?.SimilarMedia,
        CellType: CellType.Square,
        Orientation: Orientation.Horizontal,
        VisibleItemsCount: 4,
        Title: t("COMMON__MORE_LIKE_THIS"),
      };

      return <ListComponent component={recommendationsList} />;
    }

    return null;
  };

  const renderAlbumElementsHorizontalList = () => {
    if (
      (media?.MediaTypeCode === AssetType.Podcast && parentMedia.Media) ||
      loading
    ) {
      const albumElements: IListComponentModel = {
        ComponentTypeCode: ComponentType.List,
        MediaList: parentMedia.Media?.Media?.filter(
          (row: IMediaModel) => row.Id !== media?.Id
        ),
        CellType: CellType.Square,
        Orientation: Orientation.Horizontal,
        VisibleItemsCount: 4,
        Title: t("COMMON__MORE_FROM") + ` ${parentMedia.Media?.Title}`,
      };

      return <ListComponent component={albumElements} />;
    }
    return null;
  };

  const imageSrc = useMemo(() => {
    let src: string | undefined;
    if (media?.Images) {
      const squareImg = media?.Images?.find(
        (it) => it.ImageTypeCode === MediaImageType.Square
      )?.Url;
      if (squareImg) {
        src = squareImg;
      } else if (media?.Images && media?.Images.length > 0) {
        src = media?.Images[0].Url;
      }
    }
    return src;
  }, [media?.Images]);

  const renderImage = useMemo(() => {
    if (!imageSrc) {
      return (
        <PodcastFallback
          className="PodcastDetails__content--image"
          style={{ color: themeProvider.getColor("AppPrimaryTextColor") }}
        />
      );
    }
    return (
      <ImageWithRatio
        ratio={ImageRatio["1:1"]}
        disableAutoWH
        className="PodcastDetails__content--image"
        imageSrc={imageSrc}
        isSpinner={false}
      />
    );
  }, [imageSrc]);

  return (
    <MediaDetailsScreen className="PodcastDetails">
      {media && (
        <>
          <div className="PodcastDetails__info">
            <div className="PodcastDetails__content">
              <div className="PodcastDetails__image">{renderImage}</div>
              <div className="PodcastDetails__content--context">
                <div className="PodcastDetails__title">{media.Title}</div>
                <MediaAdditionalInfo media={media} />
              </div>
            </div>

            <div className="PodcastDetails__actions">
              {media?.MediaTypeCode === AssetType.Podcast && (
                <PlayButton media={media} />
              )}
              {isLoggedIn && <MyListButton media={media} />}
              <TrailerButton media={media} loading={loading} />
              <ClipboardCopyButton />
            </div>

            <div
              className="PodcastDetails__description"
              dangerouslySetInnerHTML={{
                __html:
                  media.LongDescription ||
                  media.Description ||
                  media.ShortDescription ||
                  "",
              }}
            ></div>

            <MediaPeople people={media.People} loading={loading} />
          </div>

          <EpisodesList episodeList={media.Media} media={media} />
          {renderAlbumElementsHorizontalList()}
          {renderRecommendationsList()}
        </>
      )}
    </MediaDetailsScreen>
  );
};
