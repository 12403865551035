/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AssetImageType, IFormValues } from "@bms/common";
import { useForm } from "rc-field-form";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "components";

import { WizardFooter } from "../../Component";
import { useCreatorMedia } from "../../CreatorMediaContext";
import { useWizard } from "../../WizardContext";

import { Image } from "./Image";

import "./Images.scss";

export const Images = () => {
  const { asset, refresh, insertImage } = useCreatorMedia();
  const { previousStep, nextStep, setLoadingState } = useWizard();

  const [form] = useForm();
  const { t } = useTranslation();

  const onPrevious = useCallback(async () => {
    setLoadingState(true);

    await refresh();

    previousStep();
    setLoadingState(false);
  }, [previousStep, refresh, setLoadingState]);

  const onFormFinish = useCallback(
    async (values: IFormValues) => {
      setLoadingState(true);

      await insertImage(values.Frame, AssetImageType.Frame, asset);
      await insertImage(values.Background, AssetImageType.Background, asset);

      await refresh();
      nextStep();
      setLoadingState(false);
    },
    [asset, insertImage, nextStep, refresh, setLoadingState]
  );

  const frameImageLabel = t(
    "VIDEO_EMBEDDER_WIZARD__ASSET_FRAME_IMAGE",
    "Frame image"
  );

  const backgroundImageLabel = t(
    "VIDEO_EMBEDDER_WIZARD__ASSET_BACKGROUND_IMAGE",
    "Background image"
  );

  return (
    <Form onFinish={onFormFinish} form={form}>
      <div className="Images">
        <div className="Images__Container">
          <div className="Images__Container__Item">
            <Image
              type={AssetImageType.Frame}
              fieldName="Frame"
              label={frameImageLabel}
            />
          </div>
          <div className="Images__Container__Item">
            <Image
              type={AssetImageType.Background}
              fieldName="Background"
              label={backgroundImageLabel}
            />
          </div>
        </div>
      </div>
      <WizardFooter onPrevious={onPrevious} nextButtonType="submit" />
    </Form>
  );
};
