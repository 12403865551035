/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { ForgotPasswordSuccess } from "../../../components/Forms";
import { AuthScreen, IAuthScreenProps } from "../AuthScreen";
import "../AuthScreen.scss";

type IForgotPasswordSuccessScreenProps = IAuthScreenProps;

export class ForgotPasswordSuccessScreen extends React.PureComponent<IForgotPasswordSuccessScreenProps> {
  render() {
    return (
      <AuthScreen {...this.props}>
        <ForgotPasswordSuccess />
      </AuthScreen>
    );
  }
}
