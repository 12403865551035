/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import RcSelect, { Option, SelectProps as RcSelectProps } from "rc-select";
import { TimeHelper, useConfigurationSelector } from "@bms/common";
import { useTranslation } from "react-i18next";
import ChevronDown from "../../resources/icons/chevron-down.svg";
import "./LanguageSelect.scss";

export const LanguageSelect = ({}: RcSelectProps<string>) => {
  const configuration = useConfigurationSelector();
  const { i18n } = useTranslation();

  return (
    <RcSelect<string>
      prefixCls="LanguageSelect"
      inputIcon={() => <ChevronDown />}
      menuItemSelectedIcon={() => null}
      dropdownAlign={{
        offset: [0, -1],
      }}
      value={i18n.language}
      defaultValue={i18n.language}
      onChange={async (value) => {
        await TimeHelper.setLanguage(value);
        await i18n.changeLanguage(value);
      }}
    >
      {configuration?.Languages?.map((lang) => (
        <Option value={lang.Code} key={lang.Code}>
          {lang.Name}
        </Option>
      ))}
    </RcSelect>
  );
};
