/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  buildRequiredRule,
  buildSampleDownloadDurationRule,
  buildSampleDownloadStartTimeRule,
  DataProvider,
  IErrorModel,
  IVestigitAttackSampleParams,
  IVestigitContentMetadataModel,
  IVestigitDemoUserModel,
  IVestigitPaginatedAttacksModel,
  useDataLoader,
} from "@bms/common";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  Form,
  FormButton,
  Input,
  LabelField,
  Option,
  Select,
} from "components";

import "./DownloadSampleForm.scss";

interface DownloadSampleFormProps {
  metadata: IVestigitContentMetadataModel;
  isDownloading: boolean;
  onDownload: (params: IVestigitAttackSampleParams) => void;
}

interface DownloadSampleFormValues {
  attackId: number;
  profileId: number;
  startTime: string;
  duration: string;
  userId: number;
}

export const DownloadSampleForm = (props: DownloadSampleFormProps) => {
  const { metadata, onDownload, isDownloading = false } = props;

  const maxSampleDuration = Number(metadata.metadata.duration);

  const { t } = useTranslation();

  const { data: attacks } = useDataLoader<
    IVestigitPaginatedAttacksModel,
    IErrorModel
  >({
    loader: () =>
      DataProvider.vestigitGetAttacks().then((data) => ({
        ok: true,
        data,
      })),
    deps: [],
  });

  const { data: demoUsers } = useDataLoader<
    IVestigitDemoUserModel[],
    IErrorModel
  >({
    loader: () =>
      DataProvider.vestigitGetDemoUsers().then((data) => ({
        ok: true,
        data,
      })),
    deps: [],
  });

  const onFinish = (values: DownloadSampleFormValues) =>
    onDownload({
      aid: values.attackId,
      impersonate_user_id: values.userId,
      stream_index: values.profileId,
      start_time: Math.max(0, Number(values.startTime)),
      duration: Math.max(0, Number(values.duration)),
      url: metadata.manifest_url,
    });

  const renderUserField = () => {
    return (
      <LabelField
        name="userId"
        errorClass={""}
        rules={[buildRequiredRule("number")]}
        label={t("DOWNLOAD_SAMPLE_USER_LABEL", "User")}
        requiredIcon
      >
        <Select<number>
          placeholder={t(
            "DOWNLOAD_SAMPLE_USER_SELECT_PLACEHOLDER",
            "Select user"
          )}
        >
          {demoUsers?.map((user) => (
            <Option value={user.uid} key={user.uid}>
              {user.name}
            </Option>
          ))}
        </Select>
      </LabelField>
    );
  };

  const renderProfileField = () => {
    return (
      <LabelField
        name="profileId"
        errorClass={""}
        rules={[buildRequiredRule("number")]}
        label={t("DOWNLOAD_SAMPLE_PROFILE_LABEL", "Profile")}
        requiredIcon
      >
        <Select<number>
          placeholder={t(
            "DOWNLOAD_SAMPLE_PROFILE_PLACEHOLDER",
            "Select profile"
          )}
        >
          {metadata?.streams.map((stream) => (
            <Option value={stream.stream_index} key={stream.stream_index}>
              {stream.name}
            </Option>
          ))}
        </Select>
      </LabelField>
    );
  };

  const renderAttackField = () => {
    return (
      <LabelField
        name="attackId"
        errorClass={""}
        rules={[buildRequiredRule("number")]}
        label={t("DOWNLOAD_SAMPLE_ATTACK_LABEL", "Attack")}
        requiredIcon
      >
        <Select<number>
          placeholder={t("DOWNLOAD_SAMPLE_ATTACK_PLACEHOLDER", "Select attack")}
        >
          {attacks?.results.map((attack) => (
            <Option value={attack.aid} key={attack.aid}>
              {attack.name}
            </Option>
          ))}
        </Select>
      </LabelField>
    );
  };

  const renderStartTimeField = () => {
    return (
      <LabelField
        name="startTime"
        rules={[
          buildRequiredRule(),
          buildSampleDownloadStartTimeRule(maxSampleDuration),
        ]}
        label={t("DOWNLOAD_SAMPLE_START_TIME_LABEL", "Start time (seconds)")}
        requiredIcon
      >
        <Input
          className="FormInput"
          type="number"
          min="0"
          placeholder={t(
            "DOWNLOAD_SAMPLE_START_TIME_PLACEHOLDER",
            "Start time in seconds"
          )}
        />
      </LabelField>
    );
  };

  const renderDurationField = () => {
    return (
      <LabelField
        name="duration"
        rules={[
          buildRequiredRule(),
          (context) =>
            buildSampleDownloadDurationRule(
              context,
              "startTime",
              maxSampleDuration
            ),
        ]}
        label={t("DOWNLOAD_SAMPLE_DURATION_LABEL", "Duration (seconds)")}
        requiredIcon
      >
        <Input
          className="FormInput"
          type="number"
          min="0"
          placeholder={t(
            "DOWNLOAD_SAMPLE_DURATION_PLACEHOLDER",
            "Duration in seconds"
          )}
        />
      </LabelField>
    );
  };

  return (
    <Form name="DownloadSampleForm" onFinish={onFinish}>
      <div className="DownloadSampleForm">
        {renderUserField()}
        {renderProfileField()}
        {renderAttackField()}
        {renderStartTimeField()}
        {renderDurationField()}
        <FormButton loading={isDownloading}>
          {t("COMMON__BUTTON_DOWNLOAD", "Download")}
        </FormButton>
      </div>
    </Form>
  );
};
