/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IAppState,
  IMediaListMediaOptionsModel,
  MediaStore,
} from "@bms/common";
import { push } from "connected-react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  EpgComponent as EpgComponentDefinition,
  IEpgComponentDispatchProps,
  IEpgComponentOwnProps,
  IEpgComponentStateProps,
} from "./EpgComponent";

const mapStateToProps = (state: IAppState, props?: IEpgComponentOwnProps) => {
  const mediaId = props?.component.SourceId;

  return {
    source: mediaId ? state.media.mediaList?.[mediaId] : undefined,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeRoute: (route: string) => dispatch(push(route)),
  getMediaListForEpgCancel: () => dispatch({ type: "nothing " }),
  getMediaListForEpg: (
    sourceId: number,
    mediaOptions: IMediaListMediaOptionsModel
  ) => {
    return dispatch(
      MediaStore.Actions.getMediaListForEpg({
        MediaListId: sourceId,
        IncludeCategories: false,
        IncludeImages: false,
        IncludeMedia: false,
        PageNumber: 1,
        PageSize: 100,
        MediaOptions: mediaOptions,
      })
    );
  },
});

export const EpgComponent = connect<
  IEpgComponentStateProps,
  IEpgComponentDispatchProps,
  IEpgComponentOwnProps,
  IAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EpgComponentDefinition));
