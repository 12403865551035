/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ImageHelper,
  MediaType,
  useMediaProgress,
  useTheme,
} from "@bms/common";
import cx from "classnames";
import Color from "color";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { WithTranslation } from "react-i18next";

import {
  ImageWithPlaceholder,
  MediaEpisodeInfo,
  MediaInfo,
  MediaStartInfo,
  PlayIconButton,
  ProgressBar,
} from "components";
import InfoCircleIcon from "resources/icons/info-circle.svg";
import resources from "resources/list";

import { IListComponentItemProps } from "../../models";

import { ListComponentItemSquareSkeleton } from "./ListComponentItemSquareSkeleton";

import "./ListComponentItemSquare.scss";

export interface IListComponentItemSquareProps
  extends IListComponentItemProps,
    WithTranslation {}

export const ListComponentItemSquare = (
  props: IListComponentItemSquareProps
) => {
  const { media, readOnly, isPlaceholder, withProgress, parentItems } = props;

  const [isShowInfo, setShowInfo] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { themeProvider } = useTheme();
  const progress = useMediaProgress(media);

  const squareImageUrl = useMemo(
    () => ImageHelper.getSquareImageUrl(media?.Images),
    [media?.Images]
  );

  const title = useMemo(() => media?.Title, [media?.Title]);
  const isMoreThanOneWord = title ? /\s/.test(title) : false;

  const hideDescription = Boolean(parentItems && parentItems > 6);

  const hideInfo = useCallback(() => setShowInfo(false), []);

  const onInfoIconClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      setShowInfo(true);
    },
    []
  );

  if (!media || isPlaceholder) {
    return <ListComponentItemSquareSkeleton />;
  }

  const isAdvertisement = media.MediaTypeCode === MediaType.Advertisement;
  const isArticle = media.MediaTypeCode === MediaType.Article;
  const showOnHoverOverlay = isArticle || isAdvertisement;

  return (
    <div className="ListComponentItemSquare" onMouseLeave={hideInfo}>
      <div
        ref={containerRef}
        className={cx("ListComponentItemSquare__container")}
      >
        <div className="ListComponentItemSquare__image-container">
          <ImageWithPlaceholder
            imageSrc={squareImageUrl}
            imageContainerClassName="ListComponentItemSquare__image"
            placeholderSrc={resources.framePlaceholder}
          />
        </div>

        {showOnHoverOverlay && (
          <div className="ListComponentItemSquare__overlay" />
        )}

        {!readOnly && (
          <PlayIconButton
            media={media}
            className="ListComponentItemSquare__play-button"
            style={isShowInfo ? { opacity: 0 } : {}}
          />
        )}

        {!isAdvertisement && (
          <div
            className={cx("ListComponentItemSquare__content", {
              "ListComponentItemSquare__content--hidden": isShowInfo,
            })}
          >
            <div
              className="ListComponentItemSquare__title-container"
              style={{
                background: `linear-gradient(
                  to bottom,
                  ${Color(themeProvider.getColor("AppDetailsBackgroundColor"))
                    .alpha(0)
                    .string()},
                  ${Color(themeProvider.getColor("AppDetailsBackgroundColor"))
                    .alpha(0.93)
                    .string()}
                )`,
              }}
            >
              <MediaStartInfo media={media} />
              <MediaEpisodeInfo media={media} />

              <div className="ListComponentItemSquare__title-wrapper">
                <div
                  className="ListComponentItemSquare__title"
                  style={
                    !isMoreThanOneWord
                      ? {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "100%",
                          display: "block",
                        }
                      : {}
                  }
                >
                  {title}
                </div>

                {!readOnly && (
                  <i
                    className="ListComponentItemSquare__info-trigger"
                    onClick={onInfoIconClick}
                  >
                    <InfoCircleIcon />
                  </i>
                )}
              </div>
            </div>

            {withProgress && progress != -1 && (
              <ProgressBar progress={progress} />
            )}
          </div>
        )}

        {media && (
          <div
            className={cx("ListComponentItemSquare__info", {
              "ListComponentItemSquare__info--visible": isShowInfo,
            })}
            style={{
              pointerEvents: isShowInfo ? "auto" : "none",
            }}
            onMouseLeave={hideInfo}
          >
            <MediaInfo media={media} hideDescription={hideDescription} />
          </div>
        )}
      </div>
    </div>
  );
};
