/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { RouteHelper } from "@bms/common";
import React, { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Message } from "../Message";

import { useCreatorMedia } from "./CreatorMediaContext";
import { useAllowOnlyAssetCreator } from "./useAllowOnlyAssetCreator";

interface WizardWrapperProps {
  children: ReactNode;
}

export const WizardWrapper = (props: WizardWrapperProps) => {
  const { children } = props;

  const { t } = useTranslation();
  const { asset } = useCreatorMedia();

  const onNotAllowedAccess = useCallback(() => {
    Message.error(
      t(
        "VIDEO_EMBEDDER_WIZARD_INVALID_CREATOR",
        "You do not have permission to edit selected asset."
      )
    );

    RouteHelper.goToHome();
  }, [t]);

  useAllowOnlyAssetCreator({ asset, onNotAllowedAccess });

  return <>{children}</>;
};
