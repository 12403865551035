/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

type BaseAction = {
  guid: string;
};

type RegisterAction = {
  type: "register";
  title: string;
};

type ProgressAction = {
  type: "progress";
  percent: number;
};

type UnregisterAction = {
  type: "unregister";
};

type Action = BaseAction & (RegisterAction | UnregisterAction | ProgressAction);

export type UploadAssistantFileMetadata = {
  guid: string;
  title: string;
  percent: number;
};

type State = {
  files: UploadAssistantFileMetadata[];
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "register":
      return {
        ...state,
        files: [
          ...state.files,
          { guid: action.guid, title: action.title, percent: 0 },
        ],
      };
    case "unregister":
      return {
        ...state,
        files: state.files.filter((file) => file.guid !== action.guid),
      };
    case "progress":
      return {
        ...state,
        files: state.files.map((file) =>
          file.guid === action.guid
            ? { ...file, percent: action.percent }
            : file
        ),
      };
    default:
      return state;
  }
};
