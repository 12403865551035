/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  DataProvider,
  ICurrencyModel,
  IUserSettingsModel,
  IErrorModel,
  IFormValues,
  ILanguageModel,
  useConfigurationSelector,
  useDataLoader,
  useGetUserId,
  TimeHelper,
} from "@bms/common";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Message } from "../../Message";
import { Select, Option } from "../../Select";
import { LabelField, Form, FormButton } from "../../Form";

import "./SettingsPreferences.scss";
import { LoaderSpinner } from "../../LoaderSpinner";

export const SettingsPreferences = () => {
  const { i18n, t } = useTranslation();
  const configuration = useConfigurationSelector();
  const [isProcessing, setIsProcessing] = useState(false);
  const userId = useGetUserId();

  const { data: userSettings, loading: isUserSettingsLoading } = useDataLoader<
    IUserSettingsModel,
    IErrorModel
  >({
    loader: () =>
      DataProvider.getUserSettings()
        .then((response: IUserSettingsModel) => {
          return {
            ok: true,
            data: response,
            error: {},
          };
        })
        .catch((error) => {
          Message.error(t("SETTINGS_PREFERENCES__GET_USER_SETTINGS_FAILED"));
          return {
            ok: false,
            error: error,
          };
        }),
    deps: [isProcessing],
  });

  const { data: currencies, loading: isCurrenciesLoading } = useDataLoader<
    ICurrencyModel[],
    IErrorModel
  >({
    loader: () =>
      DataProvider.selectCurrency()
        .then((response: ICurrencyModel[]) => {
          return {
            ok: true,
            data: response,
            error: {},
          };
        })
        .catch((error) => {
          Message.error(t("SETTINGS_PREFERENCES__GET_CURRENCIES_FAILED"));
          return {
            ok: false,
            error: error,
          };
        }),
    deps: [],
  });

  const onFinish = (values: IFormValues) => {
    const language = configuration?.Languages?.find(
      (language) => language.Code == values.LanguageCode
    );

    const payload = {
      RowVersion: userSettings?.RowVersion,
      RecordStatus: userSettings?.RecordStatus,
      UserId: userId!,
      LanguageId: language?.Id,
      CurrencyId: values.CurrencyId,
    };

    setIsProcessing(true);

    DataProvider.updateUserSettings(payload)
      .then(() => {
        setIsProcessing(false);
        Message.success(t("MESSAGE__SAVE_SUCCESS"));
      })
      .catch(() => {
        setIsProcessing(false);
        Message.error(t("MESSAGE__SAVE_ERROR"));
      });
  };

  const setLanguage = async (value: string) => {
    await TimeHelper.setLanguage(value);
    await i18n.changeLanguage(value);
  };

  if (isCurrenciesLoading) {
    const spinnerStyle: React.CSSProperties = {
      marginLeft: "25%",
    };
    return <LoaderSpinner style={spinnerStyle} />;
  }

  return (
    <div className="SettingsPreferences">
      <Form name="UserSettings" onFinish={onFinish}>
        <LabelField
          name="CurrencyId"
          label={t("SETTINGS_PREFERENCES__CURRENCY_PREFERENCE")}
          initialValue={userSettings?.CurrencyId || currencies![0].Id}
        >
          <Select<number>
            placeholder={t("SETTINGS_PREFERENCES__CURRENCY_PREFERENCE")}
          >
            {currencies!.map((currency, index) => {
              return (
                <Option value={currency.Id} key={index}>
                  {currency.Code}
                </Option>
              );
            })}
          </Select>
        </LabelField>
        {configuration?.Languages && (
          <LabelField
            name="LanguageCode"
            label={t("SETTINGS_PREFERENCES__LANGUAGE")}
            initialValue={i18n.language}
          >
            <Select<string>
              placeholder={t("SETTINGS_PREFERENCES__LANGUAGE")}
              onSelect={(value) => {
                setLanguage(value);
              }}
            >
              {configuration.Languages.map(
                (language: ILanguageModel, index) => {
                  return (
                    <Option value={language.Code} key={index}>
                      {language.Name}
                    </Option>
                  );
                }
              )}
            </Select>
          </LabelField>
        )}
        <FormButton loading={isProcessing} disabled={isUserSettingsLoading}>
          {t("CONTACTS_FORM__SUBMIT")}
        </FormButton>
      </Form>
    </div>
  );
};
