/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAppState, useSelector } from "@bms/common";
import React from "react";

import { AppFooter, AppHeader } from "components";
import { DetectorProvider } from "context";

import { DetectionResults, SampleUploader } from "./components";

import "./DetectorScreen.scss";

export const DetectorScreen = () => {
  const configuration = useSelector(
    (state: IAppState) => state.configuration.configuration
  );

  return (
    <div className="DetectorScreen">
      <AppHeader configuration={configuration} />
      <DetectorProvider>
        <div className="DetectorScreen__Container">
          <div className="DetectorScreen__Container__Uploader">
            <SampleUploader />
          </div>
          <div className="DetectorScreen__Container__Results">
            <DetectionResults />
          </div>
        </div>
      </DetectorProvider>
      <AppFooter />
    </div>
  );
};
