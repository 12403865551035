/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React from "react";
import {
  useDataLoader,
  IErrorModel,
  DataProvider,
  IAssetPurchasePeriodTypeModel,
  ICurrencyModel,
  IAssetPriceModel,
  IFormValues,
} from "@bms/common";
import {
  AssetPaymentFieldList,
  PriceValue,
} from "components/AssetPaymentFieldList";
import { FormInstance } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { AssetSubscriptionsPills } from "../AssetSubscriptionsPills/AssetSubscriptionsPills";
import { Message } from "components";

interface IAssetPricingProps {
  catchupId?: number;
  initialPrices: IAssetPriceModel[];
  form: FormInstance<IFormValues>;
  errors: React.ReactElement[];
}

export const AssetPricing = ({
  catchupId,
  initialPrices,
  form,
  errors,
}: IAssetPricingProps) => {
  const { t } = useTranslation();

  const { data: purchaseTypes } = useDataLoader<
    IAssetPurchasePeriodTypeModel[],
    IErrorModel
  >({
    loader: () =>
      DataProvider.selectPurchasePeriodType()
        .then((response: IAssetPurchasePeriodTypeModel[]) => {
          return {
            ok: true,
            data: response,
            error: {},
          };
        })
        .catch((error) => {
          const { MessageKey } = error;

          if (Array.isArray(MessageKey)) {
            MessageKey.map((error) => Message.error(t(error)));
          } else {
            Message.error(t(MessageKey ?? "MODEL_VALIDATION_ERROR"));
          }

          return {
            ok: false,
            error: error,
          };
        }),
    deps: [],
  });

  const { data: currencies } = useDataLoader<ICurrencyModel[], IErrorModel>({
    loader: () =>
      DataProvider.selectCurrency()
        .then((response: ICurrencyModel[]) => {
          return {
            ok: true,
            data: response,
            error: {},
          };
        })
        .catch((error) => {
          const { MessageKey } = error;

          if (Array.isArray(MessageKey)) {
            MessageKey.map((error) => Message.error(t(error)));
          } else {
            Message.error(t(MessageKey ?? "MODEL_VALIDATION_ERROR"));
          }

          return {
            ok: false,
            error: error,
          };
        }),
    deps: [],
  });

  return (
    <>
      <AssetPaymentFieldList
        listName="assetPrices"
        label={t("MEDIA_CREATOR__CATCHUP__ADD_ASSET_PRICE", "Add asset price")}
        initialValue={initialPrices.map((price) => {
          return {
            Price: price.Price,
            AssetPurchasePeriodTypeCode: price.AssetPurchasePeriodTypeCode,
            CurrencyId: price.CurrencyId,
          } as PriceValue;
        })}
        {...{ purchaseTypes, currencies }}
      />

      <ul className="Error">
        {errors.map((error: React.ReactNode, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>

      <AssetSubscriptionsPills assetId={catchupId} form={form} />
    </>
  );
};
