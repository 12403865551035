/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ConfigurationHelper,
  ScreenType,
  useConfigurationSelector,
} from "@bms/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import {
  AppFooter,
  AppHeader,
  SettingsAbout,
  SettingsPreferences,
  SettingsMenu,
  SettingsMyOrders,
  SettingsProfile,
  SettingsTerms,
  useBreakpoints,
} from "../../components";
import { ROUTES } from "../../constants/routes";
import LeftArrowIcon from "../../resources/icons/left-arrow.svg";
import "./SettingsScreen.scss";

export interface ISettingsScreenRouteProps {
  submenuKey: string;
}

export const SettingsScreen = () => {
  const configuration = useConfigurationSelector();
  const history = useHistory();
  const { submenuKey } = useParams<ISettingsScreenRouteProps>();
  const { t } = useTranslation();
  const { isXS, isSM, isMD } = useBreakpoints();
  const isXSorSMorMD = isXS || isSM || isMD;

  const getSettingsView = () => {
    switch (submenuKey) {
      case ConfigurationHelper.getScreenPathByType(ScreenType.Profile):
        return <SettingsProfile />;
      case ConfigurationHelper.getScreenPathByType(ScreenType.Consents):
        return <SettingsTerms />;
      case ConfigurationHelper.getScreenPathByType(ScreenType.UserOrders):
        return <SettingsMyOrders />;
      case ConfigurationHelper.getScreenPathByType(ScreenType.Preferences):
        return <SettingsPreferences />;
      case ConfigurationHelper.getScreenPathByType(ScreenType.About):
        return <SettingsAbout />;
      default:
        return <div>TBD</div>;
    }
  };

  return (
    <div>
      <AppHeader configuration={configuration} />
      <section className="SettingsScreen">
        <div className="SettingsScreen__title_container">
          {isXSorSMorMD && submenuKey && (
            <i
              className="SettingsScreen__back"
              onClick={() => history.push(ROUTES.SETTINGS)}
            >
              <LeftArrowIcon />
            </i>
          )}
          <div className="SettingsScreen__title">
            {t("SETTINGS_TITLE", "Settings")}
          </div>
        </div>
        <div className="SettingsScreen__wrapper">
          {((isXSorSMorMD && !submenuKey) || !isXSorSMorMD) && (
            <div className="menu">
              <SettingsMenu />
            </div>
          )}
          {((isXSorSMorMD && submenuKey) || (!isXSorSMorMD && submenuKey)) && (
            <div className="view">{getSettingsView()}</div>
          )}
        </div>
      </section>
      <AppFooter />
    </div>
  );
};
