/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { useHistory } from "react-router";
import "./PageHeader.scss";
import ChevronDown from "../../resources/icons/chevron-down.svg";

export interface IPageHeaderProps {
  title?: string;
}

export const PageHeader: React.FC<IPageHeaderProps> = (props) => {
  const { title } = props;
  const history = useHistory();

  return (
    <div className="PageHeader">
      <span className="PageHeader__backIcon" onClick={() => history.goBack()}>
        <ChevronDown />
      </span>
      <span className="PageHeader__title">{title}</span>
    </div>
  );
};
