/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum FileUploadStatus {
  Uploading = "uploading",
  Failed = "failed",
  Completed = "completed",
  Aborted = "aborted",
}
