/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum ScreenType {
  Custom = "CUSTOM",
  ApplicationMenu = "APPLICATION_MENU",
  ApplicationHeader = "APPLICATION_HEADER",
  ApplicationFooter = "APPLICATION_FOOTER",
  Home = "HOME",
  Splash = "SPLASH",
  Login = "LOGIN",
  Help = "HELP",
  About = "ABOUT",
  Info = "INFO",
  MovieDetails = "MOVIE_DETAILS",
  SeriesDetails = "SERIES_DETAILS",
  PackageDetails = "PACKAGE_DETAILS",
  LiveDetails = "LIVE_DETAILS",
  Profile = "PROFILE",
  UserOrders = "USER_ORDERS",
  Consents = "CONSENTS",
  Preferences = "PREFERENCES",
  Social = "SOCIAL",
  Messages = "MESSAGES",
  Settings = "SETTINGS",
  SettingsMenu = "SETTINGS_MENU",
  Search = "SEARCH",
  Notifications = "NOTIFICATIONS",
  Events = "EVENTS",
  Friends = "FRIENDS",
  Community = "COMMUNITY",
  Explore = "EXPLORE",
  CreatorProfiles = "CREATOR_PROFILES",
  Intro = "INTRO",
  Catalog = "CATALOG",
  EPG = "EPG",
  MyList = "MY_LIST",
  Category = "CATEGORY",
  CategoryDetails = "CATEGORY_DETAILS",
  Regulations = "REGULATIONS",
  PrivacyPolicy = "PRIVACY_POLICY",
  MyConsents = "MY_CONSENTS",
  ReadMore = "READ_MORE",
  Downloaded = "DOWNLOADED",
  Register = "REGISTER",
  RegisterSuccess = "REGISTER_SUCCESS",
  Playlist = "PLAYLIST",
  Language = "LANGUAGE",
  TermsOfUse = "TERMS_OF_USE",
  PodcastDetails = "PODCAST_DETAILS",
}
